import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { Outcome, OutcomeDriveOrder, OutcomeActivityDataRequest, UpdateRevisedValuesToOutcomeActivitiesCommand, OutcomeDriveOrderWithActivities, OutcomeDriveOrderStateRequest, UpdateOutcomeOrderRevisedReferenceIdRequest } from '../models/outcome';
import { DateRange } from '@models/dateRange';

@Injectable()
export class OutcomeService {
  private outcomeOrdersUrl = environment.outcomeUrl + environment.apiVersionOutcome + '/outcomeorders';
  private outcomeDriveOrdersUrl = environment.outcomeUrl + environment.apiVersionOutcome + '/outcomedriveorders';
  private outcomeSubcontractorOrdersUrl = environment.outcomeUrl + environment.apiVersionOutcome + '/outcomesubcontractororders';

  constructor(private http: HttpClient) {
  }

  getOutcomeOrders(stateIds: number[] = [], operatorIds: number[] = [], dateRange?: DateRange): Observable<HttpResponse<Outcome[]>> {
    let query = `?stateEnum=${stateIds.toString()}`;
    query += `&operatorIds=${operatorIds.toString()}`;
    if (dateRange !== undefined) {
      query += '&StartDate=' + encodeURIComponent(moment(dateRange.fromDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
      query += '&EndDate=' + encodeURIComponent(moment(dateRange.toDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
    }
    return this.http.get<Outcome[]>(`${this.outcomeOrdersUrl}${query}`, { observe: 'response' });
  }

  getOutcomeOrder(id: number): Observable<HttpResponse<Outcome>> {
    return this.http.get<Outcome>(this.outcomeOrdersUrl + '/' + id.toString(), { observe: 'response' });
  }

  putOutcomeOrderReference(id: number, request: UpdateOutcomeOrderRevisedReferenceIdRequest): Observable<HttpResponse<any>> {
    const url = `${this.outcomeOrdersUrl}/${id}/revised-reference-id`;
    return this.http.put(url, request, { observe: 'response' });
  }

  getOutcomeSubcontractorOrders(stateIds: number[] = []): Observable<HttpResponse<Outcome[]>> {
    const query = `?stateEnum=${stateIds.toString()}`;
    return this.http.get<Outcome[]>(`${this.outcomeSubcontractorOrdersUrl}${query}`, { observe: 'response' });
  }

  getOutcomeSubcontractorOrder(id: number): Observable<HttpResponse<Outcome>> {
    return this.http.get<Outcome>(this.outcomeSubcontractorOrdersUrl + '/' + id.toString(), { observe: 'response' });
  }

  getOutcomeDriveOrdersForOrder(id: number): Observable<HttpResponse<OutcomeDriveOrder[]>> {
    return this.http.get<OutcomeDriveOrder[]>(this.outcomeDriveOrdersUrl
      + '?Id=' + id.toString(), { observe: 'response' });
  }

  getOutcomeDriveOrderWithActivities(id: number): Observable<HttpResponse<OutcomeDriveOrderWithActivities>> {
    return this.http.get<OutcomeDriveOrderWithActivities>(`${this.outcomeDriveOrdersUrl}/${id}/activities`,
      { observe: 'response' });
  }

  updateOutcomeActivitiesForDriveOrder(id: number, activities: Array<OutcomeActivityDataRequest>): Observable<HttpResponse<any>> {
    const url = `${this.outcomeDriveOrdersUrl}/${id}/activities/`;
    const command = { outcomeDriveOrderId: id, revisedActivities: activities };

    return this.http.post<UpdateRevisedValuesToOutcomeActivitiesCommand>(url, command, { observe: 'response' });

  }

  putOutcomeDriveOrderState(driveOrderId: number, outcomeDriveOrderStateRequest: OutcomeDriveOrderStateRequest): Observable<HttpResponse<any>> {
    const url = `${this.outcomeDriveOrdersUrl}/${driveOrderId}/state`;
    return this.http.put(url, outcomeDriveOrderStateRequest, { observe: 'response' });
  }

  approveSubcontractorDriveOrder(outcomeSubcontractorOrderId: number): Observable<HttpResponse<any>> {
    const url = `${this.outcomeSubcontractorOrdersUrl}/${outcomeSubcontractorOrderId}`;
    return this.http.put(url, null, { observe: 'response' });
  }

  approveOrderDriveOrder(outcomeOrderId: number): Observable<HttpResponse<any>> {
    const url = `${this.outcomeOrdersUrl}/${outcomeOrderId}`;
    return this.http.put(url, null, { observe: 'response' });
  }

  billOrderDriveOrder(outcomeOrderId: number): Observable<HttpResponse<any>> {
    const url = `${this.outcomeOrdersUrl}/${outcomeOrderId}/bills`;
    return this.http.put(url, null, { observe: 'response' });
  }

  billSubcontractorDriveOrder(outcomeSubcontractorOrderId: number): Observable<HttpResponse<any>> {
    const url = `${this.outcomeSubcontractorOrdersUrl}/${outcomeSubcontractorOrderId}/bills`;
    return this.http.put(url, null, { observe: 'response' });
  }

  getReportFile(operatorIds: number[], subcontractorIds: number[], startDate: Date, endDate: Date): Observable<HttpResponse<Blob>> {
    let url = environment.outcomeUrl + environment.apiVersionOutcome + '/reports/orderreportfile';
    url += '?operatorIds=' + operatorIds.join(',') + '&subcontractorIds=' + subcontractorIds.join(',') +
      '&startDate=' + encodeURIComponent(moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')) +
      '&endDate=' + encodeURIComponent(moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
    return this.http.get(url, { responseType: 'blob', observe: 'response' });
  }

  getOutboundReportFile(operatorIds: number[], startDate: Date, endDate: Date): Observable<HttpResponse<Blob>> {
    let url = environment.outcomeUrl + environment.apiVersionOutcome + '/reports/unboundOutcomeOrders';
    url += '?operatorIds=' + operatorIds.join(',') +
      '&startDate=' + encodeURIComponent(moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')) +
      '&endDate=' + encodeURIComponent(moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
    return this.http.get(url, { responseType: 'blob', observe: 'response' });
  }

  getCombinedReportFile(operatorIds: number[], subcontractorIds: number[], startDate: Date, endDate: Date, includeUnassignedDriveOrders: boolean): Observable<HttpResponse<Blob>> {
    let url = environment.outcomeUrl + environment.apiVersionOutcome + '/reports/combinedorderreportfile';
    url += '?operatorIds=' + operatorIds.join(',') + '&subcontractorIds=' + subcontractorIds.join(',') +
      '&startDate=' + encodeURIComponent(moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')) +
      '&endDate=' + encodeURIComponent(moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')) +
      '&includeUnassignedDriveOrders=' + includeUnassignedDriveOrders;
    return this.http.get(url, { responseType: 'blob', observe: 'response' });
  }
}
