<h1 class="my-5 mx-0 text-center" i18n="@@manage-subcontractors.title">Administration</h1>
<app-admin-menu></app-admin-menu>
<div class="container my-0">
  <div id="container">
    <app-table-subcontractors
      class="table-subcontractors"
      [operators]="operators"
      [subcontractors]="filteredSubcontractors"
      (saveEvent)="this.getSubcontractors()"
    ></app-table-subcontractors>
    <app-side-panel
      class="side-panel"
      [operators]="operators"
      (operatorsEvent)='setFilteredSubcontractors($event)'
    ></app-side-panel>
  </div>
</div>
