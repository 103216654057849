import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MissingVehicleStopEventsService } from '../../common/services/admin/missingvehiclestopevents.service';
import { DatePickerService } from '../../common/services/date-picker.service';
import { DateRange } from '../../common/models/dateRange';
import { MissingVehicleStopEvents, RouteByTripStopCount } from '../../common/models/missing-vehicle-stop-events';
import { DateFilterOption } from '@services/DateFilterOption';
import { DateOrigin } from '@models/date-origin';

@Component({
  selector: 'app-missing-vehicle-stop-events',
  templateUrl: './missing-vehicle-stop-events.component.html',
  styleUrls: ['./missing-vehicle-stop-events.component.scss']
})
export class MissingVehicleStopEventsComponent implements OnInit, OnDestroy {
  dateOrigin = DateOrigin.MissingVehicles;
  missingVehicleStopEventsList: MissingVehicleStopEvents[] = [];
  routeByTripStopCount: RouteByTripStopCount[] = [];
  dateRangeFilter: DateRange;
  getTripStopsSubscription: Subscription;
  getRouteVariantByPlaceNameSubscription: Subscription;
  dateFilterOptionEnum = DateFilterOption;
  constructor(
    private missingVehicleStopEventsService: MissingVehicleStopEventsService,
    private datePickerService: DatePickerService,
  ) {
  }

  ngOnInit() {
  }

  onDateRangeChanged(dateRange: DateRange): void {
    if (!dateRange && (!this.datePickerService.missingVehiclesFromDate && !this.datePickerService.missingVehiclesToDate)) {
      this.dateRangeFilter = undefined;
    } else {
      this.datePickerService.missingVehiclesDateRange = dateRange;
      this.dateRangeFilter = dateRange;
      this.getTripStops(this.dateRangeFilter.fromDate, this.dateRangeFilter.toDate);
    }
  }

  getTripStops(from, to) {
    if (this.getTripStopsSubscription) {
      this.getTripStopsSubscription.unsubscribe();
    }

    this.getTripStopsSubscription = this.missingVehicleStopEventsService.getTripStopsWithMissingVehicleAtStopEvents(from, to)
      .subscribe(
        res => {
          this.missingVehicleStopEventsList = res.body;
        });
  }

  getRouteVariantByPlaceName(from, to, placeName) {
    if (this.getRouteVariantByPlaceNameSubscription) {
      this.getRouteVariantByPlaceNameSubscription.unsubscribe();
    }

    this.getRouteVariantByPlaceNameSubscription = this.missingVehicleStopEventsService.getRouteVariantByPlaceName(from, to, placeName)
      .subscribe(
        res => {
          this.routeByTripStopCount = res.body;
        }
      )
  }

  ngOnDestroy() {
    if (this.getTripStopsSubscription) this.getTripStopsSubscription.unsubscribe();
    if (this.getRouteVariantByPlaceNameSubscription) this.getRouteVariantByPlaceNameSubscription.unsubscribe();
  }
}
