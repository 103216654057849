<div class="outcome-reference">
  <div class="caption">
    <ng-container i18n="@@outcome-reference.header">Referens</ng-container>
  </div>

  <div>
    <ng-container *ngIf="isEditMode; then editMode else readOnlyMode"></ng-container>

    <ng-template #editMode>
      <input type="text" name="referenceInput" class="col-12 focus" [(ngModel)]="editRevisedReferenceId" />

      <div class="button-container mt-1">
        <button type="button" class="small-button ms-1 tn-secondary" id="cancelBtn"
                i18n="@@outcome-reference.cancelButton"
                (click)="toggleEditMode()">Avbryt</button>
        <button type="button" class="small-button ms-1 btn-primary active" id="saveBtn"
                i18n="@@outcome-reference.saveButton"
                (click)="save()">Spara</button>
      </div>
    </ng-template>

    <ng-template #readOnlyMode>
      <span [ngClass]=" {'revised-old': isRevisedAndNotSameAsExisting }">{{outcome?.referenceId}}</span>
      <span *ngIf="isRevisedAndNotSameAsExisting" class="red-text"> {{outcome?.revisedReferenceId}}</span>

      <div class="button-container">
        <button type="button" class="small-button float-right btn-primary" id="editModeBtn"
                i18n="@@outcome-reference.editModeButton"
                (click)="toggleEditMode()">Ändra</button>
      </div>
    </ng-template>


  </div>
</div>
