import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import { forkJoin, take } from 'rxjs';
import { NgbDateStruct, NgbDatepickerI18n, NgbModalOptions, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@services/auth.service';
import { AdaptRouteComponent } from './adapt-route/adapt-route.component';
import { BaseComponent } from '@common/components/base.component';
import { CancelOrderComponent } from './cancel-order/cancel-order.component';
import { CloseOrderComponent } from './close-order/close-order.component';
import { ConfirmModalComponent } from '@common/components/confirm-modal/confirm-modal.component';
import { I18n as dpI18n, CustomDatepickerI18n, DateTimePickerHelper } from '@common/datepicker-helpers';
import { Operator } from '@models/operator';
import { OperatorService } from '@services/operator.service';
import { Order, OrderRequest, OrderRoute, OrderRouteVariant } from '@models/order';
import { OrderByService } from '@services/order-by.service';
import { OrderService } from '@services/order.service';
import { OrderState } from '@models/order-state';
import { ReasonService } from '@services/reason.service';
import { Route, ScheduledStopPoints, Place, RouteVariantWithConnection } from '@models/route';
import { RouteService } from '@services/route.service';
import { Reason } from '@models/reason';
import { UserRoles } from '@authentication/user-roles';
import { VehicleTypeService } from '@services/vehicle-type.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  providers: [dpI18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }], // define custom NgbDatepickerI18n provider
})
export class OrderComponent extends BaseComponent implements OnInit {
  @Input() order: Order;
  @Input() routes: Route[];
  orderForm: UntypedFormGroup;
  routeVariants: RouteVariantWithConnection[];
  operators: Operator[];
  reasons: Reason[];
  vehicleTypeDescriptions: string[];
  buttonsDisabled = false;
  now: Date = new Date();
  datepickerDefault: NgbDateStruct = { year: this.now.getFullYear(), month: this.now.getMonth() + 1, day: this.now.getDate() };
  operatorOrganisationId: number;
  hoursInDayOrderstartList = Array.from(Array(24).keys());
  hoursInDayOrderendList = Array.from(Array(24).keys());
  minutesInHourList = Array.from(Array(60).keys());
  intervalMinutesList = [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];
  OrderState = OrderState;
  isHistory: boolean;
  UserRoles = UserRoles;
  orderstartMinDate: NgbDateStruct;
  orderstopMinDate: NgbDateStruct;
  earliestReturnMinDate: Date = null;
  specifiedReturntripErrorMessage = '';
  @ViewChild('dpStartDate', { static: true }) dpStartDate: any;
  @ViewChild('dpEndDate', { static: true }) dpEndDate: any;
  httpTimeRangeError = false;
  trafficDate: Date;
  endtimeErrorMessage: string;
  trafficDateOutput: string;
  routeVariant: OrderRouteVariant = new OrderRouteVariant();
  returnRouteVariant: OrderRouteVariant = new OrderRouteVariant();
  modalRef: NgbModalRef;
  timeStamp = new Date();
  isEdit = false;
  currentComment = null;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private orderService: OrderService,
    private routeService: RouteService,
    private reasonService: ReasonService,
    private vehicleTypeService: VehicleTypeService,
    private operatorService: OperatorService,
    private modalService: NgbModal,
    private authService: AuthService,
    private orderByService: OrderByService,
    private decimalPipe: DecimalPipe,
    private datePipe: DatePipe) {
    super();
    if (!this.isOperatorDropdownVisible()) {
      this.operatorOrganisationId = +this.authService.user.idTokenClaims.garageIds[0];
    }

  }

  ngOnInit() {
    this.order = new Order();
    this.route.paramMap.subscribe(params => {
      this.order.id = +params.get('id');
      this.init();
    });
  }

  init() {
    this.isHistory = this.router.url.startsWith('/history');
    this.createForm();
    this.OnIntervalChange();
    this.getOrder();
    if (this.isHistory) {
      this.orderForm.disable();
    }
  }

  createForm() {
    this.orderForm = this.fb.group({
      operator: [null, Validators.required],
      route: [null, Validators.required],
      tripStartPlace: '',
      destination: '',
      isRoundTrip: true,
      openEnded: false,
      direct: false,
      connectionRequired: false,
      orderstartDate: this.datepickerDefault,
      orderendDate: this.datepickerDefault,
      startTimeHour: ['', Validators.required],
      startTimeMinute: ['', Validators.required],
      endTimeHour: ['', Validators.required],
      endTimeMinute: ['', Validators.required],
      immediateStart: false,
      indefiniteEnd: false,
      specifiedReturntrip: false,
      tripIntervalMinutes: null,
      referenceId: '',
      trainNumber: '',
      orderId: { value: '', disabled: true },
      numberOfVehicles: ['', [Validators.required, Validators.min(1), Validators.max(999), Validators.pattern('[0-9]+')]],
      vehicleType: ['', Validators.required],
      reason: [null, Validators.required],
      reasonAdditionalInfo: '',
      otherRequests: '',
    });
    if (this.isOperatorDropdownVisible()) {
      this.operatorCtrl.enable();
    } else {
      // tågoperatör
      this.operatorCtrl.disable();
    }
  }

  // Form controls helpers used in validation
  get routeCtrl() {
    return this.orderForm.get('route');
  }

  get operatorCtrl() {
    return this.orderForm.get('operator');
  }

  get numberOfVehicles() {
    return this.orderForm.get('numberOfVehicles');
  }

  get reason() {
    return this.orderForm.get('reason');
  }

  get referenceId(): FormControl {
    return this.orderForm.get('referenceId') as FormControl;
  }

  get vehicleType() {
    return this.orderForm.get('vehicleType');
  }

  get trainNumber() {
    return this.orderForm.get('trainNumber');
  }

  get orderstartDate() {
    return this.orderForm.get('orderstartDate');
  }

  get startTimeHour() {
    return this.orderForm.get('startTimeHour');
  }

  get startTimeMinute() {
    return this.orderForm.get('startTimeMinute');
  }

  get orderendDate() {
    return this.orderForm.get('orderendDate');
  }

  set orderendDate(value: any) {
    this.orderendDate.setValue(value);
  }

  get endTimeHour() {
    return this.orderForm.get('endTimeHour');
  }

  get endTimeMinute() {
    return this.orderForm.get('endTimeMinute');
  }

  get immediateStart() {
    return this.orderForm.get('immediateStart');
  }

  get indefiniteEnd() {
    return this.orderForm.get('indefiniteEnd');
  }

  get specifiedReturntrip() {
    return this.orderForm.get('specifiedReturntrip');
  }

  get tripIntervalMinutes() {
    return this.orderForm.get('tripIntervalMinutes');
  }

  get isRoundTrip() {
    return this.orderForm.get('isRoundTrip').value;
  }

  getOrder(): void {
    const initOrder = () => {
      if (this.order.id !== 0) {
        const order = this.orderService.getOrder(this.order.id);
        const reasons = this.reasonService.getReasons();

        forkJoin([order, reasons]).subscribe(results => {
          this.reasons = this.orderByService.transform(results[1], 'description');
          // order exists, no need to query all routes. Create a route and route variant from order
          const resultOrder: Order = results[0].body;
          const currentRoute = this.createRouteFromOrder(resultOrder.orderRoute, resultOrder.orderReturnRoute);
          this.routes = [currentRoute];
          this.vehicleTypeDescriptions = [this.vehicleTypeService.getVehicleType(resultOrder.vehicleTypeEnum)];

          this.onOrderRetrieved(resultOrder);
        });

      } else {
        if (this.operatorOrganisationId !== undefined) {
          this.routeService.getRoutes(this.operatorOrganisationId)
            .subscribe(routes => {
              this.routes = this.orderByService.transform(routes, 'routeName');
            });
        }
        this.reasonService.getReasons()
          .subscribe(reasons => this.reasons = this.orderByService.transform(reasons, 'description'));
        this.getVehicleTypes();
      }
    }

    this.operators = [];
    if (this.isOperatorDropdownVisible()) {
      this.operatorService.getOperators(true)
        .subscribe(ops => {
          this.operators = ops.body;
          let selectedOperator = this.operators.find(x => x.operatorOrganisationId === this.authService.user.idTokenClaims.garageIds[0]);
          if (selectedOperator === undefined) {
            selectedOperator = null; // Because default value in select element should be selected
          }
          this.orderForm.patchValue({
            operator: selectedOperator,
          });
          initOrder();
        }, err => console.log('error ' + err));
    } else {
      initOrder();
    }
  }

  getVehicleTypes() {
    this.vehicleTypeDescriptions = this.vehicleTypeService.getAllVehicleTypeDescriptions();
    if (this.vehicleTypeDescriptions && this.vehicleTypeDescriptions.length > 0) {
      this.orderForm.controls['vehicleType'].setValue(this.vehicleTypeDescriptions[0]);
    }
  }

  // re-creates the Route from order
  private createRouteFromOrder(orderRoute: OrderRoute, orderReturnRoute: OrderRoute): Route {
    const r = new Route();
    r.id = -1; // signal that this route is not from database
    r.routeName = orderRoute.routeName;
    r.description = orderRoute.routeDescription;
    r.routeVariants = [this.createRouteVariantFromOrder(orderRoute)];
    if (orderReturnRoute != null) {
      r.routeVariants = r.routeVariants.concat(this.createRouteVariantFromOrder(orderReturnRoute));
    }

    return r;
  }

  private createRouteVariantFromOrder(orderRoute: OrderRoute): RouteVariantWithConnection {
    const rv = new RouteVariantWithConnection();
    rv.id = orderRoute.routeVariantId;
    rv.routeVariantName = orderRoute.routeVariantName;
    rv.scheduledStopPoints = [];
    orderRoute.orderRouteStops.forEach(stop => {
      const sp = new ScheduledStopPoints();
      sp.active = stop.isActive;
      sp.sequence = stop.sequence;
      sp.place = new Place();
      sp.place.description = stop.placeDescription;
      sp.place.placeId = stop.placeName;
      rv.scheduledStopPoints.push(sp);
    });

    return rv;
  }

  saveUpdatedForm() {
    this.save();
  }

  editForm() {
    this.isEdit = true;
    if (this.canUpdateOrderReference()) {
      this.referenceId.enable();
    }
    else{
      this.orderForm.enable();

      this.OnIsRoundTrip();

      this.operatorCtrl.disable();

      if (!this.isRoundTrip.value || this.tripIntervalMinutes.value) {
        this.specifiedReturntrip.disable();
      }

      if (!this.tripIntervalMinutes.value) {
        this.indefiniteEnd.disable();
      }
      this.OnImmediateStart();
      this.OnChangeOperator();
      this.getVehicleTypes();
    }
  }

  setRouteVariant(routeVariant: OrderRouteVariant, orderRoute: OrderRoute) {
    routeVariant.id = orderRoute.routeVariantId;
    routeVariant.name = orderRoute.routeVariantName;
    routeVariant.isAdapted = orderRoute.orderRouteStops.filter(s => s.isActive).length !== orderRoute.orderRouteStops.length;
    routeVariant.activeRouteStopPlaceIds = orderRoute.orderRouteStops.filter(s => s.isActive).map(s => s.placeName);
    routeVariant.disembarkingOnlyRouteStopPlaceIds = orderRoute.orderRouteStops.filter(s => s.disembarkingOnly).map(s => s.placeName);
  }

  onOrderRetrieved(order: Order): void {
    this.order = order;
    const selectedRoute = this.routes.find(r => r.routeName === order.orderRoute.routeName);
    const selectedReason = this.reasons.find(x => x.id === order.orderReason.id);
    const selectedOperator = this.operators.find(x => x.id === order.operator.id);
    this.setRouteVariant(this.routeVariant, order.orderRoute);
    if (order.orderReturnRoute != null) {
      this.setRouteVariant(this.returnRouteVariant, order.orderReturnRoute);
    }
    this.orderForm.enable();
    this.orderForm.patchValue({
      operator: selectedOperator,
      route: selectedRoute,
      orderstartDate: {
        year: order.startTime.getFullYear(),
        month: order.startTime.getMonth() + 1,
        day: order.startTime.getDate(),
      },
      orderendDate: {
        year: order.endTime && order.endTime.getFullYear(),
        month: order.endTime && order.endTime.getMonth() + 1,
        day: order.endTime && order.endTime.getDate(),
      },
      startTimeHour: order.startTime.getHours(),
      startTimeMinute: order.startTime.getMinutes(),
      endTimeHour: order.endTime ? order.endTime.getHours() : '',
      endTimeMinute: order.endTime ? order.endTime.getMinutes() : '',
      referenceId: order.referenceId,
      trainNumber: order.orderTrainNumber,
      orderId: order.id,
      numberOfVehicles: order.numberOfVehicles,
      vehicleType: this.vehicleTypeDescriptions[0],
      otherRequests: order.otherRequests,
      reason: selectedReason,
      reasonAdditionalInfo: order.orderReason.additionalInfo,
      immediateStart: order.immediateStart,
      indefiniteEnd: order.indefiniteEnd,
      tripIntervalMinutes: order.tripIntervalMinutes,
      isRoundTrip: order.isRoundTrip,
      specifiedReturnTripTime: order.isSpecifiedReturnTripTime,
    });

    if (!order.endTime) {
      // if endTime null, then display startTime as date in orderendDate
      this.orderForm.patchValue({
        orderendDate: {
          year: order.startTime.getFullYear(),
          month: order.startTime.getMonth() + 1,
          day: order.startTime.getDate(),
        },
      });
    }

    this.dpStartDate.navigateTo(this.orderForm.get('orderstartDate').value);
    this.dpEndDate.navigateTo(this.orderForm.get('orderendDate').value);

    this.OnImmediateStart();
    this.OnIndefiniteEnd();
    this.OnIntervalChange();

    if (this.order.currentState.stateEnum === OrderState.SentToNobina
      && this.userHasAnyRole([UserRoles.NobinaTrafikledare, UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare])) {
      this.orderService.updateOrderState(this.order.id, OrderState.ReceivedByNobina)
        .subscribe(null);
    }
    if (this.isHistory) {
      this.orderstartDate.disable();
      this.startTimeHour.disable();
      this.startTimeMinute.disable();
      this.orderendDate.disable();
      this.endTimeHour.disable();
      this.endTimeMinute.disable();
    }
    this.orderForm.disable();
  }

  routeChanged() {
    const selectedRoute = this.orderForm.get('route').value;
    if (selectedRoute === '' || selectedRoute === null) {
      this.routeVariants = [];
      this.orderForm.controls['routeVariant'].setValue('');
    } else {
      this.routeVariants = this.routes.find(p => p.id === selectedRoute.id).routeVariants;
    }
  }

  OnIntervalChange() {
    if (+this.tripIntervalMinutes.value && +this.tripIntervalMinutes.value > 0) {
      if (!this.indefiniteEnd.value) {
        this.specifiedReturntrip.setValue(false);
        this.specifiedReturntrip.disable();
        this.indefiniteEnd.enable();
        this.orderendDate.enable();
        this.endTimeHour.enable();
        this.endTimeMinute.enable();
        let localenddate: NgbDateStruct;
        localenddate = this.orderendDate.value;
        let localstartdate: NgbDateStruct;
        localstartdate = this.orderstartDate.value;

        if (localstartdate !== null && localenddate !== null) { // this is not always set when reloading page (F5), causing null-exception and error log.
          if (localenddate.day === localstartdate.day + 1) {
            this.hoursInDayOrderendList = Array.from(Array(4).keys());
          } else {
            this.hoursInDayOrderendList = Array.from(Array(24).keys());
          }
          this.trafficDate = this.GetTrafficDate(new Date(localstartdate.year, localstartdate.month, localstartdate.day));
        }
      }
    } else {
      this.indefiniteEnd.setValue(false);
      this.indefiniteEnd.disable();
      if (!this.specifiedReturntrip.value ||
          !this.tripIntervalMinutes.value ||
          +this.tripIntervalMinutes.value === 0) {
        this.orderendDate.disable();
        this.endTimeHour.disable();
        this.endTimeMinute.disable();
        this.endTimeHour.setValue('');
        this.endTimeMinute.setValue('');
      }
      if (this.immediateStart.value === false && this.isRoundTrip === true) {
        this.specifiedReturntrip.enable();
      }
      this.tripIntervalMinutes.setValue(null);

    }
  }

  OnOrderstartdateChange() {
    let localdate: NgbDateStruct;
    localdate = this.orderstartDate.value;
    this.orderendDate.setValue(this.orderstartDate.value);
    this.orderstopMinDate = { year: localdate.year, month: localdate.month, day: localdate.day };
    this.trafficDate = this.GetTrafficDate(new Date(localdate.year, localdate.month, localdate.day));
  }

  GetTrafficDate(startTime: Date): Date {
    const currentTrafficDateEnd = new Date(startTime.getFullYear(), startTime.getMonth() - 1, startTime.getDate(), 3, 59);
    const nextTrafficDateEnd = new Date(startTime.getFullYear(), startTime.getMonth() - 1, startTime.getDate() + 1, 3, 59);
    let actualTrafficDate: Date;

    if (startTime > currentTrafficDateEnd) {
      actualTrafficDate = nextTrafficDateEnd;
    } else {
      actualTrafficDate = currentTrafficDateEnd;
    }
    return actualTrafficDate;
  }

  OnImmediateStart() {
    if (this.immediateStart.value) {
      this.orderstartDate.disable();
      this.startTimeHour.disable();
      this.startTimeMinute.disable();
      this.specifiedReturntrip.setValue(false);
      this.specifiedReturntrip.disable();
      if (+this.tripIntervalMinutes.value === 0) {
        this.orderendDate.disable();
        this.endTimeHour.disable();
        this.endTimeMinute.disable();
      }

    } else {
      this.orderstartDate.enable();
      this.startTimeHour.enable();
      this.startTimeMinute.enable();
      if (+this.tripIntervalMinutes.value === 0 && this.isRoundTrip === true) {
        this.specifiedReturntrip.enable();
      }
    }
  }

  OnIndefiniteEnd() {
    if (this.indefiniteEnd.value) {
      this.orderendDate.disable();
      this.endTimeHour.disable();
      this.endTimeMinute.disable();
    } else {
      this.orderendDate.enable();
      this.endTimeHour.enable();
      this.endTimeMinute.enable();
    }
  }

  OnSpecifiedReturnTrip(): void {
    if (this.specifiedReturntrip.value) {
      // Set default value for return trip: ankomsttid till sluthållplats plus 5 minuter.
      if (this.earliestReturnMinDate == null) {
        this.setEarliestReturnTimeForRoundTripReturnTrip();
      } else {
        this.orderendDate.enable();
        this.endTimeHour.enable();
        this.endTimeMinute.enable();
      }

    } else {

      // reset endtime fields
      if(this.tripIntervalMinutes && +this.tripIntervalMinutes.value > 0)
        return;
      this.earliestReturnMinDate = null;
      this.orderendDate.disable();
      this.orderendDate.setValue(null);
      this.endTimeHour.disable();
      this.endTimeHour.setValue('');
      this.endTimeMinute.disable();
      this.endTimeMinute.setValue('');
    }
  }

  onStartTimeUpdated(): void {
    this.setEarliestReturnTimeForRoundTripReturnTrip();
  }

  OnIsRoundTrip() {
    if (this.isRoundTrip) {
      if (this.immediateStart.value === false && +this.tripIntervalMinutes.value === 0) {
        this.specifiedReturntrip.enable();
      }
    } else {
      this.specifiedReturntrip.setValue(false);
      this.specifiedReturntrip.disable();
      this.earliestReturnMinDate = null;
      this.ResetRouteVariant(this.returnRouteVariant);
      if(this.tripIntervalMinutes && +this.tripIntervalMinutes.value > 0)
        return;
      this.orderendDate.disable();
      this.orderendDate.setValue(null);
      this.endTimeHour.disable();
      this.endTimeHour.setValue('');
      this.endTimeMinute.disable();
      this.endTimeMinute.setValue('');
    }
  }

  OnChangeRoute() {
    this.ResetRouteVariant(this.routeVariant);
    this.ResetRouteVariant(this.returnRouteVariant);
  }

  ResetRouteVariant(routeVariant: OrderRouteVariant) {
    routeVariant.id = null;
    routeVariant.name = null;
    routeVariant.isChanged = false;
    routeVariant.activeRouteStopPlaceIds = null;
    routeVariant.inactivatedRouteStopPlaceIds = [];
    routeVariant.disembarkingOnlyRouteStopPlaceIds = [];
    routeVariant.isAdapted = false;
  }

  OnChangeOperator() {
    if (this.isOperatorDropdownVisible() && this.operatorOrganisationId !== this.orderForm.controls['operator'].value.operatorOrganisationId) {
      this.operatorOrganisationId = this.orderForm.controls['operator'].value.operatorOrganisationId;
      this.routeService.getRoutes(this.operatorOrganisationId)
        .subscribe(routes => {
          this.routes = this.orderByService.transform(routes, 'routeName');
          if (!this.order.id) {
            this.orderForm.get('route').reset(); // Reset the route control to default value and treat as unselected (not red)
            this.OnChangeRoute(); // Reset route variant selection
          } else {
            const chosenRoute = this.routes.find(route => route.routeName === this.orderForm.controls['route'].value.routeName);
            this.orderForm.patchValue({ route: chosenRoute });
          }
        });
    }
  }

  SetReturnRoute(routeVariantId: number) {
    if (!this.isRoundTrip) {
      return;
    }
    const route: Route = this.routeCtrl.value;
    const routeVariant = route.routeVariants.find(rv => rv.id === routeVariantId);
    const returnRouteVariant = routeVariant.routeVariantConnection;
    if (routeVariant.routeVariantConnection) {
      this.returnRouteVariant.id = returnRouteVariant.id;
      this.returnRouteVariant.name = returnRouteVariant.routeVariantName;
      this.returnRouteVariant.isAdapted = false;
      this.returnRouteVariant.inactivatedRouteStopPlaceIds = [];
      this.returnRouteVariant.isChanged = true;
      this.routeService.getRouteVariant(this.returnRouteVariant.id)
        .subscribe(variant => {
          this.returnRouteVariant.activeRouteStopPlaceIds = variant.scheduledStopPoints.map(s => s.place.placeId);
        });
    }
  }

  openAdaptRoute(routeVariant: OrderRouteVariant, setReturnRoute: boolean = true) {
    const opts: NgbModalOptions = { size: 'lg' };
    const modalRef = this.modalService.open(AdaptRouteComponent, opts);
    modalRef.componentInstance.isSaved = (this.order.currentState !== undefined && this.order.currentState !== null);
    modalRef.componentInstance.isEdit = this.isEdit;
    modalRef.componentInstance.route = this.routeCtrl.value;
    modalRef.componentInstance.selectedRouteVariantId = routeVariant.id;
    modalRef.componentInstance.activeRouteStopPlaceIds = routeVariant.activeRouteStopPlaceIds;
    modalRef.componentInstance.inactivatedRouteStopPlaceIds = routeVariant.inactivatedRouteStopPlaceIds;
    modalRef.componentInstance.disembarkingOnlyRouteStopPlaceIds = routeVariant.disembarkingOnlyRouteStopPlaceIds;
    modalRef.result.then((result) => {
      routeVariant.id = result.routeVariantId;
      routeVariant.name = result.routeVariantName;
      routeVariant.isAdapted = result.routeVariantAdapted;
      routeVariant.activeRouteStopPlaceIds = result.activeRouteStopPlaceIds;
      routeVariant.inactivatedRouteStopPlaceIds = result.inactivatedRouteStopPlaceIds;
      routeVariant.disembarkingOnlyRouteStopPlaceIds = result.disembarkingOnlyRouteStopPlaceIds;
      routeVariant.isChanged = true;
      if (setReturnRoute) {
        this.SetReturnRoute(result.routeVariantId);
      }
      this.OnSpecifiedReturnTrip();
    })
      .catch(reason => {
      });
  }

  save(): void {
    if (this.canUpdateOrderReference()) {
      this.orderService.updateReference(this.order.id, this.referenceId.value).pipe(take(1)).subscribe();
      this.orderForm.disable();
    } else {
    // trigger validation on all fields
    Object.keys(this.orderForm.controls).forEach(field => {
      const control = this.orderForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });

    if (this.routeVariant.id === null) {
      this.routeVariant.isChanged = true;
      this.orderForm.setErrors({ 'invalid': true });
    }

    if (this.isRoundTrip && this.returnRouteVariant.id === null) {
      this.returnRouteVariant.isChanged = true;
      this.orderForm.setErrors({ 'invalid': true });
    }

    if (this.orderForm.valid) {
      this.buttonsDisabled = true;
      const orderRequest: OrderRequest = {
        id: this.orderForm.controls['orderId'].value,
        routeVariantId: this.routeVariant.id,
        activeRouteStopPlaceIds: this.routeVariant.activeRouteStopPlaceIds,
        disembarkingOnlyRouteStopPlaceIds: this.routeVariant.disembarkingOnlyRouteStopPlaceIds,
        returnDisembarkingOnlyRouteStopPlaceIds: this.returnRouteVariant.disembarkingOnlyRouteStopPlaceIds,
        returnRouteVariantId: this.returnRouteVariant.id,
        returnActiveRouteStopPlaceIds: this.returnRouteVariant.activeRouteStopPlaceIds,
        startTime: this.immediateStart.value === true ?
          new Date() :
          DateTimePickerHelper.getSelectedDateTime(this.orderstartDate.value, this.startTimeHour.value, this.startTimeMinute.value),
        endTime: (+this.tripIntervalMinutes.value === 0 && !this.specifiedReturntrip.value) || this.indefiniteEnd.value === true ?
          null :
          DateTimePickerHelper.getSelectedDateTime(this.orderendDate.value, this.endTimeHour.value, this.endTimeMinute.value),
        referenceId: this.orderForm.controls['referenceId'].value,
        trainNumber: this.orderForm.controls['trainNumber'].value,
        reasonId: Number(this.orderForm.controls['reason'].value.id),
        reasonAdditionalInfo: this.orderForm.controls['reasonAdditionalInfo'].value,
        numberOfVehicles: Number(this.orderForm.controls['numberOfVehicles'].value),
        vehicleType: this.orderForm.controls['vehicleType'].value,
        vehicleTypeEnum: Number(this.vehicleTypeService.getEnumValueByDescription(this.orderForm.controls['vehicleType'].value)),
        otherRequests: this.orderForm.controls['otherRequests'].value,
        tripIntervalMinutes: this.orderForm.controls['tripIntervalMinutes'].value ? this.orderForm.controls['tripIntervalMinutes'].value : 0,
        immediateStart: this.orderForm.controls['immediateStart'].value,
        indefiniteEnd: this.orderForm.controls['indefiniteEnd'].value,
        isRoundTrip: this.orderForm.controls['isRoundTrip'].value,
        operatorOrganisationId: this.isOperatorDropdownVisible() ?
          Number(this.orderForm.controls['operator'].value.operatorOrganisationId) :
          Number(this.authService.user.idTokenClaims.garageIds[0]),
      }

      if (!orderRequest.id || orderRequest.id === 0) {
        this.orderService.addOrder(orderRequest)
        .subscribe(response => {
              this.buttonsDisabled = false;
              this.onSaveComplete(response.headers.get('location'));
          },
          err => {
            this.buttonsDisabled = false;
            this.onHttpError(err);
          });
        } else {
        this.orderService.patchOrder(orderRequest)
        .subscribe(_ => {
              this.orderForm.disable();
              this.buttonsDisabled = false;
              this.isEdit = false;
          },
          err => {
            this.buttonsDisabled = false;
            this.onHttpError(err);
          });
        }
      }
    }
  }

  private canUpdateOrderReference(): boolean {
    if(this.order?.currentState){
     //If an order is activated, ongoing or reportedComplete, update only reference.
     return [OrderState.Active, OrderState.Ongoing, OrderState.ReportedComplete].includes(this.order.currentState.stateEnum);
    }
    return false;
  }

  showEndtimeError(): boolean {
    if (((this.endTimeHour.dirty
        || this.endTimeHour.touched) && this.endTimeHour.invalid)
      || ((this.endTimeMinute.dirty || this.endTimeMinute.touched) && this.endTimeMinute.invalid)) {
      if (!this.httpTimeRangeError) {
        this.endtimeErrorMessage = $localize`:Sluttid är obligatoriskt@@order.endtime-mandatory:Sluttid är obligatoriskt`;
        return true;
      } else {
        const datePipe = new DatePipe('en-US');
        this.trafficDateOutput = datePipe.transform(this.trafficDate, 'yyyy-MM-dd hh:mm');
        this.endtimeErrorMessage = $localize`:Sluttid måste vara mindre eller lika med @@order.endtime-constraint:Sluttid måste vara mindre eller lika med ` + this.trafficDateOutput;
        return true;
      }
    }
  }

  showSpecifiedReturnTripTimeError(): boolean {
    if ((this.endTimeHour.dirty || this.endTimeHour.touched) || (this.endTimeMinute.dirty || this.endTimeMinute.touched)) {
      if (this.specifiedReturntrip.value && this.earliestReturnMinDate
        && (this.orderendDate?.value !== null && this.orderstopMinDate && this.orderendDate.value.day === this.orderstopMinDate.day)
        && ((this.endTimeHour.value < this.earliestReturnMinDate.getHours()) || (this.endTimeHour.value <= this.earliestReturnMinDate.getHours() && this.endTimeMinute.value < this.earliestReturnMinDate.getMinutes()))) {
        this.specifiedReturntripErrorMessage = $localize`:Tiden för returresa är inte möjlig. Tidigaste planerade avresetidpunkt är @@order.endtime-earliest:Tiden för returresa är inte möjlig. Tidigaste planerade avresetidpunkt är ` + `${this.decimalPipe.transform(this.earliestReturnMinDate.getHours(), '2.0')}:${this.decimalPipe.transform(this.earliestReturnMinDate.getMinutes(), '2.0')}`;
        return true;
      }
    }
    this.specifiedReturntripErrorMessage = '';
    return false;
  }

  onHttpError(httpError: HttpErrorResponse): void {
    if (httpError.error && httpError.error.errorCode === 'InvalidDateRange') {
      this.endTimeHour.setErrors({});
      this.endTimeMinute.setErrors({});
      this.httpTimeRangeError = true;
    }
    this.buttonsDisabled = false;
  }

  onSaveComplete(location: string): void {
    // Reset the form to clear the flags
    this.orderForm.reset();
    // Reload order or navigate to order confirmation
    let url: string;
    if (this.order.id !== 0) {
      url = 'order/' + this.order.id + '/drive-order';
    } else {

      url = 'order-confirmation/' + location.substr(location.lastIndexOf('/') + 1);

    }
    this.router.navigateByUrl(url);
  }


  dismiss(): void {
    if (this.isEdit) {
      this.getOrder();
    } else if (this.isHistory) {
      this.router.navigateByUrl('history');
    } else {
      this.router.navigateByUrl('orders');
    }
  }

  activateOrder() {
    this.buttonsDisabled = true;
    this.orderService.updateOrderState(this.order.id, OrderState.Active)
      .subscribe(() => this.router.navigateByUrl('order/' + this.order.id + '/subcontractors'));
  }

  cancelOrder() {
    this.buttonsDisabled = true;
    const modalRef = this.modalService.open(CancelOrderComponent);
    modalRef.componentInstance.orderId = this.order.id;
    modalRef.componentInstance.isHastusPlanned = this.order.isHastusPlanned;
    modalRef.result.then(() => {
      this.order.currentState.stateEnum = OrderState.Cancelled;
      this.buttonsDisabled = false;
    })
      .catch(reason => {
        this.buttonsDisabled = false;
      });
  }

  closeOrder() {
    this.buttonsDisabled = true;
    const modalRef = this.modalService.open(CloseOrderComponent);
    modalRef.componentInstance.orderId = this.order.id;
    modalRef.componentInstance.isHastusPlanned = false;
    modalRef.result.then(() => {
      this.order.currentState.stateEnum = OrderState.Completing;
      this.buttonsDisabled = false;
    })
      .catch(reason => {
        this.buttonsDisabled = false;
      });
  }

  isOperatorDropdownVisible(): boolean {
    return this.userHasAnyRole([UserRoles.NobinaTrafikledare, UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare])
      || (this.userHasAnyRole([UserRoles.OperatorTrafikledare]) && (this.authService.user.idTokenClaims.garageIds as string[]).length > 1);
  }

  userHasAnyRole(userRoles: UserRoles[]): boolean {
    return this.authService.userHasAnyRole(userRoles);
  }

  setEarliestReturnTimeForRoundTripReturnTrip(): void {
    if (this.specifiedReturntrip.value === true && ((this.startTimeHour.dirty && this.startTimeMinute.dirty) || typeof this.startTimeMinute.value === 'number')) {

      this.endTimeHour.disable();
      this.endTimeMinute.disable();
      this.orderendDate.disable();

      if (this.routeVariant.id === null) {
        this.routeVariant.isChanged = true;
        return;
      }
      // Copy the form values over the order object values
      const o = Object.assign(this.order, this.orderForm.value);
      o.RouteVariantId = this.routeVariant.id;
      o.ActiveRouteStopPlaceIds = this.routeVariant.activeRouteStopPlaceIds;
      if (this.returnRouteVariant.id === null) {
        o.ReturnRouteVariantId = this.routeVariant.id; // returvariant ej vald, kör med samma som utresa
        o.ReturnActiveRouteStopPlaceIds = this.routeVariant.activeRouteStopPlaceIds;
      } else {
        o.ReturnRouteVariantId = this.returnRouteVariant.id;
        o.ReturnActiveRouteStopPlaceIds = this.returnRouteVariant.activeRouteStopPlaceIds;
      }
      o.ReasonId = 1;
      o.vehicleType = 1;
      o.numberOfVehicles = 1;
      if (this.isOperatorDropdownVisible()) {
        o.operatorOrganisationId = this.orderForm.controls['operator'].value.operatorOrganisationId;
      } else {
        // tågoperatör
        o.operatorOrganisationId = this.authService.user.idTokenClaims.garageIds[0];
      }
      o.startTime = DateTimePickerHelper.getSelectedDateTime(this.orderstartDate.value, this.startTimeHour.value, this.startTimeMinute.value);
      o.endTime = null;

      this.orderService.getEarliestReturnTime(o).subscribe(
        response => {
          const returnStartTime = response.body.returnStartTime as Date;
          this.earliestReturnMinDate = returnStartTime;
          this.endTimeHour.setValue(returnStartTime.getHours());
          this.endTimeMinute.setValue(returnStartTime.getMinutes());
          const endTimeDate = { year: returnStartTime.getFullYear(), month: (returnStartTime.getMonth() + 1), day: (returnStartTime.getDate()) };
          this.orderstopMinDate = endTimeDate;
          setTimeout(() => { // To get date picker to update date selection correctly
            this.orderForm.patchValue({
              orderendDate: endTimeDate,
            });
            this.dpEndDate.navigateTo(this.orderForm.get('orderendDate').value);
          }, 0);
          this.endTimeHour.enable();
          this.endTimeMinute.enable();
          this.orderendDate.enable();
        },
        err => {
          this.endTimeHour.enable();
          this.endTimeMinute.enable();
          this.orderendDate.enable();
          this.onHttpError(err);
        },
      );
    }
  }

  sendComment(comment: string) {
    this.currentComment = null;
    const timeStamp = this.datePipe.transform(this.timeStamp, 'short');
    this.modalRef = this.modalService.open(ConfirmModalComponent);
    this.modalRef.componentInstance.title = $localize`:Skicka?@@order.comment-title:Skicka?`;
    this.modalRef.componentInstance.description = $localize`:Vill du lägga till denna anteckning? Observera att den inte går att ta bort@@order.comment-description:Vill du lägga till denna anteckning? Observera att den inte går att ta bort`;
    this.modalRef.componentInstance.confirmButton = $localize`:Bekräfta@@order.comment-confirmbtn:Bekräfta`;
    this.modalRef.componentInstance.cancelButton = $localize`:Avbryt@@order.comment-cancelbtn:Avbryt`;

    this.modalRef.result.then(() => {
      this.orderService.sendComment(this.order.id, comment, this.authService.user.idTokenClaims.name, timeStamp)
        .subscribe(resp => {
          this.ngOnInit();

        });
    })
      .catch(reason => {
      });
  }

  parseComment(comment: string): string {
    comment = comment.replace(new RegExp(/<br>/g), '\n');
    return comment;
  }

  onCommentChange(event: Event) {
    this.currentComment = (event.target as HTMLInputElement).value;
  }
}
