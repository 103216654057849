import { Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OutcomeService } from '../../common/services/outcome.service';
import { Outcome, OutcomeDriveOrder } from '../../common/models/outcome';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../common/services/auth.service';
import { UserRoles } from '../../authentication/user-roles';
import { setupMomentForExactTime } from '../../common/datepicker-helpers';
import { OutcomeDriveOrderState, OutcomeState } from '../../common/models/outcome-state';
import { OutcomeApprovalContractorComponent } from './outcome-approval-contractor/outcome-approval-contractor.component';
import { OutcomeApprovalComponent } from './outcome-approval/outcome-approval.component';
import { OutcomeBilledComponent } from './outcome-billed/outcome-billed.component';
import { OutcomeBilledContractorComponent } from './outcome-billed-contractor/outcome-billed-contractor.component';
import { environment } from '../../../environments/environment';
import { roundOffMinutes } from '@common/date-extension';
import { DatePickerService } from '@services/date-picker.service';
import { DateRange } from '@models/dateRange';

@Component({
  selector: 'app-outcome',
  templateUrl: './outcome.component.html',
  styleUrls: ['./outcome.component.scss']
})
export class OutcomeComponent implements OnInit {
  Math = Math; // To access javascript math in template.

  outcome: Outcome;
  driveOrders: OutcomeDriveOrder[];
  selectedDriveOrder: OutcomeDriveOrder;
  userIsSubcontractorOrNobinaTrafficManager = false;
  userIsNobinaLeveransansvarig = false;
  requiresActionValidStates: OutcomeDriveOrderState[];
  OutcomeDriveOrderState = OutcomeDriveOrderState;
  OutcomeState = OutcomeState;
  buttonsDisabled = false;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private outcomeService: OutcomeService,
    private modalService: NgbModal) {
    setupMomentForExactTime(environment.language);
  }

  ngOnInit(): void {
    this.userIsSubcontractorOrNobinaTrafficManager = this.authService.userHasAnyRole([UserRoles.UETrafikledare]);
    this.userIsNobinaLeveransansvarig = this.authService.userHasAnyRole([UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare]);
    this.requiresActionValidStates = this.userIsSubcontractorOrNobinaTrafficManager ? [OutcomeDriveOrderState.NotApprovedBySubcontractor, OutcomeDriveOrderState.NotApprovedByNobina] : [OutcomeDriveOrderState.ApprovedBySubcontractorVerificationRequired];
    this.getOutcome();
  }

  private getOutcome(): void {
    const id = +this.route.snapshot.paramMap.get('id');

    if (this.authService.userHasAnyRole([UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare])) {
      this.outcomeService.getOutcomeOrder(id)
      .subscribe(result => this.onOutcomRetrieved(result.body), x => console.log(x));

    } else if (this.authService.userHasAnyRole([UserRoles.UETrafikledare])) {
      this.outcomeService.getOutcomeSubcontractorOrder(id)
      .subscribe(result => this.onOutcomRetrieved(result.body), x => console.log(x));

    }
  }

  private onOutcomRetrieved(outcome: Outcome) {
    this.outcome = outcome;
    this.getDriveOrders(false);
  }

  isCardSelected(driveOrder: OutcomeDriveOrder) {
    return (driveOrder && this.selectedDriveOrder && driveOrder.id == this.selectedDriveOrder.id);
  }

  requiresAction(driveOrder: OutcomeDriveOrder) {
    return this.checkState(this.requiresActionValidStates, driveOrder.currentState.stateEnum) ||
      (this.userIsNobinaLeveransansvarig && !driveOrder.subcontractorIsExternal && driveOrder.currentState.stateEnum === OutcomeDriveOrderState.NotApprovedBySubcontractor);
  }

  driveOrderStatus(driveOrder: OutcomeDriveOrder) {
    return driveOrder.isCancelled === true ? $localize`:@@outcome.driveorder-cancelled:Avbeställd` +', '+ driveOrder.currentState.stateDescription : driveOrder.currentState.stateDescription;
  }

  checkState(validStates: any[], currentState: any): boolean {
    return (!validStates || !currentState) ? false : validStates.indexOf(currentState) > -1;
}

  private getDriveOrders(isReload: boolean) {
    const id = +this.route.snapshot.paramMap.get('id');

    // om användare subcontractor, så returnerar API bara ordrar för aktuell subcontractor
    this.outcomeService.getOutcomeDriveOrdersForOrder(id)
      .subscribe(result => {
        this.driveOrders = result.body;
        if (this.driveOrders.length > 0 && this.selectedDriveOrder === undefined) {
          this.selectDriveOrder(this.driveOrders[0]);
        }
      } );
  }

  selectDriveOrder(driveOrder: OutcomeDriveOrder) {
    roundOffMinutes([
      driveOrder.startTripStop?.time,
      driveOrder.endTripStop?.time
    ]);
    this.selectedDriveOrder = driveOrder;
  }

  handleOutcomeDriveOrderWasUpdated() {
    this.getOutcome();
  }

  isEditable(driveOrder: OutcomeDriveOrder) {
    return this.userIsSubcontractorOrNobinaTrafficManager && (
              driveOrder.currentState.stateEnum === OutcomeDriveOrderState.NotApprovedBySubcontractor ||
                driveOrder.currentState.stateEnum === OutcomeDriveOrderState.NotApprovedByNobina)
                  || this.userIsNobinaLeveransansvarig && (
                  driveOrder.currentState.stateEnum === OutcomeDriveOrderState.ApprovedBySubcontractor ||
                  driveOrder.currentState.stateEnum === OutcomeDriveOrderState.ApprovedBySubcontractorVerificationRequired ||
                  driveOrder.currentState.stateEnum === OutcomeDriveOrderState.NotApprovedByNobina ||
                  (driveOrder.subcontractorIsExternal === false && driveOrder.currentState.stateEnum !== OutcomeDriveOrderState.Billable)
                  ) ;
  }

  outcomeApprovalByContractor() {
    this.confirm(OutcomeApprovalContractorComponent);
  }

  outcomeApprovalByNobina() {
    this.confirm(OutcomeApprovalComponent);
  }

  confirm(content: any, comment: string = null) {
    this.buttonsDisabled = true;
    const modalRef = this.modalService.open(content);
    modalRef.componentInstance.id = this.outcome.id;
    modalRef.componentInstance.rejectionComment = comment;
    modalRef.result.then(() => {
      this.getOutcome();
      this.buttonsDisabled = false;
    })
    .catch(reason => {
      this.buttonsDisabled = false;
    });
  }

  outcomeBilledByContractor() {
    this.billed(OutcomeBilledContractorComponent);
  }

  outcomeBilledByNobina() {
    this.billed(OutcomeBilledComponent);
  }

  billed(content: any) {
    this.buttonsDisabled = true;
    const modalRef = this.modalService.open(content);
    modalRef.componentInstance.id = this.outcome.id;
    modalRef.result.then(() => {
      this.getOutcome();
      this.buttonsDisabled = false;
    })
    .catch(reason => {
      this.buttonsDisabled = false;
    });
  }
}
