export class NotificationMessage {
  id: number;
  title: string;
  main: string;
  submain: string;
  type: string;
  notificationType?: NotificationType;
  data?: { [key: string]: unknown };
  link: string;
  groups: string[];
  isRead: boolean;
  created: Date;

  constructor(
    id: number,
    title: string,
    main: string,
    submain: string,
    type: string,
    link: string,
    groups: string[],
    isRead: boolean,
    created: Date,
    notificationType?: NotificationType,
    data?: { [key: string]: unknown }
  ) {
    this.id = id;
    this.title = title;
    this.main = main;
    this.submain = submain;
    this.type = type;
    this.notificationType = notificationType;
    this.data = data;
    this.link = link;
    this.groups = groups;
    this.isRead = isRead;
    this.created = created;
  }
}

export enum NotificationType {
  DriveOrderInquiryCreated = 1,
  DriveOrderAssigned = 2,
}

export interface DriveOrderInquiryCreated {
  numberOfInquiries: number;
}

export interface DriveOrderAssigned {
  numberOfDriveOrders: number;
}
