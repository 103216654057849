<div class="date-filter">
  <div *ngIf="!isFilterVisible" class="d-flex gap-2">
    <svg viewBox="0 0 512 512" width="24" height="24" class="mt-2 me-4" (click)="filterClick()">
      <path class="greyish-brown"
            d="M463.952 0H48.057C5.419 0-16.094 51.731 14.116 81.941L176 243.882V416c0 15.108 7.113 29.335 19.2 40l64 47.066c31.273 21.855 76.8 1.538 76.8-38.4V243.882L497.893 81.941C528.042 51.792 506.675 0 463.952 0zM288 224v240l-64-48V224L48 48h416L288 224z" />
    </svg>
  </div>
  <div *ngIf="isFilterVisible" class="d-flex flex-wrap gap-2 filter-container" [ngbCollapse]="isSelectAllDates">
    <div class='col-auto'>
      <select class="form-select filter-item" id="daterange" (change)="dateRangeChangeSelect($event.target.value)"
              [(ngModel)]="selectedDateRangeType">
        <option *ngFor="let dateFilterOption of DateFilterOptions" [ngValue]="dateFilterOption.value">{{
          dateFilterOption.text }}</option>
      </select>
    </div>

    <div class='col-auto'>
      <input class="datepicker filter-item form-control" id="d" placeholder="åååå-mm-dd" name="d" readonly
             (click)="startDateDateTimeClicked(); $event.stopPropagation();" [(ngModel)]="startDatepickerValue" ngbDatepicker
             #d1="ngbDatepicker" (dateSelect)="onDatepickerDateChange()">
    </div>

    <div class='col-auto'>
      <input class="datepicker filter-item form-control" id="d2" placeholder="åååå-mm-dd" name="d2" readonly
             (click)="endDateDateTimeClicked(); $event.stopPropagation();" [(ngModel)]="endDatepickerValue" ngbDatepicker
             #d2="ngbDatepicker" (dateSelect)="onDatepickerDateChange()">
    </div>

    <button (click)="reset()" id="resetDate" class="col  filter-item form-control"
            i18n="@@date-filter.reset-default" type="button">Återställ
    </button>
  </div>
</div>
