<p style='font-size: 14px' i18n="@@import-subcontractor.description">
  I samband med att en utförare läggs till så synkroniseras fordon och förare så att dessa
  blir valbara i tilldelningsfunktionerna. Det kan ta flera minuter att synkronisera en
  utförare, ladda om listan med utförare manuellt nedan för att se de som är nyligen tillagda.
</p>
<input
  id="import-subcontractors.garage-placeholder"
  type="text"
  class="form-control mt-4 mb-5"
  placeholder="Ange garagekod från OMS"
  i18n-placeholder="@@import-subcontractors.garage-placeholder"
  [value]="newGarageCode"
  (input)="updateSubcontractorInput($event)"
  (keydown.enter)="addOMSGarage(newGarageCode)"
/>
<div
  *ngIf="latestResponseFromAddSubcontractorCall !== ''"
  class="p-2 mt-2 manual-pre-line"
>
  {{latestResponseFromAddSubcontractorCall}}
</div>
<button
  id="import-subcontractors.import"
  type="button"
  class="btn btn-primary mt-5"
  style="width: 100%"
  i18n="@@import-subcontractors.import"
  [disabled]="newGarageCode === ''"
  (click)="addOMSGarage(newGarageCode)"
>
  Importera
</button>
