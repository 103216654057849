<h1 class="my-5 text-center" i18n="@@manage-operators.title">Välj trafikoperatörer</h1>
<div class="container">
  <div class="mx-3 mb-3">
    <hr>
    <form [formGroup]="operatorForm" (submit)="save()">

      <div class="mt-1 mb-4">
        <div *ngFor="let operator of allOperators; let i=index" class='form-check mb-2'>
          <input [id]="operator.id" class="form-check-input m-0 me-2" type="checkbox" [checked]="selectedOperatorIds.indexOf(operator.id) > -1" (change)="toggleSelectedOperator(operator)">
          <label class='form-check-label' [for]="operator.id">
            <ng-container *ngIf="operator.isActive; then thenBlock else elseBlock"></ng-container>
            <ng-template #thenBlock>{{operator.name}}</ng-template>
            <ng-template #elseBlock><s>{{operator.name}}</s><i>(inactive)</i></ng-template>
          </label>
        </div>
      </div>
      <hr class="mb-0">
      <div class="d-flex">
        <button class="btn btn-primary mt-5 ms-auto" [disabled]="operatorForm.pristine" type="submit" i18n="@@manage-operators.save-changes">Spara ändringar</button>
      </div>
    </form>
  </div>
</div>
