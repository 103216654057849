export enum DateFilterOption {
    Today = 'Today',
    Tomorrow = 'Tomorrow',
    Yesterday = 'Yesterday',
    LastWeek = 'LastWeek',
    NextWeek = 'NextWeek',
    CurrentMonth = 'CurrentMonth',
    LastMonth = 'LastMonth',
    NextMonth = 'NextMonth',
    Period = 'Period'
}
