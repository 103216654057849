import { StateObject } from './state-object';

export enum OutcomeState {
  WaitingForApprovalBySubcontractor = 1,
  WaitingForApprovalByNobina = 2,
  Billable = 3,
  Billed = 4
}
export class OutcomeStateObject extends StateObject {
  public constructor(init?: Partial<OutcomeStateObject>) {
    super(init);
    Object.assign(this, init);
  }
}

export enum OutcomeDriveOrderState {
  // Ej godkänd av utförare
  NotApprovedBySubcontractor = 1,

  // Godkänd av utförare, kontroll krävs inte
  ApprovedBySubcontractor = 2,

  // Godkänd av utförare, kontroll krävs
  ApprovedBySubcontractorVerificationRequired = 3,

  // Ej godkänd av Nobina
  NotApprovedByNobina = 4,

  // Fakturerbar
  Billable = 5,

  // Fakturerad
  Billed = 6,
}
export class OutcomeDriveOrderStateObject extends StateObject {

  public constructor(init?: Partial<OutcomeDriveOrderStateObject>) {
    super(init);
    Object.assign(this, init);
  }
}
