

export class Operator {
    id: number;
    name: string;
    operatorOrganisationId: number;
}

export const OPERATOR_ORGID_MTR_PENDEL = 1124; // 1124    XSMOMP    Tågoperatör MTR Pendeltåg
export const OPERATOR_ORGID_MTR_TUNNELBANA = 1126; // 1126    XSMOMT    Tågoperatör MTR Tunnelbana
// 1120    XSVOTK    Tågoperatör Tågkompaniet
export const OPERATOR_ORGID_TÅGKOMPANIET_KRÖSA = 1156; // 1156    XSSOTK    Tågkompaniet Krösa

export const OPERATOR_ORGID_GO_AHEAD = 1317; // 1317       GoAhead 
