import { Component, EventEmitter, Output, OnInit, ViewChild, Input } from '@angular/core';
import * as moment from 'moment';
import { NgbDateStruct, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { DateTimePickerHelper, I18n as DatepickerI18n, CustomDatepickerI18n } from '@common/datepicker-helpers';
import { DatePickerService } from '@services/date-picker.service';
import { DateRange } from '@models/dateRange';
import { DateFilterOption } from '@services/DateFilterOption';
import { OrderPageService } from '@services/order-page.service';
import { DateOrigin } from '@models/date-origin';

@Component({
    selector: 'app-date-filter',
    templateUrl: './date-filter.component.html',
    styleUrls: ['./date-filter.component.scss'],
    providers: [DatepickerI18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }] // define custom NgbDatepickerI18n provider
})
export class DateFilterComponent implements OnInit {
    @Input() defaultRangeOption: DateFilterOption;
    @Input() isFuture = false;
    @Output() clean = new EventEmitter();

    @Input() dateOrigin: DateOrigin;
    @Output() dateRange = new EventEmitter<DateRange>();

    currentPage = 1;
    public isFilterVisible = true;
    public isSelectAllDates = false; // true betyder inget urval och datumrutor gömda
    public isSelectPeriod = false; // true om val Period, dvs datetimepickers skall visas
    public selectedDateRangeType: DateFilterOption;
    public _fromDate: Date = new Date();
    public _toDate: Date = new Date();
    public startDatepickerValue: NgbDateStruct;
    public endDatepickerValue: NgbDateStruct;
    @ViewChild('d1') startDatepicker;
    @ViewChild('d2') endDatepicker;

    public DateFilterOptions = [
        { tense: 'present', value: DateFilterOption.Today,          text: $localize`:I dag@@date-filter.today:I dag` },
        { tense: 'future', value: DateFilterOption.Tomorrow,        text: $localize`:I morgon@@date-filter.tomorrow:I morgon` },
        { tense: 'past', value: DateFilterOption.Yesterday,         text: $localize`:I går@@date-filter.yesterday:I går` },
        { tense: 'past', value: DateFilterOption.LastWeek,          text: $localize`:7 dagar bakåt@@date-filter.last-week:7 dagar bakåt` },
        { tense: 'future', value: DateFilterOption.NextWeek,        text: $localize`:7 dagar framåt@@date-filter.next-week:7 dagar framåt` },
        { tense: 'present', value: DateFilterOption.CurrentMonth,   text: $localize`:Denna månad@@date-filter.current-month:Denna månad` },
        { tense: 'past', value: DateFilterOption.LastMonth,         text: $localize`:Förra månaden@@date-filter.last-month:Förra månaden` },
        { tense: 'future', value: DateFilterOption.NextMonth,       text: $localize`:Nästa månad@@date-filter.next-month:Nästa månad` },
        { tense: 'present', value: DateFilterOption.Period,         text: $localize`:Period@@date-filter.period:Period` },
    ];

    constructor(public datePickerService: DatePickerService,
                private orderPageService: OrderPageService) {}

    ngOnInit(): void {
        this.setSelectedDateRangeType(this.dateOrigin);

        this.DateFilterOptions = this.DateFilterOptions.filter(dateFilter =>
            (this.isFuture ? dateFilter.tense === 'future' : dateFilter.tense === 'past') ||
            dateFilter.tense === 'present');


        this.dateRangeChange(this.selectedDateRangeType);
    }

    setSelectedDateRangeType(dateOrigin: DateOrigin): void {
        switch (dateOrigin) {
            case DateOrigin.Order:
                this.selectedDateRangeType = this.datePickerService.orderDateRangeType;
            break;
            case DateOrigin.History:
                this.selectedDateRangeType = this.datePickerService.historyDateRangeType;
                break;
            case DateOrigin.Outcome:
                this.selectedDateRangeType = this.datePickerService.outcomeDateRangeType;
                break;
            case DateOrigin.MissingVehicles:
                this.selectedDateRangeType = this.datePickerService.missingVehiclesDateRangeType;
                break;
            default:
                this.selectedDateRangeType = this.defaultRangeOption;
                break;
        }
      }

      setSelectedPeriod(dateOrigin: DateOrigin): void {
        switch (dateOrigin) {
            case DateOrigin.Order:
                if (this.datePickerService.orderFromDate && this.datePickerService.orderToDate) {
                    this.startDatepickerValue = this.dateStringToNgbDateStruct(new Date(this.datePickerService.orderFromDate));
                    this.endDatepickerValue = this.dateStringToNgbDateStruct(new Date(this.datePickerService.orderToDate));
                }
                break;
            case DateOrigin.History:
                if (this.datePickerService.historyFromDate && this.datePickerService.historyToDate) {
                    this.startDatepickerValue = this.dateStringToNgbDateStruct(new Date(this.datePickerService.historyFromDate));
                    this.endDatepickerValue = this.dateStringToNgbDateStruct(new Date(this.datePickerService.historyToDate));
                }
                break;
            case DateOrigin.Outcome:
                if (this.datePickerService.outcomeFromDate && this.datePickerService.outcomeToDate) {
                    this.startDatepickerValue = this.dateStringToNgbDateStruct(new Date(this.datePickerService.outcomeFromDate));
                    this.endDatepickerValue = this.dateStringToNgbDateStruct(new Date(this.datePickerService.outcomeToDate));
                }
                break;
            case DateOrigin.MissingVehicles:
                if (this.datePickerService.missingVehiclesFromDate && this.datePickerService.missingVehiclesToDate) {
                    this.startDatepickerValue = this.dateStringToNgbDateStruct(new Date(this.datePickerService.missingVehiclesFromDate));
                    this.endDatepickerValue = this.dateStringToNgbDateStruct(new Date(this.datePickerService.missingVehiclesToDate));
                }
                break;
            default:
                this.selectedDateRangeType = this.defaultRangeOption;
                break;
        }
      }



    setSelectedRange(dateOrigin: DateOrigin, selectedDate: DateFilterOption): void {
        switch (dateOrigin) {
            case DateOrigin.History:
                this.datePickerService.historyDateRangeType = selectedDate;
            break;
            case DateOrigin.Order:
                this.datePickerService.orderDateRangeType = selectedDate;
            break;
            case DateOrigin.Outcome:
                this.datePickerService.outcomeDateRangeType = selectedDate;
            break;
            case DateOrigin.MissingVehicles:
                this.datePickerService.missingVehiclesDateRangeType = selectedDate;
            break;
            default:
            this.selectedDateRangeType = this.defaultRangeOption;
            break;
        }
    }

    setDatePeriod(dateOrigin: DateOrigin): void {
        switch (dateOrigin) {
            case DateOrigin.Order:
                if (this.datePickerService.orderDateRangeType === DateFilterOption.Period) {
                    this.datePickerService.orderFromDate = this._fromDate;
                    this.datePickerService.orderToDate = this._toDate;
                }
            break;
            case DateOrigin.History:
                if (this.datePickerService.historyDateRangeType === DateFilterOption.Period) {
                    this.datePickerService.historyFromDate = this._fromDate;
                    this.datePickerService.historyToDate = this._toDate;
                }
            break;
            case DateOrigin.Outcome:
                if (this.datePickerService.outcomeDateRangeType === DateFilterOption.Period) {
                    this.datePickerService.outcomeFromDate = this._fromDate;
                    this.datePickerService.outcomeToDate = this._toDate;
                }
            break;
            case DateOrigin.MissingVehicles:
                if (this.datePickerService.missingVehiclesDateRangeType === DateFilterOption.Period) {
                    this.datePickerService.missingVehiclesFromDate = this._fromDate;
                    this.datePickerService.missingVehiclesToDate = this._toDate;
                }
            break;
            default:
                this.selectedDateRangeType = this.defaultRangeOption;
            break;
        }
    }

    public dateRangeChangeSelect(selectedRange: string): void {
        this.dateRangeChange(this.selectedDateRangeType);
    }

    public dateRangeChange(selectedRange: DateFilterOption): void {
        this.setSelectedRange(this.dateOrigin, selectedRange);
        this.startDatepickerValue = undefined;
        this.endDatepickerValue = undefined;
        this.isSelectPeriod = (selectedRange === DateFilterOption.Period);
        if (this.isSelectPeriod) {
            this.setSelectedPeriod(this.dateOrigin);
        } else {
            this.datePickerService.removeDateRangeSession(this.dateOrigin);
            this.setFromToDate(selectedRange);
            this.updateDisplayedDates();
            this.notifyDateRangeChanged();
        }
    }

    public setFromToDate(selectedRange: string): void {
        switch (selectedRange) {
            case DateFilterOption.Today:
                this._fromDate = new Date();
                this._toDate = new Date();
                break;

            case DateFilterOption.Yesterday:
                this._fromDate = moment().add(-1, 'days').toDate();
                this._toDate = moment().add(-1, 'days').toDate();
                break;

            case DateFilterOption.Tomorrow:
                this._fromDate = moment().add(1, 'days').toDate();
                this._toDate = moment().add(1, 'days').toDate();
                break;

            case DateFilterOption.LastWeek:
                this._fromDate = moment().add(-7, 'days').toDate();
                this._toDate = new Date();
                break;

            case DateFilterOption.NextWeek:
                this._fromDate = new Date();
                this._toDate = moment().add(7, 'days').toDate();
                break;

            case DateFilterOption.CurrentMonth:
                this._fromDate = moment().startOf('month').toDate();
                this._toDate = moment().endOf('month').toDate();
                break;

            case DateFilterOption.LastMonth:
                this._fromDate = moment().add(-1, 'month').startOf('month').toDate();
                this._toDate = moment().add(-1, 'month').endOf('month').toDate();
                break;

            case DateFilterOption.NextMonth:
                this._fromDate = moment().add(1, 'month').startOf('month').toDate();
                this._toDate = moment().add(1, 'month').endOf('month').toDate();
                break;

            default:
                console.log('not implemented');
        }
    }

    private updateDisplayedDates(): void {
        this.startDatepickerValue = this.dateStringToNgbDateStruct(this._fromDate);
        this.endDatepickerValue = this.dateStringToNgbDateStruct(this._toDate);
    }

    private notifyDateRangeChanged(): void {
        if (this.isSelectAllDates) {
            this.dateRange.emit(undefined);
        } else {
            // include fromdate and also all of the day of the enddate
            this._fromDate.setHours(0, 0, 0);
            this._fromDate.setMilliseconds(0);
            this._toDate.setHours(0, 0, 0);
            this._toDate.setMilliseconds(0);
            this._toDate.setDate(this._toDate.getDate() + 1); // lägg till en dag
            this._toDate = new Date(this._toDate.getTime() - 1); // drag bort en millisekund
            this.dateRange.emit(new DateRange(this._fromDate, this._toDate));
        }
    }

    dateStringToNgbDateStruct(date: Date): NgbDateStruct {
        return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
    }

    public filterClick(): void {
        this.isFilterVisible = true;
        if (this.isSelectAllDates) {
            this.isSelectAllDates = false;
            this.defaultDate();
        }
    }

    private defaultDate(): void {
        this.dateRangeChange(this.defaultRangeOption);
        this.selectedDateRangeType = this.defaultRangeOption;
    }

    public reset(): void {
        if (this.clean) {
          this.clean.emit();
        }

        this.datePickerService.removeAllDateSession(this.dateOrigin);
        this.defaultDate();
        this.orderPageService.getOrdersWithTrips(this.currentPage);
    }

    public startDateDateTimeClicked(): void {
        if (this.isSelectPeriod) {
            this.startDatepicker.toggle();
        }
    }
    public endDateDateTimeClicked(): void {
        if (this.isSelectPeriod) {
            this.endDatepicker.toggle();
        }
    }

    public onDatepickerDateChange(): void {
        if (this.startDatepickerValue && this.endDatepickerValue) {
            this._fromDate = DateTimePickerHelper.getSelectedDateTime(this.startDatepickerValue, 0, 0);
            this._toDate = DateTimePickerHelper.getSelectedDateTime(this.endDatepickerValue, 0, 0);
            this.setDatePeriod(this.dateOrigin);
            this.notifyDateRangeChanged();
        }
    }
}
