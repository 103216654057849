import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-assign-vehicle-warning',
  templateUrl: './assign-vehicle-warning.component.html',
  styleUrls: ['./assign-vehicle-warning.component.scss']
})
export class AssignVehicleWarningComponent implements OnInit {
  @Input() assignedOrderId: number;
  @Input() assignedDriveOrderId: number;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  assignVehicle() {
      this.activeModal.close();
  }

}
