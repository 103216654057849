export class Pos {
    constructor(latitude: number, longitude: number, time: number) {
        this.latitude = latitude;
        this.longitude = longitude;
        this.time = time;
    }
    time: number = null; // Timestamp
    latitude:  number  = null;
    longitude: number = null;
  }
