export enum ListType {
    Ongoing = 1,
    UnplanedOrders = 2,
    PlannedOrders = 3,
    UnboundOrders = 4,
}

export enum SortBy {
    StartTime = 0,
    OrderId = 1,
    TripId = 2,
    Operator = 3,
    EndTime = 4,
    StartTripStop = 5,
    EndTripStop = 6,
    SubContractor = 7,
    DriverPhoneNumber = 8,
    VehicleType = 9,
    TrainNumber = 10,
    OrderReason = 11,
    ReferenceId = 12,
    Comment = 13,
    OrderType = 14,
}