<app-order-menu [orderId]="orderId"></app-order-menu>
<div class="container">
  <div class="main pt-0">
    <hr>
    <h2 class="mt-4 mb-4" i18n="@@subcontractors.assign-driveorder">Tilldela körorder</h2>
    <div class="caption-lt" i18n="@@subcontractors.assigned-driveorders">Antal tilldelade körordrar</div>
    <div>
      {{numberOfAssignedDriveOrders}}
      <ng-container i18n="@@subcontractors.of">av</ng-container>
      {{totalNumberOfDriveOrders}}
    </div>

    <h3
      *ngIf="order?.currentState && (
        order.currentState.stateEnum===OrderState.SentToNobina ||
        order.currentState.stateEnum===OrderState.ReceivedByNobina
      )"
      class="mt-4 mb-5" i18n="@@subcontractors.order-not-active"
    >
      Beställningen är inte aktiverad
    </h3>

    <form
      *ngIf="order?.currentState && (
        order.currentState.stateEnum===OrderState.Active ||
        order.currentState.stateEnum===OrderState.Cancelled ||
        order.currentState.stateEnum===OrderState.Completing ||
        order.currentState.stateEnum===OrderState.Ongoing ||
        order.currentState.stateEnum===OrderState.Completed ||
        order.currentState.stateEnum===OrderState.ReportedComplete
      )"
      [formGroup]="subconForm"
    >
      <div class="row">
        <div class="col" *ngIf="(!userHasAnyRole([UserRoles.BergkvaraTrafikledare]))">
          <h3 class="mt-4 mb-3" i18n="@@subcontractors.assign-internal">
            Tilldela egna trafikområden
            <img class="assign-internal-arrow"
                 [class.rotate]="!showAssignInternal"
                 (click)="toggleAssignInternal()"
                 src="/assets/img/icons/angle-up.svg"
                 alt="Icon angle-up"
            />
          </h3>
          <div class="caption-lt" *ngIf="hasNoInternalSubcontractors()" i18n="@@subcontractors.no-internal">
            Inga egna trafikområden är aktuella för denna beställning.
          </div>
          <div *ngIf="showAssignInternal">
            <app-order-subcontractor-order *ngFor="let aq of internalSubcontractorOrders"
                                           [orderId]="aq.orderId"
                                           [numberOfVehicles]="numberOfUnassignedDriveOrders"
                                           [numberOfVehiclesLeftToSet]="numberOfUnassignedDriveOrders"
                                           [subcontractorOrder]="aq.subcontractorOrder"
                                           [disabled]="isHistory"
                                           [orderType]="order.orderType"
                                           [isInternal]="true"
                                           (askedSubcontractor)=askedSubcontractor($event)
                                           (subcontractorOrderCreatedOutput)=subcontractorOrderCreatedEvent($event)>
            </app-order-subcontractor-order>
          </div>
        </div>
        <div class="col">
          <h3 class="mt-4 mb-3" i18n="@@subcontractors.assign-subcontractor">Tilldela underentrepenörer</h3>
          <div class="alert alert-info" role="alert"
               *ngIf="!hasQueriesCanBeAssigned && externalSubcontractorOrders?.length == 0"
               i18n="@@subcontractors.no-accepted-inquiries">
            Det finns inga accepterade förfrågningar.
          </div>
          <app-order-subcontractor-order *ngFor="let aq of externalSubcontractorOrders"
                                         [orderId]="aq.orderId"
                                         [numberOfVehicles]="numberOfUnassignedDriveOrders"
                                         [numberOfVehiclesLeftToSet]="numberOfUnassignedDriveOrders"
                                         [subcontractorOrder]="aq.subcontractorOrder"
                                         [disabled]="isHistory"
                                         [orderType]="order.orderType"
                                         [isInternal]="false"
                                         (subcontractorOrderCreatedOutput)=subcontractorOrderCreatedEvent($event)
          >
          </app-order-subcontractor-order>
          <app-subcontractors-availability-inquiry *ngFor="let aq of availabilityInquiries | filterCanAssign:true"
                                                   [availQuery]="aq.availQuery"
                                                   [orderId]="aq.orderId"
                                                   [numberOfVehicles]="noOfVehicles()"
                                                   [numberOfVehiclesLeftToSet]="numberOfUnassignedDriveOrders"
                                                   [disabled]="isHistory || isSaveInProgress"
                                                   [orderType]="order.orderType"
                                                   (askedSubcontractor)=askedSubcontractor($event)
                                                   (subcontractorOrderCreatedOutput)="subcontractorOrderCreatedEvent($event)"
                                                   (saveInProgress)="onIsSaveInProgress($event)"
          >
          </app-subcontractors-availability-inquiry>

          <h3 class="mt-4 mb-3" i18n="@@subcontractors.select-subcontractor">Välj underentreprenör</h3>

          <div class="d-flex flex-column flex-sm-row align-items-end justify-content-between">
            <div class="my-2">
              <label for="numberOfVehicles" i18n="@@subcontractors.number-of-vehicles">Fråga efter antal fordon</label>
              <select class="form-control form-select" id="numberOfVehicles" formControlName="numberOfVehicles">
                <option *ngFor="let noOfVehicles of optionlistNoOfVehicles" [ngValue]="noOfVehicles">{{ noOfVehicles }}
                </option>
              </select>
            </div>
            <div>
              <button id="subcontractor-btn" class="btn btn-primary btn-block" (click)="askEveryone()"
                      type="submit"
                      [disabled]="haveAskedEveryone() || buttonsDisabled || this.numberOfUnassignedDriveOrders === 0"
                      i18n="@@subcontractors.ask-everyone">Fråga alla</button>
            </div>
          </div>
          <app-subcontractors-availability-inquiry *ngFor="let aq of availabilityInquiries | filterCanAssign:false"
                                                   [orderId]="aq.orderId"
                                                   [availQuery]="aq.availQuery"
                                                   [numberOfVehicles]="noOfVehicles()"
                                                   [numberOfVehiclesLeftToSet]="numberOfUnassignedDriveOrders"
                                                   [disabled]="isHistory"
                                                   [orderType]="order.orderType"
                                                   [numberOfVehiclesAsked]="subconForm.controls['numberOfVehicles'].value"
                                                   (askedSubcontractor)=askedSubcontractor($event)
                                                   (subcontractorOrderCreatedOutput)="subcontractorOrderCreatedEvent($event)">
          </app-subcontractors-availability-inquiry>
        </div>
      </div>
    </form>
  </div>
</div>
