<div class="tab-container pb-3">
  <ul class="nav nav-tabs mx-auto">
    <li *ngFor="let link of links; i as index" class="nav-item">
      <a class="nav-link"
         [class.active]="route?.url === ('/settings/' + link.path)"
         [routerLink]="'/settings/' + link.path"
      >{{link.text}}</a>
    </li>
  </ul>
</div>
