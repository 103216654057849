import { Injectable } from '@angular/core';

@Injectable()
export class ListViewService {


  private readonly _orderListType = 'ORDER_LIST_TYPE';

  private readonly _subContractor = 'SUB_CONTRACTOR';

  private readonly _trainNumber = 'TRAIN_NUMBER';

  private readonly _operatorIds = 'OPERATOR_IDS';

  private _pageSize: string;

  set orderListType(orderType: number) {
    localStorage.setItem(this._orderListType, JSON.stringify(orderType));
  }

  get orderListType(): number {
    const orderListType = localStorage.getItem(this._orderListType);
    if (orderListType && orderListType !== 'undefined' && orderListType !== '') {
      return JSON.parse(orderListType);
    } else {
      return 1;
    }
  }

  set subContractor(subContractor: string) {
    localStorage.setItem(this._subContractor, JSON.stringify(subContractor));
  }

  get subContractor(): string {
    const subContractor = localStorage.getItem(this._subContractor);
    if (subContractor && subContractor !== 'undefined' && subContractor !== '') {
      return JSON.parse(subContractor);
    } else {
      return '';
    }
  }

  set trainNumber(trainNumber: string) {
    localStorage.setItem(this._trainNumber, trainNumber);
  }

  get trainNumber(): string {
    const trainNumber = localStorage.getItem(this._trainNumber);
    if (trainNumber && trainNumber !== 'undefined' && trainNumber !== '') {
      return trainNumber;
    } else {
      return '';
    }
  }

  set operatorIds(operators: number[]) {
    localStorage.setItem(this._operatorIds, JSON.stringify(operators));
  }

  get operatorIds(): number[] {
    const operatorIds = localStorage.getItem(this._operatorIds);
    if (operatorIds && operatorIds !== 'undefined' && operatorIds !== '') {
      return JSON.parse(operatorIds);
    } else {
      return [];
    }
  }

  set pageSize(pageSize: number) {
    this._pageSize = JSON.stringify(pageSize);
  }

  get pageSize(): number {
    return this._pageSize && this._pageSize !== 'undefined' && this._pageSize !== '' ? JSON.parse(this._pageSize) : 10;
  }

  clearUserPerferenceSession() {
    localStorage.removeItem('_orderListType');
    localStorage.removeItem('_subContractor');
    localStorage.removeItem('_trainNumber');
    localStorage.removeItem('_operatorIds');
    localStorage.removeItem('_pageSize');
  }
}
