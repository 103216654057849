import { Component, OnInit } from '@angular/core';
import { GeodataService } from '../../../common/services/geodata.service';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from '../../../common/services/order.service';
import { Order } from '../../../common/models/order';
import { AuthService } from '../../../common/services/auth.service';
import { UserRoles } from '../../../authentication/user-roles';
import { SubcontractorService } from '../../../common/services/subcontractor.service';

@Component({
  selector: 'app-position-history-map',
  templateUrl: './position-history-map.component.html',
  styleUrls: ['./position-history-map.component.scss'],
})
export class PositionHistoryMapComponent implements OnInit {

  autoZoom = true;
  includeGpsPoints = true;
  vehicleGeoData: any = null;
  routeGeoData: any = null;
  disableStreetView = true;
  orderId: number;
  tripId: any;
  order: Order;

  constructor(
    private geodataService: GeodataService,
    private orderService: OrderService,
    private subContractorService: SubcontractorService,
    private route: ActivatedRoute,
    private authService: AuthService) {}

  ngOnInit() {
    this.orderId = +this.route.snapshot.paramMap.get('id');
    this.tripId = +this.route.snapshot.paramMap.get('tripid');
    if(this.authService.userHasAnyRole([UserRoles.UETrafikledare])){
      this.getSubContractorOrder();
    } else {
      this.getOrder();
    }
  }

  getOrder() {
    this.orderService.getOrder(this.orderId)
      .subscribe(result => {
        this.order = result.body;
        this.getVehicleGeoData();
      });
  }

  getSubContractorOrder() {
    this.subContractorService.getOrder(this.orderId)
      .subscribe(result => {
        this.order = result.body;
        this.getVehicleGeoData();
      });
  }

  getVehicleGeoData() {
    this.geodataService
      .getVehiclePositionHistory(this.tripId)
      .subscribe(result => this.onVehicleGeoDataRetrieved(result.body));
  }

  onVehicleGeoDataRetrieved(geoData: any) {
    this.routeGeoData = geoData;
  }

}
