<!--
<div ngbAccordion [closeOthers]='true' class='mb-5'>
  <div ngbAccordionItem [collapsed]='false'>
    <h2 ngbAccordionHeader>
      <button
        ngbAccordionButton
        i18n='@@side-panel.filter'
      >
        Filtrera utförare
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class='my-2'>
        <ng-template>
          Test Test 1
        </ng-template>
      </div>
    </div>
  </div>
  <div ngbAccordionItem>
    <h2 ngbAccordionHeader>
      <button
        ngbAccordionButton
        i18n='@@side-panel.filter'
      >
        Importera utförare/trafikoperatör
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class='my-2'>
        <ng-template>
          Test Test 2
        </ng-template>
      </div>
    </div>
  </div>
</div>
-->


<ngb-accordion
  #acc='ngbAccordion'
  class='mt-4'
  activeIds='filter-panel'
  [closeOthers]='true'
  [destroyOnHide]='false'
  (panelChange)='beforeChange($event, acc)'
>
  <ngb-panel id='filter-panel'>
    <ng-template ngbPanelHeader>
      <div class='d-flex align-items-center justify-content-between'>
        <button
          id='side-panel.filter'
          ngbPanelToggle
          class='btn btn-link container-fluid text-start'
          i18n='@@side-panel.filter'
        >
          Filtrera utförare
          <svg
            [ngStyle]="{'transform': firstIsExpanded ? 'rotate(180deg)' :  'rotate(0deg)'}"
            xmlns='http://www.w3.org/2000/svg'
            style='transform: rotate(180deg)'
            width='16'
            height='16'
            fill='currentColor'
            class='bi bi-chevron-down'
            viewBox='0 0 16 16'
          >
            <path fill-rule='evenodd'
                  d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' />
          </svg>
        </button>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      <app-filter-subcontractors
        [operators]='operators'
        (operatorsEvent)='operatorsEvent.emit($event)'
      ></app-filter-subcontractors>
    </ng-template>
  </ngb-panel>
  <ngb-panel id='import-panel' style='margin: 0'>
    <ng-template ngbPanelHeader>
      <div class='d-flex align-items-center justify-content-between'>
        <button
          id='side-panel.import'
          ngbPanelToggle
          class='btn btn-link container-fluid text-start'
          i18n='@@side-panel.import'
        >
          Importera utförare/trafikoperatör
          <svg
            [ngStyle]="{'transform': firstIsExpanded ? 'rotate(0deg)' :  'rotate(180deg)'}"
            xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-down'
            viewBox='0 0 16 16'>
            <path fill-rule='evenodd'
                  d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' />
          </svg>
        </button>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      <app-import-subcontractor></app-import-subcontractor>
    </ng-template>
  </ngb-panel>
</ngb-accordion>
