import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { environment } from '../environments/environment';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';

export const extraQueryParameters = { 'countryCode': environment.language === 'sv' ? 'SE' : 'NO', 'theme': 'light', 'ui_locales': environment.language };

export function msalInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.b2c.clientId,
      authority: environment.b2c.authority,
      redirectUri: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
      knownAuthorities: [environment.b2c.authorityDomain],
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback: (logLevel: LogLevel, message: string) => {
          console.log(message);
        },
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function msalGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      extraQueryParameters: extraQueryParameters,
      scopes: environment.b2c.mainServiceScopes,
    },
    loginFailedRoute: '/login-failed',
  };
}

export function msalInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap
    .set(environment.apiUrl, environment.b2c.mainServiceScopes)
    .set(environment.garageUrl, environment.b2c.garageServiceScopes)
    .set(environment.outcomeUrl, environment.b2c.outcomeServiceScopes)
    .set(environment.positionUrl, environment.b2c.iotFunctionsServiceScopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
    authRequest: {
      extraQueryParameters: extraQueryParameters,
    }
  };
}
