import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthService } from '@services/auth.service';
import { UserRoles } from '@authentication/user-roles';

// *******************************************************
// Affärslogik för att spärra navigering utifrån roller
@Injectable()
export class AuthGuardRolesService implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canNavigateToUrl(route, state.url);
  }

  // returnera TRUE om användaren, utifrån sina roller, kan navigera
  canNavigateToUrl(route: ActivatedRouteSnapshot, url: string): boolean {
    if (this.authService.user === undefined || this.authService.user.idTokenClaims === undefined) {
      return false;
    }

    if (url.startsWith('/order')) {

      if (url.endsWith('subcontractors')) {
        if (this.authService.userHasAnyRole([UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare,
          UserRoles.NobinaTrafikledare])) {
          return true;
        } else {
          this.router.navigate([`/orders`]);
          return false;
        }
      } else {
        // får INTE vara UE, utan måste vara Nobina eller tågoperatör
        if (this.authService.userHasAnyRole([UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare,
          UserRoles.NobinaTrafikledare, UserRoles.OperatorTrafikledare])) {
          return true;
        }

        // redirecta till /subcontractor/:subcontractorId/subcontractor-orders
        this.router.navigate([`/subcontractor/${this.authService.user.idTokenClaims.garageIds[0]}/subcontractor-orders`]);


        return false;
      }
    }

    if (url.startsWith('/subcontractor')) {
      const scId = route.paramMap.get('subcontractorId');
      // måste vara UETrafikledare och ha rätt subcontratorId
      return this.authService.userHasAnyRole([UserRoles.UETrafikledare])
        && (scId === null || scId === this.authService.user.idTokenClaims.garageIds[0]);
    }

    if (url.startsWith('/usersettings')) {
      return this.authService.userHasAnyRole([UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare, UserRoles.NobinaTrafikledare, UserRoles.OperatorTrafikledare]);
    }

    if (url.startsWith('/settings')) {
      return this.authService.userHasAnyRole([UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare]);
    }

    return true;
  }

}
