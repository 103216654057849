import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { OutcomeService } from '@services/outcome.service';
import { Outcome } from '@models/outcome';
import { AlertService } from '@services/alert.service';

@Component({
  selector: 'app-outcome-reference',
  templateUrl: './outcome-reference.component.html',
  styleUrls: ['./outcome-reference.component.scss']
})
export class OutcomeReferenceComponent implements OnInit, OnChanges {

  @Input() outcome: Outcome;

  isRevisedAndNotSameAsExisting: boolean;
  isEditMode = false;

  // Backing field for the input element
  editRevisedReferenceId: string;

  constructor(private outcomeService: OutcomeService, private alertService: AlertService) { }

  ngOnInit() {
    this.updateFlags();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateFlags();
  }

  updateFlags() {
    if (!this.outcome) return;

    this.isRevisedAndNotSameAsExisting = (this.outcome.revisedReferenceId && (this.outcome.revisedReferenceId !== this.outcome.referenceId));
  }

  toggleEditMode() {
    this.isEditMode = !this.isEditMode;

    if (this.isEditMode) {
      this.editRevisedReferenceId = this.outcome.revisedReferenceId ? this.outcome.revisedReferenceId : this.outcome.referenceId;
    }
  }

  save() {
    if (!this.isEditMode) return;

    if (this.editRevisedReferenceId == '') {
      this.editRevisedReferenceId = null;
    }

    this.outcomeService.putOutcomeOrderReference(this.outcome.id, { revisedReferenceId: this.editRevisedReferenceId })
      .subscribe({
        next: (v) => this.onSave(),
        error:
          (e) => {
            let apiError = e.error != null ? e.error.errorMessage : "";
            let msg = $localize`:@@outcome-reference.save-error:Kunde inte uppdatera referensen`;
            this.alertService.error(`${e.statusText}: ${msg}. Fel-ID: ${e.error.operationId}`);
          }
      });
  }

  // Called after a successful save
  private onSave() {
    this.outcome.revisedReferenceId = this.editRevisedReferenceId;
    this.updateFlags();
    this.toggleEditMode();
  }
}
