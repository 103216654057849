<div class='toast-background'>
  <div class='row align-items-center'>
    <div class='col-2 dot-col'>
      <span class='dot'></span>
    </div>
    <div class='col message-col'>

      <div class='toast-title caption-lt'>
        {{ notification?.title }}
      </div>

      <div class='toast-message'>
        <div class='main-message'>
          {{ notification?.main }}
        </div>
        <div class='sub-message'>
          <span class='caption-lt'>{{ notification?.submain }}</span>
          <span class='caption-md' [class.ms-2]="notification?.submain">{{ notification?.type }}</span>
        </div>
      </div>
    </div>
    <div class='col-2 goto-col'>
      <div (click)="navigateTo(notification)" i18n="@@toast.go-to">GÅ TILL</div>
    </div>
    <div class='col-2 close-col'>
      <a (click)='remove()'>&times;</a>
    </div>
  </div>
</div>
