<div class="modal-header">
  <h2 class="modal-title" i18n="@@notifications-modal.notifications">Notiser</h2>
  <button *ngIf="isAnyUnread()" (click)="markAllRead()" class="btn btn-link btn-sm mark-all-read" i18n="@@notifications-modal.mark-all-read">Markera alla som lästa</button>
</div>
<div class="modal-body">
  <div *ngFor="let notification of notifications?.slice(0, 5)" class="notification" [class.unread]="!notification.isRead" (click)="readNotification(notification)">
    <div class='row align-items-center'>
      <div class='col-1 dot-col'>
        <span *ngIf="!notification.isRead" class='dot'></span>
      </div>
      <div class='col message-col'>
        <div class='notification-title caption-lt'>
          <span>{{ notification.title + ' ' }}</span><span class="float-right">{{ notification.created | amTimeAgo }}</span>
        </div>
        <div class='notification-message'>
          <div class='main-message'>
            {{ notification.main }}
          </div>
          <div class='sub-message'>
            <span class='caption-lt'>{{ notification.submain }}</span>
            <span *ngIf="notification.type !== 'Alert_LateDeparture'" class='caption-md' [class.ms-2]="notification.submain">{{ notification.type }}</span>
            <span *ngIf="notification.type === 'Alert_LateDeparture'" class='caption-md' [class.ms-2]="notification.submain" i18n="@@notifications.alert-latedeparture">Försenad avgång</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="notifications?.length == 0" class="ms-3" i18n="@@notifications-modal.no-notifications">
    Inga notiser de senaste 24 timmarna
  </div>
</div>
<div class="modal-footer">
  <button *ngIf="notifications?.length > 0" type="button" class="btn btn-link see-all" (click)="navigateToAll()" i18n="@@notifications-modal.show-all">Se alla</button>
</div>
