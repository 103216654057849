import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../common/components/base.component';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UserRoles } from '../../../authentication/user-roles';
import { AuthService } from '../../../common/services/auth.service';
import { UnboundOrder } from '../../../common/models/unbound-order';
import { ActivatedRoute, Router } from '@angular/router';
import { UnboundOrderService } from '../../../common/services/unbound-order.service';
import { UnboundOrderState } from '../../../common/models/unbound-order-state';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-unbound-order-completion',
  templateUrl: './unbound-order-completion.component.html',
  styleUrls: ['./unbound-order-completion.component.scss']
})
export class UnboundOrderCompletionComponent extends BaseComponent implements OnInit {
  unboundOrder: UnboundOrder;
  unboundOrderCompletionForm: UntypedFormGroup;
  now: Date = new Date();
  UserRoles = UserRoles;
  isHistory: boolean;
  UnboundOrderState = UnboundOrderState;
  isActivated = false;
  orderNotActivatedMessage: string;

constructor(private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private unboundOrderService: UnboundOrderService,
    private authService: AuthService) {
    super();
  }

  ngOnInit() {
    this.unboundOrder = new UnboundOrder();
    this.isHistory = this.router.url.startsWith('/history');
    this.createForm();
    this.getOrder();
    if (this.isHistory) {
      this.unboundOrderCompletionForm.disable();
    }
  }

  createForm() {
    this.unboundOrderCompletionForm = this.fb.group({
      finalCost: [0, [Validators.pattern('^[0-9]*$'), Validators.required]],
      distanceKm: [null, [Validators.pattern('^[0-9]*$')]],
      additionalInfo: ''
    });
  }

  get finalCost() { return this.unboundOrderCompletionForm.get('finalCost'); }
  get distanceKm() { return this.unboundOrderCompletionForm.get('distanceKm'); }
  get additionalInfo() { return this.unboundOrderCompletionForm.get('additionalInfo'); }


  getOrder(): void {
    this.unboundOrder = new UnboundOrder();
    this.unboundOrder.id = +this.route.snapshot.paramMap.get('id');
    if (this.unboundOrder.id !== 0) {
      this.unboundOrderService.getOrder(this.unboundOrder.id)
        .subscribe(result => {
          this.onOrderRetrieved(result.body);
        });
    }
  }

  onOrderRetrieved(order: UnboundOrder): void {
    this.unboundOrder = order;
    if (this.unboundOrder !== null && (this.unboundOrder.activated.stateEnum === UnboundOrderState.Active  || this.unboundOrder.currentState.stateEnum === UnboundOrderState.Cancelled)) {
      this.isActivated = true;
    } else {
      this.orderNotActivatedMessage = $localize`:Beställningen är inte aktiverad@@unboud-order-assignment.order-not-activated-message:Beställningen är inte aktiverad`;
    }

    if (this.unboundOrder.currentState.stateEnum === UnboundOrderState.Active || this.unboundOrder.currentState.stateEnum === UnboundOrderState.Cancelled) {
      this.unboundOrderCompletionForm.enable();
    } else {
      this.unboundOrderCompletionForm.patchValue({
        finalCost: order.completion.finalCost,
        distanceKm: order.completion.distanceKm,
        additionalInfo: order.completion.additionalInfo
      });
      this.unboundOrderCompletionForm.disable();
    }

    if (this.unboundOrder.currentState.stateEnum === UnboundOrderState.SentToNobina
      && this.userHasAnyRole([UserRoles.NobinaTrafikledare, UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare])) {
      this.unboundOrderService.updateOrderState(this.unboundOrder.id, UnboundOrderState.ReceivedByNobina)
        .subscribe(null);
    }
  }

  save() {
    // trigger validation on all fields
    Object.keys(this.unboundOrderCompletionForm.controls).forEach(field => {
      const control = this.unboundOrderCompletionForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });


    if (this.unboundOrderCompletionForm.valid) {
      // Copy the form values over the order object values
      const o = Object.assign(this.unboundOrder.completion, this.unboundOrderCompletionForm.value);
      if(this.unboundOrder.currentState.stateEnum === UnboundOrderState.Active) {
        this.unboundOrderService.completeUnboundOrder(this.unboundOrder.id, o)
        .subscribe(response => this.onSaveComplete(response.headers.get('location')),
          err => this.onHttpError(err));
      }
      if(this.unboundOrder.currentState.stateEnum === UnboundOrderState.Cancelled){
        this.unboundOrderService.cancellationConfirmedWithCompletionUnboundOrder(this.unboundOrder.id, o)
        .subscribe(response => this.onSaveComplete(response.headers.get('location')),
          err => this.onHttpError(err));
      }
    }
  }


  onHttpError(httpError: HttpErrorResponse): void {
    this.unboundOrderCompletionForm.markAsDirty();
  }

  onSaveComplete(location: string): void {
    this.unboundOrderCompletionForm.markAsPristine();
    this.router.navigateByUrl('unboundorders');
  }

  dismiss(): void {
    // TODO: fixa url när history implementerat
    if (this.isHistory) {
      this.router.navigateByUrl('history');
    } else {
      this.router.navigateByUrl('unboundorders');
    }
  }

  userHasAnyRole(userRoles: UserRoles[]): boolean {
    return this.authService.userHasAnyRole(userRoles);
  }

}
