<app-order-menu [orderId]='order.id'></app-order-menu>
<div class='container'>

  <div class='main'>

    <hr class='mt-0'>

    <h2 class='mt-4' i18n='@@hastus-order.order'>Beställning</h2>
    <div class='row'
         *ngIf='userHasAnyRole([UserRoles.NobinaTrafikledare,UserRoles.NobinaLeveransansvarig,UserRoles.BergkvaraTrafikledare])'>
      <div class='col'>
        <div class='caption'>{{order?.operator?.name}}</div>
      </div>
    </div>
    <div class='row'>
      <div class='col'>
        <div class='callout-block-lg'>
          <span class='callout'></span>
          <div class='caption-lt' i18n='@@hastus-order.order-id'>Beställningsnummer</div>
          <div class='row'>
            <div class='col'>
              <div class='blackish'>{{order?.id}}</div>
            </div>
          </div>
        </div>
        <div class='callout-block-lg'>
          <span class='callout'></span>
          <div class='caption-lt' i18n='@@hastus-order.status'>Status</div>
          <div class='row'>
            <div class='col'>
              <span class='blackish'>{{order?.currentState?.stateDescription}}</span>
              <span *ngIf='cancellationReason'> - {{cancellationReason}}</span>
              <span *ngIf='order?.cancellationDetails?.additionalInfo'> : {{order?.cancellationDetails?.additionalInfo}}</span>
            </div>
          </div>
        </div>
        <div class='callout-block-lg'>
          <span class='callout'></span>
          <div class='caption-lt' i18n='@@hastus-order.period'>Period</div>
          <div class='row'>
            <div class='col'>
              <div class='blackish'>{{ order?.startTime | amCalendar }}</div>
            </div>
            <div class='col-1'>
              <img class='right-arrow' src='/assets/img/icons/right-arrow.png' alt="Icon right-arrow">
            </div>
            <div class='col'>
              <div class='blackish'>{{ order?.endTime | amCalendar }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class='col ps-5 pe-5'>
        <div class='card tb-card-lg'>
          <h4 class='card-header' i18n='@@hastus-order.complete-information'>Komplettera information</h4>
          <div class='card-body ms-3 me-3'>
            <form [formGroup]='orderForm' (ngSubmit)='save()' novalidate class='form mt-2'>


              <div class='col mb-3'>
                <label for='reason' i18n='@@hastus-order.reason'>Orsak</label>
                <select class='form-select form-control' id='reason' formControlName='reason'>
                  <option value='' i18n='@@hastus-order.select-reason'>Välj orsak</option>
                  <option *ngFor='let r2 of reasons' [ngValue]='r2'>{{ r2.description }}</option>
                </select>
                <div *ngIf='reason.invalid && (reason.dirty || reason.touched)' class='text-danger'
                     i18n='@@hastus-order.reason-mandatory'>
                  Orsak är obligatoriskt
                </div>
              </div>


              <div class='pb-3'>
                <div class='col'>
                  <label for='reasonAdditionalInfo' i18n='@@hastus-order.reason-description'>Beskriv orsak</label>
                  <textarea class='form-control' placeholder='Frivilligt' id='reasonAdditionalInfo'
                            formControlName='reasonAdditionalInfo' rows='3'
                            i18n-placeholder='@@hastus-order.placeholder-voluntary'></textarea>
                </div>
              </div>

              <div class='mt-4 mb-3'>
                <div class='col'>
                  <label for='referenceId' i18n='@@hastus-order.reference'>Referens</label>
                  <input class='form-control' placeholder='Frivilligt' type='text' id='referenceId'
                         formControlName='referenceId' i18n-placeholder='@@hastus-order.placeholder-voluntary'>
                </div>
              </div>

              <div class='mb-4'>
                <div class='col'>
                  <label for='otherRequests' i18n='@@hastus-order.other-requests'>Övriga önskemål</label>
                  <textarea class='form-control' placeholder='Frivilligt'
                            i18n-placeholder='@@hastus-order.placeholder-voluntary' id='otherRequests'
                            formControlName='otherRequests' rows='3'></textarea>
                </div>
              </div>

              <div class='mb-2' *ngIf='userHasAnyRole([UserRoles.NobinaTrafikledare,UserRoles.NobinaLeveransansvarig,UserRoles.BergkvaraTrafikledare,UserRoles.OperatorTrafikledare])
                                                  && !isHistory'>
                <div class='col d-flex justify-content-end'>
                  <button class='btn btn-outline-secondary' type='submit' [disabled]='submitDisabled'
                          i18n='@@hastus-order.save'>Spara
                  </button>
                </div>
              </div>


            </form>
          </div>
        </div>
      </div>
    </div>


    <hr>
    <div class='d-flex justify-content-end mt-5'>
      <div class='px-3'>
        <button class='btn btn-outline-secondary' type='button' [disabled]='buttonsDisabled' (click)='dismiss()'
                i18n='@@order.cancel'>Avbryt
        </button>
      </div>
      <div *ngIf='(userHasAnyRole([UserRoles.NobinaLeveransansvarig,UserRoles.BergkvaraTrafikledare])
        && (order?.currentState && order.currentState.stateEnum == OrderState.SentToNobina) || (order?.currentState && order.currentState.stateEnum == OrderState.ReceivedByNobina) || (order?.currentState && order.currentState.stateEnum == OrderState.Active) || (order?.currentState && order.currentState.stateEnum == OrderState.Ongoing))'
           class='px-3'>
        <button id='cancel-order-btn' class='btn btn-primary' type='button' [disabled]='buttonsDisabled'
                (click)='cancelOrder()' i18n='@@order.cancel-order'>Avbeställ
        </button>
      </div>
      <div
        *ngIf='(userHasAnyRole([UserRoles.NobinaLeveransansvarig,UserRoles.BergkvaraTrafikledare]) && order?.currentState && order.currentState.stateEnum == OrderState.Cancelled)'
        class='px-3'>
        <button id='verify-cancel-order-btn' class='btn btn-primary' type='button' [disabled]='buttonsDisabled'
                (click)='closeOrder()' i18n='@@order.confirm-cancel'>Bekräfta avbeställning
        </button>
      </div>
    </div>


  </div>
</div>
