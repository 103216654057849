import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderService } from '../../../common/services/order.service';
import { OrderState } from '../../../common/models/order-state';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  @Input() title: string;
  @Input() description: string;
  @Input() confirmButton: string;
  @Input() cancelButton: string;

  buttonsDisabled = false;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

}
