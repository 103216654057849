import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../common/components/base.component';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UserRoles } from '../../../authentication/user-roles';
import { AuthService } from '../../../common/services/auth.service';
import { UnboundOrder } from '../../../common/models/unbound-order';
import { ActivatedRoute, Router } from '@angular/router';
import { UnboundOrderService } from '../../../common/services/unbound-order.service';
import { forkJoin } from 'rxjs';
import { UnboundOrderState } from '../../../common/models/unbound-order-state';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmModalComponent } from '../../../common/components/confirm-modal/confirm-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponent } from '../../../common/components/alert-modal/alert-modal.component';

@Component({
  selector: 'app-unbound-order-assignment',
  templateUrl: './unbound-order-assignment.component.html',
  styleUrls: ['./unbound-order-assignment.component.scss'],
})
export class UnboundOrderAssignmentComponent extends BaseComponent implements OnInit {
  unboundOrder: UnboundOrder;
  unboundOrderAssignmentForm: UntypedFormGroup;
  now: Date = new Date();
  UserRoles = UserRoles;
  isHistory: boolean;
  isSaveButtonDisabled = true;
  UnboundOrderState = UnboundOrderState;
  isActivated = false;
  orderNotActivatedMessage: string;
  modalRef: NgbModalRef;
  request = false;

  constructor(private fb: UntypedFormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private unboundOrderService: UnboundOrderService,
              private authService: AuthService,
              private modalService: NgbModal) {
    super();
  }

  ngOnInit() {
    this.unboundOrder = new UnboundOrder();
    this.isHistory = this.router.url.startsWith('/history');
    this.createForm();
    this.getOrder();
    if (this.isHistory) {
      this.unboundOrderAssignmentForm.disable();
    }
  }

  // Regex reference: https://blog.mailtrap.io/angular-email-validation/
  createForm() {
    this.unboundOrderAssignmentForm = this.fb.group({
      contractor: [null, Validators.required],
      contactInfo: '',
      bookingReference: '',
      additionalInfo: '',
      email: ['', Validators.pattern('^\.+[a-z0-9._%-]+@[a-z0-9.-]+\.[a-z]{2,4}$')],
    });
  }

  get contractor() {
    return this.unboundOrderAssignmentForm.get('contractor');
  }

  get contactInfo() {
    return this.unboundOrderAssignmentForm.get('contactInfo');
  }

  get bookingReference() {
    return this.unboundOrderAssignmentForm.get('bookingReference');
  }

  get additionalInfo() {
    return this.unboundOrderAssignmentForm.get('additionalInfo');
  }

  get email() {
    return this.unboundOrderAssignmentForm.get('email');
  }

  getOrder(): void {
    this.unboundOrder = new UnboundOrder();
    this.unboundOrder.id = +this.route.snapshot.paramMap.get('id');

    if (this.unboundOrder.id !== 0) {
      this.unboundOrderService.getOrder(this.unboundOrder.id)
        .subscribe(result => {
          this.onOrderRetrieved(result.body);
        });
    }
  }

  onOrderRetrieved(order: UnboundOrder): void {
    this.unboundOrder = order;

    if (this.unboundOrder !== null && this.unboundOrder.activated.stateEnum === UnboundOrderState.Active) {
      this.isActivated = true;
    } else {
      this.orderNotActivatedMessage = $localize`:Beställningen är inte aktiverad@@unboud-order-assignment.order-not-activated-message:Beställningen är inte aktiverad`;
    }

    this.unboundOrderAssignmentForm.enable();

    this.unboundOrderAssignmentForm.patchValue({
      contractor: order.assignment.contractor,
      contactInfo: order.assignment.contactInfo,
      bookingReference: order.assignment.bookingReference,
      additionalInfo: order.assignment.additionalInfo,
    });


    if (this.unboundOrder.currentState.stateEnum === UnboundOrderState.SentToNobina
      && this.userHasAnyRole([UserRoles.NobinaTrafikledare, UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare])) {
      this.unboundOrderService.updateOrderState(this.unboundOrder.id, UnboundOrderState.ReceivedByNobina)
        .subscribe(null);
    }

    if (this.userHasAnyRole([UserRoles.OperatorTrafikledare])) {
      this.unboundOrderAssignmentForm.disable();
    }
  }

  save() {
    // trigger validation on all fields
    Object.keys(this.unboundOrderAssignmentForm.controls).forEach(field => {
      const control = this.unboundOrderAssignmentForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });


    if (this.unboundOrderAssignmentForm.valid) {
      // Copy the form values over the order object values
      const o = Object.assign(this.unboundOrder.assignment, this.unboundOrderAssignmentForm.value);
      this.unboundOrderService.saveAssignment(this.unboundOrder.id, o)
        .subscribe(response => this.onSaveComplete(response.headers.get('location')),
          err => this.onHttpError(err));
    }
  }


  onHttpError(httpError: HttpErrorResponse): void {
    this.unboundOrderAssignmentForm.markAsDirty();
    this.isSaveButtonDisabled = false;
  }

  onSaveComplete(location: string): void {
    this.unboundOrderAssignmentForm.markAsPristine();
    this.isSaveButtonDisabled = true;
    this.router.navigateByUrl('unboundorders');
  }

  dismiss(): void {
    // TODO: fixa url när history implementerat
    if (this.isHistory) {
      this.router.navigateByUrl('history');
    } else {
      this.router.navigateByUrl('unboundorders');
    }
  }

// TODO: global funktion? Används lite överallt.
  userHasAnyRole(userRoles: UserRoles[]): boolean {
    return this.authService.userHasAnyRole(userRoles);
  }

  confirmEmail(id: number, email: string) {
    if (this.email.valid) {
      this.sendEmail(id, email);
    } else {
      this.modalRef = this.modalService.open(AlertModalComponent);
      this.modalRef.componentInstance.title = $localize`:Ogiltig e-postadress@@unbound-order-email:Ogiltig e-postadress`;
      this.modalRef.componentInstance.description = $localize`:Ogiltig e-postadress, försök igen!@@unbound-order-email-alert:Ogiltig e-postadress, försök igen!`;
    }
  }

  sendEmail(id: number, email: string) {
    this.modalRef = this.modalService.open(ConfirmModalComponent);
    this.modalRef.componentInstance.title = $localize`:Skicka?@@unbound-order-send:Skicka?`;
    this.modalRef.componentInstance.description = $localize`:Vill du skicka beställningsunderlaget via mejl?@@unbound-order-send-confirmation:Vill du skicka beställningsunderlaget via mejl?`;
    this.modalRef.componentInstance.confirmButton = $localize`:Bekräfta@@unbound-order-confirm:Bekräfta`;
    this.modalRef.componentInstance.cancelButton = $localize`:Avbryt@@unbound-order-cancel:Avbryt`;
    this.modalRef.result.then(() => {
      const name = this.authService.user.idTokenClaims.name;
      this.unboundOrderService.sendEmail(id, email, name, this.request)
        .subscribe(resp => {
          this.modalRef = this.modalService.open(AlertModalComponent);
          this.modalRef.componentInstance.title = $localize`:Skickat!@@unbound-order-email-sent:Skickat!`;
          this.modalRef.componentInstance.description = $localize`:Skickat!@@unbound-order-email-sent:Skickat!`;
        });
    })
      .catch(reason => {
        this.request = false;
      });
  }

  toggleRequest(event) {
    if (event.target.checked) {
      this.request = true;
    } else {
      this.request = false;
    }
  }
}
