import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, Route, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { tap, first } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class AuthGuardService implements CanActivate, CanLoad {

  constructor(private _msalService: MsalService, private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.isAuthenticated();
  }

  canLoad(route: Route): boolean {
    return true;
  }

  private isAuthenticated(): boolean {
    return this._msalService.instance.getAllAccounts().length !== 0;
    // return this.authService.authenticationChallenge$
    //   .pipe(
    //     first(),
    //     tap((authenticated) => {
    //       if (!authenticated) {
    //         this.authService.signin();
    //       }
    //     }));
  }
}
