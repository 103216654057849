import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Vehicle } from '../../../common/models/vehicle';
import { SubcontractorService } from '../../../common/services/subcontractor.service';
import { AssignVehicleToDriveOrderRequest } from '../../../common/models/assign-drive-order';
import { OrderByService } from '../../../common/services/order-by.service';
import { DriveOrderService } from '../../../common/services/drive-order.service';
import { AssignVehicleWarningComponent } from './assign-vehicle-warning/assign-vehicle-warning.component';
import { DriveOrderAssigned } from '../../../common/models/drive-order';
import { Subcontractor } from '../../../common/models/subcontractor';

const CTL_MITT_FÖRFOGANDE_ID = 996;

@Component({
  selector: 'app-assign-driver-vehicle',
  templateUrl: './assign-vehicle.component.html',
  styleUrls: ['./assign-vehicle.component.scss'],
})
export class AssignVehicleComponent implements OnInit {
  @Input() assignedVehicle: Vehicle;
  @Input() subcontractor: Subcontractor;
  @Input() availabilityInquiryId: number;
  @Input() driveOrderId: number;
  @Input() assignedSubcontractorCode: string;
  @Input() startTripStop: any;
  @Input() endTripStop: any;

  vehicles: Vehicle[];
  selectedVehicle: Vehicle;
  buttonsDisabled = false;

  constructor(
    public activeModal: NgbActiveModal,
    private subcontractorService: SubcontractorService,
    private driveOrderService: DriveOrderService,
    private orderByService: OrderByService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    if (this.subcontractor.isExternal) {
      this.subcontractorService.getSubcontractorVehicles(this.subcontractor.id).subscribe(x => {
        this.onVehiclesRetrieved(x.body);
      });
    } else {
      this.subcontractorService.getVehicles().subscribe(x => {
        this.onVehiclesRetrieved(x.body);
      });
    }
    this.selectedVehicle = this.assignedVehicle;
  }

  onVehiclesRetrieved(vehicles: Vehicle[]) {
    this.vehicles = vehicles;
    this.vehicles = this.orderByService.transform(this.vehicles, 'vehicleIdentifier');
  }

  assign() {
    this.buttonsDisabled = true;
    const request = new AssignVehicleToDriveOrderRequest();

    if (this.selectedVehicle) {
      request.vehicleId = this.selectedVehicle.id;
    }
    this.assignedVehicle = this.selectedVehicle;

    let query = '';
    query = '?FromDatetime=' + this.startTripStop.toISOString();
    query += '&ToDateTime=' + this.endTripStop.toISOString();
    if (this.selectedVehicle.id !== 0) {
      query += '&vehicleId=' + this.selectedVehicle.id;
    }

    let assignedOrders: DriveOrderAssigned[];
    this.driveOrderService.getAssignedDriveOrders(query).subscribe(response => {
      if (response.body.length > 0) {
        assignedOrders = response.body.filter(x => x.assignedDriveOrderId !== this.driveOrderId);
      }
      if (assignedOrders !== undefined && assignedOrders.length > 0) {
        const opts: NgbModalOptions = {};
        const modalRef = this.modalService.open(AssignVehicleWarningComponent, opts);
        modalRef.componentInstance.assignedOrderId = assignedOrders[0].assignedOrderId;
        modalRef.componentInstance.assignedDriveOrderId = assignedOrders[0].assignedDriveOrderId;
        modalRef.result
          .then(() => {
            this.driveOrderService
              .postAssignVehicleToDriveOrder(this.driveOrderId, request)
              .subscribe(() => this.activeModal.close({ vehicle: this.assignedVehicle }));
          })
          .catch(() => {
            this.buttonsDisabled = false;
          });
      } else {
        this.driveOrderService
          .postAssignVehicleToDriveOrder(this.driveOrderId, request)
          .subscribe(() => this.activeModal.close({ vehicle: this.assignedVehicle }));
      }
    });
  }
}
