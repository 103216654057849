import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-driveorder-details',
  templateUrl: './driveorder-details.component.html',
  styleUrls: ['./driveorder-details.component.scss']
})
export class DriveorderDetailsComponent implements OnInit {
  @Input() captionText: string;
  @Input() time: number;
  @Input() distance: number;
  @Input() captionClass: string;
  Math = Math;

  constructor() { }

  ngOnInit(): void {
  }

}
