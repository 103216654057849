<div class="m-4">
    <h2 class="mb-4">{{title}}</h2>
    <p class="mb-5">{{description}}</p>

    <div class="row mt-5">
      <div class="col-4 m-auto">
        <button class="btn btn-primary" id="confirmBtn" type="button" (click)="activeModal.close()" [disabled]="buttonsDisabled" i18n="@@alert-modal.confirm">{{ buttonText || 'OK' }}</button>
      </div>
    </div>
  </div>
