import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AvailableVehicle } from '@models/availability-inquiry';
import { OrderType } from '@models/order-type';
import { AvailabilityInquiryStoreService } from '@services/availability-inquiry-store.service';

@Component({
  selector: 'app-availabilities',
  template: `
      <div class="mt-1">
        <div *ngFor="let item of availableVehicleItems.controls; let index = index; trackBy: trackByFn" @fadeInOut class="overflow-hidden availableVehicleItem" [class.odd]="!(index % 2)">
            <app-availability
              [form]="item"
              [orderType]="orderType"
              [disabled]="isAssigned"
              [hasAsked]="!isAssigned && vehicles"
              [isOnlyOneVehicleAvailableSet]="availableVehicleItems.length === 1"
              (availabilityInquiryReplyRequestItemEvent)="receiveRequestItem($event, index)"
              (removeAvailabilityInquiryReplyRequestItemEvent)="removeAvailabilityInquiryReplyRequestItem($event, index)"
            ></app-availability>
          </div>
          <div *ngIf="!isAssigned && showAddInquiryTime"
            class="addInquiryTime"
            [class.disabled]="form.disabled"
            (click)="addAvailabilityInquiryReplyRequestItem()"
          >
            <img src="/assets/img/icons/plus-circle.svg" class="circle me-2">
            <ng-container i18n="@@availabilities.add-inquiry-time">
              Lägg till inställelsetid
            </ng-container>
          </div>
      </div>
  `,
  styleUrls: ['./availabilities.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({height:0}),
        animate(500, style({height:'*'}))
      ]),
      transition(':leave', [
        animate(500, style({height:0}))
      ])
    ])
  ],
  providers: [AvailabilityInquiryStoreService],
})
export class AvailabilitiesComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() numberOfVehiclesLeftToSet: number;
  @Input() disabled: boolean;
  @Input() orderType: OrderType;
  @Input() isInternal: boolean;
  @Input() vehicles: AvailableVehicle[];
  @Input() isAssigned: boolean;

  @Output() numberOfVehiclesSetEvent = new EventEmitter();

  showAddInquiryTime: boolean = true;
  OrderType = OrderType;

  get availableVehicleItems(): FormArray {
    return this.form.get('availableVehicleItems') as FormArray;
  }

  constructor(
    private fb: FormBuilder,
    private availabilityInquiryStoreService: AvailabilityInquiryStoreService,
  ) {
  }

  ngOnInit(): void {
    this.addVehicleToForm(this.vehicles);
    this.setNumberOfVehiclesSet();

    if (this.isAssigned || this.numberOfVehiclesLeftToSet === 0) {
      this.form.disable();
    }

    this.showAddInquiryTime = this.orderType === OrderType.Urgent && !(this.vehicles || this.numberOfVehiclesLeftToSet <= 1);

    this.availabilityInquiryStoreService.numberOfUnassignedDriveOrders = this.numberOfVehiclesLeftToSet
    this.availabilityInquiryStoreService.setNumberOfVehiclesLeftToSet(this.numberOfVehiclesLeftToSet);
  }

  receiveRequestItem(form: FormGroup, index: number) {
    let numberOfVehiclesSet = this.setNumberOfVehiclesSet();

    this.availabilityInquiryStoreService.setNumberOfVehiclesLeftToSet(
      this.availabilityInquiryStoreService.numberOfUnassignedDriveOrders - numberOfVehiclesSet
    );

    this.availableVehicleItems.at(index).setValue(
      {
        numberOfVehicles: { numberOfVehicles: form.get('numberOfVehicles.numberOfVehicles').value ?? null, },
        estimatedArrivalMinutes: { estimatedArrivalMinutes: form.get('estimatedArrivalMinutes.estimatedArrivalMinutes').value ?? null },
        shouldAssign: form.get('shouldAssign').value
      }
    );

    this.showAddInquiryTime = this.orderType === OrderType.Urgent && !this.vehicles && !(this.availabilityInquiryStoreService.numberOfUnassignedDriveOrders - numberOfVehiclesSet === 0)
  }

  addAvailabilityInquiryReplyRequestItem() {
    this.addAvailableVehicle();
    this.showAddInquiryTime = this.orderType === OrderType.Urgent && !(this.availableVehicleItems.length === this.numberOfVehiclesLeftToSet)
  }

  removeAvailabilityInquiryReplyRequestItem(value: number, index) {
    this.availabilityInquiryStoreService.addNumberOfVehicles(+value);
    this.availableVehicleItems.removeAt(index);

    this.setNumberOfVehiclesSet();
    this.showAddInquiryTime = this.orderType === OrderType.Urgent;
  }

  private addVehicleToForm(vehicles: AvailableVehicle[]) {
    if (vehicles) {
      vehicles.forEach(vehicle => {
        this.addAvailableVehicle(
          vehicle.count.toString(),
          vehicle.estimatedArrivalMinutes.toString()
        );
      });
    } else {
      this.addAvailableVehicle();
    }
  }

  private addAvailableVehicle(numberOfVehicles: string = '', estimatedArrivalMinutes: string = '') {
    this.availableVehicleItems.push(
      this.fb.group({
        numberOfVehicles: this.fb.group({
          numberOfVehicles: [numberOfVehicles, [Validators.required, Validators.min(1), Validators.max(999)]],
        }),
        estimatedArrivalMinutes: this.fb.group({
          estimatedArrivalMinutes: [estimatedArrivalMinutes, [Validators.min(0), Validators.max(999), this.requiredIfValidator(() => this.orderType === OrderType.Urgent)]]
        }),
        shouldAssign: true,
      })
    );
  }

  private setNumberOfVehiclesSet(): number {
    let numberOfVehiclesSet = 0;

    this.availableVehicleItems?.value?.forEach(vehicle => {
      if (vehicle.shouldAssign) {
        numberOfVehiclesSet += +vehicle.numberOfVehicles.numberOfVehicles;
      }
    });
    this.numberOfVehiclesSetEvent.emit(numberOfVehiclesSet);
    return numberOfVehiclesSet;
  }

  private requiredIfValidator(predicate) {
    return (formControl => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    })
  }

  trackByFn(_: number, item: AvailableVehicle) {
    return item;
  }
}
