<h1 class="my-5 text-center" i18n="@@subcontractor.inquriries">Förfrågningar</h1>
<ul class="nav nav-tabs me-auto mb-4">
  <li class="nav-item">
    <a class="nav-link"
      (click)="changeAvailabilityInquiryState(AvailabilityInquiryState.Submitted)"
      [class.active]="availabilityInquiryState === AvailabilityInquiryState.Submitted"
      i18n="@@subcontractor.unanswered">Obesvarade</a>
  </li>
  <li class="nav-item">
    <a class="nav-link"
      (click)="changeAvailabilityInquiryState(AvailabilityInquiryState.Accepted)"
      [class.active]="availabilityInquiryState === AvailabilityInquiryState.Accepted"
      i18n="@@subcontractor.responded-yes">Tackat ja</a>
  </li>
  <li class="nav-item">
    <a class="nav-link"
      (click)="changeAvailabilityInquiryState(AvailabilityInquiryState.Rejected)"
      [class.active]="availabilityInquiryState === AvailabilityInquiryState.Rejected"
      i18n="@@subcontractor.responded-no">Tackat nej</a>
  </li>
</ul>

<app-availability-inquiry-list
  [subcontractor]="subcontractor"
  [availabilityInquiryState]="availabilityInquiryState">
</app-availability-inquiry-list>

<h1 class="my-5 text-center">
  <ng-container i18n="@@subcontractor.orders">Beställningar</ng-container>
</h1>
<ul class="nav nav-tabs me-auto mb-4">
  <li class="nav-item">
    <a class="nav-link"
      (click)="changeListState(ListType.Ongoing)"
      [class.active]="listState === ListType.Ongoing"
      i18n="@@subcontractor.ongoing">Pågående</a>
  </li>
  <li class="nav-item">
    <a class="nav-link"
      (click)="changeListState(ListType.UnplanedOrders)"
      [class.active]="listState === ListType.UnplanedOrders"
      i18n="@@subcontractor.unplanned">Oplanerad</a>
  </li>
  <li class="nav-item">
    <a class="nav-link"
      (click)="changeListState(ListType.PlannedOrders)"
      [class.active]="listState === ListType.PlannedOrders"
      i18n="@@subcontractor.planned">Planerad</a>
  </li>
</ul>

<app-subcontractor-order-list
  [subcontractor]="subcontractor"
  [state]="listState">
</app-subcontractor-order-list>
