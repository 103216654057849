import { StateObject } from './state-object';

export enum UnboundOrderState {
  SentToNobina = 1,
  ReceivedByNobina = 2,
  Active = 3,
  Completed = 4,
  Cancelled = 5,
  CancellationConfirmed = 6
}
export class UnboundOrderStateObject extends StateObject {

  public constructor(init?: Partial<UnboundOrderStateObject>) {
    super(init);
    Object.assign(this, init);
  }
}
