<div class="container">

  <div class="main">

    <app-order-menu [orderId]="unboundOrder.id" [isUnboundOrder]="true"></app-order-menu>

    <hr class="mt-0">

    <h2 class="mt-4" i18n="@@unbound-order-completion.completion">Slutförande</h2>
    <h3 *ngIf="!isActivated" class="mt-4 mb-5">
      {{ orderNotActivatedMessage }}
    </h3>

    <div id="unboundOrderActivated" *ngIf="isActivated">

      <form [formGroup]="unboundOrderCompletionForm" (ngSubmit)="save()" novalidate class="form mt-4">


        <div class="mb-3">
          <div class="col-6">
            <label for="finalCost" i18n="@@unbound-order-completion.cost">Kostnad (kr)</label>
            <input class="form-control" type="text" placeholder="Obligatoriskt" id="finalCost" formControlName="finalCost" i18n-placeholder="@@unbound-order-completion.placeholder-mandatory">
            <div *ngIf="(finalCost.dirty || finalCost.touched) && finalCost.invalid" class="text-danger" i18n="@@unbound-order-completion.cost-number">
              Kostnad måste anges som enbart siffror
            </div>
          </div>
        </div>
        <div class="mb-3">
          <div class="col-6">
            <label for="distanceKm" i18n="@@unbound-order-completion.distance">Sträcka (km)</label>
            <input class="form-control" type="text" placeholder="Frivilligt" id="distanceKm" formControlName="distanceKm" i18n-placeholder="@@unbound-order-completion.placeholder-voluntary">
            <div *ngIf="(distanceKm.dirty || distanceKm.touched) && distanceKm.invalid" class="text-danger" i18n="@@unbound-order-completion.distance-number">
              Sträcka måste anges som enbart siffror
            </div>
          </div>
        </div>
        <div class="mb-3">
          <div class="col-6">
            <label for="additionalInfo" i18n="@@unbound-order-completion.additional-info">Övriga kommentarer/Fakturanummer</label>
            <textarea class="form-control" type="text" placeholder="Frivilligt" id="additionalInfo" formControlName="additionalInfo" i18n-placeholder="@@unbound-order-completion.placeholder-voluntary"></textarea>
          </div>
        </div>

        <div class="justify-content-start mt-5">
          <div class="col-3">
            <button id="save-btn" class="btn btn-primary" type="submit" i18n="@@unbound-order-completion.save" [disabled]="isHistory">Slutför beställning</button>
          </div>
        </div>
      </form>
    </div>
  </div>

</div>
