import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: '[app-estimated-arrival-minutes]',
  template: `
    <form [formGroup]="form">
      <label class='mb-2' for="estimatedArrivalMinutes" i18n="@@estimated-arrival-minutes.estimated-arrival-minutes">Estimerad inställelsetid*</label>
      <input id="estimatedArrivalMinutes" formControlName="estimatedArrivalMinutes"
        type="number" class="form-control" placeholder="Minuter för fordon" min="0"
        i18n-placeholder="@@estimated-arrival-minutes.min-for-vehicle"
        (change)="estimatedArrivalMinutesBlur($event)"
      />
      <div
        *ngIf="form.controls.estimatedArrivalMinutes.invalid && (form.controls.estimatedArrivalMinutes.dirty || form.controls.estimatedArrivalMinutes.touched)"
        class="text-danger" i18n="@@estimated-arrival-minutes.invalid-estimaed-arrival-minutes"
      >
        Ogiltig inställelsetid
      </div>
    </form>
  `,
  styleUrls: ['./estimated-arrival-minutes.component.scss']
})
export class EstimatedArrivalMinutesComponent implements OnInit {
  @Input() formGroupName: string;

  @Output() estimatedArrivalMinutesEvent: EventEmitter<number> = new EventEmitter();

  form: FormGroup;

  constructor(
    private rootFormGroup: FormGroupDirective,
  ) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.form.get(this.formGroupName) as FormGroup;
  }

  estimatedArrivalMinutesBlur($event) {
    const value = $event.target.value ?? 0;
    this.estimatedArrivalMinutesEvent.emit(value);
  }
}
