import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AvailabilityInquiryListBaseComponent } from '../availability-inquiry-list-base/availability-inquiry-list-base.component';
import { AvailabilityInquiryService } from '@services/availability-inquiry.service';
import { ListViewService } from '@services/list-view.service';
import { NotificationService } from '@services/notification.service';
import { OrderByService } from '@services/order-by.service';
import { VehicleTypeService } from '@services/vehicle-type.service';

@Component({
  selector: 'app-availability-inquiry-list',
  templateUrl: './availability-inquiry-list.component.html',
  styleUrls: ['./availability-inquiry-list.component.scss']
})
export class AvailabilityInquiryListComponent extends AvailabilityInquiryListBaseComponent {
  pageSize: number;
  currentPage: number = 1;

  constructor(
    public availabilityInquiryService: AvailabilityInquiryService,
    public notificationService: NotificationService,
    public orderByService: OrderByService,
    public vehicleTypeService: VehicleTypeService,
    private listViewService: ListViewService) {
      super(availabilityInquiryService, notificationService, orderByService, vehicleTypeService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.pageSize = this.listViewService.pageSize;
  }
  
  selectedPageSize(event: number) {
    this.listViewService.pageSize = event;
    this.currentPage = 1;
    this.numberOfAvailabilityInquiries = this.getAvailabilityInquiries()
  }

  pageChanged(event: number) {
    this.currentPage = event;
    this.numberOfAvailabilityInquiries = this.getAvailabilityInquiries()
  }
}
