<ng-progress #progressBar></ng-progress>
<div *ngIf='progressBar.isStarted' class='block-screen'><span>.</span><span>.</span><span>.</span></div>
<div>
  <div [ngClass]='navColor'>
    <app-nav-bar></app-nav-bar>
    <app-alert></app-alert>
  </div>
  <div class='outer-container'>
    <router-outlet></router-outlet>
  </div>

</div>
