import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Subscription } from 'rxjs';

import {
  AddAvailabilityInquiries,
  AvailabilityInquiry,
  AvailabilityInquiryState,
  AvailabilityInquiryStateObject
} from '@models/availability-inquiry';
import { AvailabilityInquiryService } from '@services/availability-inquiry.service';
import { AuthService } from '@services/auth.service';
import { ConfirmModalComponent } from '@common/components/confirm-modal/confirm-modal.component';
import { DriveOrder, DriveOrderState } from '@models/drive-order';
import { DriveOrderService } from '@services/drive-order.service';
import { NotificationService } from '@services/notification.service';
import { Order } from '@models/order';
import { OrderByService } from '@services/order-by.service';
import { OrderService } from '@services/order.service';
import { OrderState } from '@models/order-state';
import { SubcontractorService } from '@services/subcontractor.service';
import { Subcontractor, SubcontractorVacantType } from '@models/subcontractor';
import { SubcontractorOrder, SubcontractorOrderState, SubcontractorOrderStateObject } from '@models/subcontractor-order';
import { SubcontractorsAvailabilityInquiryComponent } from './subcontractors-availability-inquiry.component';
import { SubcontractorOrderComponent } from './subcontractor-order.component';
import { SubcontractorOrderService } from '@services/subcontractor-order.service';
import { UserRoles } from '@authentication/user-roles';

@Component({
  selector: 'app-subcontractors',
  templateUrl: './subcontractors.component.html',
  styleUrls: ['./subcontractors.component.scss'],
})
export class SubcontractorsComponent implements OnInit, OnDestroy {
  @Input() order = new Order();
  subconForm: UntypedFormGroup;
  hasQueriesCanBeAssigned = false;
  OrderState = OrderState;
  orderId: number;
  isHistory: boolean;
  isSaveInProgress = false;
  stateChangedMessageSub: Subscription;
  UserRoles = UserRoles;
  private saveOperationsCount = 0;
  modalRef: NgbModalRef;
  buttonsDisabled: boolean = false;
  showAssignInternal = true;
  allAssigned: boolean = false;

  private subcontractors: Subcontractor[];
  availabilityInquiries: SubcontractorsAvailabilityInquiryComponent[] = [];
  optionlistNoOfVehicles: number[];
  internalSubcontractorOrders: SubcontractorOrderComponent[];
  externalSubcontractorOrders: SubcontractorOrderComponent[];
  numberOfAssignedDriveOrders: number;
  numberOfUnassignedDriveOrders: number; // number of driveorder with state == Created
  totalNumberOfDriveOrders: number;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private orderService: OrderService,
    private driveOrderService: DriveOrderService,
    private subcontractorOrderService: SubcontractorOrderService,
    private subcontractorService: SubcontractorService,
    private availabilityInquiryService: AvailabilityInquiryService,
    private authService: AuthService,
    private orderByService: OrderByService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.route.url.subscribe(current => {
      this.isHistory = (current !== undefined) && current[0].path === 'history' && current[2].path === 'subcontractors';
    });
    this.orderId = +this.route.snapshot.paramMap.get('id');
    this.subconForm = this.fb.group({
      numberOfVehicles: 1
    });
    if (this.isHistory) {
      this.subconForm.disable();
    }
    this.getOrder();
    this.stateChangedMessageSub = this.notificationService.getStateChangedMessage().subscribe(message => {
      // när isSaveInProgress så sker en mängd events, skippa dessa event
      if (message.referenceType === 'AvailabilityInquiry' && !this.isSaveInProgress) {
        const itemUpdated = this.availabilityInquiries.find(x => x.availQuery.id === message.referenceId);

        if (itemUpdated === undefined) {
          // när man klickar Fråga på availQuery, så uppdateras inte this.availabilityInquiries
          // av någon anledning... dubbelkolla om orderId stämmer
          // console.log(this.availabilityInquiries);
          this.availabilityInquiryService.getAvailabilityInquiry(message.referenceId)
            .subscribe(response => {
              if (response.body.orderId === this.order.id) {
                // rätt order!
                this.updateAvailInqAndSubcontractorOrders();
              }

            });
        } else { // uppdaterat item finns i vår lista, uppdatera
          this.updateAvailInqAndSubcontractorOrders();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.stateChangedMessageSub.unsubscribe();
  }

  subcontractorOrderCreatedEvent($event) {
    this.getOrder();
  }

  onIsSaveInProgress(event) {
    if (event) {
      this.saveOperationsCount++;
    } else {
      this.saveOperationsCount--;
    }
    this.isSaveInProgress = (this.saveOperationsCount > 0);
  }

  private updateAvailInqAndSubcontractorOrders(): void {
    const availInq = this.availabilityInquiryService.getAvailabilityInquiriesForOrder(this.order.id);
    const subcontractorOrders = this.subcontractorOrderService.getSubcontractorOrdersFromQuery('?OrderId=' + this.order.id);
    forkJoin([availInq, subcontractorOrders]).subscribe(results => {
      this.initFromOrder(results[0].body, results[1].body);
    });
  }

  askEveryone() {
    if (this.haveAskedEveryone()) return;
    this.buttonsDisabled = true;

    this.modalRef = this.modalService.open(ConfirmModalComponent);
    this.modalRef.componentInstance.title = $localize`:Fråga alla@@subcontractors.ask-everyone:Fråga alla`;
    this.modalRef.componentInstance.description =
      $localize`:Är du säker på att du vill skicka förfrågan till samtliga underentreprenörer?@@subcontractors.ask-everyone-note:Är du säker på att du vill skicka förfrågan till samtliga underentreprenörer?`
    this.modalRef.componentInstance.confirmButton = $localize`:Ja@@subcontractors.confirm-ask-everyone-note:Ja`;
    this.modalRef.componentInstance.cancelButton = $localize`:Nej@@subcontractors.cancel-ask-everyone-note:Nej`;
    this.modalRef.result.then(() => {
      var subcontractsIds = [];

      this.availabilityInquiries.forEach((ai) => {
        subcontractsIds.push(ai.availQuery.subcontractor.id)
      });

      const query: AddAvailabilityInquiries = {
        subcontractorIds: subcontractsIds,
        numberOfVehicles: this.numberOfUnassignedDriveOrders,
        orderId: this.orderId
      };

      this.availabilityInquiryService.addAvailabilityInquiries(query)
        .subscribe({
          next: resp => {

          },
          complete: () => {
            this.getOrder();
          }
        });
    }).catch(() => { // For closing the modal
      this.buttonsDisabled = false;
    });
  }

  askedSubcontractor(event) {
    this.haveAskedEveryone();
  }

  haveAskedEveryone() {
    return this.order.availabilityInquiries.length === this.availabilityInquiries.length;
  }

  getOrder(): void {
    this.order.id = +this.route.snapshot.paramMap.get('id');
    if (this.order.id === 0) {
      return;
    }
    const order = this.orderService.getOrder(this.order.id);
    const driveorders = this.driveOrderService.getDriveOrders('?OrderId=' + this.order.id);
    const availInq = this.availabilityInquiryService.getAvailabilityInquiriesForOrder(this.order.id);
    const subcontractorOrders = this.subcontractorOrderService.getSubcontractorOrdersFromQuery('?OrderId=' + this.order.id);
    forkJoin([order, driveorders, availInq, subcontractorOrders]).subscribe(results => {
      this.order = results[0].body;

      let vehicleAssigned = 0;
      results[3].body.forEach(subcontractorOrder => {
        if (subcontractorOrder.assignedVehicles) {
          vehicleAssigned += subcontractorOrder.assignedVehicles.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0)
        }
      });

      this.subcontractorService.getSubcontractors(results[0].body.operator.id)
        .subscribe(result => {
          this.subcontractors = result.body;
          this.initFromDriveOrder(results[1].body);
          this.initFromOrder(results[2].body, results[3].body);
        });
    });
  }
  private initFromDriveOrder(driveOrders: DriveOrder[]): void {
    this.totalNumberOfDriveOrders = driveOrders.length;

    this.numberOfAssignedDriveOrders = driveOrders.filter(function (x) {
      return !(x.assignment.assignedSubcontractorId == null);
    }).length;

    this.numberOfUnassignedDriveOrders = driveOrders.filter(function (x) {
      return (x.currentState.stateEnum === DriveOrderState.Created);
    }).length;

    if (this.totalNumberOfDriveOrders === this.numberOfAssignedDriveOrders) {
      this.allAssigned = true;
    }

    this.availabilityInquiries = new Array<SubcontractorsAvailabilityInquiryComponent>();
    this.internalSubcontractorOrders = new Array<SubcontractorOrderComponent>();
    this.externalSubcontractorOrders = new Array<SubcontractorOrderComponent>();

    this.optionlistNoOfVehicles = Array(this.numberOfUnassignedDriveOrders).fill(0).map((x, i) => i + 1);

    this.subconForm.controls['numberOfVehicles'].setValue(this.numberOfUnassignedDriveOrders);
  }

  initFromOrder(availabilityInquiries: AvailabilityInquiry[], subcontractorOrders: SubcontractorOrder[]): void {
    this.subcontractors = this.orderByService.transform(this.subcontractors, 'name');
    this.availabilityInquiries = new Array<SubcontractorsAvailabilityInquiryComponent>();
    this.internalSubcontractorOrders = new Array<SubcontractorOrderComponent>();
    this.externalSubcontractorOrders = new Array<SubcontractorOrderComponent>();
    this.order.availabilityInquiries = availabilityInquiries;

    // External men inte UE vakant eller utförare vakant
    const externalSubcontractors = this.subcontractors.filter(s => s.isExternal
      && s.subcontractorVacantType === SubcontractorVacantType.None);

    externalSubcontractors.forEach(sc => {
      const co: SubcontractorOrder = subcontractorOrders.find(x => x.subcontractorId === sc.id);
      if (co) {
        const component = new SubcontractorOrderComponent(this.subcontractorOrderService);
        component.orderId = this.order.id;
        component.numberOfVehicles = this.order.numberOfVehicles;
        co.subcontractorName = sc.name;
        component.subcontractorOrder = co;
        component.orderType = this.order.orderType;
        this.externalSubcontractorOrders.push(component);
      } else {
        const component = new SubcontractorsAvailabilityInquiryComponent(
          this.availabilityInquiryService,
          this.subcontractorOrderService,
          this.notificationService,
        );
        let aq: AvailabilityInquiry = this.order.availabilityInquiries.find(x => x.subcontractor.id === sc.id);

        if (aq === undefined) {
          // gör ny query för denna underentreprenör
          aq = new AvailabilityInquiry();
          aq.currentState = new AvailabilityInquiryStateObject({ stateEnum: AvailabilityInquiryState.StateNew, stateDescription: AvailabilityInquiryState[AvailabilityInquiryState.StateNew], updatedByUser: this.authService.user.idTokenClaims.name, updated: new Date() });
          aq.subcontractor = sc;
        }
        component.availQuery = aq;
        component.orderId = this.order.id;
        component.numberOfVehicles = this.numberOfUnassignedDriveOrders;
        if (component.availQuery.currentState.stateEnum === AvailabilityInquiryState.Accepted
          || component.availQuery.currentState.stateEnum === AvailabilityInquiryState.Assigned) {
          this.hasQueriesCanBeAssigned = true;
        }
        this.availabilityInquiries.push(component);
      }
    });

    // Alla interna entreprenörer (dvs Nobina-garage), DOCK ALDRIG om krösa tog bort: && this.order.operator.operatorOrganisationId !== OP_KRÖSATÅG
    this.subcontractors.filter(x => !x.isExternal).forEach(sc => {
      const component = new SubcontractorOrderComponent(this.subcontractorOrderService);
      component.orderId = this.order.id;
      component.numberOfVehicles = this.order.numberOfVehicles;

      let co: SubcontractorOrder = subcontractorOrders.find(x => x.subcontractorId === sc.id);

      if (co === undefined) {
        // gör ny beställning för denna underentreprenör
        co = new SubcontractorOrder();
        co.currentState = new SubcontractorOrderStateObject({ stateEnum: SubcontractorOrderState.OrderedByNobina, stateDescription: SubcontractorOrderState[SubcontractorOrderState.OrderedByNobina], updatedByUser: this.authService.user.idTokenClaims.name, updated: new Date() });
        co.subcontractorId = sc.id;
      }

      co.subcontractorName = sc.name;
      component.subcontractorOrder = co;
      component.orderType = this.order.orderType;

      this.internalSubcontractorOrders.push(component);
    });
  }

  noOfVehicles(): number {
    return this.subconForm.controls['numberOfVehicles'].value;
  }

  hasNoInternalSubcontractors(): boolean {
    return (!this.internalSubcontractorOrders || this.internalSubcontractorOrders.length === 0);
  }

  userHasAnyRole(userRoles: UserRoles[]) {
    return this.authService.userHasAnyRole(userRoles);
  }

  toggleAssignInternal() {
    this.showAssignInternal = !this.showAssignInternal;
  }
}
