import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminService } from '@services/admin.service';
import { Subject, takeUntil } from 'rxjs';
import { GarageService } from '@services/garage.service';

@Component({
  selector: 'app-import-subcontractor',
  templateUrl: './import-subcontractor.component.html',
  styleUrls: ['./import-subcontractor.component.scss'],
})
export class ImportSubcontractorComponent implements OnInit, OnDestroy {
  existingGarageCodes = new Array<string>();
  newGarageCode = '';
  latestResponseFromAddSubcontractorCall = '';
  onDestroy$ = new Subject();

  constructor(private adminService: AdminService, private garageService: GarageService) {
  }

  ngOnInit(): void {
  }

  addOMSGarage(garageCode: string) {
    this.adminService.addOMSGarage(garageCode.toUpperCase()).pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: result => {
          if (result.ok) {
            this.latestResponseFromAddSubcontractorCall = this.getResultText(result.body, garageCode);
          } else {
            this.latestResponseFromAddSubcontractorCall = this.getResultText(result.body.error.text, garageCode);
          }
        },
        error: err => {
          this.latestResponseFromAddSubcontractorCall = this.getResultText(err.error.text, garageCode);
        },
      });

    this.garageService.syncSubcontractors().pipe(takeUntil(this.onDestroy$)).subscribe();
  }

  getResultText(text: string, garageCode: string): string {
    this.newGarageCode = '';
    if (this.existingGarageCodes.indexOf(garageCode.toUpperCase()) === -1 &&
      text.match('Inga ändringar importerade från OMS garage')
    ) {
      this.existingGarageCodes.push(garageCode.toUpperCase());
      return `Import av utförare/operatör med garagekod ${garageCode.toUpperCase()} är påbörjad.
       \n\nDet kan ta flera minuter att importera en utförare samt dess förare och fordon.
        Ladda om listan med utförare manuellt nedan för att se nyligen tillagda utförare.`;
    } else {
      return text;
    }
  }

  updateSubcontractorInput($event: any) {
    this.newGarageCode = $event.target.value;
    this.latestResponseFromAddSubcontractorCall = '';
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
