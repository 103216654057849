  <h1 class="my-5 text-center" i18n="@@outcome-list.follow-up">Uppföljning</h1>

  <div class="nav">
    <ul class="nav-tabs me-auto ps-0 mb-3">
      <li id="outcomeDataNavbar" class="nav-item" *ngIf="userHasAnyRole([UserRoles.NobinaLeveransansvarig,UserRoles.BergkvaraTrafikledare,UserRoles.BergkvaraTrafikledare, UserRoles.UETrafikledare])">
          <a class="nav-link" routerLink="/billing/outcomes" [class.active]="queryStates[0] !== OutcomeState.Billable && queryStates[0] !== OutcomeState.Billed" i18n="@@outcome-list.outcome-data">Utfallsdata</a>
      </li>
      <li id="billableNavbar" class="nav-item" *ngIf="userHasAnyRole([UserRoles.NobinaLeveransansvarig,UserRoles.BergkvaraTrafikledare, UserRoles.UETrafikledare])">
          <a class="nav-link" routerLink="/billing/outcomes" [queryParams]="{ state: OutcomeState.Billable }" [class.active]="queryStates[0] === OutcomeState.Billable" i18n="@@outcome-list.billable">Fakturerbar</a>
      </li>
      <li id="billedNavbar" class="nav-item" *ngIf="userHasAnyRole([UserRoles.NobinaLeveransansvarig,UserRoles.BergkvaraTrafikledare, UserRoles.UETrafikledare])">
          <a class="nav-link" routerLink="/billing/outcomes" [queryParams]="{ state: OutcomeState.Billed }" [class.active]="queryStates[0] === OutcomeState.Billed" i18n="@@outcome-list.billed">Fakturerad</a>
      </li>
      <li id="reportsNavbar" class="nav-item" *ngIf="userHasAnyRole([UserRoles.NobinaLeveransansvarig,UserRoles.BergkvaraTrafikledare, UserRoles.UETrafikledare, UserRoles.OperatorTrafikledare])">
          <a class="nav-link" routerLink="/billing/reports/create-report" [class.active]="path?.indexOf('/billing/reports') > 0" i18n="@@outcome-list.reports">Rapporter</a>
      </li>
    </ul>

    <app-date-filter (dateRange)="onDateRangeChanged($event)" [dateOrigin]="dateOrigin" [defaultRangeOption]="dateFilterOptionEnum.Today" class="ms-2">
    </app-date-filter>
  </div>


<div class="d-flex flex-row flex-wrap card-row mt-1">
  <div *ngFor="let outcome of outcomes; let i = index" class="card-col">
    <div id="{{'outcomeCard_'}}{{i + 1}}" class="card" routerLink="{{ outcome.id }}">
      <div class="card-body">
        <div class="row">
          <div class="col-3">
            <div class="tb-badge">{{ outcome.orderId }}</div>
          </div>
          <div class="col">
            <div class="caption-lg tb-card-header">{{ outcome.operatorName }}</div>
          </div>
        </div>
        <div *ngIf="(userHasAnyRole([UserRoles.UETrafikledare]) && outcome.currentState.stateEnum == OutcomeState.WaitingForApprovalBySubcontractor)
          || (userHasAnyRole([UserRoles.NobinaLeveransansvarig,UserRoles.BergkvaraTrafikledare]) && outcome.currentState.stateEnum == OutcomeState.WaitingForApprovalByNobina)">
          <button class="action-required" tabindex="-1" i18n="@@outcome-list.action-required">
            Kräver åtgärd
          </button>
        </div>
        <div *ngIf="!outcome.isHastusPlanned" class="card-stops">
          <div class="row">
            <div class="col caption-lt-xs">
              <ng-container *ngIf="outcome?.isRoundTrip" i18n="@@outcome-list.round-trip">Tur och retur</ng-container>
              <ng-container *ngIf="!outcome?.isRoundTrip" i18n="@@outcome-list.one-way">Enkel</ng-container>
            </div>
          </div>


          <div class="row">
            <div class="col-5 caption">
              {{ outcome.startTripStop.placeDescription }}
            </div>
            <div class='col-2 text-center'>
              <img class="right-arrow" src="/assets/img/icons/right-arrow.png" alt="Icon right arrow">
            </div>
            <div class="col-5 caption">
              {{ outcome.endTripStop.placeDescription }}
            </div>
          </div>
        </div>


        <div *ngIf="outcome.isHastusPlanned">
          <div class="row col-5 caption hastusplanned-label" i18n="@@outcome-list.planned">
            Planerad
          </div>
        </div>
        <div class="card-times">
          <div class="row">
            <div class="col-5 caption">
              {{ outcome.startTripStop.time | amDateFormat:'YYYY-MM-DD HH:mm' }}
            </div>
            <div class='col-2 text-center'>
              <img *ngIf="outcome.endTripStop.time" class="right-arrow" src="/assets/img/icons/right-arrow.png" alt="Icon right arrow">
            </div>
            <div *ngIf="outcome.endTripStop.time" class="col-5 caption-lt-bl">
              {{ outcome.endTripStop.time | amDateFormat:'YYYY-MM-DD HH:mm' }}
            </div>
          </div>
        </div>
        <div *ngIf="outcome.revisedReferenceId ?? outcome.referenceId" class="callout-block">
          <span class="callout"></span>
          <div class="caption-lt-xs" i18n="@@outcome-list.reference">Referens</div>
          <div class="caption">{{ outcome.revisedReferenceId ?? outcome.referenceId}}</div>
        </div>
      </div>
      <div class="card-footer caption-lt">
        {{ outcome.currentState.stateDescription }}
      </div>
    </div>
  </div>
</div>
