import { Component, DestroyRef, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { UserRoles } from '@authentication/user-roles';
import { OrderService } from '@services/order.service';
import { Router } from '@angular/router';
import { AlertService } from '@services/alert.service';
import { DriveOrderService } from '@services/drive-order.service';
import { OrderWithQueryParm } from '@models/order';
import { OrderState } from '@models/order-state';
import { OrderSearch } from '@models/order-search';
import { DriveOrder } from '@models/drive-order';
import { UnboundOrderService } from '@services/unbound-order.service';
import { UnboundOrder } from '@models/unbound-order';
import { UnboundOrderState } from '@models/unbound-order-state';
import { environment } from '../../../../environments/environment';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

enum SearchType {
  Trip,
  DriveOrder,
  Order
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {

  UserRoles = UserRoles;
  public searchId = '';
  showSearch = false;
  set searchType(searchType: string) {
    localStorage.setItem(this._searchType, searchType);
  }

  get searchType(): string {
    const searchType = localStorage.getItem(this._searchType);
    if (searchType !== null && searchType !== 'undefined' && searchType !== '') {
      return searchType;
    } else {
      return 'OrderId';
    }
  }
  private _searchType = 'SEARCH_TYPE'

  constructor(private authService: AuthService,
              private router: Router,
              private orderService: OrderService,
              private driveOrderService: DriveOrderService,
              private unboundOrderService: UnboundOrderService,
              private alertService: AlertService,
              private destroyRef: DestroyRef) {
  }

  ngOnInit(): void {
    this.authService.userLoadededEvent$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(authUser => {
      if (authUser) {
        if (this.authService.userHasAnyRole(
          [
            UserRoles.OperatorTrafikledare, 
            UserRoles.UETrafikledare, 
            UserRoles.NobinaTrafikledare, 
            UserRoles.NobinaLeveransansvarig, 
            UserRoles.BergkvaraTrafikledare
          ]
        )) {
          this.showSearch = true;
        }
      }
    });
  }

  isUeTrafikledare() {
    return this.authService.userHasAnyRole([UserRoles.UETrafikledare]);
  }

  search() {
    const orderSearch = new OrderSearch();
    switch (this.searchType) {
      case 'OrderId': {
        if (this.validateNumber(this.searchId)) {
          orderSearch.orderIds = [+this.searchId];
          this.orderSearch(orderSearch);
        }
        break;
      }
      case 'DriveOrderId': {
        if (this.validateNumber(this.searchId)) {
          orderSearch.driveOrderIds = [+this.searchId];
          this.orderSearch(orderSearch);
        }
        break;
      }
      case 'TripId': {
        if (this.validateNumber(this.searchId)) {
          orderSearch.tripIds = [+this.searchId];
          this.orderSearch(orderSearch);
        }
        break;
      }
      case 'UnboundOrderId': {
        this.unboundOrderSearch(this.searchId);
        break;
      }
      default: {
        break;
      }
    }
  }

  navigateToOrder(order: OrderWithQueryParm, search: OrderSearch) {
    const searchType = this.getSearchType(search);
    let route = '/order';
    if (order.currentState.stateEnum === OrderState.Completed) {
      route = '/history';
    }
    if (order.isHastusPlanned && searchType === SearchType.Order) {
      route += '/planned-order';
    }

    if (order.queriedDriveOrderId === undefined || order.queriedDriveOrderId === null) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([route, order.id]);
      });
    } else {
      let fragmentString = order.queriedDriveOrderId.toString();
      if (order.queriedTripId) {
        fragmentString += '_' + order.queriedTripId.toString();
      }
      route = `/order-new${route}/${order.id}/drive-order`;
      window.location.href = `${route}?date=${order.queriedStartPassTime.toISOString().slice(0, 10)}&id=${order.id}#${fragmentString}`;
    }
    this.searchId = '';
  }

  navigateToSubcontractorOrder(driveOrder: DriveOrder, search: OrderSearch) {
    const searchType = this.getSearchType(search);
    let route = `/subcontractor/${driveOrder.assignment.assignedSubcontractorId}/subcontractor-orders`;

    if (searchType === SearchType.Order) {
      this.router.navigate([route, driveOrder.assignment.assignedSubcontractorOrderId]);
    } else {
      let fragmentString = driveOrder.id.toString();
      if (search.tripIds.length) {
        fragmentString += '_' + search.tripIds[0].toString();
      }
      route = `/order-new${route}/${driveOrder.assignment.assignedSubcontractorOrderId}/drive-order`;
      window.location.href = `${route}?date=${driveOrder.startTripStop.passTime.toISOString().slice(0, 10)}#${fragmentString}`;
    }
  }

  navigateToUnboundOrder(unboundOrder: UnboundOrder) {
    let url = 'unboundorder';
    if (unboundOrder.currentState.stateEnum === UnboundOrderState.Completed ||
      unboundOrder.currentState.stateEnum === UnboundOrderState.Cancelled) {
      url = 'history/' + url;
    }
    this.router.navigate([url, unboundOrder.id]);
  }

  unboundOrderSearch(unboundOrderId) {
    this.unboundOrderService.getOrders(undefined, unboundOrderId)
      .subscribe(o => {
        if (o.body.length) {
          this.navigateToUnboundOrder(o.body[0]);
        } else {
          this.showNotFoundMessage();
        }
      });
  }

  private getSearchType(search: OrderSearch): SearchType {
    if (search.tripIds.length) {
      return SearchType.Trip;
    } else if (search.driveOrderIds.length) {
      return SearchType.DriveOrder;
    }
    return SearchType.Order;
  }

  orderSearch(search: OrderSearch) {
    const searchType = this.getSearchType(search);
    if (this.isUeTrafikledare()) {
      this.callSubcontractorOrderService(search, searchType);
    } else {
      this.callOrderService(search, searchType);
    }
  }

  callSubcontractorOrderService(search: OrderSearch, searchType: SearchType) {
    const subcontractorId = +this.authService.user.idTokenClaims.garageIds[0];
    let query = `?SubcontractorId=${subcontractorId}`;
    query += (search.orderIds.length ? `&OrderId=${search.orderIds[0]}` : ``);
    query += (search.driveOrderIds.length ? `&DriveOrderId=${search.driveOrderIds[0]}` : ``);
    query += (search.tripIds.length ? `&TripId=${search.tripIds[0]}` : ``);
    this.driveOrderService.getDriveOrders(query)
      .subscribe(o => {
        if (o.body.length && o.body[0].assignment.assignedSubcontractorId) {
          this.navigateToSubcontractorOrder(o.body[0], search);
        } else {
          this.showNotFoundMessage();
        }
      });
  }

  callOrderService(search: OrderSearch, searchType: SearchType) {
    this.orderService.getOrders(search)
      .subscribe(o => {
        if (o.body.length) {
          this.navigateToOrder(o.body[0], search);
        } else {
          this.showNotFoundMessage();
        }
      });
  }

  validateNumber(value: string): boolean {
    if (!parseInt(value, 10)) {
      this.showNotANumberMessage();
      return false;
    } else {
      return true;
    }
  }

  showNotANumberMessage() {
    this.alertService.error('Ogiltigt nummer. Ange endast siffror.');
  }

  showNotFoundMessage() {
    this.alertService.info('Det finns ingen beställning i systemet som matchar sökt nummer.');
  }

  userHasAnyRole(userRoles: UserRoles[]) {
    return this.authService.userHasAnyRole(userRoles);
  }
}
