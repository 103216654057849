<form [formGroup]="datetimeForm" class="mt-5">
  <div>
    <label for="ctrlDate" i18n="@@modal-datetime.enter-date">Ange datum</label>
    <div class="input-group">
      <input class="form-control" formControlName="ctrlDate" id="ctrlDate" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker #d="ngbDatepicker">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button" id="ctrlDateBtn">
          <img src="/assets/img/icons/calendar.svg" style="width: 1.2rem; height: 1rem; cursor: pointer;" />
        </button>
      </div>
    </div>
    <div class="mt-4">
      <div class="col">
        <label for="ctrlHour" i18n="@@modal-datetime.enter-time">Ange klockslag</label>
        <select class="form-select form-control" id="ctrlHour" formControlName="ctrlHour">
          <option value="" i18n="@@modal-datetime.select-hour">Välj timme</option>
          <option *ngFor="let hour of hoursInDayList" [ngValue]="hour">{{ hour | number:'2.0'}}</option>
        </select>
      </div>
      <div class="col">
        <label for="ctrlMinute">&nbsp;</label>
        <select class="form-select form-control" id="ctrlMinute" formControlName="ctrlMinute">
          <option value="" i18n="@@modal-datetime.select-minute">Välj minut</option>
          <option *ngFor="let minute of minutesInHourList" [ngValue]="minute">{{ minute | number:'2.0'}}</option>
        </select>
      </div>
    </div>
    <div class="mt-5 justify-content-end">
      <div class="col"></div>
      <div class="col">
        <button class="btn btn-primary" (click)="save()" type="button" id="btn-save" i18n="@@modal-datetime.save">
          Spara
        </button>
      </div>
    </div>
  </div>
</form>
