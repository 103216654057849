import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Vehicle } from '../models/vehicle';
import { Driver } from '../models/driver';
import { Order } from '../models/order';
import { Subcontractor } from '../models/subcontractor';

@Injectable()
export class SubcontractorService {
  private baseUrl = environment.apiUrl + environment.apiVersionMain + '/subcontractors';
  private orderBaseUrl = environment.apiUrl + environment.apiVersionMain + '/subcontractor';

  constructor(private http: HttpClient) { }

  getSubcontractors(operatorId: number = null): Observable<HttpResponse<Subcontractor[]>> {
    if (operatorId === null) {
      return this.http.get<Subcontractor[]>(this.baseUrl, { observe: 'response' });
    } else {
      return this.http.get<Subcontractor[]>(`${this.baseUrl}?FilterByOperatorId=${operatorId}`, { observe: 'response' });
    }
  }

  getSubcontractor(id: number): Observable<HttpResponse<Subcontractor>> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<Subcontractor>(url, { observe: 'response' });
  }

  getVehicles(): Observable<HttpResponse<Vehicle[]>> {
    const url = `${this.baseUrl}/vehicles`;
    return this.http.get<Vehicle[]>(url, { observe: 'response' });
  }

  getSubcontractorVehicles(id: number): Observable<HttpResponse<Vehicle[]>> {
    const url = `${this.baseUrl}/${id}/vehicles`;
    return this.http.get<Vehicle[]>(url, { observe: 'response' });
  }

  getSubcontractorDrivers(id: number): Observable<HttpResponse<Driver[]>> {
    const url = `${this.baseUrl}/${id}/drivers`;
    return this.http.get<Driver[]>(url, { observe: 'response' });
  }

  /** GET an order which is assigned to us as a subcontractor.*/
  getOrder(orderId: number): Observable<HttpResponse<Order>> {
    const url = `${this.orderBaseUrl}/order/${orderId}`;
    return this.http.get<Order>(url, { observe: 'response' });
  }

}
