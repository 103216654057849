export class DateRange {
  fromDate: Date;
  toDate: Date;
  public constructor(fromDate: Date, toDate: Date) {
      this.fromDate = fromDate;
      this.toDate = toDate;
  }

  public static Default(): DateRange {
      const retval = new DateRange(new Date(), new Date());
      retval.fromDate.setHours(0, 0, 0);
      retval.fromDate.setMilliseconds(0);
      retval.toDate.setHours(0, 0, 0);
      retval.toDate.setMilliseconds(0);
      retval.toDate.setDate(retval.toDate.getDate() + 1); // lägg till en dag
      retval.toDate = new Date(retval.toDate.getTime() - 1); // drag bort en millisekund
      return retval;
  }
}
