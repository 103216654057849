import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthService } from '../../common/services/auth.service';
import { OrderByService } from '../../common/services/order-by.service';
import { OperatorService } from '../../common/services/operator.service';
import { RouteService } from '../../common/services/route.service';
import { RouteVariant, Route, RouteVariantWithConnection } from '../../common/models/route';
import { Operator } from '../../common/models/operator';
import { NgbModalOptions, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AdaptRouteComponent } from '../../orders/order/adapt-route/adapt-route.component';
import { AlertService } from '../../common/services/alert.service';
import { ConfirmModalComponent } from '../../common/components/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-connect-routevariant',
  templateUrl: './connect-routevariant.component.html',
  styleUrls: ['./connect-routevariant.component.scss']
})
export class ConnectRoutevariantComponent implements OnInit {

  connectForm: UntypedFormGroup;
  operators: Operator[] = [];
  routes: Route[] = [];
  routeVariants: RouteVariantWithConnection[] = [];
  routeVariantId1: number = null;
  routeVariantName1: string = null;
  routeVariantChanged1 = false;
  routeVariantId2: number = null;
  routeVariantName2: string = null;
  routeVariantChanged2 = false;
  buttonsDisabled = false;
  modalRef: NgbModalRef;

  constructor(
    private fb: UntypedFormBuilder,
    private routeService: RouteService,
    private operatorService: OperatorService,
    public modalService: NgbModal,
    private authService: AuthService,
    private orderByService: OrderByService,
    private alertService: AlertService,
  ) { }

  ngOnInit() {

    this.createForm();
    this.getOperators();
    this.getRouteVariants();
  }

  get routeCtrl() { return this.connectForm.get('route'); }
  get operatorCtrl() { return this.connectForm.get('operator'); }

  routeVariantId(index: number) { return index === 1 ? this.routeVariantId1 : this.routeVariantId2; }
  routeVariantName(index: number) { return index === 1 ? this.routeVariantName1 : this.routeVariantName2; }
  routeVariantChanged(index: number) { return index === 1 ? this.routeVariantChanged1 : this.routeVariantChanged2; }

  createForm() {
    this.connectForm = this.fb.group({
      operator: [null, Validators.required],
      route: [null, Validators.required],
    });
  }

  getOperators() {
    this.operatorService.getOperators(true)
      .subscribe(ops => {
        this.operators = ops.body;
        let selectedOperator = this.operators.find(x => x.operatorOrganisationId === this.authService.user.idTokenClaims.garageIds[0]);
        if (selectedOperator === undefined) {
          selectedOperator = null; // Because default value in select element should be selected
        }
        this.connectForm.patchValue({
          operator: selectedOperator
        });
      });
  }

  getRoutes(operatorOrganisationId: number) {
    if (operatorOrganisationId !== undefined) {
      this.routeService.getRoutes(operatorOrganisationId)
        .subscribe(routes => this.routes = this.orderByService.transform(routes, 'routeName'));
    }
  }

  getRouteVariants() {
    this.routeService.getRouteVariants()
      .subscribe(rv => {
        this.routeVariants = this.orderByService.transform(rv, ['routeName', 'routeVariantName']);
      });
  }

  OnChangeOperator() {
    const operatorOrganisationId = this.connectForm.controls['operator'].value.operatorOrganisationId;
    this.routeService.getRoutes(operatorOrganisationId)
      .subscribe(routes => {
        this.routes = this.orderByService.transform(routes, 'routeName');
        this.connectForm.get('route').reset();
        this.OnChangeRoute();
      });
  }

  OnChangeRoute() {
    this.routeVariantId1 = null;
    this.routeVariantName1 = null;
    this.routeVariantChanged1 = false;
    this.routeVariantId2 = null;
    this.routeVariantName2 = null;
    this.routeVariantChanged2 = false;
  }

  selectRoute(ctrlIndex: number, routeVariantId: number) {
    const opts: NgbModalOptions = { size: 'lg' };
    const modalRef = this.modalService.open(AdaptRouteComponent, opts);
    modalRef.componentInstance.route = this.routeCtrl.value;
    modalRef.componentInstance.selectedRouteVariantId = routeVariantId;
    modalRef.componentInstance.isSelectOnly = true;
    modalRef.result.then((result) => {
      if (ctrlIndex === 1) {
        this.routeVariantId1 = result.routeVariantId;
        this.routeVariantName1 = result.routeVariantName;
        this.routeVariantChanged1 = true;
      } else if (ctrlIndex === 2)  {
        this.routeVariantId2 = result.routeVariantId;
        this.routeVariantName2 = result.routeVariantName;
        this.routeVariantChanged2 = true;
      }
      this.connectForm.setErrors(null);
    })
    .catch(reason => { });
  }

  connect() {
    this.alertService.clear();

    // validation
    Object.keys(this.connectForm.controls).forEach(field => {
      const control = this.connectForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });

    if (this.routeVariantId1 == null) {
      this.routeVariantChanged1 = true;
      this.connectForm.setErrors({ 'invalid': true });
    }
    if (this.routeVariantId2 == null) {
      this.routeVariantChanged2 = true;
      this.connectForm.setErrors({ 'invalid': true });
    }

    // Save
    if (this.connectForm.valid) {
      this.buttonsDisabled = true;
      this.routeService.updateRouteVariantConnection(this.routeVariantId1, this.routeVariantId2)
      .subscribe(result => {
        this.getRouteVariants();
        this.buttonsDisabled = false;
      }, err => {
        this.buttonsDisabled = false;
      });
    }
  }

  confirmDisconnect(routeVariant: RouteVariantWithConnection) {
    this.buttonsDisabled = true;
    this.alertService.clear();
    this.modalRef = this.modalService.open(ConfirmModalComponent);
    this.modalRef.componentInstance.title = $localize`:Ta bort@@connect-routevariant.confirm-title:Ta bort`;
    this.modalRef.componentInstance.description = $localize`:Vill du ta bort kopplingen?@@connect-routevariant.confirm-description:Vill du ta bort kopplingen?`;
    this.modalRef.result.then(() => {
      this.disconnect(routeVariant);
    })
    .catch(reason => {
      this.buttonsDisabled = false;
    });
  }

  disconnect(routeVariant: RouteVariantWithConnection) {
    this.routeService.updateRouteVariantConnection(routeVariant.id, routeVariant.routeVariantConnection.id, true)
    .subscribe(result => {
      this.getRouteVariants();
      this.buttonsDisabled = false;
    }, err => {
      this.buttonsDisabled = false;
    });
  }

}
