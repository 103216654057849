<h1 class="my-5 text-center" i18n="@@import-timetable.title">Administration</h1>
<app-admin-menu></app-admin-menu>
<div class="container">
  <div>
    <form [formGroup]="importForm" (ngSubmit)="import()" novalidate class="form ms-5 mt-4">
      <p i18n="@@import-timetable.instructions">OBS! Detta ska göras i testmiljön för en specifik fil innan det görs i
        produktion.</p>
      <div>
        <div class="col-6">
          <label class='mb-2' for="operator" i18n="@@import-timetable.select-traffic-operator">Välj trafikoperatör</label>
          <select class="form-select form-control" id="operator" formControlName="operator" (change)="onChangedOperator()">
            <option [ngValue]="null" [disabled]="true" i18n="@@import-timetable.select">Välj</option>
            <option *ngFor="let op of operators" [ngValue]="op">{{ op.name }}</option>
          </select>
          <div *ngIf="operatorCtrl.invalid && (operatorCtrl.dirty || operatorCtrl.touched)" class="text-danger mt-1"
            i18n="@@import-timetable.traffic-operator-mandatory">
            Trafikoperatör är obligatoriskt
          </div>
        </div>
      </div>
      <div class="my-3">
        <div class="col-6">

          <label class='mb-2' i18n='@@import-timetable.file-with-hastus-data'>Fil med grunddata från Hastus</label>
          <div class='input-group custom-file-button'>
            <input type='file' class='form-control' id='importFile' accept='text/xml' formControlName="importFile" (change)="onFileChange($event.target.files)">
            <label i18n="@@import-timetable.select-file" class='input-group-text' for='importFile'>Välj fil</label>
          </div>


          <div *ngIf="fileInputCtrl.invalid && (fileInputCtrl.dirty || fileInputCtrl.touched)" class="text-danger mt-1"
            i18n="@@import-timetable.file-mandatory">
            Fil är obligatoriskt
          </div>
        </div>
      </div>
      <div class="mt-5 mb-1">
        <div class="col-6 d-grid gap-2">
          <button class="btn btn-primary float-right" type="button" [disabled]="uploading"
            (click)="import()" i18n="@@import-timetable.import">
            Läs in
          </button>
        </div>
      </div>
      <div>
        <div class="col" *ngIf="fileImported">
          <label class="col-6" i18n="@@import-timetable.file-imported">Filen är inläst!</label>
        </div>
      </div>
    </form>
    <h2 class="ms-5">
      <ng-container i18n="@@import-timetable.routevariants">Linjevarianter</ng-container>
      <div class="reload-container" (click)="onChangedOperator()">
        <div [ngClass]="{'active': isLoading}" class="reloadSingle"></div>
      </div>
    </h2>
    <div class="px-2">
      <div class="row mt-1">
        <div class="col-3 fw-400" i18n="@@import-timetable.route">Linje</div>
        <div class="col-8 fw-400" i18n="@@import-timetable.routevariant">Linjevariant</div>
        <div class="col-1 fw-400 text-end" i18n="@@import-timetable.routevariant-remove">Ta bort</div>
      </div>
      <div *ngFor="let routeVariant of routeVariants; let odd = odd" class="row routeVariants py-1" [class.odd]="odd">
        <div class="col-3">{{routeVariant.routeName}}</div>
        <div class="col-8">{{routeVariant.routeVariantName}}</div>
        <div class="col-1 text-end">
          <img class="removeSymbol" (click)="removeRouteVariant(routeVariant.id)" src="/assets/img/icons/close.png" alt="Close icon"/>
        </div>
      </div>
    </div>
  </div>
</div>
