import { Injectable } from '@angular/core';
import { DriveOrderAssigned, DriveOrderInquiryCreated, NotificationMessage, NotificationType } from '@models/notification-message';

@Injectable()
export class ServerNotificationService {

  setServerNotificationLocalization(notification: NotificationMessage): NotificationMessage {
    switch (notification.notificationType) {
      case NotificationType.DriveOrderInquiryCreated:
        this.setDriveOrderInquiryCreatedNotification(notification);
        break;
      case NotificationType.DriveOrderAssigned:
        this.setDriveOrderAssignedNotification(notification);
        break;

      default:
        break;
    }
    return notification;
  }

  private setDriveOrderAssignedNotification(notification: NotificationMessage) {
    const driveOrderAssignedData = notification.data as unknown as DriveOrderAssigned;
    const numberOfDriveOrders = driveOrderAssignedData.numberOfDriveOrders;
    notification.title = $localize`:@@drive-order.assigned-notification.title:Ny tilldelning`;

    if (numberOfDriveOrders > 1) {
      notification.main = this.translationStringSubstitution(
        $localize`:@@drive-orders.assigned-notification.message:Du har blivit tilldelad ${numberOfDriveOrders} nya körordrar`,
        numberOfDriveOrders.toString()
      );
    } else {
      notification.main = this.translationStringSubstitution(
        $localize`:@@drive-order.assigned-notification.message:Du har blivit tilldelad ${numberOfDriveOrders} ny körorder`,
        numberOfDriveOrders.toString()
      );
    }
    notification.link = 'orders';
  }

  private setDriveOrderInquiryCreatedNotification(notification: NotificationMessage) {
    const driveOrderInquiryCreatedData = notification.data as unknown as DriveOrderInquiryCreated;
    const numberOfInquiries = driveOrderInquiryCreatedData.numberOfInquiries;
    notification.title = $localize`:@@drive-order.inquiry-notification.title:Ny inkommen förfrågan`;

    if (numberOfInquiries > 1) {
      notification.main = this.translationStringSubstitution(
        $localize`:@@drive-order.inquiries-notification.message:Du har ${numberOfInquiries} nya förfrågningar`,
        numberOfInquiries.toString()
      );
    } else {
      notification.main = this.translationStringSubstitution(
        $localize`:@@drive-order.inquiry-notification.message:Du har ${numberOfInquiries} ny förfrågan`,
        numberOfInquiries.toString()
      );
    }
    notification.link = `${window.location.origin}/order-new/planning`;
  }

  private translationStringSubstitution(translation: string, ...args: string[]): string {
    args.forEach((arg, index) => {
      translation = translation.replace(`{${index}}`, arg);
    });
    return translation;
  }
}
