import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';
import { Route, RouteVariant, RouteVariantWithConnection } from '../models/route';

@Injectable()
export class RouteService {
  private baseUrl = environment.apiUrl + environment.apiVersionMain + '/timetables';

  constructor(private http: HttpClient,
    private authService: AuthService) {
  }

  /** GET routes from the server */
  getRoutes(operatorOrganisationId: number): Observable<Route[]> {
    const url = `${this.baseUrl}/routes?operatorOrganisationId=${operatorOrganisationId}`;
    return this.http.get<Route[]>(url);
  }

  /** GET route by id. Will 404 if id not found */
  getRoute(id: number): Observable<Route> {
    const url = `${this.baseUrl}/routes/${id}`;
    return this.http.get<Route>(url);
  }

  getRouteVariant(id: number): Observable<RouteVariant> {
    const url = `${this.baseUrl}/routevariant/${id}`;
    return this.http.get<RouteVariant>(url);
  }

  getRouteVariants(): Observable<RouteVariantWithConnection[]> {
    const url = `${this.baseUrl}/routevariants`;
    return this.http.get<RouteVariantWithConnection[]>(url);
  }

  getRouteVariantsForOperator(operatorOrganisationId: number): Observable<RouteVariantWithConnection[]> {
    const url = `${this.baseUrl}/routevariants?operatorOrganisationId=${operatorOrganisationId}`;
    return this.http.get<RouteVariantWithConnection[]>(url);
  }

  updateRouteVariantConnection(routeVariantId: number, routeVariantConnectionId: number, disconnect: boolean = false): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/routevariant/${routeVariantId}/connection`;
    return this.http.post(url, { 'routeVariantConnectionId': routeVariantConnectionId, 'disconnect': disconnect }, { observe: 'response' });
  }

  removeRouteVariant(routeVariantId: number): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/routevariant/${routeVariantId}/remove`;
    return this.http.delete(url, { observe: 'response' });
  }
}
