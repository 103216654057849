import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as moment from 'moment';
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { MissingVehicleStopEvents, RouteByTripStopCount } from "../../models/missing-vehicle-stop-events";

@Injectable()
export class MissingVehicleStopEventsService {
    private baseUrl = environment.apiUrl + environment.apiVersionMain + '/admin/missingvehiclestopevents';

    constructor(private http: HttpClient) {
    }

    getTripStopsWithMissingVehicleAtStopEvents(from: Date, to: Date):
        Observable<HttpResponse<MissingVehicleStopEvents[]>> {

        let url = `${this.baseUrl}/tripStop`
            + `?From=${encodeURIComponent(moment(from).format('YYYY-MM-DDTHH:mm:ss.SSSZ'))}`
            + `&To=${encodeURIComponent(moment(to).format('YYYY-MM-DDTHH:mm:ss.SSSZ'))}`;
        return this.http.get<MissingVehicleStopEvents[]>(url, { observe: 'response' });
    }

    getRouteVariantByPlaceName(from: Date, to: Date, placeName: string):
        Observable<HttpResponse<RouteByTripStopCount[]>> {

        let url = `${this.baseUrl}/routes`
            + `?From=${encodeURIComponent(moment(from).format('YYYY-MM-DDTHH:mm:ss.SSSZ'))}`
            + `&To=${encodeURIComponent(moment(to).format('YYYY-MM-DDTHH:mm:ss.SSSZ'))}`
            + `&PlaceName=${placeName}`;
        return this.http.get<RouteByTripStopCount[]>(url, { observe: 'response' });
    }
}