import { Component, Input, OnInit } from '@angular/core';
import { AvailableVehicle } from '@models/availability-inquiry';
import { OrderType } from '@models/order-type';

@Component({
  selector: '[app-availability-inquiry-accepted]',
  template: `
    <div class="col-12 col-sm-6">
      <div class="caption-lt">
        <ng-container i18n="@@availability-inquiry-accepted.vehicles-available">Fordon utlovat</ng-container>
      </div>
      <div>
        {{availableVehicle.count}}
      </div>
    </div>
    <div *ngIf="orderType == OrderType.Urgent"  class="col-12 col-sm-6">
      <div class="caption-lt">
        <ng-container i18n="@@availability-inquiry-accepted.estimated-time-of-arrival">
          Estimerad inställelsetid</ng-container>
      </div>
      <div>
        {{availableVehicle.estimatedArrivalMinutes}}
      </div>
    </div>
  `,
  styleUrls: ['./availability-inquiry-accepted.component.scss']
})
export class AvailabilityInquiryAcceptedComponent implements OnInit {
  @Input() availableVehicle!: AvailableVehicle;
  @Input() orderType : OrderType;

  OrderType = OrderType;

  constructor() { }

  ngOnInit(): void {
  }

}
