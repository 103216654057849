import { Operator } from './operator';
import { UnboundOrderStateObject } from './unbound-order-state';
import { OrderReason } from './order';

export class UnboundOrder {
  id: number;
  fromPlace: string;
  toPlace: string;
  referenceId: string;
  startTime: Date;
  orderReason: OrderReason;
  numberOfPassengers: number;
  otherRequests: string;
  currentState: UnboundOrderStateObject;
  created: UnboundOrderStateObject;
  activated: UnboundOrderStateObject;
  immediateStart: boolean;
  operator: Operator;
  assignment: UnboundOrderAssignment;
  completion: UnboundOrderCompletion;
  trainNumber: string;
}

export class UnboundOrderAssignment {
  contractor: string;
  contactInfo: string;
  bookingReference: string;
  additionalInfo: string;
}

export class UnboundOrderCompletion {
  finalCost: number;
  distanceKm: number;
  additionalInfo: string;
}
