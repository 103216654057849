import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControl } from '@angular/forms';
import { NgbModal, NgbDateStruct, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DateTimePickerHelper } from '../../datepicker-helpers';

@Component({
  selector: 'app-modal-datetime',
  templateUrl: './modal-datetime.component.html',
  styleUrls: ['./modal-datetime.component.scss']
})
export class ModalDatetimeComponent implements OnInit {

  datetimeForm: UntypedFormGroup;
  now: Date = new Date();
  datepickerDefault: NgbDateStruct = { year: this.now.getFullYear(), month: this.now.getMonth() + 1, day: this.now.getDate()};
  hoursInDayList = Array.from(Array(24).keys());
  minutesInHourList = Array.from(Array(60).keys());

  constructor(
    private fb: UntypedFormBuilder,
    public activeModal: NgbActiveModal
  ) { }

  get ctrlDate() { return this.datetimeForm.get('ctrlDate'); }
  get ctrlHour() { return this.datetimeForm.get('ctrlHour'); }
  get ctrlMinute() { return this.datetimeForm.get('ctrlMinute'); }

  ngOnInit() {

    this.datetimeForm = this.fb.group({
      ctrlDate: this.datepickerDefault,
      ctrlHour: this.now.getHours(),
      ctrlMinute: this.now.getMinutes(),
    });
  }

  save() {
    const selectedDateTime = DateTimePickerHelper.getSelectedDateTime(this.ctrlDate.value, this.ctrlHour.value, this.ctrlMinute.value);
    this.activeModal.close(selectedDateTime);
  }

}
