<div class='xxxl-container'>
  <app-order-menu [orderId]='unboundOrder.id' [isUnboundOrder]='true'></app-order-menu>
</div>
<div class='container'>
  <div class='main pt-0'>
    <hr>

    <h2 class='mt-4' i18n='@@unbound-order.distance'>Sträcka</h2>

    <form [formGroup]='unboundOrderForm' (ngSubmit)='save()' novalidate class='form mt-4'>

      <div *ngIf='isOperatorDropdownVisible' class='mb-4'>
        <div class='col-4'>
          <label class='mb-2' for='operator' i18n='@@unbound-order.traffic-operator'>Välj trafikoperatör</label>
          <select class='form-select form-control' id='operator' formControlName='operator'>
            <option [ngValue]='null' [disabled]='true' i18n='@@unbound-order.select'>Välj</option>
            <option *ngFor='let op of operators' [ngValue]='op'>{{ op.name }}</option>
          </select>
          <div *ngIf='operatorCtrl.invalid && (operatorCtrl.dirty || operatorCtrl.touched)' class='text-danger'
               i18n='@@unbound-order.traffic-operator-mandatory'>
            Trafikoperatör är obligatoriskt
          </div>
        </div>
        <div class='col-2'></div>
      </div>

      <div class='mb-4'>
        <div class='col-6'>
          <label class='mb-2' for='fromPlace' i18n='@@unbound-order.from'>Från</label>
          <input class='form-control' type='text' placeholder='Obligatoriskt' id='fromPlace' formControlName='fromPlace'
                 i18n-placeholder='@@unbound-order.placeholder-mandatory'>
          <div *ngIf='fromPlace.invalid && (fromPlace.dirty || fromPlace.touched)' class='text-danger'
               i18n='@@unbound-order.from-mandatory'>
            Från är obligatoriskt
          </div>
        </div>
      </div>
      <div class='mb-4'>
        <div class='col-6'>
          <label class='mb-2' for='toPlace' i18n='@@unbound-order.to'>Till</label>
          <input class='form-control' type='text' placeholder='Obligatoriskt' id='toPlace' formControlName='toPlace'
                 i18n-placeholder='@@unbound-order.placeholder-mandatory'>
          <div *ngIf='toPlace.invalid && (toPlace.dirty || toPlace.touched)' class='text-danger'
               i18n='@@unbound-order.to-mandatory'>
            Till är obligatoriskt
          </div>
        </div>
      </div>
      <hr>

      <h2 class='mt-3' i18n='@@unbound-order.time'>Tid</h2>
      <div class='mt-4 mb-3'>
        <div class='col me-5'>
          <div class='form-check'>
            <input class='form-check-input' type='checkbox' id='immediateStart' formControlName='immediateStart'
                   (change)='OnImmediateStart()'>
            <label class='form-check-label' for='immediateStart' i18n='@@unbound-order.immediate-start'>Start
              omgående</label>
          </div>
        </div>
      </div>


      <div class='col mb-4'>
        <ngb-datepicker ngbDatepicker [minDate]='orderstartMinDate' #dpStartDate='ngbDatepicker'
                        formControlName='orderstartDate'
                        (select)='OnOrderstartdateChange()'></ngb-datepicker>
      </div>

      <div class='row'>
        <div class='col-2'>
          <label class='mb-2' for='startTimeHour' i18n='@@unbound-order.start-time'>Start klockslag</label>
          <select class='form-select form-control' id='startTimeHour' formControlName='startTimeHour'>
            <option value='' i18n='@@unbound-order.select-hour'>Välj timme</option>
            <option *ngFor='let hour of hoursInDayOrderstartList' [ngValue]='hour'>{{ hour | number:'2.0'}}</option>
          </select>
        </div>
        <div class='col-2'>
          <label class='mb-2' for='startTimeMinute'>&nbsp;</label>
          <select class='form-select form-control' id='startTimeMinute' formControlName='startTimeMinute'>
            <option value='' i18n='@@unbound-order.select-minute'>Välj minut</option>
            <option *ngFor='let minute of minutesInHourList' [ngValue]='minute'>{{ minute | number:'2.0'}}</option>
          </select>
        </div>
        <div class='col-2'></div>
      </div>
      <div>
        <div class='col me-5'>
          <div *ngIf='((startTimeHour.dirty || startTimeHour.touched) && startTimeHour.invalid) ||
                          ((startTimeMinute.dirty || startTimeMinute.touched) && startTimeMinute.invalid)'
               class='text-danger' i18n='@@unbound-order.start-time-mandatory'>
            Starttid är obligatoriskt
          </div>
        </div>
      </div>
      <hr>
      <h2 class='mb-4' i18n='@@unbound-order.other'>Övrigt</h2>

      <div class='col-6 mb-4'>
        <label class='mb-2' for='referenceId' i18n='@@unbound-order.reference'>Referens</label>
        <input class='form-control' type='text' placeholder='Frivilligt egen referens' id='referenceId'
               formControlName='referenceId' i18n-placeholder='@@unbound-order.placeholder-reference'>
      </div>

      <div class='col-6 mb-4'>
        <label class='mb-2' for='trainNumber' i18n='@@unbound-order.trainNumber'>Tågnummer</label>
        <input class='form-control' type='text' placeholder='Frivilligt tågnummer' id='trainNumber'
               formControlName='trainNumber' i18n-placeholder='@@unbound-order.placeholder-trainNumber'>
      </div>

      <div class='col-6 mb-4'>
        <label class='mb-2' for='numberOfPassengers' i18n='@@unbound-order.number-of-passengers'>Antal
          passagerare</label>
        <input class='form-control' type='text' placeholder='Används till att beräkna antal taxibilar'
               id='numberOfPassengers'
               formControlName='numberOfPassengers'
               i18n-placeholder='@@unbound-order.placeholder-number-of-passengers'>
        <div *ngIf='(numberOfPassengers.dirty || numberOfPassengers.touched) && numberOfPassengers.invalid'
             class='text-danger' i18n='@@unbound-order.number-of-passengers-invalid'>
          Antal passagerare har ogiltigt värde
        </div>
      </div>
      <div class='col' *ngIf='unboundOrder.id === 0'></div>


      <div class='col-6 mb-4'>
        <label class='mb-2' for='reason' i18n='@@unbound-order.select-reason'>Välj orsak</label>
        <select class='form-select form-control' id='reason' formControlName='reason'>
          <option value='null' [disabled]='true' i18n='@@unbound-order.select'>Välj</option>
          <option *ngFor='let r2 of reasons' [ngValue]='r2'>{{ r2.description }}</option>
        </select>
        <div *ngIf='reason.invalid && (reason.dirty || reason.touched)' class='text-danger'
             i18n='@@unbound-order.reason-mandatory'>
          Orsak är obligatoriskt
        </div>
      </div>


      <div class='col-6 mb-4'>
        <label class='mb-2' for='reasonAdditionalInfo' i18n='@@unbound-order.reason-info'>Beskriv orsak</label>
        <textarea class='form-control' placeholder='Frivilligt' id='reasonAdditionalInfo'
                  formControlName='reasonAdditionalInfo'
                  i18n-placeholder='@@unbound-order.placeholder-voluntary'></textarea>
      </div>

      <div class='col-6 mb-5'>
        <label class='mb-2' for='otherRequests' i18n='@@unbound-order.other-requests'>Övriga önskemål</label>
        <textarea *ngIf='(unboundOrder.otherRequests !== null)' class='form-control'
                  placeholder='Information om till exempel medföljande barnvagn eller eventuell via-station.'
                  id='otherRequests' formControlName='otherRequests'
                  i18n-placeholder='@@unbound-order.other-requests-description'></textarea>
        <textarea *ngIf='(unboundOrder.otherRequests === null)' class='form-control'
                  placeholder='Borttaget efter 90 dagar.'
                  id='otherRequests1' formControlName='otherRequests'
                  i18n-placeholder='@@unbound-order.other-requests-description-removed'></textarea>
      </div>

      <hr>
      <div class='d-flex justify-content-between gap-2'>
        <div>
          <button class='btn btn-outline-secondary' type='button' [disabled]='buttonsDisabled'
                  (click)='dismiss()' i18n='@@unbound-order.cancel'>Avbryt
          </button>
        </div>
        <div class='d-flex justify-content-end gap-2'>
          <div *ngIf='isCancelOrderButtonVisible'>
            <button id='cancel-order-btn' class='btn btn-outline-danger' type='button' [disabled]='buttonsDisabled'
                    (click)='cancelOrder()' i18n='@@unbound-order.cancel-order'>Avbeställ
            </button>
          </div>
          <div
            *ngIf='(userHasAnyRole([UserRoles.NobinaTrafikledare,UserRoles.NobinaLeveransansvarig,UserRoles.BergkvaraTrafikledare]) && unboundOrder?.currentState && unboundOrder.currentState.stateEnum == UnboundOrderState.Cancelled)'>
            <button id='verify-cancel-order-btn' class='btn btn-outline-danger' type='button' [disabled]='buttonsDisabled'
                    (click)='closeOrder()' i18n='@@unbound-order.confirm-cancel'>Bekräfta avbeställning
            </button>
          </div>
          <div *ngIf='isActivateButtonVisible'>
            <button id='activate-btn' class='btn btn-primary' type='button' (click)='activateOrder()'
                    [disabled]='buttonsDisabled || (unboundOrder?.currentState && unboundOrder.currentState.stateEnum > UnboundOrderState.ReceivedByNobina)'
                    i18n='@@unbound-order.activate'>Aktivera beställning
            </button>
          </div>
          <div *ngIf='isSendOrderButtonVisible'>
            <button id='order-btn' class='btn btn-primary' type='submit' [disabled]='buttonsDisabled'
                    i18n='@@unbound-order.send'>Skicka beställning
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

</div>
