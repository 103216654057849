import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RouteService } from '@services/route.service';
import { Route, RouteVariant, ScheduledStopPoints } from '@models/route';
import { OrderByService } from '@services/order-by.service';

@Component({
  selector: 'app-adapt-route',
  templateUrl: './adapt-route.component.html',
  styleUrls: ['./adapt-route.component.scss']
})
export class AdaptRouteComponent implements OnInit {
  @Input() route: Route;
  routes: Route[];
  routeVariants: RouteVariant[];
  routeStops: ScheduledStopPoints[];
  adaptRouteForm: UntypedFormGroup;
  changeStartActive = false;
  changeEndActive = false;
  selectedRouteVariantId: number;
  selectedRouteVariantName: string;
  routeVariantAdapted = false;
  selectedStartId: string;
  selectedStartSequence: number;
  selectedEndId: string;
  selectedEndSequence: number;
  activeRouteStopPlaceIds: string[];
  inactivatedRouteStopPlaceIds: string[] = [];
  disembarkingOnlyRouteStopPlaceIds: string[] = [];
  isSaved: boolean;
  isEdit: boolean;
  isSelectOnly = false;

  constructor(public activeModal: NgbActiveModal,
    private routeService: RouteService,
    private orderByService: OrderByService) { }

  ngOnInit() {
    this.adaptRouteForm = new UntypedFormGroup({
      direct: new UntypedFormControl(),
      connectionRequired: new UntypedFormControl()
    });
    this.routeVariants = this.route ? this.orderByService.transform(this.route.routeVariants, 'routeVariantName') : [];
    if (this.selectedRouteVariantId) {
      this.routeVariantSelected(this.selectedRouteVariantId, true);
    }
  }

  private routeVariantSelected(routeVariantId: number, init: boolean) {
    if (this.selectedRouteVariantId == null || this.selectedRouteVariantId !== routeVariantId) {
      // ny eller ändrad linjevariant. Nollställ "Endast avstigande"
      this.disembarkingOnlyRouteStopPlaceIds = [];
    }
    this.selectedRouteVariantId = routeVariantId;
    // har varianten scheduledStopPoints? dvs är det en befintlig order route
    const tmpVariant = this.route.routeVariants.find(x => x.id === routeVariantId);
    if (tmpVariant !== undefined && tmpVariant.scheduledStopPoints !== undefined && tmpVariant.scheduledStopPoints.length > 0) {
      this.setSelectedRouteVariant(tmpVariant, init);
    } else {
      this.routeService.getRouteVariant(routeVariantId)
        .subscribe(variant => {
          this.setSelectedRouteVariant(variant, init);
        });
    }
  }

  private setSelectedRouteVariant(variant: RouteVariant, init: boolean) {
    this.selectedRouteVariantName = variant.routeVariantName;
    this.routeStops = variant.scheduledStopPoints;
    if (init && this.activeRouteStopPlaceIds && this.activeRouteStopPlaceIds.length) {
      this.inactivatedRouteStopPlaceIds = this.routeStops.filter(x => this.activeRouteStopPlaceIds.indexOf(x.place.placeId) === -1).map(y => y.place.placeId) ;
    }
    // Det får aldrig vara mindre än två stop valda, om en användare valt en linjevariant med ett superset av en mindre linjevariant
    // och valt bort alla stop i den mindre varianten, så måste man aktivera alla stop i den mindre om den blir vald, annars skulle inga
    // stop vara valda.
    if (this.inactivatedRouteStopPlaceIds) {
      const intersection = Array.from(new Set([...this.routeStops].filter(x => this.inactivatedRouteStopPlaceIds.indexOf(x.place.placeId) === -1).map(s => s.place.placeId)));
      if (intersection.length < 2) {
        this.inactivatedRouteStopPlaceIds = [];
      }
    }
    this.setDisembarkingStops();
    this.setActiveStops();
  }

  private setDisembarkingStops() {
    for (const stop of this.routeStops) {
      stop.disembarkingOnly = !!this.disembarkingOnlyRouteStopPlaceIds.filter(s => s === stop.place.placeId).length;
    }
  }

  private setActiveStops(): void {
    for (const stop of this.routeStops) {
      stop.active = !(this.inactivatedRouteStopPlaceIds && this.inactivatedRouteStopPlaceIds.filter(s => s === stop.place.placeId).length);
    }
    this.routeStops = this.orderByService.transform(this.routeStops, 'sequence');
    this.setStartBusstop();
    this.setEndBusstop();
  }

  isChangingStartOrStop() {
    return this.changeStartActive || this.changeEndActive;
  }

  changeStart() {
    this.changeStartActive = !this.changeStartActive;
    this.changeEndActive = false;
  }

  changeEnd() {
    this.changeEndActive = !this.changeEndActive;
    this.changeStartActive = false;
  }

  isChangeActive(stopId: number, sequence: number) {
    return this.changeStartActive && sequence < this.selectedEndSequence
      || this.changeEndActive && sequence > this.selectedStartSequence;
  }

  selectStop(stopId: string, sequence: number) {
    if (this.changeStartActive) {
      this.selectedStartId = stopId;
      this.selectedStartSequence = sequence;
    } else if (this.changeEndActive) {
      this.selectedEndId = stopId;
      this.selectedEndSequence = sequence;
    }
    for (const stop of this.routeStops) {
      stop.active = stop.sequence >= this.selectedStartSequence && stop.sequence <= this.selectedEndSequence;
      this.updateInactivatedStopsList(stop);
    }

  }

  setActiveOrInactive(stop: ScheduledStopPoints, isActive: boolean) {
    stop.active = isActive;
    if (!isActive) {
      stop.disembarkingOnly = false;
    }
    this.updateInactivatedStopsList(stop);
    this.setStartBusstop();
    this.setEndBusstop();
  }

  toggleDisembarking(stop: ScheduledStopPoints) {
    stop.disembarkingOnly = !stop.disembarkingOnly;
    if (stop.disembarkingOnly) {
      this.disembarkingOnlyRouteStopPlaceIds.push(stop.place.placeId);
    } else {
      this.disembarkingOnlyRouteStopPlaceIds = this.disembarkingOnlyRouteStopPlaceIds.filter(id => id !== stop.place.placeId);
    }
  }

  setStartBusstop() {
    // find first active stop and set as start
    for (const stop of this.routeStops) {
      if (stop.active) {
        this.selectedStartId = stop.place.placeId;
        this.selectedStartSequence = stop.sequence;
        stop.disembarkingOnly = false;
        break;
      }
    }
  }

  setEndBusstop() {
    // find last active stop and set as end
    const reverseRouteStops = this.orderByService.transform(this.routeStops, 'sequence', true);
    for (const stop of reverseRouteStops) {
      if (stop.active) {
        this.selectedEndId = stop.place.placeId;
        this.selectedEndSequence = stop.sequence;
        stop.disembarkingOnly = false;
        break;
      }
    }
  }

  isRemoveButtonVisible() {
    // show remove-button only if there are more than two stops active
    return this.routeStops.filter(s => s.active).length > 2;
  }

  variantBaseName(rv: RouteVariant): string {
    const ix = rv.routeVariantName.indexOf('_');
    if (ix < 0) {
      return rv.routeVariantName;
    }

    return rv.routeVariantName.substring(0, ix);
  }

  variantStartPlace(rv: RouteVariant): string {
    const ix = rv.routeVariantName.indexOf('_');
    if (ix < 0) {
      return '';
    }
    const ix2 = rv.routeVariantName.indexOf('-');
    if (ix2 > ix) {
      return rv.routeVariantName.substring(ix + 1, (ix2 + 1));
    }
    return '';
  }
  variantEndPlace(rv: RouteVariant): string {
    const ix = rv.routeVariantName.indexOf('_');
    if (ix < 0) {
      return '';
    }
    const ix2 = rv.routeVariantName.indexOf('-');
    if (ix2 > ix) {
      return rv.routeVariantName.substring(ix2 + 1);
    }
    return '';
  }
  updateInactivatedStopsList(stop: ScheduledStopPoints) {
    if (!stop.active) {
      if (!this.inactivatedRouteStopPlaceIds) {
        this.inactivatedRouteStopPlaceIds = new Array<string>();
      }
      if (this.inactivatedRouteStopPlaceIds.indexOf(stop.place.placeId) === -1) {
        this.inactivatedRouteStopPlaceIds.push(stop.place.placeId);
      }
      stop.disembarkingOnly = false;
    } else {
       const index = this.inactivatedRouteStopPlaceIds.indexOf(stop.place.placeId);
       if (index > -1) {
        this.inactivatedRouteStopPlaceIds.splice(index, 1);
       }
    }
  }
  close() {
    const selectedRouteVariantAndRouteStops = {
      routeVariantId: this.selectedRouteVariantId,
      routeVariantName: this.selectedRouteVariantName,
      routeVariantAdapted: this.routeStops !== undefined ? this.routeStops.filter(s => s.active).length !== this.routeStops.length : false,
      activeRouteStopPlaceIds: this.routeStops !== undefined ? this.routeStops.filter(s => s.active).map(s => s.place.placeId) : [],
      inactivatedRouteStopPlaceIds: this.inactivatedRouteStopPlaceIds,
      disembarkingOnlyRouteStopPlaceIds: this.disembarkingOnlyRouteStopPlaceIds
    };
    this.activeModal.close(selectedRouteVariantAndRouteStops);
  }

}
