import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import * as moment from 'moment';

import { OrderTrips, OrderTripsPageResult } from '@models/order';
import { Operator } from '@models/operator';
import { OrderListService } from '@services/orderList.service';
import { OperatorService } from '@services/operator.service';
import { OrderByService } from '@services/order-by.service';
import { UserRoles } from '@authentication/user-roles';
import { AuthService } from '@services/auth.service';
import { ListViewService } from '@services/list-view.service';
import { ListType, SortBy } from '@models/list-type';
import { DatePickerService } from '@services/date-picker.service';
import { DateRange } from '@models/dateRange';
import { roundOffMinutes } from '@common/date-extension';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { NotificationService } from '@services/notification.service';
import { DriveOrderState, DriveOrderStateLabel, DriveOrderTripState, DriveOrderTripStateLabel } from '@models/drive-order';
import { StateChangedMessage } from '@models/statechanged-message';
import { VehicleTypeService } from '@services/vehicle-type.service';
import { OrderPageService } from '@services/order-page.service';
import { DateFilterOption } from '@services/DateFilterOption';
import { environment } from 'environments/environment';
import { DateOrigin } from '@models/date-origin';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit, OnDestroy {
  
  filterForm: UntypedFormGroup;
  dateFilterOptionEnum = DateFilterOption;
  isHistory: boolean;
  dateOrigin = DateOrigin.Order;
  pageSize: number;
  currentPage = 1;
  totalRecord: number;
  filteredCount: number;
  actionRequiredCount: number;
  selectedOperators: number[];
  trainNumber = '';
  subContractor = '';
  orderListType: number;
  timeout: any = null;
  OrderTrips: OrderTripsPageResult[];
  RequireActionsList: OrderTripsPageResult[];
  Operators: Operator[];
  UserRoles = UserRoles;
  loadRequiresActionList: boolean;
  showRequiresActionList: boolean;
  dateRangeFilter: DateRange;
  sortBy: SortBy = SortBy.StartTime;
  sortDesc = false;
  stateChangedMessageSub: Subscription;
  isOperatorTrafikledare: boolean;
  messageList: StateChangedMessage[] = [];
  onDestroy$ = new Subject();
  vehicleType: string;
  orderListTypes = [
    { id: 1, name: $localize`:@@order-list.type.ongoing:Pågående` },
    { id: 2, name: $localize`:@@order-list.type.unplanned:Oplanerad`},
    { id: 3, name: $localize`:@@order-list.type.planned:Planerad`},
    { id: 4, name: $localize`:@@order-list.type.unbound-order:Friorder`}
  ];
  initialSubcontractorValue: string;
  initialTrainNumberValue: string;

  constructor(private fb: UntypedFormBuilder,
    private router: Router,
    private orderListService: OrderListService,
    private orderByService: OrderByService,
    private operatorService: OperatorService,
    private authService: AuthService,
    private datePickerService: DatePickerService,
    private listViewService: ListViewService,
    private notificationService: NotificationService,
    private vehicleTypeService: VehicleTypeService,
    private orderPageService: OrderPageService
    ) {
  }

  ngOnInit() {
    const savedActionListCollapseState = sessionStorage.getItem('showRequiresActionList');
    this.showRequiresActionList = savedActionListCollapseState !== null ? savedActionListCollapseState.toLowerCase() === 'true' : true;

    this.loadRequiresActionList = this.userHasAnyRole([
      UserRoles.NobinaLeveransansvarig,
      UserRoles.NobinaTrafikledare,
      UserRoles.BergkvaraTrafikledare,
      UserRoles.UETrafikledare,
      UserRoles.OperatorTrafikledare
    ]);

    this.isOperatorTrafikledare = this.userHasAnyRole([UserRoles.OperatorTrafikledare]);

    this.userPreferredFilters();

    this.totalRecord = this.getOrdersWithTrips();

    if (this.loadRequiresActionList) {
      this.getRequiresActionOrderList();
    }

    this.getOperators();
    this.createFilterForm();

    this.notificationService.getStateChangedMessage().pipe(takeUntil(this.onDestroy$)).subscribe(message => {
      if (!this.messageList.some(messageListElem => this.compareMessage(messageListElem, message))) {
        this.messageList.push(message);

        if (this.loadRequiresActionList &&
          (message.referenceType === 'Order' ||
          message.referenceType === 'DriveOrder' && message.message ===  DriveOrderStateLabel.get(DriveOrderState.AssignedToDriverAndVehicle))
        ) {
          this.getRequiresActionOrderList();
        } else if (
          (message.referenceType === 'DriveOrderTrip' && message.message === DriveOrderTripStateLabel.get(DriveOrderTripState.CheckedIn))  ||
          (message.referenceType === 'DriveOrder' && message.message === DriveOrderStateLabel.get(DriveOrderState.ReportedComplete))
        ) {
          this.getOrdersWithTrips();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  compareMessage(m1: StateChangedMessage, m2: StateChangedMessage): boolean {
    return (m1.referenceId === m2.referenceId &&
      m1.message === m2.message);
  }

  onDateRangeChanged(dateRange: DateRange): void {
    this.dateRangeFilter = dateRange === undefined &&
      this.datePickerService.orderFromDate === null &&
      this.datePickerService.orderToDate === null ?
        new DateRange(new Date, moment().add(7, 'days').toDate()) :
        this.datePickerService.orderDateRange = dateRange;

    this.getOrdersWithTrips();
  }

  navigateToDriveOrderTrip(orderId: number, driveOrderId: number, tripId: number) {
    window.location.href = `${window.location.origin}/order-new/order/${orderId.toString()}/drive-order#${driveOrderId.toString()}_${tripId.toString()}`
  }

  userPreferredFilters() {
    this.subContractor = this.listViewService.subContractor;
    this.trainNumber = this.listViewService.trainNumber;
    this.selectedOperators = this.listViewService.operatorIds;
    this.pageSize = this.listViewService.pageSize;
    this.orderListType = this.listViewService.orderListType;
  }

  clearFilter() {
    this.listViewService.clearUserPerferenceSession();
    this.filterForm.controls['orderListType'].setValue(this.orderListTypes[0].id, this.orderListTypes[0].name);
    this.filterForm.controls['subContractor'].reset();
    this.filterForm.controls['trainNumber'].reset();
    this.filterForm.controls['selectedOperatorIds'].reset();
    this.userPreferredFilters();
    this.totalRecord = this.getOrdersWithTrips();
    this.sortBy = SortBy.StartTime;
    this.sortDesc = false;
  }

  selectedPageSize(event: number) {
    this.listViewService.pageSize = event;
    this.currentPage = 1;
    this.totalRecord = this.getOrdersWithTrips();
  }

  pageChanged(event: number) {
    this.currentPage = event;
    this.totalRecord = this.getOrdersWithTrips();
  }

  selectedOrderListType() {
    const selectedType = this.orderListTypes.find(x => x.id === this.filterForm.controls['orderListType'].value).id;

    switch (selectedType) {
      case 1: {
        this.listViewService.orderListType = ListType.Ongoing;
        break;
      }
      case 2: {
        this.listViewService.orderListType = ListType.UnplanedOrders;
        break;
      }
      case 3: {
        this.listViewService.orderListType = ListType.PlannedOrders;
        break;
      }
      case 4: {
        this.listViewService.orderListType = ListType.UnboundOrders;
        break;
      }
    }

    this.currentPage = 1;
    this.totalRecord = this.getOrdersWithTrips();
  }

  searchSubContractor(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (event.keyCode !== 13) {
        this.listViewService.subContractor = event;
        this.orderPageService.setSubcontractorValue(event);
        this.currentPage = 1;
        this.getOrdersWithTrips();
      }
    }, 1000);
  }

  searchTrainNumber(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (event.keyCode !== 13) {
        this.listViewService.trainNumber = event;
        this.orderPageService.setTrainNumberValue(event);
        this.currentPage = 1;
        this.getOrdersWithTrips();
      }
    }, 1000);
  }

  onSelectOperator() {
    this.listViewService.operatorIds = this.selectedOperators;
    this.currentPage = 1;
    this.getOrdersWithTrips();
  }

  getOperators() {
    this.operatorService.getOperators(true)
      .subscribe(ops => {
        this.Operators = this.orderByService.transform(ops.body, 'name');
      });
  }

  getRequiresActionOrderList() {
    this.orderListService.getOrdersRequiresActionList().subscribe(
      (resp) => {
        const response: OrderTrips = resp.body;
        response.items.forEach(item => {
          item.orderUrl = this.orderListService.orderLink(this.isHistory, item.orderId, item.orderType);
          item.orderTypeShortString = this.orderListService.orderTypeString(item.orderType, item.orderId);
          item.vehicleType = this.vehicleTypeService.getVehicleType(item.vehicleTypeEnum)
        });
        this.RequireActionsList = response.items;
        roundOffMinutes([
          ...this.RequireActionsList.flatMap(ot => ot.startTime),
          ...this.RequireActionsList.flatMap(ot => ot.endTime)
        ]);
        this.actionRequiredCount = response.totalItems;
      });
  }

  getOrdersWithTrips(): number {
    this.orderPageService.getOrdersWithTrips(this.currentPage).subscribe(response => {
        this.OrderTrips = response.items;
        roundOffMinutes([
          ...this.OrderTrips.flatMap(ot => ot.startTime),
          ...this.OrderTrips.flatMap(ot => ot.endTime)
        ]);
        this.totalRecord = response.totalItems;
        this.filteredCount = response.totalItems;
    });
    return this.totalRecord;
  }

  createFilterForm() {
    this.filterForm = this.fb.group({
      selectedOperatorIds: [null],
      orderListType: [null],
      subContractor: [null],
      trainNumber: [null]
    });

    this.initialSubcontractorValue = this.orderPageService.getSubcontractorValue();
    this.initialTrainNumberValue = this.orderPageService.getTrainNumberValue();
    const orderListType = this.orderListTypes.find(x => x.id === this.listViewService.orderListType);
    this.filterForm.controls['orderListType'].setValue(orderListType.id, orderListType.name);
    this.filterForm.controls['subContractor'].setValue(this.initialSubcontractorValue)
    this.filterForm.controls['trainNumber'].setValue(this.initialTrainNumberValue)
  }

  newOrder() {
    this.router.navigateByUrl('/order');
  }

  userHasAnyRole(userRoles: UserRoles[]) {
    return this.authService.userHasAnyRole(userRoles);
  }

  toggleRequiredActionList() {
    this.showRequiresActionList = !this.showRequiresActionList;
    sessionStorage.setItem('showRequiresActionList', this.showRequiresActionList.toString());
  }

  setSortBy($event: {target: HTMLElement}, sortBy: SortBy) {
    const arrow = $event.target.children[0];

    if (this.sortBy === sortBy) {
      this.sortDesc = !this.sortDesc;

      arrow.classList.toggle('arrow-up');
      arrow.classList.toggle('arrow-down');
    } else {
      this.sortBy = sortBy as SortBy;
      this.sortDesc = false;

      Array.from($event.target.parentNode.children).map(item => item.firstElementChild.classList.add('hidden'));

      arrow.classList.toggle('hidden');
    }

    this.orderPageService.setSortBy(this.sortBy, this.sortDesc);
    this.totalRecord = this.getOrdersWithTrips();
  }
}
