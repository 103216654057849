import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Component } from '@angular/core';

import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NotificationMessage } from '@models/notification-message';
import { NotificationService } from '@services/notification.service';
import { map } from 'rxjs/operators';
import { ServerNotificationService } from '@services/server-notification.service';

@Component({
  selector: '[app-toast-component]',
  styleUrls: ['./toast.component.scss'],
  templateUrl: './toast.component.html',
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        opacity: 0,
      })),
      state('active', style({})),
      state('removed', style({ opacity: 0 })),
      transition('inactive => active',
        animate('{{ easeTime }}ms {{ easing }}')
      ),
      transition('active => removed',
        animate('{{ easeTime }}ms {{ easing }}'),
      ),
    ]),
  ],
  preserveWhitespaces: false,
})

export class ToastComponent extends Toast {

  public notification: NotificationMessage;

  // constructor is only necessary when not using AoT
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    private router: Router,
    private notificationService: NotificationService,
    private serverNotificationService: ServerNotificationService,
  ) {
    super(toastrService, toastPackage);
  }

  navigateTo(notification: NotificationMessage) {
    this.notificationService.addNotificationRead([notification.id]).subscribe(() => {
      this.notificationService.getNotifications(true).pipe(map((notifications) => {
        return notifications.map(not => {
          if (not.notificationType) {
            return this.serverNotificationService.setServerNotificationLocalization(not);
          } else {
            return not;
          }
        });
      })).subscribe(un => {
        const unreadNotifications = un.length;
        this.notificationService.updateUnreadNotifications(unreadNotifications);
        if (this.router.url === notification.link) {
          window.location.reload();
        } else if (notification.link.indexOf('/order-new/') >= 0 ||
                    notification.link.indexOf('/garage/') >= 0) {
          window.location.href = notification.link;
        } else {
          this.router.navigateByUrl(notification.link);
        }
      });
    });
  }
  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }
}
