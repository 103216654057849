import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { OrderType } from '@models/order-type';
import { SubcontractorOrder, SubcontractorOrderState } from '@models/subcontractor-order';
import { SubcontractorOrderService } from '@services/subcontractor-order.service';
import { AvailableVehicle } from '@models/availability-inquiry';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-order-subcontractor-order',
  template: `
  <div class="sc-order mb-3">
    <span class="callout"></span>
    <div class="d-flex align-items-center justify-content-between px-3" style="height: 45px;">
      <div>
        <div class="caption-lt" *ngIf="isInternal" i18n="@@internal-subcontractor-order.traffic-area">Trafikområde</div>
        <div class="caption-lt" *ngIf="!isInternal" i18n="@@subcontractors-availability-inquiry.subcontractor">Underentreprenör</div>
        <div id="SubContractorName">{{ subcontractorOrder.subcontractorName }}</div>
      </div>
      <div *ngIf="subcontractorOrder.currentState.stateEnum === SubcontractorOrderState.Ongoing">
        <div id="assignedText" class="caption" i18n="@@internal-subcontractor-order.assigned">Tilldelat</div>
        <div class="caption-lt">{{subcontractorOrder.orderedByNobina.updated | amTimeAgo}}</div>
      </div>
    </div>
    <app-availabilities-form
      [numberOfVehiclesLeftToSet]="numberOfVehiclesLeftToSet"
      [disabled]="disabled"
      [orderType]="orderType"
      [isInternal]="isInternal"
      [vehicles]="subcontractorOrder.assignedVehicles"
      [isAssigned]="subcontractorOrder.assignedVehicles?.length > 0"
      (saveEvent)="save($event)"
    ></app-availabilities-form>
    <div
      *ngIf="!(subcontractorOrder.currentState.stateEnum === SubcontractorOrderState.OrderedByNobina || numberOfVehicles === 0)"
      class="row mt-3 align-items-center"
    >
      <div class="col text-end">
        <label class="caption-lt" (click)="toggleAssignForm()">
          <ng-container *ngIf="isInternal" i18n="@@internal-subcontractor-order.assign">Tilldela</ng-container>
          <ng-container *ngIf="!isInternal" i18n="@@internal-subcontractor-order.direct-assign">Direkttilldela</ng-container>
        </label>
        <img
          class="expand px-2"
          src="/assets/img/icons/angle-down.png"
          [class.expandRotate]="assignFormVisible"
          (click)="toggleAssignForm()"
          />
      </div>
    </div>
    <div *ngIf="assignFormVisible" @fadeInOut style="overflow: hidden;">
      <app-availabilities-form
        [numberOfVehiclesLeftToSet]="numberOfVehiclesLeftToSet"
        [disabled]="disabled"
        [orderType]="orderType"
        [isInternal]="isInternal"
        (saveEvent)="save($event)"
      ></app-availabilities-form>
    </div>
  </div>
  `,
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({height:0}),
        animate(500, style({height:'*'}))
      ]),
      transition(':leave', [
        animate(500, style({height:0}))
      ])
    ])
  ],
  styleUrls: ['./subcontractor-order.component.scss'],
})
export class SubcontractorOrderComponent implements OnInit {
  @Input() orderId: number;
  @Input() subcontractorOrder: SubcontractorOrder;
  @Input() numberOfVehicles: number;
  @Input() numberOfVehiclesLeftToSet: number;
  @Input() disabled: boolean;
  @Input() orderType: OrderType;
  @Input() isInternal: boolean;
  @Output() subcontractorOrderCreatedOutput = new EventEmitter<string>();

  assignFormVisible: boolean;
  buttonsDisabled: boolean;
  SubcontractorOrderState = SubcontractorOrderState;

  constructor(
    private subcontractorOrderService: SubcontractorOrderService,
  ) { }

  ngOnInit() {
    if (this.disabled || this.numberOfVehicles === 0) {
      this.buttonsDisabled = true;
    }
  }

  toggleAssignForm() {
    this.assignFormVisible = !this.assignFormVisible;
  }

  save(list: AvailableVehicle[]) {
    if (this.numberOfVehicles === undefined || this.numberOfVehicles === 0) {
      throw (new Error('noOfVehicles 0 eller undefined'));
    }

    this.buttonsDisabled = true;

    let location = '';
    const subcontractorId = this.subcontractorOrder.subcontractorId;
    this.subcontractorOrderService.directAssign(this.orderId, subcontractorId, list)
      .subscribe({
        next: resp => { location = resp.headers.get('location'); },
        complete: () => {
          if (location !== undefined && location !== '') {
            this.subcontractorOrderCreatedOutput.emit(location);
            this.subcontractorOrderService.getSubcontractorOrderFromLocation(location)
              .subscribe({
                next: tmp => {
                  // hämta skapad ContractorOrder och uppdatera
                  const subcontractorName = this.subcontractorOrder.subcontractorName;
                  this.subcontractorOrder = tmp.body;
                  this.subcontractorOrder.subcontractorName = subcontractorName;
                },
                complete: () => this.buttonsDisabled = false
              });
          } else {
            this.buttonsDisabled = false;
          }
        }
      });
  }

  trackByFn(_: number, item: AvailableVehicle) {
    return item;
  }
}
