import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { registerLocaleData, TranslationWidth } from '@angular/common';
import { environment } from '../../environments/environment';
const I18N_VALUES = {
  'sv': {
    weekdays: ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
  },
  'nb': {
    weekdays: ['Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag', 'Søndag'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des']
  }
  // other languages you would support
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
  locale: string = environment.language;
  constructor() {
    this.locale = environment.language;
  }
  language = this.locale || 'sv';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  language: string;

  constructor() {
    super();
    this.language = environment.language;
    registerLocaleData(this.language);
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    switch (width) {
      case TranslationWidth.Narrow:
        return I18N_VALUES[this.language].weekdays[weekday - 1].substring(0, 1);
      case TranslationWidth.Abbreviated:
        return I18N_VALUES[this.language].weekdays[weekday - 1].substring(0, 3);
      case TranslationWidth.Short:
        return I18N_VALUES[this.language].weekdays[weekday - 1].substring(0, 2);
      case TranslationWidth.Wide:
      default:
        return I18N_VALUES[this.language].weekdays[weekday - 1];
    }
  }

  getWeekdayShortName(weekday: number): string {
    return this.getWeekdayLabel(weekday, TranslationWidth.Short);
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.year}-${date.month}-${date.day}`;
  }
}

export class DateTimePickerHelper {
  language: string;
  constructor() {
    this.language = environment.language;
    registerLocaleData(this.language);
    console.log(this.language);
  }
  static getSelectedDateTime(date: NgbDateStruct, hours: number, minutes: number): Date {
    const sd: NgbDateStruct = date;
    const selectedDateTime = new Date(sd.year, sd.month - 1, sd.day);

    if (hours != null) {
      selectedDateTime.setHours(hours);
    }
    if (minutes !== null) {
      selectedDateTime.setMinutes(minutes);
    }
    return selectedDateTime;
  }
}

export function setupMomentForExactTime(lang: string) {

  const relativeTimes = {
    'sv': {
      future: 'om %s',
      past: '%s sedan',
      s: '1 sek',
      ss: '%d sek',
      m: '1 min',
      mm: '%d min',
      h: '1 h',
      hh: '%d h',
      d: '1 dag',
      dd: '%d dagar',
      M: '1 månad',
      MM: '%d månader',
      y: '1 år',
      yy: '%d år'
    },
    'nb': {
      future: 'om %s',
      past: '%s siden',
      s: '1 sek',
      ss: '%d sek',
      m: '1 min',
      mm: '%d min',
      h: '1 t',
      hh: '%d t',
      d: '1 dag',
      dd: '%d dager',
      M: '1 måned',
      MM: '%d måneder',
      y: '1 år',
      yy: '%d år'
    }
  };
  moment.updateLocale(lang, {
    relativeTime: relativeTimes[lang]
  });

  moment.relativeTimeThreshold('s', 59);
  moment.relativeTimeThreshold('ss', 2);
  moment.relativeTimeThreshold('m', 59);
  moment.relativeTimeThreshold('h', 24);
  moment.relativeTimeThreshold('d', 28);
  moment.relativeTimeThreshold('M', 12);
}
