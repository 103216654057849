import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'checkState',
    pure: false
})
export class CheckStatePipe implements PipeTransform {
    transform(validStates: any[], currentState: any): boolean {
        if (!validStates || !currentState) {
            return false;
        }
        return validStates.indexOf(currentState) > -1;
    }
}
