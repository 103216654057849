<h1 class="my-5 text-center">
  <ng-container i18n="@@subcontractor-order-list.history">Historik</ng-container>
</h1>
<ul class="nav nav-tabs me-auto">
  <li class="nav-item">
    <a class="nav-link" routerLink="/subcontractor/{{subcontractor.id}}/history"
      [queryParams]="{ state: OrderType.Unplanned }"
      [class.active]="state === OrderType.Unplanned"
      i18n="@@subcontractor-order-trip-list.unplanned">Oplanerad</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/subcontractor/{{subcontractor.id}}/history"
      [queryParams]="{ state: OrderType.Planned }"
      [class.active]="state === OrderType.Planned"
      i18n="@@subcontractor-order-trip-list.planned">Planerad</a>
  </li>
  <li class="nav-item">
    <a class="nav-link"
      [routerLink]="'/subcontractor/' + subcontractor.id + '/history'"
      [queryParams]="{ availabilityinquiry: 1 }" [class.active]="isAvailabilityInquiry"
      i18n="@@subcontractor-order-list.not-assigned">Ej tilldelad förfrågan</a>
  </li>
</ul>

<div class="d-flex flex-row flex-wrap card-row mt-4" *ngIf="!isAvailabilityInquiry">
  <div *ngFor="let subcontractorOrder of subcontractorOrders" class="card-col">
    <div class="card"
      [routerLink]="'/subcontractor/' + subcontractor.id + '/history/' + subcontractorOrder.id">
      <div class="card-body">
        <div class="row">
          <div class="col-3">
            <div class="tb-badge">{{ subcontractorOrder.orderId }}</div>
          </div>
        </div>
        <div class="card-stops">
          <div class="row">
            <div class="col caption-lt-xs">
              <ng-container *ngIf="subcontractorOrder?.isRoundTrip" i18n="@@subcontractor-order-list.round-trip">
                Tur och retur
              </ng-container>
              <ng-container *ngIf="!subcontractorOrder?.isRoundTrip" i18n="@@subcontractor-order-list.one-way">
                Enkel
              </ng-container>
            </div>
          </div>
          <div class="row">
            <div class="col-5 caption">
              {{ subcontractorOrder.startOrderRouteStopDescription }}
            </div>
            <div class="w-auto">
              <img class="right-arrow" src="/assets/img/icons/right-arrow.png" alt="Icon right arrow">
            </div>
            <div class="col-5 caption">
              {{ subcontractorOrder.endOrderRouteStopDescription }}
            </div>
          </div>
        </div>
        <div class="card-times">
          <div class="row">
            <div class="col-5 caption">
              {{ subcontractorOrder?.startTripStop?.passTime | amCalendar }}
            </div>
            <div *ngIf="subcontractorOrder.endTripStop.passTime" class="w-auto">
              <img class="right-arrow" src="/assets/img/icons/right-arrow.png" alt="Icon right arrow">
            </div>
            <div *ngIf="subcontractorOrder.endTripStop.passTime" class="col-5 caption-lt-bl">
              {{ subcontractorOrder?.endTripStop?.passTime | amCalendar }}
            </div>
          </div>
        </div>
        <div *ngIf="subcontractorOrder?.orderTrainNumber" class="callout-block">
          <span class="callout"></span>
          <div class="caption-lt-xs" i18n="@@order-list.train-number">Tågnummer</div>
          <div class="caption">{{ subcontractorOrder?.orderTrainNumber }}</div>
        </div>
      </div>
      <div class="card-footer caption-lt">
        {{ stateDescription }} {{ subcontractorOrder.currentState.updated | amTimeAgo }}
      </div>
    </div>
  </div>
</div>

<!--För att lista ej tilldelade och nekade förfrågningar i historikvyn kanske kan optimeras till att bli portabla komponenter framåt-->
<div class="d-flex flex-row flex-wrap card-row mt-4" *ngIf="isAvailabilityInquiry">
  <div *ngFor="let availabilityInquiry of availabilityInquiries" class="card-col">
    <div class="card" routerLink="/subcontractor/{{subcontractor.id}}/assignment-history/{{availabilityInquiry.id}}">
      <div class="card-body">
        <div class="row">
          <div class="col-3">
            <div class="tb-badge">{{ availabilityInquiry?.orderRouteName }}</div>
          </div>
          <div class="col">
            <div class="caption-lg tb-card-header">{{ availabilityInquiry?.orderId }}</div>
          </div>
        </div>
        <div class="card-stops">
          <div class="row">
            <div class="col caption-lt-xs" i18n="@@subcontractor-order-list.between">Mellan</div>
          </div>
          <div class="row">
            <div class="col-5 caption">
              {{ getStartPlace(availabilityInquiry) }}
            </div>
            <div class="w-auto">
              <img class="right-arrow" src="/assets/img/icons/right-arrow.png" alt="Icon right arrow">
            </div>
            <div class="col-5 caption">
              {{ getEndPlace(availabilityInquiry) }}
            </div>
          </div>
        </div>
        <div class="card-times">
          <div class="row">
            <div class="col-5 caption">
              {{ availabilityInquiry?.startTripStop?.passTime | amCalendar }}
            </div>
            <div class="w-auto">
              <img class="right-arrow" src="/assets/img/icons/right-arrow.png" alt="Icon right arrow">
            </div>
            <div class="col-5 caption-lt-bl">
              {{ availabilityInquiry?.endTripStop?.passTime | amCalendar }}
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer caption-lt">
        {{ availabilityInquiry?.currentState?.stateDescription }} {{ availabilityInquiry?.currentState?.updated |
        amTimeAgo }}.
        <br />
        <span i18n *ngIf="!isReplied(availabilityInquiry)">
          Ej besvarad.
        </span>
        <span i18n
          *ngIf="isReplied(availabilityInquiry) && availabilityInquiry.replyInquiry.numberOfVehiclesAvailable === 0">
          Tackat nej {{ availabilityInquiry.replyInquiry.repliedTime | amDateFormat:'lll' }}.
        </span>
        <span i18n
          *ngIf="isReplied(availabilityInquiry) && availabilityInquiry.replyInquiry.numberOfVehiclesAvailable>0">
          Tackat ja {{ availabilityInquiry.replyInquiry.repliedTime | amDateFormat:'lll' }}
          med {{ availabilityInquiry.replyInquiry.numberOfVehiclesAvailable }} fordon,
          inställelsetid {{ availabilityInquiry.replyInquiry.estimatedTimeOfArrivalMinutes }} minuter.
        </span>
      </div>
    </div>
  </div>
</div>
