<div class="d-flex flex-row flex-wrap bg-white overflow-auto">
  <table class="table datatable">
    <thead>
      <th i18n="@@availability-inquiry-list.order">Order</th>
      <th></th>
      <th i18n="@@availability-inquiry-list.from">Från</th>
      <th i18n="@@availability-inquiry-list.departuretime">Avgångstid</th>
      <th i18n="@@availability-inquiry-list.to">Till</th>
      <th i18n="@@availability-inquiry-list.arrivaltime">Ankomsttid</th>
      <th i18n="@@availability-inquiry-list.vehicleType">Fordonstyp</th>
      <th i18n="@@availability-inquiry-list.numberOfVehicle">Antal fordon</th>
      <th></th>
    </thead>
    <tbody>
      <tr *ngFor="let availabilityInquiry of availabilityInquiries | paginate: {
        itemsPerPage: pageSize,
        currentPage: currentPage,
        totalItems: numberOfAvailabilityInquiries }">
        <td>
          <a routerLink="/subcontractor/{{subcontractor.id}}/availability-inquiry/{{availabilityInquiry.id}}">
            {{ availabilityInquiry.orderId }}
          </a>
        </td>
        <td>
          <ng-container *ngIf="availabilityInquiry.isRoundTrip" i18n="@@availability-inquiry-list.round-trip">
            Tur och retur</ng-container>
          <ng-container *ngIf="!availabilityInquiry.isRoundTrip" i18n="@@availability-inquiry-list.one-way">
            Enkel </ng-container>
        </td>
        <td> {{ availabilityInquiry.startOrderRouteStopDescription }} </td>
        <td> {{ availabilityInquiry?.startTripStop?.passTime | amCalendar }} </td>
        <td> {{ availabilityInquiry.endOrderRouteStopDescription }} </td>
        <td> {{ availabilityInquiry?.endTripStop?.passTime | amCalendar }} </td>
        <td>{{ availabilityInquiry.vehicleType }}</td>
        <td>{{ availabilityInquiry.numberOfVehicles }}</td>
        <td>
          {{ availabilityInquiry.currentState.stateDescription }} {{ availabilityInquiry.currentState.updated | amTimeAgo }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
