<div class="order-menu-container">
    <div *ngIf="!order?.isHastusPlanned && order?.orderRouteName">
    </div>
    <h1 class="my-5 text-center"><ng-container i18n="@@order-menu.order">Beställning</ng-container> {{order?.id}}</h1>
  <div *ngIf="order" class="row g-0">
    <ul class="nav nav-tabs mx-auto">
      <li id="orderMenuOrderTab" class="nav-item">
        <a *ngIf="!order?.isHastusPlanned" class="nav-link" [routerLink]="getLink(order?.id)" [class.active]="isActive('/order/?$', order?.id) || isActive('/order;id=?;date=.{10}', order?.id)  || isActive('/history;id=?;date=.{10}', order?.id) || isActive('/history/?$', order?.id) || isActive('/unboundorder/?$', order?.id) || isActive('/history/unboundorder/?', order?.id) " i18n="@@order-menu.order">Beställning</a>
        <a *ngIf="order?.isHastusPlanned" class="nav-link" [routerLink]="getLink('planned-order/'+ order?.id)" [class.active]="isActive('/order/planned-order/?', order?.id) || isActive('/history/planned-order/?', order?.id)" i18n="order-menu.order">Beställning</a>
      </li>
      <li id="orderMenuAssignmentTab" *ngIf="userHasAnyRole([UserRoles.NobinaTrafikledare,UserRoles.NobinaLeveransansvarig,UserRoles.BergkvaraTrafikledare]) && !order?.isHastusPlanned && !isUnboundOrder" class="nav-item">
        <a class="nav-link" [routerLink]="getLink(order?.id + '/subcontractors')" [class.active]="isActive('/order/?/subcontractors$', order?.id) || isActive('/history/?/subcontractors$', order?.id)" i18n="@@order-menu.assignment">Tilldelning</a>
      </li>
      <li id="orderMenuUnboundOrderAssignmentTab" *ngIf="userHasAnyRole([UserRoles.NobinaTrafikledare,UserRoles.NobinaLeveransansvarig,UserRoles.BergkvaraTrafikledare,UserRoles.OperatorTrafikledare]) && isUnboundOrder" class="nav-item">
        <a class="nav-link" [routerLink]="getLink(order?.id + '/assignment')" [class.active]="isActive('/unboundorder/?/assignment$', order?.id) || isActive('/history/unboundorder/?/assignment$', order?.id)" i18n="@@order-menu.assignment">Tilldelning</a>
      </li>
      <li id="orderMenuDriveOrdersTab" *ngIf="userHasAnyRole([UserRoles.NobinaTrafikledare,UserRoles.NobinaLeveransansvarig,UserRoles.BergkvaraTrafikledare,UserRoles.OperatorTrafikledare]) && !isUnboundOrder"
        class="nav-item">
        <a class="nav-link" (click)="navigateToDriveOrder()" [class.active]="isActive('/order/?/drive-order$', order?.id) || isActive('/?/drive-order;id=?;date=.{10}', order?.id) || isActive('/history/?/drive-order', order?.id)" i18n="@@order-menu.driveorders">Körordrar</a>
      </li>
      <li id="orderMenuUnboundOrderCompletionTab" *ngIf="userHasAnyRole([UserRoles.NobinaLeveransansvarig,UserRoles.BergkvaraTrafikledare]) && isUnboundOrder"
        class="nav-item">
        <a class="nav-link" [routerLink]="getLink(order?.id + '/completion')" [class.active]="isActive('/unboundorder/?/completion$', order?.id) || isActive('/history/unboundorder/?/completion$', order?.id)" i18n="@@order-menu.completion">Slutförande</a>
      </li>
      <li id="orderMenuLogTab" *ngIf="userHasAnyRole([UserRoles.NobinaTrafikledare,UserRoles.NobinaLeveransansvarig,UserRoles.BergkvaraTrafikledare,UserRoles.OperatorTrafikledare]) && !isUnboundOrder" class="nav-item">
        <a class="nav-link" [routerLink]="getLink(order?.id + '/log')" [class.active]="isActive('/order/?/log$', order?.id) || isActive('/history/?/log$', order?.id)" i18n="@@order-menu.logs">Logghändelser</a>
      </li>
      <li id="orderMenuUnboundOrderLogTab" *ngIf="userHasAnyRole([UserRoles.NobinaTrafikledare,UserRoles.NobinaLeveransansvarig,UserRoles.BergkvaraTrafikledare,UserRoles.OperatorTrafikledare]) && isUnboundOrder" class="nav-item">
        <a class="nav-link" [routerLink]="getLink(order?.id + '/log')" [class.active]="isActive('/unboundorder/?/log$', order?.id) || isActive('/history/unboundorder/?/log$', order?.id)" i18n="@@order-menu.logs">Logghändelser</a>
      </li>
      <li id="orderMenuRouteMapTab" *ngIf="userHasAnyRole([UserRoles.NobinaTrafikledare,UserRoles.NobinaLeveransansvarig,UserRoles.BergkvaraTrafikledare,UserRoles.OperatorTrafikledare]) && !isUnboundOrder"
        class="nav-item">
        <a class="nav-link" [routerLink]="getLink(order?.id + '/route-map')" [class.active]="isActive('/order/?/route-map$', order?.id) || isActive('/history/?/route-map$', order?.id)" i18n="@@order-menu.route-map">Linjesträckningskarta</a>
      </li>
    </ul>
  </div>

  <div *ngIf="!order" class='tab-container'>
    <ul class="nav nav-tabs mx-auto">
      <li id="orderMenuReplacementTrafficTab" *ngIf="userHasAnyRole([UserRoles.NobinaTrafikledare,UserRoles.NobinaLeveransansvarig,UserRoles.BergkvaraTrafikledare,UserRoles.OperatorTrafikledare]) && enableUnboundOrder" class="nav-item">
        <a class="nav-link" routerLink="/order" [class.active]="this.router.url === '/order'" i18n="@@order-menu.replacement-traffic">Ersättningstrafik</a>
      </li>
      <li id="orderMenuUnboundOrderTab" *ngIf="userHasAnyRole([UserRoles.NobinaTrafikledare,UserRoles.NobinaLeveransansvarig,UserRoles.BergkvaraTrafikledare,UserRoles.OperatorTrafikledare]) && enableUnboundOrder" class="nav-item">
        <a class="nav-link" routerLink="/unboundorder" [class.active]="this.router.url === '/unboundorder'" i18n="@@order-menu.unbound-order">Friorder</a>
      </li>
    </ul>
  </div>

</div>
