import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { Order, OrderRequest, OrderWithQueryParm } from '@models/order';
import { OrderSearch } from '@models/order-search';

@Injectable()
export class OrderService {
  private baseUrl = environment.apiUrl + environment.apiVersionMain + '/orders';

  constructor(private http: HttpClient) {
  }

  reviver(key, value): any {
    if (key === 'startTime' || key === 'endTime') {
      return new Date(value);
    }
    return value;
  }

  /** GET orders from the server */
  getOrders(search: OrderSearch): Observable<HttpResponse<OrderWithQueryParm[]>> {
    let url = this.baseUrl;
    url += `?OrderTypes=${search.orderTypes.toString()}`;
    url += `&OrderStates=${search.orderStates.toString()}`;
    url += `&OrderIds=${search.orderIds.toString()}`;
    url += `&ReferenceIds=${search.referenceIds.toString()}`;
    url += `&DriveOrderIds=${search.driveOrderIds.toString()}`;
    url += `&ExternalIds=${search.externalIds.toString()}`;
    url += `&TripIds=${search.tripIds.toString()}`;
    if (search.dateRange !== undefined) {
      url += '&StartDate=' + encodeURIComponent(moment(search.dateRange.fromDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
      url += '&EndDate=' + encodeURIComponent(moment(search.dateRange.toDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
    }
    return this.http.get<OrderWithQueryParm[]>(url, { observe: 'response' });
  }

  /** GET order by id. Will 404 if id not found */
  getOrder(id: number): Observable<HttpResponse<Order>> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<Order>(url, { observe: 'response' });
  }

  /** POST: add a new order to the server */
  addOrder(order: Order | OrderRequest): Observable<HttpResponse<Order>> {
    return this.http.post<Order>(this.baseUrl, order, { observe: 'response' });
  }

  /** PUT: update the order on the server */
  updateOrder(order: Order): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/${order.id}`;
    return this.http.put(url, order, { observe: 'response' });
  }

  /** PATCH: update the order on the server */
  patchOrder(order: OrderRequest): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/${order.id}`;
    return this.http.patch(url, order, { observe: 'response' });
  }

   /** PUT: update the order reference on the server */
  updateReference(id: number, referenceId: string): Observable<unknown>{
    const url = `${this.baseUrl}/${id}/reference`;
    return this.http.put(url, {"referenceId": referenceId})
  }

  updateOrderState(id: number, state: number, reason?: number, comment?: string): Observable<any> {
    const url = `${this.baseUrl}/${id}/state`;
    let body: { State: number; CancellationDetails?: { ReasonEnum: number; AdditionalInfo: string; }; };
    if (state === 4) {
      body = { 'State': state, 'CancellationDetails': { 'ReasonEnum': reason, 'AdditionalInfo': comment } };
    } else {
      body = { 'State': state };
    }

    return this.http.put(url, body);
  }

  saveOrder(order: Order): Observable<HttpResponse<Order>> {
    if (order.id === 0) {
      return this.addOrder(order);
    } else {
      return this.updateOrder(order);
    }
  }

  // Active vehicle for order
  getActiveVehicles(orderId: number):
    Observable<HttpResponse<string>> {

    const url = `${this.baseUrl}/${orderId}/vehicles`;
    return this.http.get<string>(url, { observe: 'response' });
  }

  getEarliestReturnTime(order: Order): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/earliestreturntime`;
    return this.http.post(url, order, { observe: 'response' });
  }

  sendComment(orderId: number, comment: string, name: string, timeStamp: string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/${orderId}/comment`;
    return this.http.put(url, `{"OrderId": ${orderId}, "Comment": "${comment}", "UpdatedByUser": "${name}", "TimeStamp": "${timeStamp}"}`, { observe: 'response' });
  }
}
