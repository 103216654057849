import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OperatorService } from '@services/operator.service';
import { Operator } from '@models/operator';
import { TimetableImportService } from '@services/timetable-import.service';
import { RouteService } from '@services/route.service';
import { RouteVariantWithConnection } from '@models/route';
import { OrderByService } from '@services/order-by.service';
import { ConfirmModalComponent } from '@common/components/confirm-modal/confirm-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponent } from '@common/components/alert-modal/alert-modal.component';

@Component({
    selector: 'app-import-timetable',
    templateUrl: './import-timetable.component.html',
    styleUrls: ['./import-timetable.component.scss']
  })
export class ImportTimetableComponent implements OnInit {
    fileToUpload: File = null;
    importForm: UntypedFormGroup;
    operators: Operator[];
    uploading = false;
    fileImported = false;
    isLoading = false;
    modalRef: NgbModalRef;

    routeVariants: RouteVariantWithConnection[] = [];

    constructor(private fb: UntypedFormBuilder,
        private operatorService: OperatorService,
        private routeService: RouteService,
        private timetableImportService: TimetableImportService,
        private orderByService: OrderByService,
        private modalService: NgbModal  ) { }

    ngOnInit(): void {
            this.importForm = this.fb.group({
                operator: [null, Validators.required],
                importFile: [null, Validators.required]
              });
            this.operatorService.getOperators(true)
                .subscribe(ops => {
                  this.operators = ops.body;
                });
    }

    get operatorCtrl() { return this.importForm.get('operator'); }
    get fileInputCtrl() { return this.importForm.get('importFile'); }

    onFileChange(files: FileList) {
        this.fileToUpload = files.item(0);
        this.fileImported = false;
    }

    import(): void {
        // trigger validation on all fields
        Object.keys(this.importForm.controls).forEach(field => {
          const control = this.importForm.get(field);
          control.markAsTouched({ onlySelf: true });
        });
        if (this.isValid() && this.fileToUpload !== null) {
          this.uploading = true;
          const operatorOrganisationId = this.operatorCtrl.value.operatorOrganisationId;
          this.timetableImportService
            .import(operatorOrganisationId, this.fileToUpload)
            .subscribe(x => {
              this.onSuccessfulUpload();
            }, err => {
              this.uploading = false;
            });
        }
    }

    public isValid(): boolean {
        return this.importForm.valid;
    }

    public onSuccessfulUpload(): void {
        this.fileToUpload = null;
        this.uploading = false;
        this.fileImported = true;
    }

    public onChangedOperator(): void {
        this.getRouteVariants();
    }

    getRouteVariants() {
        if (this.operatorCtrl.value === null) {
            return;
        }

        this.isLoading = true;
        this.routeService.getRouteVariantsForOperator(this.operatorCtrl.value.operatorOrganisationId)
            .subscribe(rv => {
                this.routeVariants = this.orderByService.transform(rv, ['routeName', 'routeVariantName']);
                this.isLoading = false;
            });
    }

    removeRouteVariant(routeVariantId: number) {
      this.modalRef = this.modalService.open(ConfirmModalComponent);
      this.modalRef.componentInstance.title = $localize`:Ta bort linjevariant@@import-timetable.remove:Ta bort linjevariant`;
      this.modalRef.componentInstance.description = $localize`:Vill du ta bort denna linjevariant? Observera att tidtabellen blir inkorrekt om det finns beställningar med denna linje.@@import-timetable.remove-route:Vill du ta bort denna linjevariant? Observera att tidtabellen blir inkorrekt om det finns beställningar med denna linje.`;
      this.modalRef.componentInstance.confirmButton = $localize`:Bekräfta@@import-timetable.confirm-remove-route:Bekräfta`;
      this.modalRef.componentInstance.cancelButton = $localize`:Avbryt@@import-timetable.cancel-remove-route:Avbryt`;
      this.modalRef.result.then(() => {
        this.routeService.removeRouteVariant(routeVariantId)
      .subscribe(resp => {
        this.modalRef = this.modalService.open(AlertModalComponent);
        this.modalRef.componentInstance.title = $localize`:Borta!@@import-timetable.removed:Borta!`;
        this.modalRef.componentInstance.description = $localize`:Borta!@@import-timetable.removed:Borta!`;
        this.getRouteVariants();
      });
    })
    .catch(reason => {

     });
    }
}
