import { ErrorHandler, inject, Injectable } from '@angular/core';
import { ApplicationInsightsService } from '@services/application-insights.service';
import { AlertService } from '@services/alert.service';
import { AuthService } from '@services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  appInsightsService: ApplicationInsightsService = inject(ApplicationInsightsService);
  alertService: AlertService = inject(AlertService);
  authService: AuthService = inject(AuthService);
  activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  locale: string;
  constructor() {
    this.locale = environment.language;
  }

  handleError(error) {

    if (error instanceof HttpErrorResponse) {
      // Handled by HttpCustomInterceptor
      return;
    }

    if (error.rejection) {
      error.message = error.rejection.message;
    }

    if (error.message === 'No matching state found in storage') {
      this.appInsightsService.logEvent(error.message);
      window.location.pathname = '/';
    } else {

      const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' } as const;
      const now = new Date();
      const opId = this.appInsightsService.getOperationId();
      this.alertService.error('Något gick fel. ' + now.toLocaleDateString(this.locale, options) + (opId !== '' ? '. Fel-ID: ' + opId : ''));

      this.logError(error);
    }

    throw error;
  }

  logError(error: any) {
    const properties: { [name: string]: string } = {};
    if (this.authService.user) {
      properties['user.profile.email'] = this.authService.user.idTokenClaims.email as string;
      properties['user.profile.roles'] = this.authService.user.idTokenClaims.role.toString();
    }

    if (this.activatedRoute && this.activatedRoute.snapshot['_routerState']) {
      properties['url'] = this.activatedRoute.snapshot['_routerState'].url;
    }

    if (error instanceof ErrorEvent) {
      const errorEvent: ErrorEvent = error;
      properties['error.error'] = errorEvent.error;
      properties['error.message'] = errorEvent.message;
      properties['error.filename'] = errorEvent.filename;
      if (errorEvent.lineno) {
        properties['error.lineno'] = errorEvent.lineno.toString();
      }
      if (errorEvent.colno) {
        properties['error.colno'] = errorEvent.colno.toString();
      }
    }
    this.appInsightsService.logException(error, undefined, properties);
  }
}
