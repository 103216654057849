import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable()
export class GeodataService {
  private mapUrl = environment.apiUrl + environment.apiVersionMain + '/map';
  private positionUrl = environment.positionUrl + '/currentpositions';

  constructor(private http: HttpClient) {
  }

  // Route GeoData
  getRouteGeoData(orderId: number, routeVariantId: number = null):
    Observable<HttpResponse<string>> {

    const url = `${this.mapUrl}/order/${orderId}?routeVariantId=${encodeURIComponent(routeVariantId)}`;
    return this.http.get<string>(url, { observe: 'response' });
  }


  // Vehicle GeoData, vehicles supposed to be comma separated list
  getVehicleGeoData(vehicles: string):
    Observable<HttpResponse<string>> {
    const url = `${this.positionUrl}?vehicles=${vehicles}`;
    return this.http.get<string>(url, { observe: 'response' });
  }

  // Vehicle position history
  getVehiclePositionHistory(tripId: number):
    Observable<HttpResponse<string>> {
    const url = `${this.mapUrl}/trip/${tripId}/positionhistory`;
    return this.http.get<string>(url, { observe: 'response' });
  }
}
