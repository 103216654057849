import { OutcomeStateObject, OutcomeDriveOrderStateObject, OutcomeDriveOrderState } from './outcome-state';

export class Outcome {
  id: number;
  orderId: number;
  operatorName: string;
  routeName: string;
  isRoundTrip: boolean;
  startTripStop: OutcomeTripStop;
  endTripStop: OutcomeTripStop;
  totalCustomerCarrying: Total;
  totalNonCustomerCarrying: Total;
  totalDeadhead: Total;
  numberOfAssignedVehicles: number;
  currentState: OutcomeStateObject;
  isHastusPlanned: boolean;
  referenceId: string;
  revisedReferenceId: string;
}


export class OutcomeDriveOrder {
  id: number;
  driveOrderId: number;
  startTripStop: OutcomeTripStop;
  endTripStop: OutcomeTripStop;
  externalId: string;
  isCancelled: boolean;
  subcontractorIsExternal: boolean;
  subcontractorName: string;
  tripCount: number;
  totalCustomerCarrying: Total;
  totalNonCustomerCarrying: Total;
  totalDeadhead: Total;
  rejectionComment: string;
  currentState: OutcomeDriveOrderStateObject;
}

export class OutcomeDriveOrderWithActivities extends OutcomeDriveOrder {
  activities: Array<OutcomeActivity>;
}

export class OutcomeActivity {
  id: number;
  driveOrderTripId: number;
  outcomeDriveOrderId: number;
  state: OutcomeTripStateEnum;
  activityType: ActivityType;
  startTripStop: OutcomeTripStop;
  endTripStop: OutcomeTripStop;
  plannedRegistered: OutcomeActivityData;
  revised: OutcomeActivityData;
  passengers: OutcomeActivityPassengers;
  revisionComment: string;
  cancellationDetails: OutcomeCancellationReason;
}

export class OutcomeCancellationReason {
  reasonEnum: OutcomeCancellationReasonEnum;
  additionalInfo: string;
}

export enum OutcomeCancellationReasonEnum {
  IncorrectlyOrdered = 1,
  CancelledByOperator = 2,
  ChangesToOrder = 3,
  NotAssigned = 4,
  NotPerformedBySubcontractor = 5,
  OtherReason = 6,
}

export enum OutcomeTripStateEnum {
  Completed = 3,
  Cancelled = 5
}
export class OutcomeActivityData {
  startTripStop: OutcomeTripStop;
  endTripStop: OutcomeTripStop;
  durationMinutes: number;
  timeSource: string;
  distanceKm: number;
  distanceSource: string;
}
export class OutcomeActivityPassengers {
  registeredCount: number;
  revisedCount: number;
}
export class ActivityType {
  key: number;
  value: string;
}
export enum ActivityTypeEnum {
  Trip = 1,
  Deadhead = 2,
  PullOut = 3,
  PullIn = 4
}

export class UpdateRevisedValuesToOutcomeActivitiesCommand {
  OutcomeDriveOrderId: number;
  RevisedActivities: Array<OutcomeActivityDataRequest>;
}
export class OutcomeActivityDataRequest {
  outcomeActionId: number;
  startTime: Date;
  endTime: Date;
  distance: number;
  comment: string;
  duration: number;
  passengers: number;
}

export class OutcomeTripStop {
  placeDescription: string;
  time: Date;
}

export class Total {
  durationMinutes: number;
  distanceKm: number;
}

export class OutcomeDriveOrderStateRequest {
  State: OutcomeDriveOrderState;
  comment: string;
}

export class UpdateOutcomeOrderRevisedReferenceIdRequest {
  revisedReferenceId: string;
}