import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { 
  AvailabilityInquiry, 
  AddAvailabilityInquiry, 
  AddAvailabilityInquiries,
  AvailabilityInquiryReplyRequest
} from '@models/availability-inquiry';

@Injectable()
export class AvailabilityInquiryService {
  private baseUrl = environment.apiUrl + environment.apiVersionMain + '/availabilityinquiries';
  
  constructor(private http: HttpClient) {
  }

  getAvailabilityInquiryFromLocation(url: string): Observable<AvailabilityInquiry> {
    return this.http.get<AvailabilityInquiry>(url);
  }

  getAvailabilityInquiry(inquiryId: number): Observable<HttpResponse<AvailabilityInquiry>> {
    const url = `${this.baseUrl}/${inquiryId}`;
    return this.http.get<AvailabilityInquiry>(url, { observe: 'response' });
  }

  getAvailabilityInquiriesForOrder(orderId: number): Observable<HttpResponse<AvailabilityInquiry[]>> {
    const url = `${this.baseUrl}/?orderId=${orderId}`;
    return this.http.get<AvailabilityInquiry[]>(url, { observe: 'response' });
  }

  getAvailabilityInquiriesForSubcontractor(subcontractorId: number): Observable<HttpResponse<AvailabilityInquiry[]>> {
    const url = `${this.baseUrl}?subcontractorid=${subcontractorId}`;
    return this.http.get<AvailabilityInquiry[]>(url, { observe: 'response' });
  }

  addAvailabilityInquiry(availabilityQuery: AddAvailabilityInquiry): Observable<HttpResponse<any>> {
    var addAvailabilityInquiries: AddAvailabilityInquiries = {
      subcontractorIds: [availabilityQuery.subcontractorId],
      numberOfVehicles: availabilityQuery.numberOfVehicles,
      orderId: availabilityQuery.orderId
    }

    return this.addAvailabilityInquiries(addAvailabilityInquiries)
  }

  addAvailabilityInquiries(availabilityQuery: AddAvailabilityInquiries): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}`;
    return this.http.post<AddAvailabilityInquiries>(url, availabilityQuery, { observe: 'response' });
  }

  postAvailabilityInquiryReply(availabilityInquiryId: number, reply: AvailabilityInquiryReplyRequest):
    Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/${availabilityInquiryId}/reply`;
    return this.http.post<AvailabilityInquiryReplyRequest>(url, reply, { observe: 'response' });
  }
}
