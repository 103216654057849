import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Driver } from '../../../common/models/driver';
import { SubcontractorService } from '../../../common/services/subcontractor.service';
import { AssignDriverToDriveOrderTripRequest } from '../../../common/models/assign-drive-order';
import { OrderByService } from '../../../common/services/order-by.service';
import { DriveOrderService } from '../../../common/services/drive-order.service';
import { DriveOrderAssigned } from '../../../common/models/drive-order';
import { AssignDriverWarningComponent } from './assign-driver-warning/assign-driver-warning.component';

@Component({
  selector: 'app-assign-driver',
  templateUrl: './assign-driver.component.html',
  styleUrls: ['./assign-driver.component.scss'],
})
export class AssignDriverComponent implements OnInit {
  @Input() assignedDriver: Driver;
  @Input() subcontractorId: number;
  @Input() availabilityInquiryId: number;
  @Input() driveOrderId: number;
  @Input() driveOrderTripId: number;
  @Input() startTripStop: any;
  @Input() endTripStop: any;
  drivers: Driver[];
  selectedDriver: Driver;
  buttonsDisabled = false;

  constructor(
    public activeModal: NgbActiveModal,
    private subcontractorService: SubcontractorService,
    private driveOrderService: DriveOrderService,
    private orderByService: OrderByService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.subcontractorService.getSubcontractorDrivers(this.subcontractorId).subscribe(x => {
      this.drivers = x.body;
    });
    this.selectedDriver = this.assignedDriver;
  }

  assign() {
    this.buttonsDisabled = true;
    const request = new AssignDriverToDriveOrderTripRequest();
    if (this.selectedDriver) {
      request.driverId = this.selectedDriver.id;
      request.driveOrderTripId = this.driveOrderTripId;
    }
    this.assignedDriver = this.selectedDriver;

    let query = '';
    query = '?FromDatetime=' + this.startTripStop.toISOString();
    query += '&ToDateTime=' + this.endTripStop.toISOString();
    if (this.selectedDriver.id !== 0) {
      query += '&driverId=' + this.selectedDriver.id;
    }

    let assignedOrders: DriveOrderAssigned[];
    this.driveOrderService.getAssignedDriveOrders(query).subscribe(response => {
      if (response.body.length > 0) {
        assignedOrders = response.body.filter(x => x.assignedDriveOrderId !== this.driveOrderId);
      }
      if (assignedOrders !== undefined && assignedOrders.length > 0) {
        const opts: NgbModalOptions = {};
        const modalRef = this.modalService.open(AssignDriverWarningComponent, opts);
        modalRef.componentInstance.assignedOrderId = assignedOrders[0].assignedOrderId;
        modalRef.componentInstance.assignedDriveOrderId = assignedOrders[0].assignedDriveOrderId;
        modalRef.result
          .then(() => {
            this.driveOrderService
              .postAssignDriverToDriveOrderTrip(this.driveOrderId, request)
              .subscribe(() => this.activeModal.close({ driver: this.assignedDriver }));
          })
          .catch(() => {
            this.buttonsDisabled = false;
          });
      } else {
        this.driveOrderService
          .postAssignDriverToDriveOrderTrip(this.driveOrderId, request)
          .subscribe(() => this.activeModal.close({ driver: this.assignedDriver }));
      }
    });
  }
}
