import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderService } from '../../../common/services/order.service';
import { OrderState } from '../../../common/models/order-state';

@Component({
  selector: 'app-close-order',
  templateUrl: './close-order.component.html',
  styleUrls: ['./close-order.component.scss']
})
export class CloseOrderComponent implements OnInit {

  @Input() orderId: number;
  @Input() isHastusPlanned: boolean;

  buttonsDisabled = false;

  constructor(public activeModal: NgbActiveModal, private orderService: OrderService) { }

  ngOnInit() {
  }

  closeOrder() {
    this.buttonsDisabled = true;
    if (this.isHastusPlanned) {
      this.activeModal.close();
    } else {
      this.orderService.updateOrderState(this.orderId, OrderState.Completing)
      .subscribe(
        response => this.activeModal.close(),
        err => {
           this.activeModal.dismiss();
        }
      );

    }
  }

}
