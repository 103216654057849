import { Pipe, PipeTransform } from '@angular/core';
import { SubcontractorsAvailabilityInquiryComponent } from './subcontractors-availability-inquiry.component';
import { AvailabilityInquiryState } from '../../../common/models/availability-inquiry';

@Pipe({
 name: 'filterCanAssign'
})
export class FilterCanAssignPipe implements PipeTransform {
 transform(items: SubcontractorsAvailabilityInquiryComponent[], flag: boolean): SubcontractorsAvailabilityInquiryComponent[] {
   if (!items) {
       return [];
   }
   if (flag) {
    return items.filter(it => it.availQuery.currentState.stateEnum === AvailabilityInquiryState.Accepted
                          || it.availQuery.currentState.stateEnum === AvailabilityInquiryState.Assigned)
                .sort(it => it.availQuery.replyInquiry?.availableVehicles[0]?.estimatedArrivalMinutes);
   }
   return items.filter(it => it.availQuery.currentState.stateEnum !== AvailabilityInquiryState.Accepted
                          && it.availQuery.currentState.stateEnum !== AvailabilityInquiryState.Assigned);
 }
}
