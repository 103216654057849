import { Component, OnInit } from '@angular/core';
import { MomentModule, DateFormatPipe } from 'ngx-moment';
import { AdminService } from '../../common/services/admin.service';
import { Version } from '../../common/models/version';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {

  mainServiceVersion: Version;
  outcomeServiceVersion: Version;

  constructor(private adminService: AdminService) { }

  ngOnInit() {
    this.adminService.getMainServiceVersion().subscribe(response => {
      this.mainServiceVersion = response.body;
    });
    this.adminService.getOutcomeServiceVersion().subscribe(response => {
      this.outcomeServiceVersion = response.body;
    });
  }

}
