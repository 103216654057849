import { Component, OnInit } from '@angular/core';
import { NotificationMessage } from '@models/notification-message';
import { NotificationService } from '@services/notification.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { ServerNotificationService } from '@services/server-notification.service';

@Component({
  selector: 'app-notifications-modal',
  templateUrl: './notifications-modal.component.html',
  styleUrls: ['./notifications-modal.component.scss'],
})
export class NotificationsModalComponent implements OnInit {

  notifications: NotificationMessage[];

  constructor(
    private notificationService: NotificationService,
    public activeModal: NgbActiveModal,
    private router: Router,
    private serverNotificationService: ServerNotificationService) {
  }

  ngOnInit() {
    this.notificationService.getNotifications().pipe(map((notifications) => {
      return notifications.map(notification => {
        if (notification.notificationType) {
          return this.serverNotificationService.setServerNotificationLocalization(notification);
        } else {
          return notification;
        }
      });
    })).subscribe(n => {
      this.notifications = n;
    });
  }

  isAnyUnread(): boolean {
    return this.notifications && this.notifications.filter(n => !n.isRead).length > 0;
  }

  markAllRead() {
    const unreadNotifications = this.notifications.filter(x => !x.isRead);
    this.notificationService.addNotificationRead(unreadNotifications.map(un => un.id)).subscribe(() => {
      this.notificationService.updateUnreadNotifications(0);
      this.activeModal.close();
    });
  }

  readNotification(notification: NotificationMessage) {
    this.notificationService.addNotificationRead([notification.id]).subscribe(() => {
      let unreadNotificationsCount = this.notifications.filter(un => !un.isRead).length;
      unreadNotificationsCount = unreadNotificationsCount > 0 ? unreadNotificationsCount - 1 : 0;
      this.notificationService.updateUnreadNotifications(unreadNotificationsCount);
      if (notification.link.indexOf('/order-new/') !== -1) {
        window.location.href = notification.link;
      } else if (notification.link.indexOf('drive-order') === -1) {
        this.router.navigateByUrl(notification.link);
      }
      if (window.location.pathname === notification.link) {
        window.location.reload();
      }
      this.activeModal.close();
    });
  }

  navigateToAll() {
    if (this.router.url === '/notifications') {
      window.location.reload();
    } else {
      this.router.navigateByUrl('/notifications');
    }
    this.activeModal.close();
  }
}
