import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import * as moment from 'moment';

import { OrderTrips } from '@models/order';
import { environment } from '../../../environments/environment';
import { ListSearch } from '@models/list-search';
import { OrderType } from '@models/order-type';
import { ApplicationInsightsService } from './application-insights.service';

@Injectable()
export class OrderListService {
  private baseUrl = environment.apiUrl + environment.apiVersionMain + '/list';

  constructor(
    private http: HttpClient,
    private applicationInsightsService: ApplicationInsightsService) {}

  getOrdersRequiresActionList(): Observable<HttpResponse<OrderTrips>> {
    const url = this.baseUrl + '/requiresaction';
    return this.http.get<OrderTrips>(url, { observe: 'response' });
  }

  /** GET orders with trips from server */
  getOrdersWithTrips(search: ListSearch): Observable<HttpResponse<OrderTrips>> {
      let url = this.baseUrl +
        `?ListType=${search.listType.toString()}` +
        `&OrderIds=${search.orderIds.toString()}` +
        `&ReferenceIds=${search.referenceIds.toString()}` +
        `&DriveOrderIds=${search.driveOrderIds.toString()}` +
        `&Operators=${search.operators.toString()}` +
        `&ExternalIds=${search.externalIds.toString()}` +
        `&PageSize=${search.pageSize.toString()}` +
        `&Page=${search.page.toString()}` +
        `&TripIds=${search.tripIds.toString()}` +
        `&TrainNumber=${search.trainNumber.toString()}` +
        `&SubContractor=${encodeURIComponent(search.subContractor)}` +
        `&SortBy=${search.sortBy.toString()}` +
        `&SortDesc=${search.sortDesc.toString()}`;
      if (search.dateRange !== undefined) {
        url += `&StartDate=${encodeURIComponent(moment(search.dateRange.fromDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ'))}` +
          `&EndDate=${encodeURIComponent(moment(search.dateRange.toDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ'))}`;
      }
      return this.http.get<OrderTrips>(url, { observe: 'response' });
  }

  orderLink(isHistory: boolean, orderId: number, orderType: OrderType) {
    const base = isHistory ? '/history/' : '/order/';
    switch (orderType) {
      case OrderType.Planned: return base + 'planned-order/' + orderId;
      case OrderType.Unplanned:
      case OrderType.Urgent: return base + orderId;
      case OrderType.Unbound: return '/unboundorder/' + orderId;
      default:
        this.logNotImplementedOrderType(orderType, 'OrderListService.orderLink', orderId);
        return '';
    }
  }

  orderTypeString(orderType: OrderType, orderId: number): string {
    switch (orderType) {
      case OrderType.Planned: return 'PL';
      case OrderType.Unbound: return 'FR';
      case OrderType.Unplanned:
      case OrderType.Urgent: return 'OP';
      default:
        this.logNotImplementedOrderType(orderType, 'OrderListService.orderTypeString', orderId);
        return ''; // TODO: log error
    }
  }

  logNotImplementedOrderType(orderType: OrderType, methodName: string, orderId: number) {
    const error = new Error('Ordertype' + orderType + ' not supported in ' + methodName + ' for order ' + orderId);
    const properties: { [name: string]: string } = {};
    properties['message'] = error.message;
    properties['orderId'] = orderId === undefined ? 'undefined' : orderId.toString();
    properties['orderType'] = orderType === undefined ? 'undefined' : orderType.toString();
    properties['methodName'] = methodName;
    this.applicationInsightsService.logException(error, null, properties);
  }
}
