import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AvailabilityInquiryState } from '../../../common/models/availability-inquiry';
import { SubcontractorOrder, SubcontractorOrderState } from '../../../common/models/subcontractor-order';
import { OrderType } from '../../../common/models/order-type';
import { SubcontractorOrderService } from '../../../common/services/subcontractor-order.service';
import { environment } from 'environments/environment';
import { log } from 'console';

@Component({
  selector: 'app-subcontractor-order',
  templateUrl: './subcontractor-order.component.html',
  styleUrls: ['./subcontractor-order.component.scss'],
})
export class SubcontractorOrderComponent implements OnInit {

  subcontractorId: number;
  subcontractorOrder: SubcontractorOrder;
  ContractorOrderState = SubcontractorOrderState;
  AvailabilityInquiryState = AvailabilityInquiryState;
  isHistory: boolean;
  OrderType = OrderType;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private subcontractorOrderService: SubcontractorOrderService) {
  }

  ngOnInit() {
    this.route.url.subscribe(current => {
      this.isHistory = (current !== undefined && current.length > 2 && current[2].path === 'history');
    });
    this.subcontractorId = +this.route.snapshot.paramMap.get('subcontractorId');
    this.subcontractorOrder = new SubcontractorOrder();
    this.route.paramMap.subscribe(params => {
      this.subcontractorOrder.id = +params.get('id');
      this.getContractorOrder();
    });
  }

  getContractorOrder() {
    this.subcontractorOrderService.getSubcontractorOrder(this.subcontractorOrder.id)
      .subscribe(x => {
        this.subcontractorOrder = x.body;

        if (this.subcontractorOrder.currentState.stateEnum === SubcontractorOrderState.Completed) {
          const route = `/subcontractor/${this.subcontractorId}/history`;
          this.router.navigate([route, this.subcontractorOrder.id]);
        }
      });
  }

  getAssignedVehicles() {
    return this.subcontractorOrder?.assignedVehicles?.reduce((acc, curr) => acc + curr.count, 0);
  }

  navigateToDriveOrder() {
    const isHistoryEnabled = this.isHistory ? 'history' : 'subcontractor-orders';
    window.location.href = `${window.location.origin}/order-new/subcontractor/${this.subcontractorId.toString()}/${isHistoryEnabled}/${this.subcontractorOrder.id.toString()}/drive-order`;
  }
}
