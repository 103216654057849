import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

interface Link {
  path: string;
  text: string;
}

@Component({
  selector: 'app-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss']
})
export class AdminMenuComponent implements OnInit {
  links: Link[] = [
    { path: 'import-order',
      text: $localize`:@@admin.menu.import-order:Läs in planerad beställning`
    },
    {
      path: 'import-timetable',
      text: $localize`:@@admin.menu.import-timetable:Hantera linjer och linjevarianter`
    },
    {
      path: 'subcontractors',
      text: $localize`:@@admin.menu.subcontractors:Hantera utförare och trafikoperatörer`
    },
    {
      path: 'connect-routevariant',
      text: $localize`:@@admin.menu.connect-routevariant:Koppla linjevarianter`
    },
    {
      path: 'missingVehicleStopEvents',
      text: $localize`:@@admin.menu.missing-vehicleatstopevents:Felplacerade hållplatser?`
    },
  ];

  path: string;
  route: Router;

  constructor(private router: Router) {
    this.route = router;
  }

  ngOnInit() { }

}
