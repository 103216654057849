<div class="assign-container">
  <h2 i18n="@@assign-vehicle.assign-vehicle">Tilldela fordon</h2>

  <div class="row ms-4">

    <div class="col vehicle-col pt-4">
      <div class="row">
        <div class="col-2">
          <img src="/assets/img/icons/bus.png" alt="Icon bus"/>
        </div>
        <div class="col">
          <div class="caption-lt" i18n="@@assign-vehicle.assigned-vehicle">Tilldelat fordon</div>
          <div>{{ assignedVehicle?.vehicleIdentifier }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-2">
          <img src="/assets/img/icons/search.png" class="search-icon" alt="Icon search"/>
        </div>
        <div class="col">
          <input class="form-control" type="text" placeholder="Sök fordon" #searchVehicle (keyup)="0" i18n-placeholder="@@assign-vehicle.search-vehicle-placeholder"/>
        </div>
      </div>
      <div class="name-rows">
        <div class="row name-row" *ngFor="let vehicle of vehicles | search:'vehicleIdentifier':searchVehicle.value; let i = index">
          <div class="col-2 button-col">
              <button *ngIf="selectedVehicle?.id!=vehicle.id" id="{{'assignVehicleBtn_'}}{{i + 1}}" (click)="selectedVehicle=vehicle" class="small-button" i18n="@@assign-vehicle.select">Välj</button>
              <button *ngIf="selectedVehicle?.id==vehicle.id" id="{{'assignedVehicleBtn_'}}{{i + 1}}" (click)="selectedVehicle=null" class="small-button active" i18n="@@assign-vehicle.selected">Vald</button>
          </div>
          <div class="col name-col">
            <div>{{ vehicle.vehicleIdentifier }}</div>
            <div class="caption-lt" i18n="@@assign-vehicle.vehicle">Fordon</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-end m-4">
    <button class="btn btn-outline-primary me-2" id="cancelAssignVehicleBtn" (click)="activeModal.close({vehicle: assignedVehicle})" [disabled]="buttonsDisabled" i18n="@@assign-vehicle.cancel">Avbryt</button>
    <button class="btn btn-primary" id="assignVehicleBtn" (click)="assign()" [disabled]="buttonsDisabled || !selectedVehicle" i18n="@@assign-vehicle.assign">Tilldela</button>
  </div>

</div>
