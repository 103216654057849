import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: '[app-number-of-vehicles]',
  template: `
  <form [formGroup]="form">
    <label class='mb-2' for="numberOfVehicles" i18n="@@number-of-vehicles.number-of-vehicles">Antal fordon*</label>
    <select id="'numberOfVehicles_{{index}}'" class="form-select form-control" formControlName="numberOfVehicles" (change)="numberOfVehiclesChange($event)">
      <option value="" selected disabled i18n="@@number-of-vehicles.enter-number">Ange antal</option>
      <option *ngFor="let x of optionlistNoOfVehicles" [value]="x">{{ x }}</option>
    </select>
    <div *ngIf="form.controls.numberOfVehicles.invalid && (form.controls.numberOfVehicles.dirty || form.controls.numberOfVehicles.touched)"
      class="text-danger" i18n="@@number-of-vehicles.number-of-vehicles-mandatory"
    >
      Antal fordon är obligatoriskt
    </div>
  </form>
  `,
  styleUrls: ['./number-of-vehicles.component.scss']
})
export class NumberOfVehiclesComponent implements OnInit {
  @Input() index: number = 0;
  @Input() numberOfVehicleLeftToSet: number;
  @Input() formGroupName: string;

  @Output() changeVehicleEvent: EventEmitter<number> = new EventEmitter();

  form: FormGroup;
  optionlistNoOfVehicles: number[] = [];

  constructor(
    private rootFormGroup: FormGroupDirective,
  ) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.form.get(this.formGroupName) as FormGroup;
  }

  ngOnChanges() {
    this.setOptionList();
  }

  setOptionList() {
    const optionListSize = this.form?.get('numberOfVehicles').disabled
      ? +(this.form?.get('numberOfVehicles').value ?? 0)
      : this.numberOfVehicleLeftToSet;

    this.optionlistNoOfVehicles = [];

    for (var index = 1; index <= optionListSize; index++) {
      this.optionlistNoOfVehicles.push(index);
    }
  }

  numberOfVehiclesChange($event) {
    this.changeVehicleEvent.emit($event.target.value);
  }
}
