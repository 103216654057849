import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-assign-driver-warning',
  templateUrl: './assign-driver-warning.component.html',
  styleUrls: ['./assign-driver-warning.component.scss']
})
export class AssignDriverWarningComponent implements OnInit {
  @Input() assignedOrderId: number;
  @Input() assignedDriveOrderId: number;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  assignDriver() {
      this.activeModal.close();
  }
}
