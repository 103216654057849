import { Injectable } from '@angular/core';

import { AppInsights } from 'applicationinsights-js';

import { environment } from '../../../environments/environment';
import { EnvironmentType } from '@models/environment.model';

@Injectable()
export class ApplicationInsightsService {
  private config: Microsoft.ApplicationInsights.IConfig = {
    instrumentationKey: environment.appInsights.instrumentationKey
  };

  constructor() {
    if (!AppInsights.config && environment.appInsights.isEnabled) {
      AppInsights.downloadAndSetup(this.config);
    }
  }

  setOperationId(operationId: string): void {
    if (AppInsights.context) {
      AppInsights.context.operation.id = operationId;
    }
  }

  getOperationId(): string {
    if (AppInsights.context) {
      return AppInsights.context.operation.id;
    } else {
      return '';
    }
  }

  logPageView(
    name?: string,
    url?: string,
    properties?: { [name: string]: string },
    measurements?: { [name: string]: number },
    duration?: number
  ) {
    AppInsights.trackPageView(name, url, properties, measurements, duration);
  }

  logEvent(
    name: string,
    properties?: { [name: string]: string },
    measurements?: { [name: string]: number }
  ) {
    AppInsights.trackEvent(name, properties, measurements);
  }

  logException(
    error: Error, // Error or HttpErrorResponse
    handledAt?: string,
    properties?: { [name: string]: string },
    measurements?: { [name: string]: number },
    severityLevel?: AI.SeverityLevel
  ) {
    AppInsights.trackException(
      error,
      handledAt,
      properties,
      measurements,
      severityLevel
    );
  }

  logTrace(
    message: string,
    properties?: { [name: string]: string },
    severityLevel?: AI.SeverityLevel
  ) {
    AppInsights.trackTrace(message, properties, severityLevel);
  }

  logDependency(
    id: string,
    method: string,
    absoluteUrl: string,
    pathName: string,
    totalTime: number,
    success: boolean,
    resultCode: number
  ) {
    AppInsights.trackDependency(
      id,
      method,
      absoluteUrl,
      pathName,
      totalTime,
      success,
      resultCode
    );
  }
}
