import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Operator } from '@models/operator';

@Component({
  selector: 'app-filter-subcontractors',
  templateUrl: './filter-subcontractors.component.html',
  styleUrls: ['./filter-subcontractors.component.scss']
})
export class FilterSubcontractorsComponent implements OnInit {
  @Input() operators: Operator[];
  @Output() operatorsEvent = new EventEmitter<any>();
  selectedOperatorsIds = new Array<number>();

  constructor() { }

  ngOnInit(): void {}

  toggleSelectedOperator(operator: Operator) {
    const index = this.selectedOperatorsIds.indexOf(operator.id);
    if (index > -1) {
      this.selectedOperatorsIds.splice(index, 1);
    } else {
      this.selectedOperatorsIds.push(operator.id);
    }
    this.operatorsEvent.emit(this.selectedOperatorsIds);
  }
}
