import { B2cConfig } from '@models/environment.model';

export const b2cConfigLocalST: B2cConfig = {
  mainServiceScopes: ['openid', 'https://tbumsst.onmicrosoft.com/55b5a525-48f6-4cf9-92db-61c37aa1627e/Tbums.Main'],
  garageServiceScopes: ['openid', 'https://tbumsst.onmicrosoft.com/064b4d2c-627e-49a3-8fe7-0b957526480c/Tbums.Garage'],
  outcomeServiceScopes: ['openid', 'https://tbumsst.onmicrosoft.com/119604fa-b3ce-4cdc-a188-130df9955527/Tbums.Outcome'],
  iotFunctionsServiceScopes: ['openid', 'https://tbumsst.onmicrosoft.com/da8abae4-c175-41f2-95fb-36f2b831a2f4/Tbums.IOTFunctions'],
  authorityDomain: 'tbumsst.b2clogin.com',
  authority: 'https://tbumsst.b2clogin.com/tbumsst.onmicrosoft.com/B2C_1A_NOBINAFALLBACKPOLICY',
  clientId: '277ae760-aadf-4676-8b42-3f7ce85b33fd',
  tenantId: '4341c565-4ac4-4ef0-bbd4-8041d91cf7a6',
  redirectUrl: '/',
};

export const b2cConfigST: B2cConfig = {
  mainServiceScopes: ['openid', 'https://tbumsst.onmicrosoft.com/55b5a525-48f6-4cf9-92db-61c37aa1627e/Tbums.Main'],
  garageServiceScopes: ['openid', 'https://tbumsst.onmicrosoft.com/064b4d2c-627e-49a3-8fe7-0b957526480c/Tbums.Garage'],
  outcomeServiceScopes: ['openid', 'https://tbumsst.onmicrosoft.com/119604fa-b3ce-4cdc-a188-130df9955527/Tbums.Outcome'],
  iotFunctionsServiceScopes: ['openid', 'https://tbumsst.onmicrosoft.com/da8abae4-c175-41f2-95fb-36f2b831a2f4/Tbums.IOTFunctions'],
  authorityDomain: 'tbumsst.b2clogin.com',
  authority: 'https://tbumsst.b2clogin.com/tbumsst.onmicrosoft.com/B2C_1A_NOBINAFALLBACKPOLICY',
  clientId: '44d3fa88-11f3-46f0-b3fa-bb477a6edbd8',
  tenantId: '4341c565-4ac4-4ef0-bbd4-8041d91cf7a6',
  redirectUrl: '/',
};

export const b2cConfigFT: B2cConfig = {
  mainServiceScopes: ['openid', 'https://tbumsft.onmicrosoft.com/6794f1e6-aec9-4fad-a319-c413e3a80aab/Tbums.Main'],
  garageServiceScopes: ['openid', 'https://tbumsft.onmicrosoft.com/aa59f5dc-eb2b-4598-b6cc-b169c2ff5037/Tbums.Garage'],
  outcomeServiceScopes: ['openid', 'https://tbumsft.onmicrosoft.com/55f38af1-da98-490a-9ad0-bd3301c872b7/Tbums.Outcome'],
  iotFunctionsServiceScopes: ['openid', 'https://tbumsft.onmicrosoft.com/203957ef-09a7-4be8-8f56-52e4404cd6e1/Tbums.IOTFunctions'],
  authorityDomain: 'tbumsft.b2clogin.com',
  authority: 'https://tbumsft.b2clogin.com/tbumsft.onmicrosoft.com/B2C_1A_NOBINAFALLBACKPOLICY',
  clientId: 'c1bdfa4a-b698-4d94-8fe0-89a5e4d021b2',
  tenantId: '64b46fb2-50d1-4fc3-91c2-7a582ac92dae',
  redirectUrl: '/',
};

export const b2cConfigAT: B2cConfig = {
  mainServiceScopes: ['openid', 'https://tbumsat.onmicrosoft.com/e31e1380-7b93-40a2-bfd1-ed4103ca3279/Tbums.Main'],
  garageServiceScopes: ['openid', 'https://tbumsat.onmicrosoft.com/933446d4-e756-4894-81ab-d954abcef3c0/Tbums.Garage'],
  outcomeServiceScopes: ['openid', 'https://tbumsat.onmicrosoft.com/6658772e-ea59-443f-aeee-9f8465f8c562/Tbums.Outcome'],
  iotFunctionsServiceScopes: ['openid', 'https://tbumsat.onmicrosoft.com/e6bf5cff-ee06-4f8a-8b8e-150e6b5748ea/Tbums.IOTFunctions'],
  authorityDomain: 'tbumsat.b2clogin.com',
  authority: 'https://tbumsat.b2clogin.com/tbumsat.onmicrosoft.com/B2C_1A_NOBINAFALLBACKPOLICY',
  clientId: '3efc4a3e-c5a6-4934-b1ad-fbf0cfc00d17',
  tenantId: 'd9568c3b-e63b-46e5-be06-822181747bb7',
  redirectUrl: '/',
};

export const b2cConfigProd: B2cConfig = {
  mainServiceScopes: ['openid', 'https://tbumsprod.onmicrosoft.com/9fa3fe92-d93b-4d67-a819-a92160623e80/Tbums.Main'],
  garageServiceScopes: ['openid', 'https://tbumsprod.onmicrosoft.com/12c17c7d-caa7-4980-b376-26cfaf880977/Tbums.Garage'],
  outcomeServiceScopes: ['openid', 'https://tbumsprod.onmicrosoft.com/17c84f9c-8163-42f2-9d5b-4004bc389443/Tbums.Outcome'],
  iotFunctionsServiceScopes: ['openid', 'https://tbumsprod.onmicrosoft.com/76fdf63e-4017-41a4-993d-656f0ab2d019/Tbums.IOTFunctions'],
  authorityDomain: 'tbumsprod.b2clogin.com',
  authority: 'https://tbumsprod.b2clogin.com/tbumsprod.onmicrosoft.com/B2C_1A_NOBINAFALLBACKPOLICY',
  clientId: 'c762b331-71c9-4838-a811-26f7d0356469',
  tenantId: '886bdb76-7830-4859-940a-bf2d7add933f',
  redirectUrl: '/',
};
