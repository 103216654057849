import { Operator } from './operator';
export class Subcontractor {
  id: number;
  name: string;
  isExternal: boolean;
  depot: string;
  code: string;
  operators: Array<Operator>;
  subcontractorVacantType: SubcontractorVacantType;
}

export enum SubcontractorVacantType {
  None = 0,
  UeVakant = 1,
  UtforareVakant = 2
}
