import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UnboundOrderService } from '../../../common/services/unbound-order.service';
import { UnboundOrderState } from '../../../common/models/unbound-order-state';
import { UnboundOrder } from '../../../common/models/unbound-order';
import { UntypedFormGroup } from '@angular/forms';
import { OrderState } from '../../../common/models/order-state';

@Component({
  selector: 'app-close-unbound-order',
  templateUrl: './close-unbound-order.component.html',
  styleUrls: ['./close-unbound-order.component.scss']
})
export class CloseUnboundOrderComponent implements OnInit {

  @Input() orderId: number;

  buttonsDisabled = false;
  unboundOrder: UnboundOrder;
  unboundOrderCompletionForm: UntypedFormGroup;

  constructor(public activeModal: NgbActiveModal, private unboundOrderService: UnboundOrderService) { }

  ngOnInit() {
  }

  closeOrder() {
    this.buttonsDisabled = true;
      this.unboundOrderService.updateOrderState(this.orderId, UnboundOrderState.CancellationConfirmed)
      .subscribe(
        response => this.activeModal.close(),
        err => {
           this.activeModal.dismiss();
        }
      );

    }

}
