import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Reason } from '../models/reason';

@Injectable()
export class ReasonService {
  private baseUrl = environment.apiUrl + environment.apiVersionMain + '/orders/reasons';

  constructor(private http: HttpClient) {
  }

  /** GET reasons from the server */
  getReasons(): Observable<Reason[]> {
    return this.http.get<Reason[]>(this.baseUrl);

  }
}
