import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AvailableVehicle } from '@models/availability-inquiry';
import { OrderType } from '@models/order-type';
import { AvailabilityInquiryStoreService } from '@services/availability-inquiry-store.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-availability',
  template: `
    <form [formGroup]="form">
      <div class="row availabilityForm align-items-center">
        <div class="col-1 shouldAssign" *ngIf="!disabled && hasAsked">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" formControlName="shouldAssign" (change)='onSelect()'>
          </div>
        </div>
        <div class="col row">
          <div app-number-of-vehicles class="col-12 col-sm-6 py-1"
            formGroupName="numberOfVehicles"
            [numberOfVehicleLeftToSet]="numberOfVehiclesInDropDown"
            (changeVehicleEvent)="numberOfVehiclesChangeEvent($event)"
          >
          </div>
          <div app-estimated-arrival-minutes class="col-12 col-sm-6 py-1"
            *ngIf="orderType === OrderType.Urgent"
            formGroupName="estimatedArrivalMinutes"
            (estimatedArrivalMinutesEvent)="estimatedArrivalMinutesEvent($event)"
          >
          </div>
        </div>
        <div *ngIf="!disabled && !hasAsked && !isOnlyOneVehicleAvailableSet" class="col removeAvailabilityInquiryReplyRequestItem">
          <img src="/assets/img/icons/close.png" (click)="removeAvailabilityInquiryReplyRequestItem()" alt="Icon close" />
        </div>
      </div>
    </form>
  `,
  styleUrls: ['./availability.component.scss']
})
export class AvailabilityComponent implements OnInit, OnDestroy {
  @Input() form!: FormGroup;
  @Input() orderType: OrderType;
  @Input() disabled: boolean;
  @Input() hasAsked: boolean;
  @Input() isOnlyOneVehicleAvailableSet: boolean;

  @Output() availabilityInquiryReplyRequestItemEvent = new EventEmitter<FormGroup>();
  @Output() removeAvailabilityInquiryReplyRequestItemEvent = new EventEmitter();

  availableVehicleItem: AvailableVehicle = new AvailableVehicle();
  initNumberOfVehicles = 0;
  numberOfVehiclesInDropDown = 0;
  subscription: Subscription;
  OrderType = OrderType;

  get numberOfVehicles() { return this.form.get('numberOfVehicles.numberOfVehicles'); }
  get estimatedArrivalMinutes() { return this.form.get('estimatedArrivalMinutes.estimatedArrivalMinutes'); }
  get shouldAssign() { return this.form.get('shouldAssign'); }

  constructor(
    private availabilityInquiryStoreService: AvailabilityInquiryStoreService,
  ) {
  }

  ngOnInit(): void {
    if (this.hasAsked) {
      this.initNumberOfVehicles = this.numberOfVehicles.value;
      this.availableVehicleItem.count = this.numberOfVehicles.value;
      this.availableVehicleItem.estimatedArrivalMinutes = this.estimatedArrivalMinutes.value;

      this.estimatedArrivalMinutes.disable();
    }

    this.subscription = this.availabilityInquiryStoreService.getNumberOfVehiclesLeftToSet().subscribe({
      next: noVehicle => {
        if (this.numberOfVehicles.value && this.hasAsked) {
          this.numberOfVehiclesInDropDown = this.initNumberOfVehicles > +this.numberOfVehicles.value
            ? this.initNumberOfVehicles
            : +this.numberOfVehicles.value;
        } else {
          this.numberOfVehiclesInDropDown = noVehicle + (+this.numberOfVehicles.value ?? 0);
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  numberOfVehiclesChangeEvent(value: number) {
    this.availableVehicleItem.count = value;
    this.sendToParent();
  }

  estimatedArrivalMinutesEvent(value: number) {
    this.availableVehicleItem.estimatedArrivalMinutes = value;
    this.sendToParent();
  }

  removeAvailabilityInquiryReplyRequestItem() {
    this.removeAvailabilityInquiryReplyRequestItemEvent.emit(this.numberOfVehicles.value);
  }

  onSelect() {
    this.sendToParent();
  }

  sendToParent() {
    this.availabilityInquiryReplyRequestItemEvent.emit(this.form);
  }
}
