<div class="row g-0 align-items-center p-3 mt-4">
  <div class="col-lg-3">
    <div class="d-flex justify-content-between">
      <div>
        <div class="caption-lg" i18n="@@outcome.order">Beställning</div>
        <h1>{{outcome?.orderId}}</h1>
      </div>
      <div class="mx-4">
        <app-outcome-reference [outcome]="outcome">
        </app-outcome-reference>
      </div>
    </div>
  </div>

  <div class="col-lg-6">
    <div class="driveorder-details d-flex flex-wrap justify-content-sm-between">
      <app-driveorder-details
        captionText="Resor utan passagerare"
        [time]='outcome?.totalNonCustomerCarrying.durationMinutes'
        [distance]='outcome?.totalNonCustomerCarrying.distanceKm'
        i18n-captionText="@@outcome.without-passengers">
      </app-driveorder-details>
      <app-driveorder-details
        captionText="Tomkörning inom omlopp"
        [time]='outcome?.totalDeadhead.durationMinutes'
        [distance]='outcome?.totalDeadhead.distanceKm'
        i18n-captionText='@@outcome.deadhead-within-driveorder'>
      </app-driveorder-details>
      <app-driveorder-details
        captionText="Resor med passagerare"
        [time]='outcome?.totalCustomerCarrying.durationMinutes'
        [distance]='outcome?.totalCustomerCarrying.distanceKm'
        i18n-captionText='@@outcome.with-passengers'>
      </app-driveorder-details>
    </div>
  </div>

  <div class="col-lg-3 my-3">
    <button id="validateBtn"
            *ngIf="userIsSubcontractorOrNobinaTrafficManager && outcome?.currentState.stateEnum === OutcomeState.WaitingForApprovalBySubcontractor"
            type="button" [disabled]="buttonsDisabled" class="btn btn-approve btn-primary float-end"
            (click)="outcomeApprovalByContractor()" i18n="@@outcome.for-approval">Skicka in för
      granskning</button>
    <button id="approveAllBtn"
            *ngIf="userIsNobinaLeveransansvarig && outcome?.currentState.stateEnum === OutcomeState.WaitingForApprovalByNobina"
            type="button" [disabled]="buttonsDisabled" class="btn btn-approve btn-primary float-end"
            (click)="outcomeApprovalByNobina()" i18n="@@outcome.approve-all">Godkänn alla där kontroll ej
      krävs</button>
    <button id="markBilled_SubcontractorOrNobinaTrafficManager"
            *ngIf="userIsSubcontractorOrNobinaTrafficManager && outcome?.currentState.stateEnum === OutcomeState.Billable"
            type="button" [disabled]="buttonsDisabled" class="btn btn-approve btn-primary float-end"
            (click)="outcomeBilledByContractor()" i18n="@@outcome.mark-billed">Markera som fakturerad</button>
    <button id="markBilled_NobinaLeveransansvarig"
            *ngIf="userIsNobinaLeveransansvarig && (outcome?.currentState.stateEnum === OutcomeState.Billable)"
            type="button" [disabled]="buttonsDisabled" class="btn btn-approve btn-primary float-end"
            (click)="outcomeBilledByNobina()" i18n="@@outcome.mark-billed">Markera som fakturerad</button>
  </div>
</div>
<div class="row g-0">
    <div class="col-lg-auto px-3 mt-5 outcome-container g-0">
        <div>
            <h4 class="pb-2" i18n="@@outcome.driveorders">Körordrar</h4>
            <div *ngFor="let order of driveOrders" tabindex="0" class="card card-driveorder card-selected ps-4 pt-3"
                [class.read-only]="!isEditable(order)" [class.card-selected]="isCardSelected(order)"
                (click)="selectDriveOrder(order)" (keyup.enter)="selectDriveOrder(order)">
                <div class="d-flex">
                    <h4 class="col-3 ps-0">{{ order.driveOrderId }}</h4>
                    <button *ngIf="requiresAction(order)" class="ms-auto me-5 action-required" tabindex="-1"
                        i18n="@@outcome.action-required">Kräver åtgärd</button>
                </div>
                <div class="d-flex mt-3">
                    <div class="col-4">
                        <div class="row caption-lt" i18n="@@outcome.date">Datum</div>
                        <div class="row">{{ order.startTripStop.time | amDateFormat:'YYYY-MM-DD' }}
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="row caption-lt" i18n="@@outcome.start">Start</div>
                        <div class="row">{{ order.startTripStop.time | amDateFormat:'LT' }}</div>
                    </div>
                    <div class="col-2">
                        <div class="row caption-lt" i18n="@@outcome.end">Slut</div>
                        <div class="row">{{ order.endTripStop.time | amDateFormat:'LT' }}</div>
                    </div>
                    <div class="col text-end pe-4">
                        <div class="caption-lt" i18n="@@outcome.trips">Turer</div>
                        <div> {{ order.tripCount }}</div>
                    </div>
                </div>
                <div class="d-flex mt-3 me-3 mb-2 pb-2 divider">
                    <div class="col-8">
                        <div class="row caption-lt" i18n="@@outcome.subcontractor">Utförare</div>
                        <div class="row"> {{ order.subcontractorName }}</div>
                    </div>
                    <div class="col-4 text-end pe-4">
                        <div class="caption-lt" i18n="@@outcome.external-id">Omlopp</div>
                        <div> {{ order.externalId }}</div>
                    </div>
                </div>
                <div class="d-flex caption-lt mb-2"
                    [class.green]="order.currentState.stateEnum === OutcomeDriveOrderState.Billable">
                    {{ driveOrderStatus(order) }}
                </div>
            </div>
        </div>
    </div>
    <div class="col mx-3 mt-5 outcome-container">
        <app-outcome-details *ngIf="!!selectedDriveOrder" [outcomeDriveOrderId]="selectedDriveOrder.id"
            [orderDate]="selectedDriveOrder.startTripStop.time" (wasUpdated)="handleOutcomeDriveOrderWasUpdated()"
            [userIsSubcontractorOrNobinaTrafficManager]="userIsSubcontractorOrNobinaTrafficManager"
            [userIsNobinaLeveransansvarig]="userIsNobinaLeveransansvarig"></app-outcome-details>
    </div>
</div>
