import { Injectable } from '@angular/core';
import { ListViewService } from './list-view.service';
import { OrderListService } from './orderList.service';
import { VehicleTypeService } from './vehicle-type.service';
import { ListSearch } from '@models/list-search';
import { DateRange } from '@models/dateRange';
import moment from 'moment';
import { SortBy } from '@models/list-type';
import { OrderTrips, OrderTripsPageResult } from '@models/order';
import { Observable, map, tap } from 'rxjs';
import { DatePickerService } from './date-picker.service';

@Injectable({ providedIn: 'root' })
export class OrderPageService {
    currentPage = 1;
    selectedOperators: number[];
    sortBy: SortBy = SortBy.StartTime;
    sortDesc = false;
    isHistory: boolean;
    private subcontractorValue: string = '';
    private trainNumberValue: string = '';

    constructor(private listViewService: ListViewService,
              private orderListService: OrderListService,
              private vehicleTypeService: VehicleTypeService,
              private datePickerService: DatePickerService) {}

    createListSearch(currentPage: number): ListSearch {
        const listSearch = new ListSearch();
        listSearch.listType = this.listViewService.orderListType;
        listSearch.trainNumber = this.listViewService.trainNumber;
        listSearch.subContractor = this.listViewService.subContractor;
        listSearch.pageSize = this.listViewService.pageSize;
        listSearch.dateRange = this.datePickerService.orderDateRange;
        listSearch.page = currentPage;
        listSearch.sortBy = this.sortBy;
        listSearch.sortDesc = this.sortDesc;
        listSearch.operators = this.listViewService.operatorIds !== undefined ? this.listViewService.operatorIds : [];
        return listSearch;
    }

    getOrdersWithTrips(currentPage: number): Observable<OrderTrips> {
        const listSearch = this.createListSearch(currentPage);
        return this.orderListService.getOrdersWithTrips(listSearch).pipe(
            map(resp => {
                const response: OrderTrips = resp.body;
                response.items.forEach(item => {
                    item.orderUrl = this.orderListService.orderLink(this.isHistory, item.orderId, item.orderType);
                    item.orderTypeShortString = this.orderListService.orderTypeString(item.orderType, item.orderId);
                    item.vehicleType = this.vehicleTypeService.getVehicleType(item.vehicleTypeEnum);
                });
                return response;
            })
        );
    }

    getSubcontractorValue(): string {
        return this.subcontractorValue;
    }
    setSubcontractorValue(value: string): void {
        this.subcontractorValue = value;
    }

    getTrainNumberValue(): string {
        return this.trainNumberValue;
    }
    setTrainNumberValue(value: string): void {
        this.trainNumberValue = value;
    }

    setSortBy(sortBy: number, sortDesc: boolean): void {
        this.sortBy = sortBy;
        this.sortDesc = sortDesc;
    }
}