<div class="modal-header">
  <h3 *ngIf="!isSelectOnly" class="modal-title" i18n="@@adapt-route.adapt-route">Linjeanpassning</h3>
  <h3 *ngIf="isSelectOnly" class="modal-title" i18n="@@adapt-route.select-routevariant">Val av linjevariant</h3>
</div>
<form [formGroup]="adaptRouteForm" novalidate class="form m-3">
  <h4 i18n="@@adapt-route.routevariants">Linjevarianter</h4>
  <div class="ms-1 mt-3 mb-3">
    <button *ngFor="let rv of routeVariants; let i = index" id="{{'selectedRouteVariant_'}}{{i + 1}}" (click)="routeVariantSelected(rv.id)"
              class="btn button-routevariant" [attr.disabled]="((isSaved && !isEdit && rv.id !== selectedRouteVariantId) ? 'disabled': null)" [class.focus]="(rv.id === selectedRouteVariantId)">
      {{ variantBaseName(rv) }} <br>
      {{ variantStartPlace(rv) }} <br>
      {{ variantEndPlace(rv) }}
    </button>
  </div>
  <hr>
  <div class="mt-4 mb-5">
    <div class="col">
      <div class="stop-col">
        <div *ngFor="let s of routeStops" class="stop-group" [class.mb-3]="s.sequence === routeStops.length" [class.inactive]="!s.active">
          <div class="row">
            <div class="col-2 disembarking-col">
              <ng-container *ngIf="!isSelectOnly && s.active && !isChangingStartOrStop() && !(s.place.placeId === selectedStartId || s.place.placeId === selectedEndId)">
                <button *ngIf="!isSaved || isEdit" class="small-button" [class.active]="s.disembarkingOnly" (click)="toggleDisembarking(s)" i18n="@@adapt-route.disembarking-only">Endast avstigande</button>
              </ng-container>
            </div>
            <div *ngIf="!isSelectOnly" class="col-2 active-col">
              <button *ngIf="isChangeActive(s.place.placeId, s.sequence) && (!isSaved || isEdit)" class="small-button" (click)="selectStop(s.place.placeId, s.sequence)" i18n="@@adapt-route.select">Välj</button>
              <button *ngIf="s.active && !this.changeStartActive && !this.changeEndActive && isRemoveButtonVisible() && (!isSaved || isEdit)" class="small-button" (click)="setActiveOrInactive(s, false)" i18n="@@adapt-route.remove">Ta bort</button>
              <button *ngIf="!s.active && !this.changeStartActive && !this.changeEndActive && (!isSaved || isEdit)" class="small-button active" (click)="setActiveOrInactive(s, true)" i18n="@@adapt-route.reset">Återställ</button>
            </div>
            <div class="col-1 icon-col">
              <img *ngIf="s.place.placeId === selectedStartId" class="busstop-start-end" src="/assets/img/icons/busstop-start.svg" />
              <img *ngIf="s.active && !s.disembarkingOnly && s.place.placeId !== selectedStartId && s.place.placeId !== selectedEndId"  class="busstop" src="/assets/img/icons/busstop-active.png" />
              <img *ngIf="(!s.active || s.disembarkingOnly) && s.place.placeId !== selectedStartId && s.place.placeId !== selectedEndId"  class="busstop" src="/assets/img/icons/busstop-inactive.png" />
              <img *ngIf="s.place.placeId === selectedEndId" class="busstop-start-end" src="/assets/img/icons/busstop-end.png" />
              <div *ngIf="s.sequence !== routeStops.length" class="line"></div>
            </div>
            <div class="col description-col">
              <div class="busstop-description" [class.caption-lt]="s.sequence > 1 && s.sequence !== routeStops.length">{{ s.place.description }}</div>
              <div *ngIf="isSaved && !isEdit && s.disembarkingOnly" class="busstop-description caption-lt" i18n="@@adapt-route.disembarking-only-descr">(Endast avstigande)</div>
              <div *ngIf="s.place.placeId === selectedStartId" class="caption-lt start-end-label" i18n="@@adapt-route.adapt-route" i18n="@@adapt-route.start-tripstop">Starthållplats</div>
              <div *ngIf="s.place.placeId === selectedEndId" class="caption-lt start-end-label" i18n="@@adapt-route.end-tripstop">Sluthållplats</div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!isSelectOnly" class="change-buttons">
        <button class="small-button" *ngIf="!isSaved || isEdit" (click)="changeStart()" [class.active]="changeStartActive" i18n="@@adapt-route.change-start-tripstop">Ändra starthållplats</button>
        <button class="small-button" *ngIf="!isSaved || isEdit" (click)="changeEnd()" [class.active]="changeEndActive" i18n="@@adapt-route.change-end-tripstop">Ändra sluthållplats</button>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button id="closeRouteSelector" type="button" class="btn btn-primary" (click)="close()" ngbAutofocus i18n="@@adapt-route.close">Stäng</button>
  </div>
</form>
