import { OnInit, Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ActivityTypeEnum, Outcome, OutcomeActivity, OutcomeActivityData, OutcomeActivityDataRequest, OutcomeActivityPassengers, OutcomeCancellationReason, OutcomeTripStateEnum, OutcomeTripStop } from '@common/models/outcome';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { roundOffMinutes } from '@common/date-extension';
import { CancelReasonUtilService } from '@services/cancel-reason-util.service';

@Component({
  selector: 'app-outcome-trip',
  templateUrl: './outcome-trip.component.html',
  styleUrls: ['./outcome-trip.component.scss']
})
export class OutcomeTripComponent implements OnInit {

  protected readonly Math = Math;

  private _editMode: boolean;

  @Input() activity: OutcomeActivity;
  @Input() orderDate: Date; // Used when data is missing in for example pullout times.
  @Input() set editMode(editMode: boolean) {
    this._editMode = editMode;
    if (this.editMode) {
      this.setupRowFormAndValidation();
    }
  }

  @Output() addAlteredActivityEvent = new EventEmitter<OutcomeActivityDataRequest>();

  @Output() rowFormGroup = new EventEmitter<UntypedFormGroup>();
  @Output() removeUnalteredActivityEvent = new EventEmitter<number>();

  @ViewChild('distanceElement') distanceElement: ElementRef;
  rowform: UntypedFormGroup;
  revisedActivityData: OutcomeActivityDataRequest;
  activityDataSource: string;
  activityData: OutcomeActivityData;
  passengers: OutcomeActivityPassengers;
  isRevised: boolean;
  tripState: string;
  isTripCancelled: boolean;
  activityTypeEnum = ActivityTypeEnum;
  cancellationReason: string;
  cancellationReasonComment: string;
  cancellationReasonInfo: string;
  showCancellationReasonIcon: boolean;

  isDistanceRevisedAndNotSameAsExisting: boolean;
  isDurationRevisedAndNotSameAsExisting: boolean;
  isEndTimeRevisedAndNotSameAsExisting: boolean;
  isStartTimeRevisedAndNotSameAsExisting: boolean;
  isPassengersRevisedAndNotSameAsExisting: boolean;

  constructor(private cancelReasonUtilService: CancelReasonUtilService) { }

  get editMode(): boolean {
    return this._editMode;
  }

  ngOnInit(): void {
    this.activityData = this.activity.plannedRegistered;
    this.passengers = this.activity.passengers;
    this.getTripState(this.activity);
    
    this.setupRowFormAndValidation();
  }

  setupRowFormAndValidation() {
    this.setrevisedActivityData(this.activity, this.activityData, this.passengers);
    this.rowform = new UntypedFormGroup({
      outcomeActionId: new UntypedFormControl(''),
      comment: new UntypedFormControl(''),
      startTime: new UntypedFormControl(''),
      endTime: new UntypedFormControl(''),
      distance: new UntypedFormControl(''),
      passengers: new UntypedFormControl('')
    });
    const outcomeActionIdField = this.rowform.controls['outcomeActionId'];
    const commentField = this.rowform.controls['comment'];
    const startTimeField = this.rowform.controls['startTime'];
    const endTimeField = this.rowform.controls['endTime'];
    const distanceField = this.rowform.controls['distance'];
    const passengersField = this.rowform.controls['passengers'];

    outcomeActionIdField.setValue(this.revisedActivityData.outcomeActionId);
    commentField.setValue(this.revisedActivityData.comment);
    startTimeField.setValue(moment(this.revisedActivityData.startTime).format('HH:mm'));
    endTimeField.setValue(moment(this.revisedActivityData.endTime).format('HH:mm'));
    distanceField.setValue(this.revisedActivityData.distance);
    passengersField.setValue(this.revisedActivityData.passengers);

    this.rowform.valueChanges.subscribe(
      (controlValue: UntypedFormGroup) => {
        // Validate comment if values in other field have changed.
        if (startTimeField.value !== '' || startTimeField.value !== null || startTimeField.value !== 'Invalid date' || startTimeField.value !== '--:--' ||
          endTimeField.value !== '' || endTimeField.value !== null || endTimeField.value !== 'Invalid date' || endTimeField.value !== '--:--' ||
          // tslint:disable-next-line:triple-equals
          distanceField.value != this.revisedActivityData.distance) {
          commentField.setValidators([Validators.required]);
          commentField.updateValueAndValidity({ onlySelf: false, emitEvent: false });
        } else {
          commentField.clearValidators();
          commentField.updateValueAndValidity({ onlySelf: false, emitEvent: false });
        }

        // Validate starttime/endtime, if one is altered the other is required
        if (startTimeField.value !== moment(this.revisedActivityData.startTime).format('HH:mm') ||
          endTimeField.value !== moment(this.revisedActivityData.endTime).format('HH:mm')) {
          if (endTimeField.value === 'Invalid date' || endTimeField.value === '') {
            endTimeField.setValidators([validDateValidator(outcomeActionIdField.value)]);
            endTimeField.updateValueAndValidity({ onlySelf: false, emitEvent: false });
          } else {
            endTimeField.clearValidators();
            endTimeField.updateValueAndValidity({ onlySelf: false, emitEvent: false });
          }
          if (startTimeField.value === 'Invalid date' || startTimeField.value === '') {
            startTimeField.setValidators([validDateValidator(outcomeActionIdField.value)]);
            startTimeField.updateValueAndValidity({ onlySelf: false, emitEvent: false });
          } else {
            startTimeField.clearValidators();
            startTimeField.updateValueAndValidity({ onlySelf: false, emitEvent: false });
          }
        }

        if (!(/^[0-9]+([@.|@,][0-9]+)?$/.test(distanceField.value))) {
          distanceField.setValidators([validateDistanceValidator(outcomeActionIdField.value)]);
          distanceField.updateValueAndValidity({ onlySelf: false, emitEvent: false });
        } else {
          distanceField.clearValidators();
          distanceField.updateValueAndValidity({ onlySelf: false, emitEvent: false });
        }

        if (!(/^(\s*|\d{0,3}|null)$/.test(passengersField.value)) || passengersField.value < 0 || passengersField.value > 150) {
          passengersField.setValidators([validatePassengersValidator(outcomeActionIdField.value)]);
          passengersField.updateValueAndValidity({ onlySelf: false, emitEvent: false });

        } else {
          passengersField.clearValidators();
          passengersField.updateValueAndValidity({ onlySelf: false, emitEvent: false });
        }


        this.rowFormGroup.emit(this.rowform);
        if (this.rowform.valid) {
          this.emitRequestObject(controlValue);
        }
        this.updateIsRevisedAndNotSameAsExistingFlags();
      });

  }

  emitRequestObject(alteredFormValues: UntypedFormGroup) {
    const commentField = alteredFormValues['comment'];
    const startTimeField = alteredFormValues['startTime'];
    const endTimeField = alteredFormValues['endTime'];
    const distanceField = alteredFormValues['distance'];
    const passengersField = alteredFormValues['passengers'];

    const requestObject = new OutcomeActivityDataRequest();
    requestObject.outcomeActionId = this.revisedActivityData.outcomeActionId;

    if (startTimeField !== undefined && startTimeField !== null && startTimeField.length === 5) {
      if (requestObject.startTime === null || requestObject.startTime === undefined) {
        requestObject.startTime = moment(this.orderDate).toDate();
      }
      requestObject.startTime.setHours(startTimeField.substring(0, 2), startTimeField.substring(3, 5), 0, 0);
    } else {
      requestObject.startTime = null;
    }
    if (endTimeField !== undefined && endTimeField !== null && endTimeField.length === 5) {
      if (requestObject.endTime === null || requestObject.endTime === undefined) {
        requestObject.endTime = moment(this.orderDate).toDate();
      }
      requestObject.endTime.setHours(endTimeField.substring(0, 2), endTimeField.substring(3, 5), 0, 0);
      if (moment(requestObject.endTime).isBefore(moment(requestObject.startTime).subtract('59', 'seconds'))) {
        requestObject.endTime = moment(requestObject.endTime).add('1', 'days').toDate();
      }
    } else {
      requestObject.endTime = null;
    }
    requestObject.distance = distanceField;
    requestObject.comment = commentField;
    requestObject.passengers = (passengersField >= 0 && passengersField <= 150) ? passengersField : 0;

    if (this.revisedActivityData.comment !== requestObject.comment || moment(this.revisedActivityData.startTime).format('HH:mm:ss') !== moment(requestObject.startTime).format('HH:mm:ss') ||
      // tslint:disable-next-line:triple-equals
      moment(this.revisedActivityData.endTime).format('HH:mm:ss') !== moment(requestObject.endTime).format('HH:mm:ss') || this.revisedActivityData.distance != requestObject.distance || this.revisedActivityData.passengers != requestObject.passengers) {

      this.addAlteredActivityEvent.emit(requestObject);

    } else {
      this.removeUnalteredActivityEvent.emit(requestObject.outcomeActionId);
    }
  }

  getTripState(activity: OutcomeActivity): void {
    this.isTripCancelled = activity.state === OutcomeTripStateEnum.Cancelled;
    
    if (this.isTripCancelled) {
      this.cancellationReason = this.cancelReasonUtilService.getCancellationReason(activity?.cancellationDetails?.reasonEnum);
      this.cancellationReasonComment = this.activity?.cancellationDetails?.additionalInfo;
      
      if (this.cancellationReason) {
        this.cancellationReasonInfo = this.cancellationReasonComment
          ? `${this.cancellationReason}: ${this.cancellationReasonComment}`
          : this.cancellationReason;
          this.showCancellationReasonIcon = true;
      } else {
        this.showCancellationReasonIcon = false;
      }
    }
  }

  getTypeDescription(activity: OutcomeActivity): string {
    switch (activity.activityType.key) {
      case ActivityTypeEnum.Trip: {
        return `${activity.activityType.value} ${activity.driveOrderTripId}`;
      }
      default: {
        return `${activity.activityType.value}`;
      }
    }
  }

  setrevisedActivityData(activity: OutcomeActivity, activityData: OutcomeActivityData, passengers: OutcomeActivityPassengers) {
    this.revisedActivityData = new OutcomeActivityDataRequest();
    this.revisedActivityData.outcomeActionId = activity.id;
    this.revisedActivityData.comment = activity.revisionComment;

    if (activity.revised !== null) {
      this.revisedActivityData.startTime = activity.revised.startTripStop.time;
      this.revisedActivityData.endTime = activity.revised.endTripStop.time;
      this.revisedActivityData.distance = activity.revised.distanceKm;
      this.revisedActivityData.duration = activity.revised.durationMinutes;
      this.revisedActivityData.passengers = activity.passengers.revisedCount;
      this.isRevised = true;
    } else {
      // Set default values for editing from shown data.
      this.revisedActivityData.startTime = activityData.startTripStop.time;
      this.revisedActivityData.endTime = activityData.endTripStop.time;
      this.revisedActivityData.distance = activityData.distanceKm;
      this.revisedActivityData.duration = activityData.durationMinutes;
      this.revisedActivityData.passengers = passengers.registeredCount;
      this.isRevised = false;
    }

    roundOffMinutes([
      activity.startTripStop?.time,
      activity.endTripStop?.time,
      this.revisedActivityData?.startTime,
      this.revisedActivityData?.endTime
    ]);

    this.activityDataSource = this.activityData.timeSource === this.activityData.distanceSource ? this.activityData.timeSource : `Tid: ${this.activityData.timeSource}, Distans: ${this.activityData.distanceSource}`;
    this.updateIsRevisedAndNotSameAsExistingFlags();
  }
  updateIsRevisedAndNotSameAsExistingFlags() {
    this.isStartTimeRevisedAndNotSameAsExisting = (this.isRevised && (this.minutesOfDay(this.activityData.startTripStop.time)) !== this.minutesOfDay(this.revisedActivityData.startTime));
    this.isEndTimeRevisedAndNotSameAsExisting = (this.isRevised && (this.minutesOfDay(this.activityData.endTripStop.time)) !== this.minutesOfDay(this.revisedActivityData.endTime));
    this.isDurationRevisedAndNotSameAsExisting = (this.isRevised && this.activityData.durationMinutes !== this.revisedActivityData.duration);
    this.isDistanceRevisedAndNotSameAsExisting = (this.isRevised && this.activityData.distanceKm !== this.revisedActivityData.distance);
    this.isPassengersRevisedAndNotSameAsExisting = (this.isRevised && this.passengers.registeredCount !== this.passengers.revisedCount);
  }

  minutesOfDay(m: Date): number {
    return moment(m).minutes() + moment(m).hours() * 60;
  }

  // Special edge-case handling to avoid buggy behaviour in Edge browser, in future we would prefer to just use <input type="number">
  // User can enter comma-sign, it will be converted to dot, user can use up and down arrows and get effect of step="0.1"
  // We also apply validation on pattern number followed by dot or comma, followed by number
  // it will be set to fixed length of 2 decimals, and removing trailing zeroes.
  handleNumericInputUp(event: any) {
    const distanceField = this.rowform.controls['distance'];
    distanceField.setValue(event.target.value.replace(',', '.'));
    if (!!distanceField.value) {
    const pos = distanceField.value.indexOf('.') + 1;
    if (pos > 0 && (event.keyCode === 188 || event.keyCode === 190)) {
      this.distanceElement.nativeElement.setSelectionRange(pos, pos, 'none');
    }
    if (event.keyCode === 38 || event.keyCode === 40) {
      const endPos = event.target.value.length;
      this.distanceElement.nativeElement.setSelectionRange(endPos, endPos, 'none');
    }
  }
  }
  handleNumericInputDown(event: any) {
    const distanceField = this.rowform.controls['distance'];
    if (event.keyCode === 38) { // ARROW UP
      event.preventDefault();
      distanceField.setValue((event.target.value * 1 + 0.1).toFixed(2).toString());
    } else if (event.keyCode === 40) { // ARROW DOWN
      event.preventDefault();
      distanceField.setValue((event.target.value * 1 - 0.1).toFixed(2).toString());
    }
  }
  handleNumericInputEmpty(event: any) {
    const distanceField = this.rowform.controls['distance'];
    if (!event.target.value || event.target.value === '') {
      distanceField.setValue(0);
    }
  }
}

/**
 * Validator to check if endtime is after starttime or hour is not between 0-3 in the morning
 * Usage:  endTimeField.setValidators([endTimeValidator('15:45')]);
 */
export function endTimeValidator(startTime: string, id: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const endTimeFieldHour = control.value.substring(0, 2);
    const endTimeFieldMinute = control.value.substring(3, 5);
    const startTimeFieldHour = startTime.substring(0, 2);
    const startTimeFieldMinute = startTime.substring(3, 5);
    let illegalEndTime = false;
    if (endTimeFieldHour <= startTimeFieldHour) {
      if (endTimeFieldHour === startTimeFieldHour && endTimeFieldMinute <= startTimeFieldMinute) {
        illegalEndTime = true;
      } else if (endTimeFieldHour < startTimeFieldHour && endTimeFieldHour > 3) {
        illegalEndTime = true;
      }
    }
    return illegalEndTime ? { 'illegalEndTime': { value: control.value, objectId: id } } : null;
  };
}

export function validDateValidator(id: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return (control.value === 'Invalid date' || control.value === '' || control.value === undefined || control.value === null) ? { 'InvalidDate': { value: control.value, objectId: id } } : null;
  };
}

export function validateDistanceValidator(id: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return (control.value === null || control.value === undefined || control.value === '' || !(/^[0-9]+([@.|@,][0-9]+)?$/.test(control.value))) ? { 'InvalidDistance':  { value: control.value, objectId: id } } : null;
  };
}

export function validatePassengersValidator(id: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return (!(/^(\s*|\d{0,3}|null)$/.test(control.value)) || control.value > 150 || control.value < 0 ) ? { 'InvalidPassengers': { value: control.value, objectId: id } } : null;
  };
}
