import { Component, OnInit } from '@angular/core';
import { LogService } from '@services/log.service';
import { ActivatedRoute } from '@angular/router';
import { LogResponse } from '@models/log';

@Component({
  selector: 'app-order-log',
  templateUrl: './order-log.component.html',
  styleUrls: ['./order-log.component.scss']
})
export class OrderLogComponent implements OnInit {

  logs: LogResponse[];
  orderId: number;

  constructor(private logService: LogService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.orderId = +this.route.snapshot.paramMap.get('id');



    this.logService.getOrderLogs(this.orderId)
      .subscribe(logs => this.onLogsRetrieved(logs.body));
  }

  onLogsRetrieved(logs: LogResponse[]) {
    this.logs = logs;
  }

}
