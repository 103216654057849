<app-order-menu [orderId]="orderId"></app-order-menu>
<div class="container">
  <form [formGroup]="mapForm">
    <div class="row g-0">
      <div class="col-5 pe-4">
        <div *ngIf="routeVariants?.length === 1">
          <label for="routeVariant" i18n="@@route-map.routevariant">Linjevariant</label>
          <input id="routeVariant" class="form-control" type="text" formControlName="routeVariant">
        </div>
          <div *ngIf="routeVariants?.length > 1">
            <label for="routeVariant" i18n="@@route-map.select-routevariant">Välj linjevariant</label>
            <select (change)="onRouteVariantChanged()" id="routeVariant" class="form-control form-select" formControlName="routeVariant">
            <option value="null" [disabled]="true" i18n="@@route-map.select">Välj</option>
            <option *ngFor="let r of routeVariants" [ngValue]="r">{{ r.routeName + ' - ' + r.routeVariantName }}</option>
          </select>
        </div>
      </div>
      <div class="col-2 d-flex ms-auto align-items-end mb-1">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" [checked]="autoZoom" (change)="autoZoom = !autoZoom" id="autoZoom"
                 title="Zooma kartan automatiskt till att visa hela linjen och alla fordon" i18-title="@@route-map.auto-zoom-title"
          />
          <label for="autoZoom" class="form-check-label" i18n="@@route-map.auto-zoom">Auto zoom</label>
        </div>
      </div>
    </div>
  </form>
</div>
<app-googlemap
  [routeGeoData]="routeGeoData"
  [vehicleGeoData]="vehicleGeoData"
  [disableStreetView]="disableStreetView"
  [autoZoom]="autoZoom"
></app-googlemap>
