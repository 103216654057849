import { StateObject } from './state-object';

export enum OrderState {
  SentToNobina = 1,
  ReceivedByNobina = 2,
  Active = 3,
  Cancelled = 4,
  Completing = 5,
  Completed = 6,
  Ongoing = 7,
  ReportedComplete = 8
}
export class OrderStateObject extends StateObject {

  public constructor(init?: Partial<OrderStateObject>) {
    super(init);
    Object.assign(this, init);
  }
}
