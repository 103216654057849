import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { UserRoles } from '@authentication/user-roles';
import { AuthService } from '@services/auth.service';
import { filter } from 'rxjs';
import { InteractionStatus } from '@azure/msal-browser';
import { map } from 'rxjs/operators';


export const roleGuardFn = (activatedRoute: ActivatedRouteSnapshot, routerState: RouterStateSnapshot) => {
  const msalService = inject(MsalService);
  const authService = inject(AuthService);
  const broadcastService = inject(MsalBroadcastService);
  const router = inject(Router);
  const url = routerState.url;

  return broadcastService.inProgress$.pipe(
    filter((status: InteractionStatus) => status === InteractionStatus.None),
    map(() => {
      const user = msalService.instance.getActiveAccount();
      if (!user || user.idTokenClaims === undefined) {
        return false;
      }

      if (url.startsWith('/order')) {
        // får INTE vara UE, utan måste vara Nobina eller tågoperatör
        if (authService.userHasAnyRole([UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare,
          UserRoles.NobinaTrafikledare, UserRoles.OperatorTrafikledare])) {
          return true;
        }

        // redirecta till /subcontractor/:subcontractorId/subcontractor-orders
        router.navigate([`/subcontractor/${authService.user.idTokenClaims.garageIds[0]}/subcontractor-orders`]);

        return false;
      }

      if (url.startsWith('/subcontractor')) {
        const scId = activatedRoute.paramMap.get('subcontractorId');
        // måste vara UETrafikledare och ha rätt subcontratorId
        return authService.userHasAnyRole([UserRoles.UETrafikledare])
          && (scId === null || scId === user.idTokenClaims.garageIds[0]);
      }

      if (url.startsWith('/usersettings')) {
        return authService.userHasAnyRole([UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare, UserRoles.NobinaTrafikledare, UserRoles.OperatorTrafikledare]);
      }

      if (url.startsWith('/settings')) {
        return authService.userHasAnyRole([UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare]);
      }

      return true;
    }),
  );
};
