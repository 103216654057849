import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderService } from '../../../common/services/order.service';
import { OrderState } from '../../../common/models/order-state';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements OnInit {

  @Input() title: string;
  @Input() description: string;
  @Input() buttonText: string;

  buttonsDisabled = false;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

}
