import { Injectable } from '@angular/core';
import { HttpResponse, HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable()
export class TimetableImportService {
  private baseUrl = environment.apiUrl + environment.apiVersionMain + '/timetables';
  // /api/v1/timetables/1156/upload
  constructor(private http: HttpClient) { }

  import(operatorOrganisationId: number, file: File): Observable<HttpResponse<any>> {

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post(this.baseUrl + '/' + operatorOrganisationId + '/upload',
      formData, { observe: 'response' });
  }
}
