<div class="assign-container">
  <h2 i18n="@@assign-subcontractor.assign-subcontractor">Tilldela utförare</h2>

  <div class="d-flex">
    <div class="col subcontractor-col pt-4">
      <div class="d-flex">
        <div class="col-2">
          <img src="/assets/img/icons/driver.png" alt="Icon driver"/>
        </div>
        <div class="col">
          <div class="caption-lt" i18n="@@assign-subcontractor.assigned-subcontractor">Tilldelad utförare</div>
          <div>{{ assignedSubcontractor?.name }}</div>
        </div>
      </div>
      <div class="d-flex">
        <div class="col-2">
          <img src="/assets/img/icons/search.png" class="search-icon" alt="Icon search"/>
        </div>
        <div class="col">
          <input class="form-control" type="text" placeholder="Sök utförare" #searchSubcontractor (keyup)="0" i18n-placeholder="@@assign-subcontractor.search-subcontractor-placeholder"/>
        </div>
      </div>
      <div class="name-rows">
        <div class="row name-row" *ngFor="let subcontractor of subcontractors | search:'name,id':searchSubcontractor.value">
          <div class="col-2 button-col">
            <button *ngIf="selectedSubcontractor?.id!=subcontractor.id" (click)="selectedSubcontractor=subcontractor" class="small-button" i18n="@@assign-subcontractor.select">Välj</button>
            <button *ngIf="selectedSubcontractor?.id==subcontractor.id" (click)="selectedSubcontractor=null" class="small-button active" i18n="@@assign-subcontractor.selected">Vald</button>
          </div>
          <div class="d-flex align-items-center col name-col">
            <div>{{ subcontractor.name }} </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="d-flex justify-content-end m-4">
    <button class="btn btn-outline-primary me-2" (click)="activeModal.close({subcontractor: assignedSubcontractor})" i18n="@@assign-subcontractor.cancel">Avbryt</button>
    <button class="btn btn-primary" (click)="assign()" [disabled]="this.selectedSubcontractor == null" i18n="@@assign-subcontractor.assign">Tilldela</button>
  </div>

</div>
