import { Component, Injector, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AuthService } from '@services/auth.service';
import { environment } from '../environments/environment'; // used in template
import { Title } from '@angular/platform-browser';
import { EnvironmentType } from '@models/environment.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  navColor = 'bg-primary';

  constructor(
    private authService: AuthService,
    private injector: Injector,
    private title: Title) {
    const locale = injector.get(LOCALE_ID);
    moment.locale(locale);
  }

  ngOnInit(): void {
    this.setNavColor();

    this.authService.initialize();

    this.title.setTitle($localize`:Ersätta@@app.title:Ersätta`);
  }

  ngOnDestroy(): void {
  }

  setNavColor() {
    switch (environment.environmentType) {
      case EnvironmentType.Local:
        this.navColor = 'bg-local';
        break;
      case EnvironmentType.FT:
        this.navColor = 'bg-ft';
        break;
      case EnvironmentType.AT:
        this.navColor = 'bg-at';
        break;
      case EnvironmentType.ST:
        this.navColor = 'bg-st';
        break;
      case EnvironmentType.Production:
        this.navColor = 'bg-primary';
        break;
      default:
        this.navColor = 'bg-primary';
        break;
    }
  }
}
