import { Injectable } from '@angular/core';
import { HttpResponse, HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Operator } from '../models/operator';

@Injectable()
export class OperatorService {
  private baseUrl = environment.apiUrl + environment.apiVersionMain + '/operators';

  constructor(private http: HttpClient) { }

  getOperators(filterByUser: boolean = false, includeInactive: boolean = false): Observable<HttpResponse<Operator[]>> {
    return this.http.get<Operator[]>(this.baseUrl + `?FilterByUserSelection=${filterByUser}` + `&IncludeInactive=${includeInactive}`,  { observe: 'response' });
  }

}
