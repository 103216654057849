import { Subcontractor } from './subcontractor';
import { TripStopSummary } from './drive-order';
import { OrderType } from './order-type';
import { StateObject } from './state-object';
import { VehicleTypeEnum } from './vehicle-type';

export enum AvailabilityInquiryState {
  StateNew = 0, // OBS! Kan inte ha namnet 'New'
  Submitted = 1,
  Rejected = 2,
  Accepted = 3,
  Assigned = 4,
  Ongoing = 5,
  NotAssigned = 6
}

export class AvailabilityInquiry {
  id: number;
  subcontractor: Subcontractor;
  currentState: AvailabilityInquiryStateObject;
  numberOfVehicles: number;
  submitted: AvailabilityInquiryStateObject;
  replyInquiry: AvailabilityInquiryReply;
  assignInquiry: AvailabilityInquiryAssign;
  orderId: number;
  startTripStop: TripStopSummary;
  endTripStop: TripStopSummary;
  isRoundTrip: boolean;
  startOrderRouteStopDescription: string;
  endOrderRouteStopDescription: string;
  orderRouteName: string;
  orderReason: AvailabilityInquiryReason;
  orderType: OrderType;
  vehicleType: string;
  vehicleTypeEnum: VehicleTypeEnum;
  trainNumber: string;
}

export class AvailabilityInquiryReply {
  repliedTime: Date;
  availableVehicles: AvailableVehicle[];
}

export class AvailabilityInquiryAssign {
  assignedTime: Date;
  numberOfVehiclesAssigned: number;
}

export class AddAvailabilityInquiry {
  subcontractorId: number;
  numberOfVehicles: number;
  orderId: number;
}

export class AddAvailabilityInquiries {
  subcontractorIds: number[];
  numberOfVehicles: number;
  orderId: number;
}

export enum ReplyOptions {
  Accept = 1,
  Reject = 2
}

export class AvailabilityInquiryReplyRequest {
  acceptOrReject: ReplyOptions;
  availableVehicles: AvailableVehicle[];
}

export class AvailableVehicle {
  count: number;
  estimatedArrivalMinutes: number;
}

export type AvailableVehicleForm = AvailableVehicle & {
  shouldAssign: boolean
  isVehicleValid?: boolean;
  isMinutesValid?: boolean;
  isShouldAssignValid?: boolean;
};

export class AvailabilityInquiryAssignRequest {
  numberOfVehiclesAssigned: number;
}

export class AvailabilityInquiryReason {
  id: number;
  description: string;
  additionalInfo: string;
}

export class AvailabilityInquiryStateObject extends StateObject {

  public constructor(init?: Partial<AvailabilityInquiryStateObject>) {
    super(init);
    Object.assign(this, init);
  }
}

