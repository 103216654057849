import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AvailabilityInquiry, AvailabilityInquiryReplyRequest, ReplyOptions, AvailabilityInquiryState, AvailableVehicle } from '@models/availability-inquiry';
import { OrderType } from '@models/order-type';
import { Subcontractor } from '@models/subcontractor';
import { AvailabilityInquiryService } from '@services/availability-inquiry.service';
import { DriveOrderService } from '@services/drive-order.service';
import { DriveOrder } from '@models/drive-order';
import { AlertModalComponent } from '@common/components/alert-modal/alert-modal.component';
import { VehicleTypeService } from '@services/vehicle-type.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-availability-inquiry',
  templateUrl: './availability-inquiry.component.html',
  styleUrls: ['./availability-inquiry.component.scss']
})
export class AvailabilityInquiryComponent implements OnInit {
  driveOrders: DriveOrder[];
  subcontractor: Subcontractor = new Subcontractor();
  inquiry: AvailabilityInquiry = new AvailabilityInquiry();
  form: FormGroup;

  startPlace = '';
  endPlace = '';
  path: string;
  buttonsDisabled = false;
  modalRef: NgbModalRef;
  showAddInquiryTime = true;
  numberOfVehiclesSet: number;
  vehicleType: string;

  AvailabilityInquiryState = AvailabilityInquiryState;
  OrderType = OrderType;

  get availableVehicleItems() {
    return this.form.controls["availableVehicleItems"] as FormArray;
  }

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private availabilityInquiryService: AvailabilityInquiryService,
    private driveOrderService: DriveOrderService,
    private modalService: NgbModal,
    private vehicleTypeService: VehicleTypeService
  ) {
    this.route.params.subscribe(params => {
      this.subcontractor.id = +params['subcontractorId'];
      this.inquiry.id = +params['id'];
    });
  }

  ngOnInit() {
    this.form = this.fb.group({
      availableVehicleItems: this.fb.array([])
    });

    this.path = this.router.url;
    this.getAvailabilityInquiry();
  }

  getAvailabilityInquiry() {
    this.availabilityInquiryService.getAvailabilityInquiry(this.inquiry.id)
      .subscribe(resp => {
        this.inquiry = resp.body;
        this.subcontractor = this.inquiry.subcontractor;
        this.vehicleType = this.vehicleTypeService.getVehicleType(this.inquiry.vehicleTypeEnum)
        this.getDriveOrders();
      });
  }

  setNumberOfVehicle($event: number) {
    this.numberOfVehiclesSet = $event;
  }

  getDriveOrders() {
    this.driveOrderService.getDriveOrders('?OrderId=' + this.inquiry.orderId).subscribe(y => {
      this.driveOrders = y.body;
    });
  }

  reject() {
    const inquiryReply = new AvailabilityInquiryReplyRequest();
    inquiryReply.acceptOrReject = ReplyOptions.Reject;
    this.postReplyAndUpdateAndRouteBack(inquiryReply);
  }

  accept() {
    // trigger validation on all fields
    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      control.markAsTouched({ onlySelf: true });
    });

    if (this.form.valid) {
      this.buttonsDisabled = true;
      const inquiryReply = new AvailabilityInquiryReplyRequest();
      inquiryReply.acceptOrReject = ReplyOptions.Accept;
      inquiryReply.availableVehicles = []

      this.availableVehicleItems.value.forEach((item) => {
        inquiryReply.availableVehicles.push(
          {
            count: item.numberOfVehicles['numberOfVehicles'],
            estimatedArrivalMinutes: item.estimatedArrivalMinutes['estimatedArrivalMinutes'] ? item.estimatedArrivalMinutes['estimatedArrivalMinutes'] : 0
          } as AvailableVehicle
        )
      });

      this.availabilityInquiryService.getAvailabilityInquiry(this.inquiry.id)
        .subscribe(x => {
          this.inquiry = x.body;
          if (this.inquiry.currentState.stateEnum === AvailabilityInquiryState.Submitted) {
            this.postReplyAndUpdateAndRouteBack(inquiryReply);
          }
          else {
            this.modalRef = this.modalService.open(AlertModalComponent);
            this.modalRef.componentInstance.title = $localize`:Förfrågan är redan besvarad@@availability-inquiry.inquiry-submitted:Förfrågan är redan besvarad`;
            this.modalRef.componentInstance.description = $localize`:Förfrågan är redan besvarad@@availability-inquiry.inquiry-submitted:Förfrågan är redan besvarad`;
          }
        });
    }
  }

  postReplyAndUpdateAndRouteBack(inquiryReply: AvailabilityInquiryReplyRequest) {
    let location = '';
    this.availabilityInquiryService.postAvailabilityInquiryReply(this.inquiry.id, inquiryReply)
      .subscribe({
        next: resp => {
          location = resp.headers.get('location');
        },
        complete: () => {
          // enl kravspec: navigera till listan med obesvarade förfrågningar
          this.router.navigate([`subcontractor/${this.subcontractor.id}/subcontractor-orders`]);
        }
      });
  }

  navigateToDriveOrder() {
    window.location.href = `${window.location.origin}/order-new/subcontractor/${this.subcontractor.id.toString()}/availability-inquiry/${this.inquiry.id.toString()}/drive-order`
  }
}
