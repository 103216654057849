<div class="container">
  <div class="py-5">
    <h1 class="d-inline py-4" i18n="@@version.version-information">Versionsinformation</h1>
    <hr>
    <div class="row">
      <h3 class="col-2">MainService</h3>
    </div>
    <div class="row">
      <div class="col-2">Version</div>
      <div class="col">{{ mainServiceVersion?.version }}</div>
    </div>
    <div class="row mt-3">
      <h3 class="col-2">OutcomeService</h3>
    </div>
    <div class="row">
      <div class="col-2">Version</div>
      <div class="col">{{ outcomeServiceVersion?.version }}</div>
    </div>
  </div>
</div>
