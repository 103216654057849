import { Injectable } from '@angular/core';
import { VehicleTypeEnum } from '../models/vehicle-type';

@Injectable()
export class VehicleTypeService {

  vehicleTypes = new Map<number, string>();

  constructor() {
    this.vehicleTypes.set(1, $localize`:@@vehicle.type.big-bus:Stor buss`);
    this.vehicleTypes.set(2, $localize`:@@vehicle.type.small-bus:Liten buss`);
    this.vehicleTypes.set(3, $localize`:@@vehicle.type.double-decker:Dubbeldäckare`);
    this.vehicleTypes.set(4, $localize`:@@vehicle.type.taxi:Taxi`);
    this.vehicleTypes.set(5, $localize`:@@vehicle.type.big-taxi:Stor taxi`);
    this.vehicleTypes.set(6, $localize`:@@vehicle.type.wheelchair-taxi:Rullstolstaxi`);
    this.vehicleTypes.set(7, $localize`:@@vehicle.type.standby-bus:Stand-by buss`);
    this.vehicleTypes.set(8, $localize`:@@vehicle.type.standby-wheelchair-taxi:Stand-by rullstolstaxi`);
    this.vehicleTypes.set(9, $localize`:@@vehicle.type.customer-host:Kundvärd`);
    this.vehicleTypes.set(10, $localize`:@@vehicle.type.bus-coordinator:Busskoordinator`);
  }

  getVehicleType(vehicleTypeEnum: number): string {
    return this.vehicleTypes.get(vehicleTypeEnum);
  }

  getAllVehicleTypeDescriptions(): string[] {
    return Array.from(this.vehicleTypes.values());
  }

  getEnumValueByDescription(description: string): number {
    for (const [key, value] of this.vehicleTypes) {
      if (value === description) {
        return key;
      }
    }
    return null;
  }
}