import { Injectable } from '@angular/core';

@Injectable()
export class CancelReasonUtilService {

  reasons = new Map<number, string>();

  constructor() {
    this.reasons.set(1, $localize`:@@trip.cancel-reason-1:Felbeställning`);
    this.reasons.set(2, $localize`:@@trip.cancel-reason-2:Avbeställd av beställare`);
    this.reasons.set(3, $localize`:@@trip.cancel-reason-3:Ändring av beställning`);
    this.reasons.set(4, $localize`:@@trip.cancel-reason-4:Ej tillsatt`);
    this.reasons.set(5, $localize`:@@trip.cancel-reason-5:Ej utförd`);
    this.reasons.set(6, $localize`:@@trip.cancel-reason-6:Annan orsak`);
    this.reasons.set(7, $localize`:@@trip.cancel-reason-7:Ersatt`);
  }

  getCancellationReason(reasonEnum: number): string {
    return this.reasons.get(reasonEnum) || "";
  }
}
