import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class AvailabilityInquiryStoreService {
  private _numberOfVehiclesLeftToSet = new BehaviorSubject(0);
  private _numberOfVehiclesLeftToSet$ = this._numberOfVehiclesLeftToSet.asObservable();
  private _numberOfUnassignedDriveOrders: number = 0;

  getNumberOfVehiclesLeftToSet(): Observable<number> {
    return this._numberOfVehiclesLeftToSet$;
  }

  setNumberOfVehiclesLeftToSet(latestValue: number) {
    return this._numberOfVehiclesLeftToSet.next(latestValue);
  }

  addNumberOfVehicles(value: number) {
    const currentValue = this._numberOfVehiclesLeftToSet.getValue();
    const updatedValue = currentValue + (value ?? 0);

    this._numberOfVehiclesLeftToSet.next(updatedValue);
  }

  public get numberOfUnassignedDriveOrders(): number {
    return this._numberOfUnassignedDriveOrders;
  }
  
  public set numberOfUnassignedDriveOrders(value: number) {
    this._numberOfUnassignedDriveOrders = value;
  }
  
  constructor() {
  }
}
