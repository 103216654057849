import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OutcomeService } from '../../../common/services/outcome.service';
import { OutcomeDriveOrderState } from '../../../common/models/outcome-state';
import { OutcomeDriveOrderStateRequest } from '../../../common/models/outcome';

@Component({
  selector: 'app-outcome-approval',
  templateUrl: './outcome-approval.component.html',
  styleUrls: ['./outcome-approval.component.scss']
})
export class OutcomeApprovalComponent implements OnInit {

  @Input() id: number;

  buttonsDisabled = false;

  constructor(public activeModal: NgbActiveModal, private outcomeService: OutcomeService) { }

  ngOnInit() {
  }

  approve() {
    this.buttonsDisabled = true;
    this.outcomeService.approveOrderDriveOrder(this.id)
      .subscribe(response => this.activeModal.close(),
      err => {
         this.activeModal.dismiss();
      });
  }

}
