<div class="row g-0 justify-content-center align-items-center py-5 xxxl-container">
  <app-back-button></app-back-button>
  <h1 class="m-0 mt-4 mt-md-0"><ng-container i18n="@@position-history-map-menu.order">Beställning</ng-container> {{orderId}}</h1>
  <span class="tb-badge ms-3 mt-4 mt-md-0" *ngIf="!order?.isHastusPlanned && order?.orderRoute?.routeName">
    {{ order?.orderRoute?.routeName }}
  </span>
</div>
<app-googlemap
  [routeGeoData]="routeGeoData"
  [disableStreetView]="disableStreetView"
  [autoZoom]="autoZoom"
  [includeGpsPoints]="includeGpsPoints"
></app-googlemap>
