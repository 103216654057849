import { Injectable } from '@angular/core';
import { DateRange } from '@models/dateRange';
import { DateFilterOption } from './DateFilterOption';
import moment from 'moment';
import { DateOrigin } from '@models/date-origin';

@Injectable()
export class DatePickerService {

  private _orderDateRangeType = 'ORDER_DATE_RANGE_TYPE';

  private _orderFromDate = 'ORDER_FROM_DATE';

  private _orderToDate = 'ORDER_TO_DATE';

  private _historyDateRangeType = 'HISTORY_DATE_RANGE_TYPE';

  private _historyFromDate = 'HISTORY_FROM_DATE';

  private _historyToDate = 'HISTORY_TO_DATE';

  private _outcomeDateRangeType = 'OUTCOME_DATE_RANGE_TYPE';

  private _outcomeFromDate = 'OUTCOME_FROM_DATE';

  private _outcomeToDate = 'OUTCOME_TO_DATE';

  private _missingVehiclesDateRangeType = 'MISSING_VEHICLES_DATE_RANGE_TYPE';

  private _missingVehiclesFromDate = 'MISSING_VEHICLES_FROM_DATE';

  private _missingVehiclesToDate = 'MISSING_VEHICLES_TO_DATE';

  readonly dateFormat = 'YYYY-MM-DD HH:mm:ss Z';


  // ORDER
  set orderDateRange(dateRange: DateRange) {
    localStorage.setItem(this._orderFromDate, moment(dateRange.fromDate).format(this.dateFormat));
    localStorage.setItem(this._orderToDate, moment(dateRange.toDate).format(this.dateFormat));
  }

  get orderDateRange(): DateRange {
    const fromDate = localStorage.getItem(this._orderFromDate);
    const toDate = localStorage.getItem(this._orderToDate);

    if (fromDate && toDate) {

      return new DateRange(moment(fromDate, this.dateFormat).toDate(), moment(toDate, this.dateFormat).toDate());
    } else {
      return new DateRange(new Date(), moment().add(7, 'days').toDate());
    }
  }

  set orderDateRangeType(dateRangeType: DateFilterOption) {
    localStorage.setItem(this._orderDateRangeType, dateRangeType);
  }

  get orderDateRangeType(): DateFilterOption {
    const dateRangeType = localStorage.getItem(this._orderDateRangeType);
    if (dateRangeType && dateRangeType !== 'undefined' && dateRangeType !== '') {
      return DateFilterOption[dateRangeType as keyof typeof DateFilterOption];
    } else {
      return DateFilterOption.Today;
    }
  }

  set orderFromDate(fromDate: Date) {
    localStorage.setItem(this._orderFromDate, moment(fromDate).format(this.dateFormat));

  }

  get orderFromDate(): Date {
    const fromDate = localStorage.getItem(this._orderFromDate);
    if (fromDate && fromDate !== 'undefined' && fromDate !== '') {
      return moment(fromDate, this.dateFormat).toDate();
    } else {
      return new Date();
    }
  }

  set orderToDate(toDate: Date) {
    localStorage.setItem(this._orderToDate, moment(toDate).format(this.dateFormat));
  }

  get orderToDate(): Date {
    const toDate = localStorage.getItem(this._orderToDate);
    if (toDate && toDate !== 'undefined' && toDate !== '') {
      return moment(toDate, this.dateFormat).toDate();
    } else {
      return new Date();
    }
  }


  // HISTORY
  set historyDateRange(dateRange: DateRange) {
    localStorage.setItem(this._historyFromDate, moment(dateRange.fromDate).format(this.dateFormat));
    localStorage.setItem(this._historyToDate, moment(dateRange.toDate).format(this.dateFormat));
  }

  get historyDateRange(): DateRange {
    const fromDate = localStorage.getItem(this._historyFromDate);
    const toDate = localStorage.getItem(this._historyToDate);

    if (fromDate && toDate) {
      return new DateRange(moment(fromDate, this.dateFormat).toDate(), moment(toDate, this.dateFormat).toDate());
    } else {
      return new DateRange(new Date(), moment().add(7, 'days').toDate());
    }
  }

  set historyDateRangeType(dateRangeType: DateFilterOption) {
    localStorage.setItem(this._historyDateRangeType, dateRangeType);
  }

  get historyDateRangeType(): DateFilterOption {
    const dateRangeType = localStorage.getItem(this._historyDateRangeType);
    if (dateRangeType && dateRangeType !== 'undefined' && dateRangeType !== '') {
      return DateFilterOption[dateRangeType as keyof typeof DateFilterOption];
    } else {
      return DateFilterOption.Today;
    }
  }

  set historyFromDate(fromDate: Date) {
    localStorage.setItem(this._historyFromDate, moment(fromDate).format(this.dateFormat));
  }

  get historyFromDate(): Date {
    const fromDate = localStorage.getItem(this._historyFromDate);
    if (fromDate && fromDate !== 'undefined' && fromDate !== '') {
      return moment(fromDate, this.dateFormat).toDate();
    } else {
      return new Date();
    }
  }

  set historyToDate(toDate: Date) {
    localStorage.setItem(this._historyToDate, moment(toDate).format(this.dateFormat));
  }

  get historyToDate(): Date {
    const toDate = localStorage.getItem(this._historyToDate);
    if (toDate && toDate !== 'undefined' && toDate !== '') {
      return moment(toDate, 'YYYY-MM-DD HH:mm:ss Z').toDate();
    } else {
      return new Date();
    }
  }


  // OUTCOME
  set outcomeDateRange(dateRange: DateRange) {
    localStorage.setItem(this._outcomeFromDate, moment(dateRange.fromDate).format(this.dateFormat));
    localStorage.setItem(this._outcomeToDate, moment(dateRange.toDate).format(this.dateFormat));
  }

  get outcomeDateRange(): DateRange {
    const fromDate = localStorage.getItem(this._outcomeFromDate);
    const toDate = localStorage.getItem(this._outcomeToDate);

    if (fromDate && toDate) {
      return new DateRange(moment(fromDate, this.dateFormat).toDate(), moment(toDate, this.dateFormat).toDate());
    } else {
      return new DateRange(new Date(), moment().add(7, 'days').toDate());
    }
  }

  set outcomeDateRangeType(dateRangeType: DateFilterOption) {
    localStorage.setItem(this._outcomeDateRangeType, dateRangeType);
  }

  get outcomeDateRangeType(): DateFilterOption {
    const dateRangeType = localStorage.getItem(this._outcomeDateRangeType);
    if (dateRangeType && dateRangeType !== 'undefined' && dateRangeType !== '') {
      return DateFilterOption[dateRangeType as keyof typeof DateFilterOption];
    } else {
      return DateFilterOption.Today;
    }
  }

  set outcomeFromDate(fromDate: Date) {
    localStorage.setItem(this._outcomeFromDate, moment(fromDate).format(this.dateFormat));
  }

  get outcomeFromDate(): Date {
    const fromDate = localStorage.getItem(this._outcomeFromDate);
    if (fromDate && fromDate !== 'undefined' && fromDate !== '') {
      return moment(fromDate, this.dateFormat).toDate();
    } else {
      return new Date();
    }
  }

  set outcomeToDate(toDate: Date) {
    localStorage.setItem(this._outcomeToDate, moment(toDate).format(this.dateFormat));
  }

  get outcomeToDate(): Date {
    const toDate = localStorage.getItem(this._outcomeToDate);
    if (toDate && toDate !== 'undefined' && toDate !== '') {
      return moment(toDate, this.dateFormat).toDate();
    } else {
      return new Date();
    }
  }


  // MISSING VEHICLES
  set missingVehiclesDateRange(dateRange: DateRange) {
    localStorage.setItem(this._missingVehiclesFromDate, moment(dateRange.fromDate).format(this.dateFormat));
    localStorage.setItem(this._missingVehiclesToDate, moment(dateRange.toDate).format(this.dateFormat));
  }

  get missingVehiclesDateRange(): DateRange {
    const fromDate = localStorage.getItem(this._missingVehiclesFromDate);
    const toDate = localStorage.getItem(this._missingVehiclesToDate);

    if (fromDate && toDate) {
      return new DateRange(moment(fromDate, this.dateFormat).toDate(), moment(toDate, this.dateFormat).toDate());
    } else {
      return new DateRange(new Date(), moment().add(7, 'days').toDate());
    }
  }

  set missingVehiclesDateRangeType(dateRangeType: DateFilterOption) {
    localStorage.setItem(this._missingVehiclesDateRangeType, dateRangeType);
  }

  get missingVehiclesDateRangeType(): DateFilterOption {
    const dateRangeType = localStorage.getItem(this._missingVehiclesDateRangeType);
    if (dateRangeType && dateRangeType !== 'undefined' && dateRangeType !== '') {
      return DateFilterOption[dateRangeType as keyof typeof DateFilterOption];
    } else {
      return DateFilterOption.Today;
    }
  }

  set missingVehiclesFromDate(fromDate: Date) {
    localStorage.setItem(this._missingVehiclesFromDate, moment(fromDate).format(this.dateFormat));
  }

  get missingVehiclesFromDate(): Date {
    const fromDate = localStorage.getItem(this._missingVehiclesFromDate);
    if (fromDate && fromDate !== 'undefined' && fromDate !== '') {
      return moment(fromDate, this.dateFormat).toDate();
    } else {
      return new Date();
    }
  }

  set missingVehiclesToDate(toDate: Date) {
    localStorage.setItem(this._missingVehiclesToDate, moment(toDate).format(this.dateFormat));
  }

  get missingVehiclesToDate(): Date {
    const toDate = localStorage.getItem(this._missingVehiclesToDate);
    if (toDate && toDate !== 'undefined' && toDate !== '') {
      return moment(toDate, this.dateFormat).toDate();
    } else {
      return new Date();
    }
  }


  removeDateRangeSession(dateOrigin: DateOrigin) {
    if (dateOrigin === DateOrigin.Order) {
      localStorage.removeItem(this._orderFromDate);
      localStorage.removeItem(this._orderToDate);
    } else if (dateOrigin === DateOrigin.History) {
      localStorage.removeItem(this._historyFromDate);
      localStorage.removeItem(this._historyToDate);
    } else if (dateOrigin === DateOrigin.Outcome) {
      localStorage.removeItem(this._outcomeFromDate);
      localStorage.removeItem(this._outcomeToDate);
    } else if (dateOrigin === DateOrigin.MissingVehicles) {
      localStorage.removeItem(this._missingVehiclesFromDate);
      localStorage.removeItem(this._missingVehiclesToDate);
    }
  }

  removeAllDateSession(dateOrigin: DateOrigin) {
    if (dateOrigin === DateOrigin.Order) {
      localStorage.removeItem(this._orderDateRangeType);
      localStorage.removeItem(this._orderFromDate);
      localStorage.removeItem(this._orderToDate);
    } else if (dateOrigin === DateOrigin.History) {
      localStorage.removeItem(this._historyDateRangeType);
      localStorage.removeItem(this._historyFromDate);
      localStorage.removeItem(this._historyToDate);
    } else if (dateOrigin === DateOrigin.Outcome) {
      localStorage.removeItem(this._outcomeDateRangeType);
      localStorage.removeItem(this._outcomeFromDate);
      localStorage.removeItem(this._outcomeToDate);
    } else if (dateOrigin === DateOrigin.MissingVehicles) {
      localStorage.removeItem(this._missingVehiclesDateRangeType);
      localStorage.removeItem(this._missingVehiclesFromDate);
      localStorage.removeItem(this._missingVehiclesToDate);
    }
  }
}
