import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { LogResponse } from '../models/log';

@Injectable()
export class LogService {
  private baseUrl = environment.apiUrl + environment.apiVersionMain + '/logs';

  constructor(private http: HttpClient) {
  }

  /** GET orders from the server */
  getOrderLogs(id: number): Observable<HttpResponse<LogResponse[]>> {
    const url = `${this.baseUrl}/orders/${id}`;
    return this.http.get<LogResponse[]>(url, { observe: 'response' });
  }

  /** GET unbound order logs from the server */
  getUnboundOrderLogs(id: number): Observable<HttpResponse<LogResponse[]>> {
    const url = `${this.baseUrl}/unboundorders/${id}`;
    return this.http.get<LogResponse[]>(url, { observe: 'response' });
  }
}
