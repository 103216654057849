import { Injectable } from '@angular/core';

import { filter, BehaviorSubject } from 'rxjs';
import { UserRoles } from '@authentication/user-roles';
import { AccountInfo } from '@azure/msal-common';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';

@Injectable()
export class AuthService {

  userLoadededEvent$: BehaviorSubject<AccountInfo | null> = new BehaviorSubject<AccountInfo | null>(null);
  private _user: AccountInfo;

  constructor(private _msalService: MsalService, private _msalBroadcastService: MsalBroadcastService) {

  }

  get user(): AccountInfo {
    return this._user;
  }

  initialize() {
    this._msalBroadcastService.msalSubject$.subscribe((event: EventMessage) => {
      if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS
      ) {
        const account = event.payload as AuthenticationResult;
        this._msalService.instance.setActiveAccount(account.account);
      } else if (event.eventType === EventType.LOGIN_FAILURE || event.eventType === EventType.SSO_SILENT_FAILURE) {
        this.logout();
      }
    });

    this._msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
    ).subscribe(() => {
      this.checkAndSetActiveAccount();
    });
  }

  checkAndSetActiveAccount() {
    const activeAccount = this._msalService.instance.getActiveAccount();
    const accounts = this._msalService.instance.getAllAccounts();

    if (activeAccount) {
      this.setActiveUser(activeAccount);
    } else if (
      !activeAccount &&
      accounts.length === 1 &&
      accounts[0].idTokenClaims
    ) {
      this._msalService.instance.setActiveAccount(accounts[0]);
      this.setActiveUser(accounts[0]);
    } else if (accounts.length > 1) {
      this._msalService.loginRedirect();
    } else {
      this.setActiveUser(null);
    }
  }

  setActiveUser(account: AccountInfo) {
    this._user = account;
    this.userLoadededEvent$.next(this._user);
  }

  logout() {
    this._msalService.logout();
  }

  public userHasAnyRole(roles: UserRoles[]): boolean {
    if (!this._msalService.instance.getActiveAccount()) {
      return false;
    }

    const tmp: string[] = this._msalService.instance.getActiveAccount().idTokenClaims.role as string[];
    for (const ix in roles) {
      if (tmp.includes(roles[ix].toString())) {
        return true;
      }
    }
    return false;
  }

}
