<div>
  <div *ngFor="let operator of operators; let i=index" class="row">
    <label class="col py-2">
      <input
        [id]="'filter-subcontractors-' + i"
        type="checkbox"
        [checked]="selectedOperatorsIds.indexOf(operator.id) > -1"
        (change)="toggleSelectedOperator(operator)"
      >
      {{operator.name}}
    </label>
  </div>
</div>
