import { AvailabilityInquiry } from './availability-inquiry';
import { OrderType } from './order-type';
import { OrderStateObject } from './order-state';
import { CancellationDetails, TripStopSummary } from './drive-order';
import { Operator } from './operator';
import { VehicleTypeEnum } from './vehicle-type';

export interface OrderRequest {
  id: number;
  routeVariantId: number;
  activeRouteStopPlaceIds: string[];
  disembarkingOnlyRouteStopPlaceIds: string[];
  returnDisembarkingOnlyRouteStopPlaceIds: string[];
  returnRouteVariantId: number;
  returnActiveRouteStopPlaceIds: string[];
  startTime: Date;
  endTime: Date;
  referenceId?: string;
  trainNumber?: string;
  reasonId: number;
  reasonAdditionalInfo?: string;
  numberOfVehicles: number;
  vehicleType: string;
  vehicleTypeEnum: VehicleTypeEnum;
  otherRequests?: string;
  tripIntervalMinutes: number;
  immediateStart: boolean;
  indefiniteEnd: boolean;
  isRoundTrip: boolean;
  operatorOrganisationId: number;
}

export class Order {
  id: number;
  orderRoute: OrderRoute;
  orderReturnRoute: OrderRoute;
  orderRouteName: string;
  referenceId: string;
  trainNumber: string;
  orderTrainNumber: string;
  startTime: Date;
  endTime: Date;
  startTripStop: TripStopSummary;
  endTripStop: TripStopSummary;
  orderReason: OrderReason;
  numberOfVehicles: number;
  vehicleTypeEnum: VehicleTypeEnum;
  otherRequests: string;
  currentState: OrderStateObject;
  created: OrderStateObject;
  activated: OrderStateObject;
  availabilityInquiries: AvailabilityInquiry[];
  immediateStart: boolean;
  indefiniteEnd: boolean;
  tripIntervalMinutes: number;
  isRoundTrip: boolean;
  orderType: OrderType;
  isHastusPlanned: boolean;
  operator: Operator;
  isSpecifiedReturnTripTime: boolean;
  orderActionState: OrderActionState;
  comment: string;
  tripTrainNumbers: string[];
  cancellationDetails?: CancellationDetails;
}


// viewModel for listViewItems
export class OrderTripsPageResult {
  orderId: number;
  driveOrderId: number;
  tripId: number;
  referenceId: string;
  trainNumber: string;
  startTime: Date;
  endTime: Date;
  subContractor: string;
  startTripStop: string;
  endTripStop: string;
  driveOrderStartTime: Date;
  orderReason: string;
  vehicleType: string;
  vehicleTypeEnum: VehicleTypeEnum;
  isActivated: boolean;
  isActionNeeded: boolean;
  orderType: OrderType;
  type: string;
  driverFirstName: string;
  driverLastName: string;
  driverPhoneNumber: string;
  operator: string;
  comment: string;
  // not from api:
  orderUrl: string;
  orderTypeShortString: string;
}


// ViewModel for Order view
export class OrderRouteVariant {
  id: number = null;
  name = '';
  isAdapted = false;
  activeRouteStopPlaceIds: string[] = null;
  inactivatedRouteStopPlaceIds: string[] = [];
  disembarkingOnlyRouteStopPlaceIds: string[] = [];
  isChanged = false;
}

export class OrderTrips {
  totalItems: number;
  items: OrderTripsPageResult[];
}

export class OrderWithQueryParm extends Order {
  queriedDriveOrderId: number;
  queriedStartPassTime: Date;
  queriedTripId: number;
}

export const OP_MTR = 1124;
export const OP_KRÖSATÅG = 1156;

export class OrderRoute {
  id: number;
  routeName: string;
  routeDescription: string;
  routeVariantId: number;
  routeVariantName: string;
  orderRouteStops: OrderRouteStop[];
}

export class OrderRouteStop {
  id: number;
  placeName: string;
  placeDescription: string;
  sequence: number;
  isActive: boolean;
  disembarkingOnly: boolean;
}

export class OrderReason {
  id: number;
  description: string;
  additionalInfo: string;
}

export class OrderForMenu {
  id: number;
  isHastusPlanned: boolean;
  orderRouteName: string;
  constructor(id: number, isHastusPlanned: boolean, routename: string) {
    this.id = id;
    this.isHastusPlanned = isHastusPlanned;
    this.orderRouteName = routename;
  }
}

export class EarliestReturnTime {
  returnStartTime: Date;
}

export enum OrderActionState {
  None = 0,
  ActionNeeded = 1,
  Ongoing = 2
}

