import { Component, OnInit } from '@angular/core';
import { OperatorService } from '@services/operator.service';
import { Operator } from '@models/operator';
import { SubcontractorService } from '@services/subcontractor.service';
import { Subcontractor } from '@models/subcontractor';
import { OrderByService } from '@services/order-by.service';

@Component({
  selector: 'app-manage-subcontractors',
  templateUrl: './manage-subcontractors.component.html',
  styleUrls: ['./manage-subcontractors.component.scss']
})
export class ManageSubcontractorsComponent implements OnInit {
  subcontractors: Subcontractor[] = [];
  operators: Operator[];
  filteredSubcontractors: Subcontractor[] = [];

  constructor(private subcontractorService: SubcontractorService,
              private operatorService: OperatorService,
              private orderByService: OrderByService) { }

  ngOnInit(): void {
    this.getOperators();
    this.getSubcontractors();
  }

  getSubcontractors() {
    this.subcontractorService.getSubcontractors()
      .subscribe(subcontractors => {
        this.subcontractors = this.orderByService.transform(subcontractors.body, 'name');
        this.filteredSubcontractors = this.subcontractors;
      });
  }

  getOperators() {
    this.operatorService.getOperators(false)
      .subscribe(operators => {
        this.operators = this.orderByService.transform(operators.body, 'name');
      });
  }

  setFilteredSubcontractors(selectedOperatorsIds: number[]) {
    if (selectedOperatorsIds.length === 0) {
      this.filteredSubcontractors = this.subcontractors;
    } else {
      this.filteredSubcontractors = this.subcontractors.filter(subcontractor => subcontractor.operators
        .find(operator => selectedOperatorsIds.includes(operator.id)));
    }
  }

}
