<div class="container">

  <div class="main">

    <app-order-menu [orderId]="unboundOrder.id" [isUnboundOrder]="true"></app-order-menu>

    <hr class="mt-0">

    <h2 class="mt-4" i18n="@@unbound-order-assignment.assignment">Tilldelning</h2>

    <h3 *ngIf="!isActivated" class="mt-4 mb-5">
      {{ orderNotActivatedMessage }}
    </h3>

    <div id="unboundOrderActivated" *ngIf="isActivated">
      <form [formGroup]="unboundOrderAssignmentForm" novalidate class="form mt-4">
        <div class="sendemail" *ngIf="userHasAnyRole([UserRoles.NobinaTrafikledare,UserRoles.NobinaLeveransansvarig,UserRoles.BergkvaraTrafikledare,UserRoles.BergkvaraTrafikledare])">
          <h2 class="mt-4" i18n="@@unbound-order-assignment.sendEmail">Skicka beställningsunderlag</h2>
          <div class="col-6">
            <label for="email" i18n="@@unbound-order-assugnment.emailAdress">Ange e-postadress:</label>
            <input id="email" class="form-control" formControlName="email" type="text" #email email.value ='' placeholder="Frivilligt" i18n-placeholder="@@unbound-order-assignment.placeholder-voluntary">
            <div class="form-check checkbox mt-2">
              <input id="checkbox" class="form-check-input" type="checkbox" (change)="toggleRequest($event)">
              <label class="form-check-label" for="checkbox" i18n="@@unbound-order-request"> Bifoga övriga önskemål </label>
            </div>
          </div>
          <button id="send-btn" class="btn btn-primary" [disabled]="!email.value" (click)="[confirmEmail(unboundOrder.id, email.value), email.value='']" i18n="@@unbound-order-send-btn">Skicka</button>
        </div>
      </form>
    </div>

    <div id="unboundOrderActivated" *ngIf="isActivated">
      <form [formGroup]="unboundOrderAssignmentForm" (ngSubmit)="save()" novalidate class="form mt-4">
        <div class="mb-3">
          <div class="col-6">
            <label for="contractor" i18n="@@unbound-order-assignment.contractor">Utförare</label>
            <input class="form-control" type="text" placeholder="Obligatoriskt" id="contractor" formControlName="contractor" i18n-placeholder="@@unbound-order-assignment.placeholder-mandatory">
            <div *ngIf="(contractor.dirty || contractor.touched) && contractor.invalid" class="text-danger" i18n="@@unbound-order-assignment.contractor-mandatory">
              Utförare måste anges
            </div>
          </div>
        </div>
        <div class="mb-3">
          <div class="col-6">
            <label for="contactInfo" i18n="@@unbound-order-assignment.contact-info">Kontaktuppgifter</label>
            <input class="form-control" type="text" placeholder="Frivilligt" id="contactInfo" formControlName="contactInfo" i18n-placeholder="@@unbound-order-assignment.placeholder-voluntary">
          </div>
        </div>
        <div class="mb-3">
          <div class="col-6">
            <label for="bookingReference" i18n="@@unbound-order-assignment.booking-reference">Bokningsnummer</label>
            <input class="form-control" type="text" placeholder="Frivilligt" id="bookingReference" formControlName="bookingReference" i18n-placeholder="@@unbound-order-assignment.placeholder-voluntary">
          </div>
        </div>

        <div class="mb-3">
          <div class="col-6">
            <label for="additionalInfo" i18n="@@unbound-order-assignment.additional-info">Övriga kommentarer</label>
            <textarea class="form-control" type="text" placeholder="Frivilligt" id="additionalInfo" formControlName="additionalInfo" i18n-placeholder="@@unbound-order-assignment.placeholder-voluntary"></textarea>
          </div>
        </div>

        <div class="justify-content-start mt-5">
          <div *ngIf="userHasAnyRole([UserRoles.NobinaTrafikledare,UserRoles.NobinaLeveransansvarig,UserRoles.BergkvaraTrafikledare])" class="col-3">
            <button id="save-btn" class="btn btn-primary" type="submit" [disabled]="!unboundOrderAssignmentForm.dirty" i18n="@@unbound-order-assignment.save">Spara uppgifter</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
