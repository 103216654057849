import { Driver } from './driver';
import { Vehicle } from './vehicle';
import { StateObject } from './state-object';
import { SubcontractorVacantType } from './subcontractor';

export class DriveOrder {
  id: number;
  orderId: number;
  externalId: string;
  startTripStop: TripStopSummary;
  endTripStop: TripStopSummary;
  trips: DriveOrderTrip[];
  assignment: DriveOrderAssignment;
  currentState: DriveOrderStateObject;
  activated: DriveOrderStateObject;
  completed: DriveOrderStateObject;
  reportedComplete: DriveOrderStateObject;
  isReadyToReportCompleted: boolean;
  tripCount: number;
}

export class DriveOrderTrip {
  state: DriveOrderTripStateObject;
  checkedIn: DriveOrderTripStateObject;
  id: number;
  routeName: string;
  routeVariantName: string;
  tripStops: TripStop[];
  sequence: number;
  startTripStop: TripStopSummary;
  endTripStop: TripStopSummary;
  isReadyToCheckin: boolean;
  trainNumber: string;
  passengerCount: number;
  externalTripId: string;
  driveOrderTripAssignment: DriveOrderTripAssignment;
  cancellationDetails?: CancellationDetails;
}

export class CancellationDetails {
  reasonEnum: number;
  additionalInfo?: string;
}

export class DriveOrderTripRoute {
  routeName: string;
  routeVariantId: number;
  routeVariantName: string;
}

export class TripStop {
  id: number;
  placeName: string;
  placeDescription: string;
  sequence: number;
  distanceKmFromPrev: number;
  passTime: Date;
  vehicleAtStopEnter: Date;
  vehicleAtStopExit: Date;
  disembarkingOnly: boolean;
  note: string;
  travellerNote: string;
}

export class TripStopSummary {
  placeDescription: string;
  passTime: Date;
  orderedTime: Date;
}

export class DriveOrderAssignment {
  assignedVehicle: Vehicle;
  assignedSubcontractorId: number;
  assignedSubcontractorOrderId: number;
  assignedSubcontractorName: string;
  assignedSubcontractorCode: string;
  assignedSubcontractorVacantType: SubcontractorVacantType;
}

export class DriveOrderTripAssignment {
  assignedDriver: Driver;
}

export class DriveOrderAssigned {
  assignedDriveOrderId: number;
  assignedOrderId: number;
}

export enum DriveOrderState {
  Created = 1,
  OrderedFromNobina = 2,
  AssignedToDriverAndVehicle = 3,
  Activated = 4,
  ReportedComplete = 5,
  Completed = 6,
  Cancelled = 7
}

export const DriveOrderStateLabel = new Map<number, string>([
  [DriveOrderState.Created, 'Skapad'],
  [DriveOrderState.OrderedFromNobina, 'Beställd'],
  [DriveOrderState.AssignedToDriverAndVehicle, 'Tilldelad förare och fordon'],
  [DriveOrderState.Activated, 'Aktiverad'],
  [DriveOrderState.ReportedComplete, 'Klarrapporterad'],
  [DriveOrderState.Completed, 'Avslutad'],
  [DriveOrderState.Cancelled, 'Avbeställd']
]);

export class DriveOrderStateObject extends StateObject {


  public constructor(init?: Partial<DriveOrderStateObject>) {
    super(init);
    Object.assign(this, init);
  }
}

export enum DriveOrderTripState {
  Created = 1,
  CheckedIn = 2,
  Completed = 3,
  OrderedFromNobina = 4,
  Cancelled = 5
}

export const DriveOrderTripStateLabel = new Map<number, string>([
  [DriveOrderTripState.Created, 'Skapad'],
  [DriveOrderTripState.CheckedIn, 'Incheckad'],
  [DriveOrderTripState.Completed, 'Avslutad'],
  [DriveOrderTripState.OrderedFromNobina, 'Beställd'],
  [DriveOrderTripState.Cancelled, 'Avbeställd']
]);

export class DriveOrderTripStateObject extends StateObject {

  public constructor(init?: Partial<DriveOrderTripStateObject>) {
    super(init);
    Object.assign(this, init);
  }
}

export class DriveOrderStateRequest {
  State: DriveOrderState;
  StateChangeTime: Date;
  Latitude: number;
  Longitude: number;
}

export interface DriveOrderStartTimeRequest {
  startTime: Date;
}
