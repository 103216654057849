import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Order, OrderForMenu } from '@models/order';
import { UserRoles } from '@authentication/user-roles';
import { AuthService } from '@services/auth.service';
import { SessionStorage } from '@common/sessionStorage.decorator';
import { OrderService } from '@services/order.service';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { UnboundOrderService } from '@services/unbound-order.service';

@Component({
  selector: 'app-order-menu',
  templateUrl: './order-menu.component.html',
  styleUrls: ['./order-menu.component.scss'],
})
export class OrderMenuComponent implements OnInit, OnChanges {

  @Input() orderId: number;
  @Input() isUnboundOrder: boolean;

  isHistory: boolean;
  UserRoles = UserRoles;

  @SessionStorage
  ordersForMenu: string;

  ordersForMenuCollection: OrderForMenu[];
  order: OrderForMenu; // Required for menu component
  enableUnboundOrder = environment.enableUnboundOrder;


  constructor(
    private authService: AuthService,
    private orderService: OrderService,
    private unboundOrderService: UnboundOrderService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.isHistory = this.router.url.startsWith('/history');
    this.isUnboundOrder = false;
    if (this.router.url.indexOf('unbound') > 0) {
      this.isUnboundOrder = true;
    }
    this.setupMenu();
  }

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    if (changes.orderId) {
      this.ngOnInit();
    }
  }

  setupMenu() {
    this.ordersForMenuCollection = [];
    if (this.ordersForMenu && this.ordersForMenu !== '' && this.ordersForMenu !== 'undefined') {
      this.ordersForMenuCollection = JSON.parse(this.ordersForMenu);
    }
    this.order = this.ordersForMenuCollection.find(x => x.id === this.orderId);
    if (this.isUnboundOrder) {
      if (this.order) {
        this.order.orderRouteName = $localize`:FRI@@order-menu.order-route-name:FRI`;
      }
    }

    // Om man kommer via direktlänk finns det en risk att ordern inte har hämtats.
    if (this.orderId > 0 && (!this.order)) {
      if (this.isUnboundOrder) {
        this.unboundOrderService.getOrder(this.orderId).subscribe(order => {
          this.order = new OrderForMenu(order.body.id, false, $localize`:FRI@@order-menu.order-route-name:FRI`);
          this.ordersForMenuCollection.push(this.order);
          this.ordersForMenu = JSON.stringify(this.ordersForMenuCollection);
        });
      } else {
        this.orderService.getOrder(this.orderId).subscribe(order => {
          this.order = new OrderForMenu(order.body.id, order.body.isHastusPlanned, order.body.orderRoute.routeName);
          this.ordersForMenuCollection.push(this.order);
          this.ordersForMenu = JSON.stringify(this.ordersForMenuCollection);
        });

      }
    }
  }

  isActive(link: string, id: number): boolean {
    if (!id) {
      return false;
    }

    const path = link.replace('?', id.toString());
    if (window.location.pathname.match(path)) {
      return true;
    }
    return false;
  }

  getLink(partLink: string) {
    if (this.isHistory && this.isUnboundOrder) {
      return '/history/unboundorder/' + partLink;
    }
    if (this.isHistory && !this.isUnboundOrder) {
      return '/history/' + partLink;
    }
    if (this.isUnboundOrder) {
      return '/unboundorder/' + partLink;
    }
    return '/order/' + partLink;
  }
  
  navigateToDriveOrder() {
    if (this.isHistory) {
      window.location.href = `${window.location.origin}/order-new/history/${this.orderId.toString()}/drive-order`;
    } else {
      window.location.href = `${window.location.origin}/order-new/order/${this.orderId.toString()}/drive-order`;
    }
  }

  userHasAnyRole(userRoles: UserRoles[]) {
    return this.authService.userHasAnyRole(userRoles);
  }
}
