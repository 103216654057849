import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrderCardListComponent } from '@orders/order-card-list/order-card-list.component';
import { OrderComponent } from '@orders/order/order.component';
import { HastusOrderComponent } from '@orders/order/hastus-order/hastus-order.component';
import { OrderConfirmationComponent } from '@orders/order/order-confirmation/order-confirmation.component';
import { SubcontractorsComponent } from '@orders/order/subcontractors/subcontractors.component';
import { AvailabilityInquiryComponent } from '@orders/subcontractor/availability-inquiry/availability-inquiry.component';
import { GooglemapComponent } from './googlemap/googlemap.component';
import { RouteMapComponent } from '@orders/order/route-map/route-map.component';
import { SubcontractorOrderComponent } from '@orders/subcontractor/subcontractor-order/subcontractor-order.component';
import { OrderLogComponent } from '@orders/order/order-log/order-log.component';
import { SubcontractorRouteMapComponent } from '@orders/subcontractor/subcontractor-order/subcontractor-route-map/subcontractor-route-map.component';
import { ManageOperatorsComponent } from './admin/manage-operators/manage-operators.component';
import { OutcomeListComponent } from './billing/outcome-list/outcome-list.component';
import { OutcomeComponent } from './billing/outcome/outcome.component';
import { UnboundorderComponent } from '@orders/unboundorder/unboundorder.component';
import { UnboundOrderConfirmationComponent } from '@orders/unboundorder/unbound-order-confirmation/unbound-order-confirmation.component';
import { ManageSubcontractorsComponent } from './admin/manage-subcontractors/manage-subcontractors.component';
import { UnboundOrderListComponent } from '@orders/unbound-order-list/unbound-order-list.component';
import { UnboundOrderAssignmentComponent } from '@orders/unboundorder/unbound-order-assignment/unbound-order-assignment.component';
import { UnboundOrderCompletionComponent } from '@orders/unboundorder/unbound-order-completion/unbound-order-completion.component';
import { UnboundOrderLogComponent } from '@orders/unboundorder/unbound-order-log/unbound-order-log.component';
import { VersionComponent } from './admin/version/version.component';
import { CreateReportComponent } from './billing/reports/create-report/create-report.component';
import { ImportOrderComponent } from './admin/import-order/import-order.component';
import { ConnectRoutevariantComponent } from './admin/connect-routevariant/connect-routevariant.component';
import { ImportTimetableComponent } from './admin/import-timetable/import-timetable.component';
import { NotificationsComponent } from '@common/components/notifications/notifications.component';
import { PositionHistoryMapComponent } from '@orders/order/position-history-map/position-history-map.component';
import { OrderListComponent } from '@orders/order-list/order-list.component';
import { MissingVehicleStopEventsComponent } from './admin/missing-vehicle-stop-events/missing-vehicle-stop-events.component';
import { SubcontractorComponent } from '@orders/subcontractor/subcontractor/subcontractor.component';
import { SubcontractorHistoryComponent } from '@orders/subcontractor/subcontractor-history/subcontractor-history.component';
import { MsalGuard } from '@azure/msal-angular';
import { LoginFailedComponent } from './login-failed/login-failed.component';
import { roleGuardFn } from '@services/role-guard-fn';
import { ClearComponent } from './clear/clear.component';

// OBS det verkar vara lite lurigt med flera canActivate-guards. Se
// https://stackoverflow.com/questions/40589878/multiple-canactivate-guards-all-run-when-first-fails
// det verkar som om en enda canActivate som returnerar Observable<boolean> verkar funka bäst.
const routes: Routes = [
  { path: 'clear', component: ClearComponent},
  { path: 'orders', component: OrderListComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'orders/ordercard', component: OrderCardListComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'orders/orderlist', component: OrderListComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'order/:id', component: OrderComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'order/planned-order/:id', component: HastusOrderComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'order', component: OrderComponent, canActivate: [MsalGuard] },
  { path: 'order-confirmation/:id', component: OrderConfirmationComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'order/:id/subcontractors', component: SubcontractorsComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'order/:id/route-map', component: RouteMapComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'order/:id/trip/:tripid/position-history-map', component: PositionHistoryMapComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'order/:id/log', component: OrderLogComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'subcontractor/:subcontractorId/availability-inquiries', component: SubcontractorComponent, canActivate: [MsalGuard] },
  { path: 'subcontractor/:subcontractorId/availability-inquiry/:id', component: AvailabilityInquiryComponent, canActivate: [MsalGuard] },
  { path: 'subcontractor/:subcontractorId/subcontractor-orders', component: SubcontractorComponent, canActivate: [MsalGuard] },
  { path: 'subcontractor/:subcontractorId/subcontractor-orders/:id', component: SubcontractorOrderComponent, canActivate: [MsalGuard] },
  { path: 'subcontractor/:subcontractorId/subcontractor-orders/:id/subcontractor-route-map', component: SubcontractorRouteMapComponent, canActivate: [MsalGuard] },
  { path: 'subcontractor/:subcontractorId/assignments/:id', component: AvailabilityInquiryComponent, canActivate: [MsalGuard] },
  // History related routes, usually reuses same component as original route
  { path: 'history/unboundorder', component: UnboundOrderListComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'history/unboundorder/:id', component: UnboundorderComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'history/unboundorder/:id/assignment', component: UnboundOrderAssignmentComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'history/unboundorder/:id/completion', component: UnboundOrderCompletionComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'history/unboundorder/:id/log', component: UnboundOrderLogComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'history/order/:id/trip/:tripid/position-history-map', component: PositionHistoryMapComponent, canActivate: [MsalGuard] },
  { path: 'history', component: OrderCardListComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'history/:id', component: OrderComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'history/planned-order/:id', component: HastusOrderComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'history/:id/subcontractors', component: SubcontractorsComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'history/:id/route-map', component: RouteMapComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'history/:id/log', component: OrderLogComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'subcontractor/:subcontractorId/history', component: SubcontractorHistoryComponent, canActivate: [MsalGuard] },
  { path: 'subcontractor/:subcontractorId/history/:id', component: SubcontractorOrderComponent, canActivate: [MsalGuard] },
  { path: 'subcontractor/:subcontractorId/history/:id/subcontractor-route-map', component: SubcontractorRouteMapComponent, canActivate: [MsalGuard] },
  { path: 'subcontractor/:subcontractorId/assignment-history/:id', component: AvailabilityInquiryComponent, canActivate: [MsalGuard] },
  // Billing
  { path: 'billing/outcomes', component: OutcomeListComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'billing/outcomes/:id', component: OutcomeComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'billing/reports/create-report', component: CreateReportComponent, canActivate: [MsalGuard, roleGuardFn] },
  // Unbound order (Friorder)
  { path: 'unboundorder', component: UnboundorderComponent, canActivate: [MsalGuard] },
  { path: 'unbound-order-confirmation/:id', component: UnboundOrderConfirmationComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'unboundorder/:id', component: UnboundorderComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'unboundorder/:id/assignment', component: UnboundOrderAssignmentComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'unboundorder/:id/completion', component: UnboundOrderCompletionComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'unboundorders', component: UnboundOrderListComponent, canActivate: [MsalGuard] },
  { path: 'unboundorder/:id/log', component: UnboundOrderLogComponent, canActivate: [MsalGuard, roleGuardFn] },
  // Special routes
  { path: 'notifications', component: NotificationsComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'usersettings', component: ManageOperatorsComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'settings/subcontractors', component: ManageSubcontractorsComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'settings/import-order', component: ImportOrderComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'settings/import-timetable', component: ImportTimetableComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'settings/connect-routevariant', component: ConnectRoutevariantComponent, canActivate: [MsalGuard, roleGuardFn] },
  { path: 'settings/missingVehicleStopEvents', component: MissingVehicleStopEventsComponent },
  { path: 'version', component: VersionComponent },
  { path: 'googlemap', component: GooglemapComponent },
  { path: 'subcontractor/:subcontractorId', component: SubcontractorComponent, canActivate: [MsalGuard] },
  { path: 'login-failed', component: LoginFailedComponent },
  { path: '', redirectTo: '/orders', pathMatch: 'full' },
  { path: '**', redirectTo: '/orders' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
