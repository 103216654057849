<form [formGroup]="rowform">
    <input type="hidden" formControlName="outcomeActionId">
    <div class="d-flex triprow" *ngIf="activity !== null">
        <div class="outcome-details-small-column-width">
            {{getTypeDescription(activity)}}
        </div>
        <div class="text-truncate outcome-details-large-column-width"
              data-toggle="tooltip" 
              data-placement="top" 
              title="{{activityData.startTripStop?.placeDescription}}">
            {{activityData.startTripStop?.placeDescription}}
        </div>
        <div *ngIf="editMode" class="outcome-details-small-column-width">
            <input type="time" formControlName="startTime" />
        </div>
        <div *ngIf="!editMode" class="outcome-details-small-column-width">
            <span [ngClass]="{'revised-old': isStartTimeRevisedAndNotSameAsExisting}">
              {{activityData?.startTripStop?.time | amDateFormat:'LT'}}&nbsp;
            </span>
            <span *ngIf="isStartTimeRevisedAndNotSameAsExisting">&nbsp;</span>
            <span class="red-text" *ngIf="isStartTimeRevisedAndNotSameAsExisting">
              {{revisedActivityData?.startTime | amDateFormat:'LT'}}
            </span>
        </div>
        <div class="text-truncate outcome-details-large-column-width"
              data-toggle="tooltip" 
              data-placement="top" 
              title="{{activityData.endTripStop?.placeDescription}}">
          {{activityData.endTripStop?.placeDescription}}
        </div>
        <div *ngIf="editMode" class="outcome-details-small-column-width">
            <input type="time" formControlName="endTime" />
        </div>
        <div *ngIf="!editMode" class="outcome-details-small-column-width">
            <span [ngClass]="{'revised-old': isEndTimeRevisedAndNotSameAsExisting}">
              {{activityData?.endTripStop?.time | amDateFormat:'LT'}}&nbsp;
            </span>
            <span *ngIf="isEndTimeRevisedAndNotSameAsExisting">&nbsp;</span>
            <span class="red-text" *ngIf="isEndTimeRevisedAndNotSameAsExisting">
              {{revisedActivityData?.endTime | amDateFormat:'LT'}}
            </span>
        </div>
        <div class="outcome-details-small-column-width">
            <span [ngClass]="{'revised-old': isDurationRevisedAndNotSameAsExisting}" *ngIf="activityData?.durationMinutes >= 60">
              {{ Math.floor((activityData?.durationMinutes / 60)) | amDuration: 'hours'}}&nbsp;
            </span>
            <span [ngClass]="{'revised-old': isDurationRevisedAndNotSameAsExisting}" *ngIf="activityData?.durationMinutes % 60 >= 1">
              {{(activityData?.durationMinutes % 60) }} min </span>
            <span *ngIf="isDurationRevisedAndNotSameAsExisting">&nbsp;</span>
            <span class="red-text" *ngIf="isDurationRevisedAndNotSameAsExisting && (revisedActivityData?.duration >= 60)">
              {{ Math.floor((revisedActivityData?.duration / 60)) | amDuration: 'hours'}}&nbsp;
            </span>
            <span class="red-text" *ngIf="isDurationRevisedAndNotSameAsExisting && (revisedActivityData?.duration % 60 >= 1)">
              {{(revisedActivityData?.duration % 60) }} min
            </span>
          </div>
        <div *ngIf="editMode" class="outcome-details-small-column-width">
            <span><input type="text" formControlName="distance"
                (blur)="handleNumericInputEmpty($event)"
                (keydown)="handleNumericInputDown($event)"
                (keyup)="handleNumericInputUp($event)"
                pattern="[0-9]+([@.|@,][0-9]+)?" #distanceElement>km</span>
        </div>
        <div *ngIf="!editMode" class="outcome-details-small-column-width">
            <span [ngClass]="{'revised-old': isDistanceRevisedAndNotSameAsExisting}">
              {{activityData?.distanceKm}} km ({{activityData?.distanceSource}})
            </span>
            <span *ngIf="isDistanceRevisedAndNotSameAsExisting">&nbsp;</span>
            <span class="red-text" *ngIf="isDistanceRevisedAndNotSameAsExisting">
              {{revisedActivityData?.distance}} km
            </span>
        </div>
        <div *ngIf="editMode && activity.activityType.key === activityTypeEnum.Trip" class="outcome-details-medium-column-width">
          <span>
            <input type="text" formControlName="passengers" #passengerElement>st
          </span>
        </div>
        <div *ngIf="!editMode && activity.activityType.key === activityTypeEnum.Trip" 
              class="outcome-details-medium-column-width">
          <span [ngClass]="{'revised-old': isPassengersRevisedAndNotSameAsExisting}">
            {{ passengers.registeredCount !== null ? passengers.registeredCount + " st" : null }}
          </span>
          <span *ngIf="isPassengersRevisedAndNotSameAsExisting">&nbsp;</span>
          <span class="red-text outcome-details-medium-column-width" *ngIf="isPassengersRevisedAndNotSameAsExisting">
            {{ revisedActivityData?.passengers !== null ? revisedActivityData?.passengers + " st" : null }}
          </span>
        </div>
        <div *ngIf="activity.activityType.key !== activityTypeEnum.Trip" class="outcome-details-medium-column-width">

        </div>
        <div *ngIf="!editMode"
              class="text-truncate outcome-details-medium-column-width" 
              title="{{activity.revisionComment}}">
            {{activity.revisionComment}}
        </div>
        <div *ngIf="editMode" class="outcome-details-medium-column-width">
            <input type="text" formControlName="comment" />
        </div>
        <div class="trip-state-cancelled outcome-details-small-column-width" [class.trip-state-cancelled]="isTripCancelled">
            <span *ngIf="isTripCancelled" i18n="@@outcome-trip.statusCancelled">Avbeställd</span>
            <span *ngIf="!isTripCancelled" i18n="@@outcome-trip.statusCompleted">Avslutad</span>
        </div>
        
        <div class="cancellation-reason-tooltip-container">
          <img 
            id="info-circle.svg" 
            class="cancellation-reason-icon" 
            src="/assets/img/icons/info-circle.svg" 
            alt="Info Icon" width="20" height="20"
            [class.cancellation-reason-icon-disabled]="!showCancellationReasonIcon">
          <span class="cancellation-reason-tooltip">{{cancellationReasonInfo}}</span>
        </div>
    </div>
</form>
