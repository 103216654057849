
export enum UserRoles {
    NobinaTrafikledare = 26,
    NobinaLeveransansvarig = 27,
    OperatorTrafikledare = 25,
    UETrafikledare = 29,
    UEForare = 30,
    NobinaForare = 28,
    BergkvaraTrafikledare = 37
}
