import { Component, OnInit, Input, HostListener } from '@angular/core';
import { OrderService } from '../../../common/services/order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Order } from '../../../common/models/order';
import { OPERATOR_ORGID_MTR_PENDEL, OPERATOR_ORGID_MTR_TUNNELBANA, OPERATOR_ORGID_TÅGKOMPANIET_KRÖSA, OPERATOR_ORGID_GO_AHEAD } from '../../../common/models/operator';

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.scss']
})
export class OrderConfirmationComponent implements OnInit {
  @Input() order: Order;
  orderId = '';
  referenceId = '';
  KEY_ENTER = 13;
  KEY_SPACE = 32;

  isCtlMitt = false;
  isCtlSyd = false;
  isCtlVast = false;
  isCtlNorge = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private orderService: OrderService) { }

  ngOnInit() {
    const id = +this.route.snapshot.paramMap.get('id');
    this.orderService.getOrder(id)
      .subscribe(order => this.onOrderRetrieved(order.body));
  }

  onOrderRetrieved(order: Order): void {
    this.order = order;
    this.orderId = order.id + '';
    this.referenceId = order.referenceId;

    // CTL Norge hanterar go ahead
    this.isCtlNorge = (this.order.operator.operatorOrganisationId === OPERATOR_ORGID_GO_AHEAD);

    if (!this.isCtlNorge) {
    // CTL Mitt hanterar MTR
    this.isCtlMitt = (this.order.operator.operatorOrganisationId === OPERATOR_ORGID_MTR_PENDEL
      || this.order.operator.operatorOrganisationId === OPERATOR_ORGID_MTR_TUNNELBANA);

    // CTL Syd hanterar 1156    XSSOTK    Tågkompaniet Krösa
    this.isCtlSyd = (this.order.operator.operatorOrganisationId === OPERATOR_ORGID_TÅGKOMPANIET_KRÖSA);

    // CTL Väst hanterar alla övriga
    this.isCtlVast = !(this.isCtlMitt || this.isCtlSyd || this.isCtlNorge);
    }
  }

  navigateToOrder() {
      this.router.navigateByUrl('/order/' + this.orderId);
  }
}
