import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class JsonInterceptor implements HttpInterceptor {

  locale: string;

  constructor() {
    this.locale = environment.language;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Set headers för alla utgående anrop, accept-language och authorization
    // req.headers.set('accept-language', this.locale);
    let newReq;

    if (!(req.body instanceof FormData)) {
      newReq = req.clone({
        headers: req.headers.set('Content-Type', 'application/json').set('accept-language', this.locale),
      });
    } else {
      newReq = req.clone({
        headers: req.headers.set('accept-language', this.locale),
      });
    }

    return next.handle(newReq).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          const resp: HttpResponse<any> = event;

          // Mutate the body, replace ASP.NET Dates with actual local Date objects.
          const body: {} = resp.body;
          this.recurseBody(body);
        }
      }));
  }

  private recurseBody(body: {}) {
    if (!body) {
      return;
    }

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        const element = body[key];
        if (typeof element === 'object') {
          this.recurseBody(element);
        } else if (typeof element === 'string') {
          // Try to parse as date
          body[key] = this.reviveUtcDate(element);
        }
      }
    }
  }

  private reviveUtcDate(value: any): any {
    if (value === '0001-01-01T00:00:00+00:00') {
      return null;
    }
    const match = /^[+-]?\d{4}(-[01]\d(-[0-3]\d(T[0-2]\d:[0-5]\d:([0-5]\d(\.\d+)?)?([+-][0-2]\d:[0-5]\d)?Z?)))$/.exec(value);
    if (!match || ('' + value).length < 10) {
      return value;
    }

    return new Date(value);

  }
}
