<ng-template #longContent let-modal>
  <div class="modal-header px-4">
    <h1 class="modal-title">{{subcontractor.name}}</h1>
  </div>
  <div class="modal-body px-4">

    <p i18n="@@manage-subcontractors.subcontractors">Hantera operatörer</p>
    <div *ngFor="let operator of editedSubcontractor?.operators; index as i" class='button-container'>
      <label>
        <button
          id="manage-subcontractors.btn-selected"
          type="button"
          class="small-button active me-3"
          data-toggle="button"
          aria-pressed="true"
          i18n="@@manage-subcontractors.btn-selected"
          (click)="onRemove(operator)"
        >
          Vald
        </button>
        {{operator.name}}
      </label>
    </div>

    <hr *ngIf='editedSubcontractor?.operators.length > 0 && filteredOperators.length > 0'/>

    <div *ngFor="let operator of filteredOperators; index as i" class='button-container'>
      <label>
        <button
          id="manage-subcontractors.btn-add"
          type="button"
          class="small-button me-3"
          data-toggle="button"
          aria-pressed="false"
          i18n="@@manage-subcontractors.btn-add"
          (click)="onAdd(operator)"
        >
          Tillsätt
        </button>
        {{operator.name}}
      </label>
    </div>

  </div>
  <div class="modal-footer p-4">
    <button
      id="manage-subcontractors.btn-cancel"
      type="button"
      class="btn btn-outline-primary"
      i18n="@@manage-subcontractors.btn-cancel"
      (click)="
            modal.close('Close click');
            onClose();"
    >
      Avbryt
    </button>
    <button
      id="manage-subcontractors.btn-save"
      type="button"
      class="btn btn-primary"
      i18n="@@manage-subcontractors.btn-save"
      (click)="
            modal.close('Close click');
            onSave();"
    >
      Spara
    </button>
  </div>
</ng-template>
<button
  id="manage-subcontractors.btn-operator"
  type="button"
  class="btn btn-link show"
  style="width: auto; padding: 0; border: none;"
  i18n="@@manage-subcontractors.btn-operator"
  (click)="openScrollableContent($event, longContent)"
>
  Operatörer
</button>
<a i18n='@@manage-subcontractors.btn-handle'
   *ngIf="showGarageAdminLink"
   class='btn btn-link show'
   style='width: auto; padding: 0; border: none; margin-left: 10px;'
   href='garage/{{subcontractor.id}}/users'>Användare</a>
