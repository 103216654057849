import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OutcomeService } from '../../../../common/services/outcome.service';
import { OutcomeDriveOrderState } from '../../../../common/models/outcome-state';
import { OutcomeDriveOrderStateRequest } from '../../../../common/models/outcome';

@Component({
  selector: 'app-reject-driveorder',
  templateUrl: './reject-driveorder.component.html',
  styleUrls: ['./reject-driveorder.component.scss']
})
export class RejectDriveOrderComponent implements OnInit {

  @Input() outcomeDriveOrderId: number;
  @Input() rejectionComment: string;

  buttonsDisabled = false;

  constructor(public activeModal: NgbActiveModal, private outcomeService: OutcomeService) { }

  ngOnInit() {
  }

  reject() {
    this.buttonsDisabled = true;
    const request: OutcomeDriveOrderStateRequest = { State: OutcomeDriveOrderState.NotApprovedByNobina, comment: this.rejectionComment };
    this.outcomeService.putOutcomeDriveOrderState(this.outcomeDriveOrderId, request)
      .subscribe(response => this.activeModal.close(),
      err => {
         this.activeModal.dismiss();
      });
  }

}
