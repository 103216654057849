// Base class for StateObject (value object in tbums domain)
// Extend and replace StateEnum with actual enum for given type.
export class StateObject {
  updatedByUser: string;
  stateEnum: number;
  stateDescription: string;
  updated: Date;

  public constructor(init?: Partial<StateObject>) {
    Object.assign(this, init);
}
}
