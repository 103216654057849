import { OrderType } from './order-type';
import { OrderState } from './order-state';
import { DateRange } from './dateRange';

export class OrderSearch {

  orderTypes: OrderType[] = [];
  orderStates: OrderState[] = [];
  dateRange: DateRange;
  orderIds: number[] = [];
  referenceIds: string[] = [];
  driveOrderIds: number[] = [];
  operators: number[] = [];
  externalIds: string[] = [];
  tripIds: number[] = [];
  trainNumber: string ="";
  subContractor: string ="";
  pageSize: number;
  page: number;
}
