import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import {
  DriveOrder,
  DriveOrderTrip,
  DriveOrderStateRequest,
  DriveOrderTripRoute,
  DriveOrderAssigned,
  DriveOrderStartTimeRequest,
} from '@models/drive-order';
import {
  AssignSubcontractorToDriveOrderRequest,
  AssignDriveOrderRequest,
  AssignDriverToDriveOrderTripRequest,
  AssignVehicleToDriveOrderRequest,
  CancelDriveOrderTripRequest,
} from '@models/assign-drive-order';

@Injectable()
export class DriveOrderService {
  private baseUrl = environment.apiUrl + environment.apiVersionMain + '/driveorders';

  constructor(private http: HttpClient) {
  }

  reviver(key, value): any {
    if (key === 'startTime' || key === 'endTime') {
      return new Date(value);
    }
    return value;
  }

  getDriveOrders(query = ''): Observable<HttpResponse<DriveOrder[]>> {
    return this.http.get<DriveOrder[]>(this.baseUrl + query, { observe: 'response' });
  }

  getDriveOrder(id: number): Observable<HttpResponse<DriveOrder>> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<DriveOrder>(url, { observe: 'response' });
  }

  getDriveOrderTrip(driveOrderId: number, tripId: number): Observable<HttpResponse<DriveOrderTrip>> {
    const url = `${this.baseUrl}/${driveOrderId}/trip/${tripId}`;
    // return this.http.get<DriveOrderTrip>(url, { observe: 'response'});
    return this.http.get<DriveOrderTrip>(url, { observe: 'response' });
  }

  getDriveOrderTripRoutes(orderId: number): Observable<HttpResponse<DriveOrderTripRoute[]>> {
    const url = `${this.baseUrl}/routes?orderId=${orderId}`;
    return this.http.get<DriveOrderTripRoute[]>(url, { observe: 'response' });
  }

  getAssignedDriveOrders(query: string): Observable<HttpResponse<DriveOrderAssigned[]>> {
    const url = `${this.baseUrl}/assigneddriveorders`;
    return this.http.get<DriveOrderAssigned[]>(url + query, { observe: 'response' });
  }

  updateAssignedSubcontractorToDriveOrder(driveOrderId: number, assignSubcontractorToDriveOrderRequest: AssignSubcontractorToDriveOrderRequest):
    Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/${driveOrderId}/subcontractor`;
    return this.http.put<AssignSubcontractorToDriveOrderRequest>(url, assignSubcontractorToDriveOrderRequest, { observe: 'response' });
  }

  postAssignDriverToDriveOrderTrip(driveOrderId: number, assignDriverToDriveOrderRequest: AssignDriverToDriveOrderTripRequest):
    Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/${driveOrderId}/assignments/drivers`;
    return this.http.post<AssignDriverToDriveOrderTripRequest>(url, assignDriverToDriveOrderRequest, { observe: 'response' });
  }

  postAssignVehicleToDriveOrder(driveOrderId: number, assignVehicleToDriveOrderRequest: AssignVehicleToDriveOrderRequest):
    Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/${driveOrderId}/assignments/vehicles`;
    return this.http.post<AssignVehicleToDriveOrderRequest>(url, assignVehicleToDriveOrderRequest, { observe: 'response' });
  }

  postCheckinTrip(subcontractorId, driveOrderId: number, driveOrderTripId):
    Observable<HttpResponse<any>> {
    const checkinRequest = `{"subcontractorId": "${subcontractorId}", "allowForcedCheckIn":  ${false}}`;
    const url = `${this.baseUrl}/${driveOrderId}/trips/${driveOrderTripId}/checkins`;
    return this.http.post<AssignDriveOrderRequest>(url, checkinRequest, { observe: 'response' });
  }

  postCancelTrip(driveOrderId: number, driveOrderTripId: number, reason?: number, comment?: string):
    Observable<any> {
    const url = `${this.baseUrl}/${driveOrderId}/trips/${driveOrderTripId}/cancel`;
    const body = { 'ReasonEnum': reason, 'AdditionalInfo': comment };
    return this.http.post<CancelDriveOrderTripRequest>(url, body);
  }

  putDriveOrderState(driveOrderId: number, driveOrderStateRequest: DriveOrderStateRequest): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/${driveOrderId}/state`;
    return this.http.put(url, driveOrderStateRequest, { observe: 'response' });
  }

  putChangeStartTime(driveorderId: number, startTimeRequest: DriveOrderStartTimeRequest): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/${driveorderId}/startTime`;
    return this.http.put(url, startTimeRequest, { observe: 'response' });
  }

  postNote(driveorderId: number, driveorderTripId: number, noteBody: string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/${driveorderId}/trips/${driveorderTripId}/note`;
    return this.http.put(url, noteBody, { observe: 'response' });
  }

  removeNote(driveorderId: number, driveorderTripId: number, removeNoteBody: string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/${driveorderId}/trips/${driveorderTripId}/note`;
    return this.http.put(url, removeNoteBody, { observe: 'response' });
  }

  addPassengerCount(driveorderId: number, driveorderTripId: number, passengers: number): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/${driveorderId}/trips/${driveorderTripId}/passengerCount`;
    return this.http.put(url, `{"PassengerCount": ${passengers}}`, { observe: 'response' });
  }

  changeTrainNumberForTrip(driveorderId: number, driveorderTripId: number, trainNumber: string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/${driveorderId}/trips/${driveorderTripId}/trainNumber`;
    return this.http.put(url, `{"TrainNumber": ${trainNumber}}`, { observe: 'response' });
  }
}
