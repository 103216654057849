<h1 class="my-5 text-center" i18n="@@missing-vehicle-stop-events.title2">Administration</h1>
<app-admin-menu></app-admin-menu>
<div class="container">
  <div class="main pt-0">
    <h1 i18n="@@missing-vehicle-stop-events.title">Stationer med saknade stop events</h1>

    <p i18n="@@missing-vehicle-stop-events.description">Välj period för att visa hållplatser som saknar matchning av ankomst och avgång</p>
    <app-date-filter (dateRange)="onDateRangeChanged($event)" [dateOrigin]="dateOrigin" [defaultRangeOption]="dateFilterOptionEnum.Today">
    </app-date-filter>

    <p class="mt-3" i18n="@@missing-vehicle-stop-events.action-text">Klicka sedan på en hållplats för att se vilka linjevarianter som missmatchningen gäller.</p>
    <div class="row" *ngIf="missingVehicleStopEventsList">
      <div class="col-6 d-flex align-items-start">
        <table class="table" aria-label="Missing vehicle stop">
          <thead>
            <tr>
              <th scope="col">Place Name</th>
              <th scope="col">Place Description</th>
              <th scope="col">Count</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let tripStop of missingVehicleStopEventsList"
              (click)="getRouteVariantByPlaceName(dateRangeFilter.fromDate, dateRangeFilter.toDate, tripStop.placeName)">
              <td>{{ tripStop.placeName }}</td>
              <td>{{ tripStop.placeDescription }}</td>
              <td>{{ tripStop.count }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-6 d-flex align-items-start">
        <table class="table" aria-label="Missing vehicle stop">
          <thead>
            <tr>
              <th scope="col">Route Variant Id</th>
              <th scope="col">Route Variant Name</th>
              <th scope="col">Count</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let routeVariant of routeByTripStopCount">
              <td>{{ routeVariant.routeVariantId }}</td>
              <td>{{ routeVariant.routeVariantName }}</td>
              <td>{{ routeVariant.count }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
