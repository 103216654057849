<h1 class="my-5 text-center" i18n='@@connect-routevariant.title'>Administration</h1>
<app-admin-menu></app-admin-menu>
<div class='container'>
  <div>
    <div class='ms-5 pb-1'>
      <form [formGroup]='connectForm' (ngSubmit)='connect()' novalidate class='form mt-4'>

        <div class='row'>
          <div class='col-5 mb-4'>
            <label class='mb-2' for='operator' i18n='@@connect-routevariant.select-traffic-operator'>Välj
              trafikoperatör</label>
            <select class='form-select' id='operator' formControlName='operator' (change)='OnChangeOperator()'>
              <option [ngValue]='null' [disabled]='true' i18n='@@connect-routevariant.select'>Välj</option>
              <option *ngFor='let op of operators' [ngValue]='op'>{{ op.name }}</option>
            </select>
            <div *ngIf='operatorCtrl.invalid && (operatorCtrl.dirty || operatorCtrl.touched)' class='text-danger'
                 i18n='@@connect-routevariant.traffic-operator-mandatory'>
              Trafikoperatör är obligatoriskt
            </div>
          </div>
        </div>
        <div class='row'>
          <div class='col-5 mb-4'>
            <label class='mb-2' for='route' i18n='@@connect-routevariant.select-route'>Välj linje</label>
            <select class='form-select form-control' id='route' formControlName='route' (change)='OnChangeRoute()'>
              <option value='null' [disabled]='true' i18n='@@connect-routevariant.select'>Välj</option>
              <option *ngFor='let r of routes' [ngValue]='r'>{{ r.routeName + ' - ' + r.description }}</option>
            </select>
            <div *ngIf='routeCtrl.invalid && (routeCtrl.dirty || routeCtrl.touched)' class='text-danger'
                 i18n='@@connect-routevariant.route-mandatory'>
              Linje är obligatoriskt
            </div>
          </div>
        </div>

        <div class='row'>
          <div *ngFor='let idx of [1,2]' class='col-5'>
            <label>
              <ng-container i18n='@@connect-routevariant.routevariant'>Linjevariant</ng-container>
              {{idx}}
            </label>
            <div class='callout-block-lg'>
              <span class='callout'></span>
              <div class='row'>
                <div class='col'>
                  <div class='caption-lt' i18n='@@connect-routevariant.route'>Linje</div>
                  <div>{{ routeCtrl?.value?.routeName }}</div>
                </div>
                <div class='col'>
                  <div class='caption-lt' i18n='@@connect-routevariant.routevariant'>Linjevariant</div>
                  <div *ngIf='routeVariantId(idx) !== null'>{{ routeVariantName(idx) }}</div>
                  <div *ngIf='routeCtrl?.value?.routeName && routeVariantId(idx) === null'
                       i18n='@@connect-routevariant.not-selected'>Ej vald
                  </div>
                </div>
                <div class='col'>
                  <button type='button' class='small-button' *ngIf='routeCtrl.value !== null'
                          (click)='selectRoute(idx, routeVariantId(idx))' i18n='@@connect-routevariant.select'>Välj
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf='routeVariantChanged(idx) && routeVariantId(idx) === null' class='text-danger'>
              <ng-container i18n='@@connect-routevariant.routevariant'>Linjevariant</ng-container>
              {{idx}}
              <ng-container
                i18n='@@connect-routevariant.routevariant-mandatory'>är obligatorisk
              </ng-container>
            </div>
          </div>
        </div>

        <div class='col-10 mt-4'>
          <button class='btn btn-primary' type='button' [disabled]='buttonsDisabled'
                  (click)='connect()' i18n='@@connect-routevariant.save'>Spara
          </button>
        </div>

      </form>
    </div>
    <hr />
    <div class='pt-4'>
      <h2 i18n='@@connect-routevariant.connections' class='ms-5'>Kopplingar</h2>
      <div class='main'>
        <div class='row'>
          <h3 class='col-1' i18n='@@connect-routevariant.route'>Linje</h3>
          <h3 class='col-5' i18n='@@connect-routevariant.routevariant1'>Linjevariant 1</h3>
          <h3 class='col-4' i18n='@@connect-routevariant.routevariant2'>Linjevariant 2</h3>
          <h3 class='col-2' i18n='@@connect-routevariant.remove-connection'>Ta bort koppling</h3>
        </div>
        <div *ngFor='let routeVariant of routeVariants; let odd = odd' class='row routeVariants py-1' [class.odd]='odd'>
          <div class='col-1'>{{routeVariant.routeName}}</div>
          <div class='col-5'>{{routeVariant.routeVariantName}}</div>
          <div class='col-5'>{{routeVariant.routeVariantConnection?.routeVariantName}}</div>
          <div *ngIf='routeVariant.routeVariantConnection' class='col text-end disconnect'
               (click)='confirmDisconnect(routeVariant)'>&times;
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
