import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, } from '@angular/core';
import { AddAvailabilityInquiry, AvailabilityInquiry, AvailabilityInquiryState, AvailableVehicle } from '@common/models/availability-inquiry';
import { SubcontractorOrderService } from '@services/subcontractor-order.service';
import { AvailabilityInquiryService } from '@services/availability-inquiry.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { NotificationService } from '@services/notification.service';
import { OrderType } from '@models/order-type';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-subcontractors-availability-inquiry',
  template: `
    <div class="sc-inquiry mb-3">
      <span class="callout"></span>
      <div [ngSwitch]="availQuery.currentState.stateEnum" class="d-flex align-items-center justify-content-between px-3" style="height: 45px;">
        <div>
          <div class="caption-lt" i18n="@@subcontractors-availability-inquiry.subcontractor">Underentreprenör</div>
          <div>{{ availQuery.subcontractor.name }}</div>
        </div>
        <div *ngSwitchCase="AvailabilityInquiryState.StateNew">
          <button
            id="{{'inquiryBtn_' + availQuery.subcontractor.name}}"
            class="btn btn-primary ask-button"
            [disabled]="buttonsDisabled || disabled"
            [class.inactive]="disabled"
            (click)="query()"
            i18n="@@subcontractors-availability-inquiry.inquiry">Fråga ( {{numberOfVehiclesAsked}} )</button>
        </div>
        <div *ngSwitchCase="AvailabilityInquiryState.Submitted || AvailabilityInquiryState.Rejected" class="text-container">
          <div *ngSwitchCase="AvailabilityInquiryState.Submitted" class="mx-4">
            <div class="caption-lt" i18n="@@subcontractors-availability-inquiry.number-of-vehicles">Antal fordon</div>
            <div class="caption-lt">{{availQuery.numberOfVehicles}}</div>
          </div>
          <div *ngSwitchCase="AvailabilityInquiryState.Submitted">
            <div class="caption" i18n="@@subcontractors-availability-inquiry.sent">Skickad</div>
            <div class="caption-lt">{{availQuery.submitted.updated | amTimeAgo}}</div>
          </div>
        </div>
        <div *ngSwitchCase="AvailabilityInquiryState.Rejected">
          <div class="caption text-danger" i18n="@@subcontractors-availability-inquiry.rejected">Tackat nej</div>
          <div class="caption-lt">{{availQuery.replyInquiry.repliedTime | amTimeAgo}}</div>
        </div>
        <div *ngSwitchCase="AvailabilityInquiryState.Accepted">
          <div class="caption" i18n="@@subcontractors-availability-inquiry.accepted">Accepterat</div>
          <div class="caption-lt">{{availQuery.replyInquiry.repliedTime | amTimeAgo}}</div>
        </div>
        <div *ngSwitchCase="AvailabilityInquiryState.Assigned">
          <div class="caption" i18n="@@subcontractors-availability-inquiry.assigned">Tilldelat</div>
          <div class="caption-lt">{{availQuery.assignInquiry.assignedTime | amTimeAgo}}</div>
        </div>
        <div *ngSwitchCase="AvailabilityInquiryState.NotAssigned">
          <div class="caption" i18n="@@subcontractors-availability-inquiry.not-assigned">Ej tilldelat</div>
          <div class="caption-lt">{{availQuery.currentState.updated | amTimeAgo}}</div>
        </div>
        <div *ngSwitchCase="AvailabilityInquiryState.Ongoing">
          <div class="caption" i18n="@@subcontractors-availability-inquiry.assigned">Tilldelat</div>
          <div class="caption-lt">{{availQuery.assignInquiry.assignedTime | amTimeAgo}}</div>
        </div>
      </div>
      <app-availabilities-form
          *ngIf="availQuery.replyInquiry?.availableVehicles"
          [numberOfVehiclesLeftToSet]="numberOfVehiclesLeftToSet"
          [disabled]="disabled && buttonsDisabled"
          [isAssigned]="false"
          [orderType]="orderType"
          [isInternal]="false"
          [vehicles]="availQuery.replyInquiry?.availableVehicles"
          (saveEvent)="save($event, false)"
        ></app-availabilities-form>
      <div class="d-flex my-2 px-3 align-items-center">
        <div id="{{'directAssignDetail_' + availQuery.subcontractor.name}}" class="col text-end">
          <label class="caption-lt expand" (click)="toggleDirectAssignVisible()"
            i18n="@@subcontractors-availability-inquiry.direct-assign"
          >Direkttilldela</label>
          <img
            class="expand m-0 ms-1"
            src="/assets/img/icons/angle-down.png"
            alt="Icon angle-down"
            [class.expandRotate]="isDirectAssignVisible"
            (click)="toggleDirectAssignVisible()"
          />
        </div>
      </div>
      <div *ngIf="isDirectAssignVisible" @fadeInOut style="overflow: hidden;">
        <app-availabilities-form
          [numberOfVehiclesLeftToSet]="numberOfVehiclesLeftToSet"
          [disabled]="disabled && buttonsDisabled"
          [isAssigned]="false"
          [orderType]="orderType"
          [isInternal]="false"
          (saveEvent)="save($event, true)"
        ></app-availabilities-form>
      </div>
    </div>
  `,
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({height: 0}),
        animate(500, style({height: '*'}))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({height: 0}))
      ])
    ])
  ],
  styleUrls: ['./subcontractors-availability-inquiry.component.scss']
})
export class SubcontractorsAvailabilityInquiryComponent implements OnInit, OnDestroy {
  @Input() orderId: number;
  @Input() availQuery: AvailabilityInquiry;
  @Input() numberOfVehicles: number;
  @Input() numberOfVehiclesLeftToSet: number;
  @Input() disabled: boolean;
  @Input() orderType: OrderType;
  @Input() numberOfVehiclesAsked: number;
  @Output() subcontractorOrderCreatedOutput = new EventEmitter<string>();
  @Output() saveInProgress = new EventEmitter<boolean>();
  @Output() askedSubcontractor = new EventEmitter<boolean>();

  AvailabilityInquiryState = AvailabilityInquiryState;
  buttonsDisabled: boolean;
  stateChangedMessageSub: Subscription;
  isDirectAssignVisible: boolean;
  showAddInquiryTime: boolean;

  get isInquiryReplied() {
    return (this.availQuery.currentState.stateEnum === AvailabilityInquiryState.Accepted ||
      this.availQuery.currentState.stateEnum === AvailabilityInquiryState.Assigned);
  }

  constructor(
    private availabilityInquiryService: AvailabilityInquiryService,
    private subcontractorOrderService: SubcontractorOrderService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    if (this.disabled || this.numberOfVehicles === 0) {
      this.buttonsDisabled = true;
    }

    this.stateChangedMessageSub = this.notificationService.getStateChangedMessage().subscribe(message => {
      if (message.referenceType === 'AvailabilityInquiry' && message.referenceId === this.availQuery.id) {
        this.refreshInquiry();
      }
    });
    if (this.availQuery.replyInquiry?.availableVehicles && this.orderType === OrderType.Urgent) {
      this.availQuery.replyInquiry?.availableVehicles
        .sort((current, next) => current.estimatedArrivalMinutes > next.estimatedArrivalMinutes ? 1 : -1);
    }
  }

  ngOnDestroy(): void {
    this.stateChangedMessageSub.unsubscribe();
  }

  public refreshInquiry(): void {
    this.availabilityInquiryService.getAvailabilityInquiry(this.availQuery.id)
      .subscribe(x => {
        this.availQuery = x.body;
      });
  }

  toggleDirectAssignVisible() {
    this.isDirectAssignVisible = !this.isDirectAssignVisible;
  }

  query() {
    this.buttonsDisabled = true;

    const query: AddAvailabilityInquiry = {
      subcontractorId: this.availQuery.subcontractor.id,
      numberOfVehicles: this.numberOfVehicles,
      orderId: this.orderId
    };

    let location = '';
    this.availabilityInquiryService.addAvailabilityInquiry(query)
      .subscribe({
        next: resp => {
          location = `${resp.url}/${resp.body[0]}`;
        },
        complete: () => {
          if (location !== undefined && location !== '') {
            this.availabilityInquiryService.getAvailabilityInquiryFromLocation(location)
              .subscribe(tmp => {
                // hämta skapad AvailabilityInquiry och uppdatera
                this.availQuery = tmp;
              });
          }
          this.buttonsDisabled = false;
        }
      });
    this.askedSubcontractor.emit(true);
  }

  save(list: AvailableVehicle[], isDirectAssign: boolean = false) {
    this.saveInProgress.emit(true);

    if (this.numberOfVehicles === undefined || this.numberOfVehicles === 0) {
      throw (new Error('noOfVehicles 0 eller undefined'));
    }

    let location = '';
    const subcontractorId = this.availQuery.subcontractor.id;
    const inquryId = this.availQuery.id;
    const assignCall = isDirectAssign
      ? this.subcontractorOrderService.directAssign(this.orderId, subcontractorId, list)
      : this.subcontractorOrderService.inquiryAssign(this.orderId, subcontractorId, inquryId, list);
    assignCall
      .subscribe({
        next: resp => { location = resp.headers.get('location'); },
        error: () => {
          this.saveInProgress.emit(false);
          this.buttonsDisabled = true;
        },
        complete: () => {
          if (location !== undefined && location !== '') {
            this.subcontractorOrderCreatedOutput.emit(location);
          }
        }
      });
  }

  trackByFn(_: number, item: AvailableVehicle) {
    return item;
  }
}
