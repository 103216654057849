import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';

import { BaseComponent } from '@common/components/base.component';
import { OrderService } from '@services/order.service';
import { ReasonService } from '@services/reason.service';
import { AuthService } from '@services/auth.service';
import { Reason } from '@models/reason';
import { Order } from '@models/order';
import { OrderState } from '@models/order-state';
import { UserRoles } from '@authentication/user-roles';
import { CancelOrderComponent } from '../cancel-order/cancel-order.component';
import { CloseOrderComponent } from '../close-order/close-order.component';
import { CancelReasonUtilService } from '@services/cancel-reason-util.service';

@Component({
  selector: 'app-hastus-order',
  templateUrl: './hastus-order.component.html',
  styleUrls: ['./hastus-order.component.scss'],
})
export class HastusOrderComponent extends BaseComponent implements OnInit {
  order: Order;
  orderForm: UntypedFormGroup;
  reasons: Reason[];
  UserRoles = UserRoles;
  submitDisabled: boolean;
  OrderState = OrderState;
  isHistory: boolean;
  buttonsDisabled = false;
  cancellationReason: string;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private orderService: OrderService,
    private reasonService: ReasonService,
    private authService: AuthService,
    private modalService: NgbModal,
    private cancelReasonUtilService: CancelReasonUtilService,
  ) {
    super();
  }

  // Form controls helpers used in validation
  get reason() {
    return this.orderForm.get('reason');
  }
  get reasonAdditionalInfo() {
    return this.orderForm.get('reasonAdditionalInfo');
  }
  get referenceId() {
    return this.orderForm.get('referenceId');
  }
  get otherRequests() {
    return this.orderForm.get('otherRequests');
  }

  ngOnInit() {
    this.isHistory = this.router.url.startsWith('/history');
    this.createForm();
    this.getOrder();
  }

  createForm() {
    this.orderForm = this.fb.group({
      reason: ['', Validators.required],
      reasonAdditionalInfo: '',
      referenceId: '',
      otherRequests: '',
    });
  }

  getOrder(): void {
    this.order = new Order();
    this.order.id = +this.route.snapshot.paramMap.get('id');


    if (this.order.id !== 0) {
      const order = this.orderService.getOrder(this.order.id);
      const reasons = this.reasonService.getReasons();

      forkJoin([order, reasons]).subscribe(results => {
        this.reasons = results[1];
        this.onOrderRetrieved(results[0].body);
      });
    } else {
      this.reasonService.getReasons().subscribe(reasons => (this.reasons = reasons));
    }


  }

  onOrderRetrieved(order: Order): void {
    this.order = order;
    const selectedReason = this.reasons.find(x => x.id === order.orderReason.id);
    this.cancellationReason = this.cancelReasonUtilService.reasons.get(this.order?.cancellationDetails?.reasonEnum) || null;
    this.orderForm.patchValue({
      referenceId: order.referenceId,
      orderId: order.id,
      otherRequests: order.otherRequests,
      reason: selectedReason,
      reasonAdditionalInfo: order.orderReason.additionalInfo,
    });

    if (this.isHistory) {
      this.orderForm.disable();
    }
  }

  userHasAnyRole(userRoles: UserRoles[]) {
    return this.authService.userHasAnyRole(userRoles);
  }

  save(): void {
    // trigger validation on all fields
    Object.keys(this.orderForm.controls).forEach(field => {
      const control = this.orderForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });

    if (this.orderForm.valid) {
      this.submitDisabled = true;
      // Copy the form values over the order object values
      const o = Object.assign(this.order, this.orderForm.value);
      o.ReasonId = Number(this.orderForm.controls['reason'].value.id);

      this.orderService
        .saveOrder(o)
        .subscribe(() => this.router.navigateByUrl('orders?ordertype=3'));
    }
  }

  dismiss(): void {
    if (this.isHistory) {
      this.router.navigateByUrl('history');
    } else {
      this.router.navigateByUrl('orders');
    }
  }

  cancelOrder() {
    this.buttonsDisabled = true;
    const modalRef = this.modalService.open(CancelOrderComponent);
    modalRef.componentInstance.orderId = this.order.id;
    modalRef.componentInstance.isHastusPlanned = this.order.isHastusPlanned;
    modalRef.result
      .then(() => {
        this.order.currentState.stateEnum = OrderState.Cancelled;
        this.getOrder();
        this.buttonsDisabled = false;
      })
      .catch(() => {
        this.buttonsDisabled = false;
      });
  }

  closeOrder() {
    this.buttonsDisabled = true;
    const modalRef = this.modalService.open(CloseOrderComponent);
    modalRef.componentInstance.orderId = this.order.id;
    modalRef.componentInstance.isHastusPlanned = true;
    modalRef.result
      .then(() => {
        this.doCancelOrder();
        this.buttonsDisabled = false;
      })
      .catch(() => {
        this.buttonsDisabled = false;
      });
  }

  doCancelOrder() {
    this.orderService
      .updateOrderState(this.order.id, OrderState.Completing)
      .subscribe(() => {
          this.order.currentState.stateEnum = OrderState.Completing;
          this.getOrder();
        }
        );
  }
}
