
<div class="d-flex flex-row flex-wrap bg-white overflow-auto">
  <table class="table datatable" aria-label="Subcontractor order list">
    <thead>
      <tr>
        <th i18n="@@subcontractor-order-trip-list.order">Order</th>
        <th i18n="@@subcontractor-order-trip-list.trip">Tur</th>
        <th i18n="@@subcontractor-order-trip-list.from">Från</th>
        <th i18n="@@subcontractor-order-trip-list.departuretime">Avgångstid</th>
        <th i18n="@@subcontractor-order-trip-list.to">Till</th>
        <th i18n="@@subcontractor-order-trip-list.arrivaltime">Ankomsttid</th>
        <th i18n="@@subcontractor-order-trip-list.vehicle">Fordon</th>
        <th i18n="@@subcontractor-order-trip-list.driver">Förare</th>
        <th i18n="@@subcontractor-order-trip-list.driverphone">Förartelefon</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let subcontractorOrder of subcontractorOrdersList | paginate: {
        itemsPerPage: pageSize,
        currentPage: currentPage,
        totalItems: subcontractorOrderTripsCount }">
        <td>
          <a routerLink="/subcontractor/{{ subcontractor.id }}/subcontractor-orders/{{ subcontractorOrder.id }}">
            {{ subcontractorOrder.orderId }}
          </a>
        </td>
        <td>{{ subcontractorOrder.tripId }}</td>
        <td>{{ subcontractorOrder.startOrderRouteStopDescription }}</td>
        <td>{{ subcontractorOrder.startTripStop?.passTime | amDateFormat:'YYYY-MM-DD HH:mm' }}</td>
        <td>{{ subcontractorOrder.endOrderRouteStopDescription }}</td>
        <td>{{ subcontractorOrder.endTripStop?.passTime | amDateFormat:'YYYY-MM-DD HH:mm' }}</td>
        <td>{{ subcontractorOrder.vehicle?.vehicleIdentifier }}</td>
        <td>{{ subcontractorOrder.driver?.firstName }} {{ subcontractorOrder.driver?.lastName }}</td>
        <td>{{ subcontractorOrder.driver?.phoneNumber }}</td>
      </tr>
    </tbody>
  </table>
</div>
<div class="table-pagination d-flex flex-wrap justify-content-end align-items-center bg-white mt-3">
  <label for="pageRows" class="filter-label me-2" i18n="@@order-list.show-pagesize">Antal per sida: </label>
  <select id="pageRows" class="select form-control form-select" [(ngModel)]="pageSize"
    (change)="selectedPageSize($event.target.value)">
    <option>10</option>
    <option>25</option>
    <option>50</option>
  </select>
  <span class="mx-4">
    <span class="filter-result">{{ subcontractorOrderTripsCount }}</span> resultat
  </span>
  <pagination-controls class="pagination me-3" [responsive]="true" (pageChange)="pageChanged($event)"
                       previousLabel="" nextLabel="" autoHide="true">
  </pagination-controls>
</div>
