import { Component, OnInit, OnDestroy, Input, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';

import { AvailabilityInquiry, AvailabilityInquiryState } from '@models/availability-inquiry';
import { AvailabilityInquiryService } from '@services/availability-inquiry.service';
import { OrderByService } from '@services/order-by.service';
import { NotificationService } from '@services/notification.service';
import { Subcontractor } from '@models/subcontractor';
import { ConstantPool } from '@angular/compiler';
import { VehicleTypeService } from '@services/vehicle-type.service';

@Component({
  selector: 'app-availability-inquiry-list-base',
  templateUrl: './availability-inquiry-list-base.component.html',
  styleUrls: ['./availability-inquiry-list-base.component.scss']
})
export class AvailabilityInquiryListBaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() subcontractor: Subcontractor;
  @Input() availabilityInquiryState: number = AvailabilityInquiryState.Submitted;

  availabilityInquiries: AvailabilityInquiry[];
  stateChangedMessageSub: Subscription;
  numberOfAvailabilityInquiries: number;

  AvailabilityInquiryState = AvailabilityInquiryState;

  constructor(
    public availabilityInquiryService: AvailabilityInquiryService,
    public notificationService: NotificationService,
    public orderByService: OrderByService,
    public vehicleTypeService: VehicleTypeService
  ) { }

  ngOnInit() {
    this.numberOfAvailabilityInquiries = this.getAvailabilityInquiries();

    this.stateChangedMessageSub = this.notificationService.getStateChangedMessage().subscribe(async message => {
      if (message.referenceType === 'AvailabilityInquiry') {
        const ix = this.availabilityInquiries?.findIndex(x => x.id === message.referenceId);
        if (ix !== undefined && ix >= 0) {
          this.numberOfAvailabilityInquiries = this.getAvailabilityInquiries();
        }
      }
    });
  }

  ngOnChanges() {
    this.numberOfAvailabilityInquiries = this.getAvailabilityInquiries();
  }

  ngOnDestroy() {
    this.stateChangedMessageSub.unsubscribe();
  }

  getAvailabilityInquiries(): number {
    if (this.subcontractor?.id) {
      this.availabilityInquiryService.getAvailabilityInquiriesForSubcontractor(this.subcontractor.id)
        .subscribe(
          s => {
            this.availabilityInquiries = s.body;
            this.availabilityInquiries = this.availabilityInquiries.filter(a => this.aiFilter(a, this.availabilityInquiryState));
            this.availabilityInquiries = this.orderByService.transform(this.availabilityInquiries, 'startTripStop.passTime');
            this.availabilityInquiries.forEach(item => {
              item.vehicleType = this.vehicleTypeService.getVehicleType(item.vehicleTypeEnum);
            })
            this.numberOfAvailabilityInquiries = this.availabilityInquiries.length;
          });

      return this.numberOfAvailabilityInquiries;
    }
  }

  aiFilter = function (a: AvailabilityInquiry, state: number) {
    return a.currentState.stateEnum === state;
  };
}
