import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderService } from '@services/order.service';
import { OrderState } from '@models/order-state';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@services/auth.service';
import { UserRoles } from '@authentication/user-roles';

@Component({
  selector: 'app-cancel-order',
  templateUrl: './cancel-order.component.html',
  styleUrls: ['./cancel-order.component.scss'],
})
export class CancelOrderComponent {

  @Input() orderId: number;
  @Input() isHastusPlanned: boolean;

  isNobinaOperator = false;
  buttonsDisabled = false;
  form: FormGroup;

  constructor(public activeModal: NgbActiveModal, private orderService: OrderService,
              private fb: FormBuilder, private authService: AuthService) {
    this.form = this.fb.group({
      reason: ['', Validators.required],
      comment: [''],
    });

    this.isNobinaOperator = this.authService.userHasAnyRole([UserRoles.NobinaTrafikledare, UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare]);
  }

  cancelOrder() {
    this.buttonsDisabled = true;
    const formValue = this.form.value;
    this.orderService.updateOrderState(this.orderId, OrderState.Cancelled, +formValue.reason, formValue.comment)
      .subscribe(
        {
          next: () => this.activeModal.close(),
          error: () => this.activeModal.dismiss(),
        });
  }

}

