<div class="my-1 mx-1">
  <div class="row g-0 {{captionClass ?? 'caption'}}" >
    {{captionText}}
  </div>
  <div class="row g-0">
    <div class="tb-badge-medium-green">
      {{distance}} km
    </div>
    <div class="tb-badge-light-green">
        <span *ngIf="time >= 60">
            {{Math.floor ((time / 60))}} h
        </span>
      <span *ngIf="time % 60 > 0">
            {{(time % 60)}} min
        </span>
      <span *ngIf="!time">0 min</span>
    </div>
  </div>
</div>
