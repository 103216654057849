<h1 class="my-5 text-center" i18n="@@create-report.follow-up">Uppföljning</h1>

<ul *ngIf="userHasAnyRole([UserRoles.NobinaLeveransansvarig,UserRoles.BergkvaraTrafikledare, UserRoles.UETrafikledare])" class="nav nav-tabs me-auto">
  <li class="nav-item">
    <a class="nav-link" routerLink="/billing/outcomes" [class.active]="path == '/billing/outcomes'" i18n="@@create-report.outcome-data">Utfallsdata</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/billing/outcomes"  [queryParams]="{ state: OutcomeState.Billable }"  [class.active]="queryStates[0] === OutcomeState.Billable" i18n="@@create-report.billable">Fakturerbar</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/billing/outcomes"  [queryParams]="{ state: OutcomeState.Billed }"  [class.active]="queryStates[0] === OutcomeState.Billed" i18n="@@create-report.billed">Fakturerad</a>
  </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/billing/reports/create-report" [class.active]="path?.indexOf('/billing/reports') > -1" i18n="@@create-report.reports">Rapporter</a>
    </li>
</ul>

<div class="container">

  <div class="main">

    <h1 class="" i18n="@@create-report.create-report">Skapa rapport</h1>

    <hr>

    <form [formGroup]="reportForm" (ngSubmit)="createReport()" novalidate class="form">

      <div *ngIf="(userIsNobinaLeveransansvarig || (userIsOperatorTrafikledare && userIsOperatorWithMultipleOrganisations))" class="row my-5">
        <div *ngIf="(userIsNobinaLeveransansvarig || (userIsOperatorTrafikledare && userIsOperatorWithMultipleOrganisations))" class="col-4">
          <h2 class="mb-3" i18n="@@create-report.select-traffic-operator">Välj trafikoperatör</h2>
          <ng-select
          #operatorsSelect
           class="custom"
           [items]="operators"
           bindLabel="name"
           bindValue="operatorOrganisationId"
           [multiple]="true"
           placeholder="Trafikoperatörer"
           clearAllText="Rensa"
           [closeOnSelect]="true"
           formControlName="selectedOperatorIds">
            <ng-template ng-header-tmp>
                <option (click)="selectAllOperators(operatorsSelect)">Välj alla</option>
            </ng-template>
          </ng-select>
          <div *ngIf="(operatorsCtrl.dirty || operatorsCtrl.touched) && operatorsCtrl.invalid" class="mt-2 text-danger" i18n="@@create-report.traffic-operator-mandatory">
            Trafikoperatör är obligatoriskt
          </div>
        </div>
        <div *ngIf="userIsNobinaLeveransansvarig" class="col-4">
          <h2 class="mb-3" i18n="@@create-report.select-subcontractor">Välj utförare</h2>
          <ng-select
            #subcontractorsSelect
            class="custom"
            [items]="subcontractors"
            bindLabel="name"
            bindValue="id"
            [multiple]="true"
            placeholder="Utförare"
            clearAllText="Rensa"
            [closeOnSelect]="true"
            formControlName="selectedSubcontractorIds"
            i18n-placeholder="@@create-report.select-subcontractor-placeholder">
            <ng-template ng-header-tmp>
                <option (click)="selectAllSubcontractors(subcontractorsSelect)">Välj alla</option>
            </ng-template>
          </ng-select>
          <div *ngIf="(subcontractorsCtrl.dirty || subcontractorsCtrl.touched) && subcontractorsCtrl.invalid" class="mt-2 text-danger" i18n="@@create-report.subcontractor-mandatory">
            Utförare är obligatoriskt
          </div>
        </div>
      </div>
      <hr *ngIf="(userIsNobinaLeveransansvarig || (userIsOperatorTrafikledare && userIsOperatorWithMultipleOrganisations))">
      <div class="row">
        <div class="col">
          <h2 class="mt-2 mb-4" i18n="@@create-report.date-range">Ange datumintervall</h2>
        </div>
      </div>
      <div class="row mb-4 justify-content-center">
        <div class="col-4 datepicker mb-4">
          <div class="mb-2" i18n="@@create-report.start-date">Startdatum</div>
          <ngb-datepicker ngbDatepicker #dpStartDate="ngbDatepicker" formControlName="startDate" (select)="onDateSelection()"></ngb-datepicker>
        </div>
        <div class="col-4 datepicker mb-4">
          <div class="mb-2" i18n="@@create-report.end-date">Slutdatum</div>
          <ngb-datepicker ngbDatepicker #dpEndDate="ngbDatepicker" formControlName="endDate" (select)="onDateSelection()"></ngb-datepicker>
          <div *ngIf="(endDateCtrl.dirty || endDateCtrl.touched) && endDateCtrl.invalid" class="mt-2 text-danger" i18n="@@create-report.end-date-invalid">
            Slutdatum måste vara efter startdatum
          </div>
        </div>
      </div>
      <hr>
      <div *ngIf="userIsNobinaLeveransansvarig || userIsOperatorTrafikledare" class="col-4 mt-5">
        <div class="form-check">
          <input id="include-unassigned-drive-orders" class="form-check-input" type="checkbox" [checked]="includeUnassignedDriveOrders" (change)="toggleIncludeUnassignedDriveOrders(includeUnassignedDriveOrders)" >
          <label class="form-check-label" for="include-unassigned-drive-orders" i18n="@@create-report.include-unassigned-drive-orders">
            Inkludera otilldelade körordrar
          </label>
        </div>
      </div>
      <div class="d-flex mt-3 justify-content-end">
        <button
          *ngIf="(userIsNobinaLeveransansvarig || (userIsOperatorTrafikledare && userIsOperatorWithMultipleOrganisations))"
          id="create-unbound-report-btn" class="btn btn-primary float-right mb-2" type="button" [disabled]="buttonsDisabled"
          (click)="createOutboundReport()" i18n="@@create-report.create-unbound-report-button"
        >Rapport för friorder</button>
        <button id="create-report-btn" class="btn btn-primary float-right mb-2 ms-2" type="button" [disabled]="buttonsDisabled"
          (click)="createReport()" i18n="@@create-report.create-report-button"
        >Rapport för ordrar</button>
        <button id="create-combined-report-btn" class="btn btn-primary float-right mb-2 ms-2" type="button" [disabled]="buttonsDisabled"
          (click)="createCombinedReport()" i18n="@@create-report.create-combined-report-button"
        >Rapport för ordrar (Nytt format)</button>
      </div>

    </form>
  </div>

  </div>

