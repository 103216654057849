<table class="table table-striped" aria-label="Manage subcontractors table">
  <thead>
  <tr>
    <th style="width: 35%" scope="col" i18n="@@table-subcontractors.subcontractors">
      Utförare
    </th>
    <th style="width: 55%" scope="col" i18n="@@table-subcontractors.oms-depot-id">
      OMS garagekod
    </th>
    <th style="width: 10%" scope="col"></th>
  </tr>
  </thead>
  <tbody>
  <tr
    *ngFor="let subcontractor of renderedSubcontractors; index as i"
    [id]="'tr-table-subcontractors-' + i"
    [class.active]="activeId === i"
    [class.subcontractor]="subcontractor.name"
    [class.shadow]="subcontractor.shadowElement"
    (click)="subcontractor.name ? onClick(i, subcontractor.operators) : null"
  >
    <td *ngIf="subcontractor.name">
      {{ subcontractor.name }}
    </td>
    <td *ngIf="subcontractor.name">{{ subcontractor.code}}</td>
    <td *ngIf="subcontractor.name">
      <app-edit-subcontractor
        [operators]="operators"
        [subcontractor]="subcontractor"
        (saveEvent)="onSaveEvent()"
      ></app-edit-subcontractor>
    </td>

    <td *ngIf="subcontractor.shadowElement" style='display: none'>
      I'm here to make alternating row colors work ;)
    </td>

    <td
      *ngIf="!subcontractor.name && !subcontractor.shadowElement"
      colspan="3"
      class='active-subcontractor'
    >
      <div *ngIf='subcontractor.operators.length === 0'>
        <p i18n="@@table-subcontractors.no-operators">Inga operatörer tillgängliga</p>
      </div>
      <div *ngIf='subcontractor.operators.length > 0'>
        <p *ngFor="let operator of subcontractor.operators; index as idx">
          {{idx + 1 === subcontractor.operators.length ? operator.name : operator.name + ', '}}
        </p>
      </div>
    </td>
  </tr>
  </tbody>
</table>
