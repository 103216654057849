<div class="m-4">
  <h2 class="mb-4" i18n="@@cancel-unbound-order.cancel">Avbeställ</h2>
  <p class="mb-5" i18n="@@cancel-unbound-order.cancel-info">Friordern kommer avbeställas. </p>

  <form [formGroup]='form' (ngSubmit)='cancelOrder()'>
    <div class="form-group mb-4">
      <label class='form-label' for="cancelReason" i18n="@@cancel-unbound-order.cancel-reason">Avbeställningsorsak</label>
      <select class="form-select" id="cancelReason" formControlName='reason'>
        <option value="1" i18n="@@cancel-unbound-order.cancel-reason-1">Felbeställning</option>
        <option value="2" i18n="@@cancel-unbound-order.cancel-reason-2">Avbeställd av beställare</option>
        <option value="3" i18n="@@cancel-unbound-order.cancel-reason-3">Ändring av beställning</option>
        <option value="4" i18n="@@cancel-unbound-order.cancel-reason-4" [hidden]='!isNobinaOperator'>Ej tillsatt</option>
        <option value="5" i18n="@@cancel-unbound-order.cancel-reason-5" [hidden]='!isNobinaOperator'>Ej utförd</option>
        <option value="6" i18n="@@cancel-unbound-order.cancel-reason-6" [hidden]='!isNobinaOperator'>Annan orsak</option>
      </select>
    </div>
    <div class="form-group mb-4">
      <label class='form-label' for="cancelReason" i18n="@@cancel-unbound-order.cancel-comment">Kommentar</label>
      <textarea class="form-control" id="cancel-comment" rows='3' formControlName='comment'></textarea>
    </div>

    <div class="d-flex">
      <button class="btn btn-outline-primary btn-min-width ms-auto me-2" id="dismissBtn" type="button" (click)="activeModal.dismiss()" [disabled]="buttonsDisabled" i18n="@@cancel-unbound-order.close">Stäng</button>
      <button class="btn btn-primary btn-min-width" id="cancelOrderBtn" type="submit" [disabled]="buttonsDisabled || !form.valid" i18n="@@cancel-unbound-order.cancel">Avbeställ</button>
    </div>
  </form>
</div>
