<app-order-menu [orderId]="orderId"></app-order-menu>
<div class="container">
  <div class="main pt-0">
    <hr>

    <h2 class="mt-4 mb-4" i18n="order-log.logs">Logghändelser</h2>

    <h3 i18n="order-log.order">Beställning</h3>

    <div>
      <div *ngFor="let logEntry of logs" tabindex="0" class="card card-selected p-2">
        <div class="caption-lt-xs">{{logEntry.logText}}</div>
        <div class="caption-md-bl">{{logEntry.createdBy}}</div>
        <div class="caption-lt">
          {{ logEntry.created | amDateFormat: 'YYYY-MM-DD HH:mm:ss' }}
        </div>
      </div>
    </div>
  </div>
</div>
