<h1 class="my-5 text-center"><ng-container i18n="@@subcontractor-route-map.order">Beställning</ng-container> {{ subcontractorOrder?.orderId  }}</h1>

<ul class="nav nav-tabs mx-auto">
  <li class="nav-item">
    <a class="nav-link" [routerLink]="'/subcontractor/' + subcontractorId + (isHistory ? '/history/' : '/subcontractor-orders/') + subcontractorOrderId" i18n="@@subcontractor-route-map.order">Beställning</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [href]="'/order-new/subcontractor/' + subcontractorId + (isHistory ? '/history/' : '/subcontractor-orders/') + subcontractorOrderId + '/drive-order'" i18n="@@subcontractor-route-map.driveorders">Körordrar</a>
  </li>
  <li class="nav-item">
    <a class="nav-link active" [routerLink]="'/subcontractor/' + subcontractorId + (isHistory ? '/history/' : '/subcontractor-orders/') + subcontractorOrderId + '/subcontractor-route-map'" i18n="@@subcontractor-route-map.route-map">Linjesträckningskarta</a>
  </li>
</ul>

<div class="container">
  <form [formGroup]="mapForm">
    <div class="row mb-2">
      <div class="route-variant">
        <div *ngIf="routeVariants?.length === 1">
          <label for="routeVariant" i18n="@@subcontractor-route-map.routevariant">Linjevariant</label>
          <input id="routeVariant" class="form-control" type="text" formControlName="routeVariant">
        </div>
        <div *ngIf="routeVariants?.length > 1">
          <label for="routeVariant" i18n="@@subcontractor-route-map.select-routevariant">Välj linjevariant</label>
          <select (change)="onRouteVariantChanged()" id="routeVariant" class="form-control form-select" formControlName="routeVariant">
            <option value="null" [disabled]="true" i18n="@@subcontractor-route-map.select">Välj</option>
            <option *ngFor="let r of routeVariants" [ngValue]="r">{{ r.routeName + ' - ' + r.routeVariantName }}</option>
          </select>
        </div>
      </div>
    </div>
  </form>
</div>
<app-googlemap
  [routeGeoData]="routeGeoData"
  [vehicleGeoData]="vehicleGeoData"
  [disableStreetView]="disableStreetView"
></app-googlemap>
