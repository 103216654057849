import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Pos } from '../models/pos';

@Injectable()
export class GeoLocationService {

  public getLocation(geoLocationOptions?: any): Observable<Pos> {
    geoLocationOptions = geoLocationOptions || { timeout: 5000 };

    return new Observable(observer => {

      if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = new Pos(position.coords.latitude, position.coords.longitude, position.timestamp);
            observer.next(pos);
            observer.complete();
          },
          (error) => {
            // observer.error(error.message);
            // swallow the error and return a default position with null values,
            // This is to prevent exception in user client as positioning is optional.
            const pos = new Pos(null, null, null);
            observer.next(pos);
            observer.complete();
          },
          geoLocationOptions);
      } else {
        observer.error('Browser does not support location services');
      }
    });
  }
}

export let geolocationServiceInjectables: Array<any> = [
  { provide: GeoLocationService, useClass: GeoLocationService }
];
