import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OPERATOR_ORGID_MTR_PENDEL, OPERATOR_ORGID_MTR_TUNNELBANA, OPERATOR_ORGID_TÅGKOMPANIET_KRÖSA, OPERATOR_ORGID_GO_AHEAD } from '../../../common/models/operator';
import { UnboundOrder } from '../../../common/models/unbound-order';
import { UnboundOrderService } from '../../../common/services/unbound-order.service';

@Component({
  selector: 'app-unbound-order-confirmation',
  templateUrl: './unbound-order-confirmation.component.html',
  styleUrls: ['./unbound-order-confirmation.component.scss']
})
export class UnboundOrderConfirmationComponent implements OnInit {
  @Input() order: UnboundOrder;
  orderId = '';
  referenceId = '';
  KEY_ENTER = 13;
  KEY_SPACE = 32;

  isCtlMitt = false;
  isCtlSyd = false;
  isCtlVast = false;
  isCtlNorge = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private unboundOrderService: UnboundOrderService) { }

  ngOnInit() {
    const id = +this.route.snapshot.paramMap.get('id');
    this.unboundOrderService.getOrder(id)
      .subscribe(order => this.onOrderRetrieved(order.body));
  }

  onOrderRetrieved(order: UnboundOrder): void {
    this.order = order;
    this.orderId = order.id + '';
    this.referenceId = order.referenceId;

    // CTL Norge hanterar go ahead
    this.isCtlNorge = (this.order.operator.operatorOrganisationId === OPERATOR_ORGID_GO_AHEAD);

    if (!this.isCtlNorge) {
      // CTL Mitt hanterar MTR
      this.isCtlMitt = (this.order.operator.operatorOrganisationId === OPERATOR_ORGID_MTR_PENDEL
        || this.order.operator.operatorOrganisationId === OPERATOR_ORGID_MTR_TUNNELBANA);

      // CTL Syd hanterar 1156    XSSOTK    Tågkompaniet Krösa
      this.isCtlSyd = (this.order.operator.operatorOrganisationId === OPERATOR_ORGID_TÅGKOMPANIET_KRÖSA);

      // CTL Väst hanterar alla övriga
      this.isCtlVast = !(this.isCtlMitt || this.isCtlSyd || this.isCtlNorge);
    }
  }

  navigateToOrder() {
    this.router.navigateByUrl('/unboundorder/' + this.orderId);
  }
}
