import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { SubcontractorService } from '../../../common/services/subcontractor.service';
import { OrderByService } from '../../../common/services/order-by.service';
import { DriveOrderService } from '../../../common/services/drive-order.service';

import { AssignSubcontractorToDriveOrderRequest } from '../../../common/models/assign-drive-order';
import { Subcontractor, SubcontractorVacantType } from '../../../common/models/subcontractor';
import { OP_KRÖSATÅG, OP_MTR } from '../../../common/models/order';

@Component({
  selector: 'app-assign-subcontractor',
  templateUrl: './assign-subcontractor.component.html',
  styleUrls: ['./assign-subcontractor.component.scss']
})
export class AssignSubcontractorComponent implements OnInit {

  @Input() assignedSubcontractor: Subcontractor;
  @Input() subcontractorId: number;
  @Input() availabilityInquiryId: number;
  @Input() driveOrderId: number;
  @Input() operatorId: number;
  subcontractors: Subcontractor[];
  selectedSubcontractor: Subcontractor;
  constructor(public activeModal: NgbActiveModal,
    private subcontractorService: SubcontractorService,
    private driveorderService: DriveOrderService,
    private orderByService: OrderByService) { }

  ngOnInit() {

    this.subcontractorService.getSubcontractors(this.operatorId)
      .subscribe(x => {
        this.subcontractors = x.body;
        this.subcontractors = this.subcontractors.filter(s => (
          s.subcontractorVacantType === SubcontractorVacantType.None || 
          (s.subcontractorVacantType === SubcontractorVacantType.UtforareVakant && s.code === "XSMUET")
        ));
        this.subcontractors = this.orderByService.transform(this.subcontractors, 'name');
  });
  this.selectedSubcontractor = this.assignedSubcontractor;
}

  assign() {
    const request = new AssignSubcontractorToDriveOrderRequest();
    if (this.selectedSubcontractor) {
      request.subcontractorId = this.selectedSubcontractor.id;
    }
    this.assignedSubcontractor = this.selectedSubcontractor;

    this.driveorderService.updateAssignedSubcontractorToDriveOrder(this.driveOrderId, request)
                      .subscribe(response =>
                        this.activeModal.close({ subcontractor: this.assignedSubcontractor }
                        ),
                        err => {
                          console.log('Fel vid uppdatering av underentreprenör på körorder');
                       });

  }

}
