import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { DateRange } from '@models/dateRange';
import { UnboundOrder, UnboundOrderAssignment, UnboundOrderCompletion } from '@models/unbound-order';

@Injectable()
export class UnboundOrderService {
  private baseUrl = environment.apiUrl + environment.apiVersionMain + '/unboundorders';

  constructor(private http: HttpClient) {
  }

  reviver(key, value): any {
    if (key === 'startTime' || key === 'endTime') {
      return new Date(value);
    }
    return value;
  }

  /** GET orders from the server */
  getOrders(dateRange: DateRange, unboundOrderId: number = null): Observable<HttpResponse<UnboundOrder[]>> {
    let url = this.baseUrl;
    if (dateRange !== undefined) {
      url += '?FromDateTime=' + encodeURIComponent(moment(dateRange.fromDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ'))
        + '&ToDateTime=' + encodeURIComponent(moment(dateRange.toDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
    }
    if (unboundOrderId != null) {
      url += url === this.baseUrl ? '?' : '&';
      url += 'UnboundOrderId=' + unboundOrderId;
    }
    return this.http.get<UnboundOrder[]>(url, { observe: 'response' });
  }

  /** GET order by id. Will 404 if id not found */
  getOrder(id: number): Observable<HttpResponse<UnboundOrder>> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<UnboundOrder>(url, { observe: 'response' });
  }

  /** POST: add a new order to the server */
  addOrder(unboundOrder: UnboundOrder): Observable<HttpResponse<UnboundOrder>> {
    return this.http.post<UnboundOrder>(this.baseUrl, unboundOrder, { observe: 'response' });
  }

  /** PUT: update order state */
  updateOrderState(id: number, state: number, reason?: number, comment?: string): Observable<any> {
    const url = `${this.baseUrl}/${id}/state`;
    let body: { State: number; CancellationDetails?: { ReasonEnum: number; AdditionalInfo: string; }; };
    if (state === 5) {
      body = { 'State': state, 'CancellationDetails': { 'ReasonEnum': reason, 'AdditionalInfo': comment } };
    } else {
      body = { 'State': state };
    }

    return this.http.put(url, body);
  }

  /** PUT: save assignment */
  saveAssignment(id: number, assignment: UnboundOrderAssignment): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/${id}/assignments`;
    return this.http.put(url, assignment, { observe: 'response' });
  }


  /** PUT: complete order and save completion data */
  completeUnboundOrder(id: number, completion: UnboundOrderCompletion): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/${id}/completion`;
    return this.http.put(url, completion, { observe: 'response' });
  }

  cancellationConfirmedWithCompletionUnboundOrder(id: number, completion: UnboundOrderCompletion): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/${id}/cancellationConfirmedWithCompletion`;
    return this.http.put(url, completion, { observe: 'response' });
  }

  sendEmail(id: number, email: string, name: string, request: boolean): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/${id}/sendEmail`;
    return this.http.put(url, `{"UnboundOrderId": "${id}", "Email": "${email}", "Name": "${name}", "OtherRequest": "${request}"}`, { observe: 'response' });
  }
}
