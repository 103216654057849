import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { OperatorService } from '@services/operator.service';
import { Operator } from '@models/operator';
import { OrderImportService } from '@services/order-import.service';

@Component({
  selector: 'app-import-order',
  templateUrl: './import-order.component.html',
  styleUrls: ['./import-order.component.scss']
})
export class ImportOrderComponent implements OnInit {

  importForm: FormGroup;
  operators: Operator[];
  fileToUpload: File = null;
  fileName: string;
  uploading = false;
  importedOrderId: string;

  constructor(
    private fb: FormBuilder,
    private operatorService: OperatorService,
    private importService: OrderImportService) { }

  ngOnInit() {
    this.importForm = this.fb.group({
      operator: [null, Validators.required],
      importFile: [null, Validators.required]
    });

    this.operatorService.getOperators(true)
      .subscribe(ops => {
        this.operators = ops.body;
      });
  }

  get operatorCtrl() { return this.importForm.get('operator'); }
  get fileInputCtrl() { return this.importForm.get('importFile'); }

  onFileChange(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  import(): void {
    this.importedOrderId = '';
    // trigger validation on all fields
    Object.keys(this.importForm.controls).forEach(field => {
      const control = this.importForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });

    if (this.isValid()) {
      this.uploading = true;
      const operatorOrganisationId = this.operatorCtrl.value.operatorOrganisationId;
      this.importService
        .import(operatorOrganisationId, this.fileToUpload)
        .subscribe({
          next:  response => this.onSuccessfulUpload(response.headers.get('location')),
          error: () => this.uploading = false
        });
    }
  }

  isValid() {
    return this.importForm.valid;
  }

  onSuccessfulUpload(location: string): any {
    this.importedOrderId = location.substring(location.lastIndexOf('/') + 1);
    this.fileName = this.fileToUpload.name;
    this.fileToUpload = null;
    this.importForm.reset();
    this.uploading = false;
  }
}
