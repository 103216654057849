<h1 class="my-5 text-center">Förfrågan {{ inquiry.orderId }}</h1>
<ul class="nav nav-tabs mx-auto">
  <li class="nav-item">
    <a class="nav-link active"
      routerLink="/subcontractor/{{subcontractor.id}}/availability-inquiry/{{inquiry.id}}"
      i18n="@@availability-inquiry.inquiry">Förfrågan</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" *ngIf="(!!driveOrders && driveOrders.length > 0)"
      (click)="navigateToDriveOrder()"
      i18n="@@availability-inquiry.driveorders">Körordrar</a>
    <a class="nav-link disabled" *ngIf="!(driveOrders && driveOrders.length > 0)"
      i18n="@@availability-inquiry.inquiry">Körordrar</a>
  </li>
</ul>

<div class="container mt-4">
  <form [formGroup]="form" (ngSubmit)="accept()" novalidate>
    <div class="row justify-content-between ms-0">
      <div class="col-12 col-lg-5 mb-4">
        <h2 i18n="@@availability-inquiry.inquiry">Förfrågan</h2>
        <div class="availabilityInfo">
          <div class="caption-lt" i18n="@@availability-inquiry.trainnumber">Tågnummer</div>
          <div>
            <span *ngIf="inquiry.trainNumber" class="blackish">{{inquiry.trainNumber}}</span>
            <span *ngIf="!inquiry.trainNumber" class="blackish" i18n="@@availability-inquiry.noTrainnumber">Inget tågnummer</span>
          </div>
        </div>
        <div class="availabilityInfo">
          <div class="caption-lt" i18n="@@availability-inquiry.period">Period</div>
          <div class="d-flex align-items-center">
            <span class="blackish">{{ inquiry?.startTripStop?.passTime | amCalendar }}</span>
            <img class="right-arrow mx-2" src="/assets/img/icons/right-arrow.png" alt="Icon right-arrow">
            <span class="blackish">{{ inquiry?.endTripStop?.passTime | amCalendar }}</span>
          </div>
        </div>
        <div class="availabilityInfo">
          <div class="caption-lt">
            <ng-container *ngIf="inquiry?.isRoundTrip" i18n="@@availability-inquiry.round-trip">Tur och
              retur</ng-container>
            <ng-container *ngIf="!inquiry?.isRoundTrip" i18n="@@availability-inquiry.one-way">Enkel</ng-container>
          </div>
          <div class="d-flex align-items-center">
            <span class="blackish">{{inquiry.startOrderRouteStopDescription}}</span>
            <img class="right-arrow mx-2" src="/assets/img/icons/right-arrow.png" alt="Icon right-arrow">
            <span class="blackish">{{inquiry.endOrderRouteStopDescription}}</span>
          </div>
        </div>
        <div class="availabilityInfo">
          <div class="caption-lt" i18n="@@availability-inquiry.order-id">Beställningsnummer</div>
          <div>
            <div class="blackish">{{inquiry.orderId}}</div>
          </div>
        </div>
        <div class="availabilityInfo">
          <div class="caption-lt" i18n="@@availability-inquiry.reason">Orsak</div>
          <div>
            <div class="blackish">{{inquiry?.orderReason?.description}}</div>
          </div>
        </div>
        <div *ngIf="inquiry?.orderReason?.additionalInfo" class="availabilityInfo">
          <div class="caption-lt" i18n="@@availability-inquiry.reason.additionalInfo">Ytterligare info</div>
          <div class="row">
            <div class="col">
              <div class="blackish">{{inquiry?.orderReason?.additionalInfo}}</div>
            </div>
          </div>
        </div>
      </div>
      <div [ngSwitch]="inquiry?.currentState?.stateEnum" class="col-12 col-lg-6">
        <h2 i18n="@@availability-inquiry.demand">Efterfrågan</h2>
        <ng-container *ngSwitchCase="AvailabilityInquiryState.Submitted">
          <div class="availabilityInfo">
            <div class="caption-lt" i18n="@@availability-inquiry.number-of-vehicles">Antal fordon</div>
            <div class="blackish">{{inquiry.numberOfVehicles}}</div>
          </div>
          <div class="availabilityInfo">
            <div class="caption-lt" i18n="@@availability-inquiry.vehicletype">Fordonstyp</div>
            <div class="blackish">{{vehicleType}}</div>
          </div>
          <div class="addInquiry">
            <div>
              <h4 i18n="@@availability-inquiry.enter-availability">Ange tillgänglighet</h4>
            </div>
            <app-availabilities
              [form]="form"
              [numberOfVehiclesLeftToSet]="this.inquiry.numberOfVehicles"
              [orderType]="inquiry?.orderType"
              [disabled]="disabled"
              [isInternal]="false"
              (numberOfVehiclesSetEvent)="setNumberOfVehicle($event)"
            >
            </app-availabilities>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="AvailabilityInquiryState.Rejected">
          <div class="availabilityInfo">
            <div class="caption-lt">
              <ng-container i18n="@@availability-inquiry.rejected">Avvisad</ng-container>
            </div>
            <div>
              {{inquiry.replyInquiry?.repliedTime | date:'yyyy-MM-dd HH:mm'}}
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="AvailabilityInquiryState.Accepted">
          <div class="availabilityInfo">
            <div class="caption-lt">
              <ng-container i18n="@@availability-inquiry.accepted">Accepterad</ng-container>
            </div>
            <div>
              {{inquiry.replyInquiry?.repliedTime | date:'yyyy-MM-dd HH:mm'}}
            </div>
          </div>
          <div *ngFor="let item of inquiry.replyInquiry?.availableVehicles" class="availabilityInfo">
            <div app-availability-inquiry-accepted class="row" [availableVehicle]="item"
              [orderType]="inquiry.orderType"></div>
          </div>
        </ng-container>
      </div>
    </div>
    <hr *ngIf="inquiry?.currentState && inquiry.currentState.stateEnum === AvailabilityInquiryState.Submitted" class="bottom-divider">
    <div *ngIf="inquiry?.currentState && inquiry.currentState.stateEnum === AvailabilityInquiryState.Submitted"
      class="d-flex flex-column align-items-center flex-sm-row justify-content-sm-end">
      <button id="rejectbutton" class="btn btn btn-outline-primary button-margin" type="button" (click)="reject()"
        i18n="@@availability-inquiry.reject">Avvisa</button>
      <button id="acceptbutton" class="btn btn btn-primary button-margin" [disabled]="form.invalid || buttonsDisabled" type="submit"
        i18n="@@availability-inquiry.accept">Acceptera</button>
    </div>
  </form>
</div>
