<h1 class="my-5 text-center" i18n="@@notifications.notifications">Notiser</h1>
<div class="header-container">
  <div class="header-inner">
    <p *ngIf="notifications?.length > 0" class="m-0" i18n="@@notifications.shows-notifications-last-24-hours">Visar notiser för de senaste 24 timmarna</p>
    <p *ngIf="notifications?.length === 0" class="m-0" i18n="@@notifications.no-notifications-last-24-hours">
        Inga notiser de senaste 24 timmarna
    </p>
  </div>
</div>
<div class="container">
  <div *ngFor="let notification of notifications" class="notification" [class.unread]="!notification.isRead" (click)="readNotification(notification)">
    <div class='row align-items-center'>
      <div class='dot-col'>
        <span *ngIf="!notification.isRead" class='dot'></span>
      </div>
      <div class='col message-col'>
        <div class='notification-title caption-lt'>
          <span>{{ notification.title }}</span><span class="float-right">{{ notification.created | amTimeAgo }}</span>
        </div>
        <div class='notification-message'>
          <div class='main-message'>
            {{ notification.main }}
          </div>
          <div class='sub-message'>
            <span class='caption-lt'>{{ notification.submain }}</span>
            <span *ngIf="notification.type !== 'Alert_LateDeparture'" class='caption-md' [class.ms-2]="notification.submain">{{ notification.type }}</span>
            <span *ngIf="notification.type === 'Alert_LateDeparture'" class='caption-md' [class.ms-2]="notification.submain" i18n="@@notifications.alert-latedeparture">Försenad avgång</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
