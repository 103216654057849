import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subcontractor } from '@models/subcontractor';
import { Operator } from '@models/operator';
import { AdminService } from '@services/admin.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-edit-subcontractor',
  templateUrl: './edit-subcontractor.component.html',
  styleUrls: ['./edit-subcontractor.component.scss'],
})
export class EditSubcontractorComponent implements OnInit, OnChanges {
  @Input() operators: Operator[] = null;
  @Input() subcontractor: Subcontractor = null;
  @Output() saveEvent = new EventEmitter<boolean>();
  filteredOperators: Operator[] = [];
  editedSubcontractor: Subcontractor = null;
  showGarageAdminLink = false;

  constructor(private modalService: NgbModal, private adminService: AdminService) {
  }

  ngOnInit(): void {
    this.resetEditedSubcontractor();
    this.setFilteredOperators();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.subcontractor?.currentValue?.code?.startsWith('X')) {
      this.showGarageAdminLink = true;
    }
  }

  resetEditedSubcontractor() {
    this.editedSubcontractor = JSON.parse(JSON.stringify(this.subcontractor));
  }

  openScrollableContent(event: Event, longContent) {
    event.stopPropagation();
    this.modalService.open(longContent, { scrollable: true, centered: true });
  }

  setFilteredOperators() {
    const operatorIds = this.editedSubcontractor?.operators.map(operator => operator.id);
    this.filteredOperators = this.operators?.filter(operator => !operatorIds.includes(operator.id));
  }

  onRemove(operator: Operator) {
    const index = this.editedSubcontractor?.operators.indexOf(operator);
    this.editedSubcontractor?.operators.splice(index, 1);
    this.setFilteredOperators();
  }

  onAdd(operator: Operator) {
    this.editedSubcontractor?.operators.push(operator);
    this.setFilteredOperators();
  }

  onClose() {
    this.resetEditedSubcontractor();
    this.setFilteredOperators();
  }

  onSave() {
    const operatorIds = this.editedSubcontractor?.operators.map(operator => operator.id);
    this.adminService.changeSubcontractorOperators(this.subcontractor.id, operatorIds)
      .subscribe({
        next: () => {
          this.saveEvent.emit();
        },
        error: (err) => {
          console.log('error ' + err);
        },
      });
  }
}
