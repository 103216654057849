import { Component, inject, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';


@Component({
  selector: 'app-login-failed',
  templateUrl: './login-failed.component.html',
  styleUrls: ['./login-failed.component.scss'],
})
export class LoginFailedComponent implements OnInit {

  authService: AuthService = inject(AuthService);

  ngOnInit(): void {
    this.authService.logout();
  }
}
