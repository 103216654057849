import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { OrderByService } from '../../../../common/services/order-by.service';
import { UserRoles } from '../../../../authentication/user-roles';
import { AuthService } from '../../../../common/services/auth.service';
import { SubcontractorOrderService } from '../../../../common/services/subcontractor-order.service';
import { SubcontractorOrder } from '../../../../common/models/subcontractor-order';
import { Vehicle } from '../../../../common/models/vehicle';
import { GeodataService } from '../../../../common/services/geodata.service';
import { DriveOrderTripRoute } from '../../../../common/models/drive-order';
import { DriveOrderService } from '../../../../common/services/drive-order.service';
import { RouteVariant } from '../../../../common/models/route';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-subcontractor-route-map',
  templateUrl: './subcontractor-route-map.component.html',
  styleUrls: ['./subcontractor-route-map.component.scss']
})
export class SubcontractorRouteMapComponent implements OnInit {

  subcontractorId: number;
  subcontractorOrderId: number;
  subcontractorOrder = new SubcontractorOrder();
  routeGeoData: any = null;
  vehicleGeoData: any = null;
  UserRoles = UserRoles;
  disableStreetView = true;
  isHistory: boolean;
  vehicles: Vehicle[] = null;
  mapForm: UntypedFormGroup;
  routeVariants: DriveOrderTripRoute[];

  constructor(
    private route: ActivatedRoute,
    private driveOrderService: DriveOrderService,
    private orderByService: OrderByService,
    private geodataService: GeodataService,
    private subcontractorOrderService: SubcontractorOrderService,
    private authService: AuthService,
    private fb: UntypedFormBuilder
  ) {
  }


  ngOnInit() {

    this.mapForm = this.fb.group({
      routeVariant: null
    });

    this.route.url.subscribe(current => {
      this.isHistory = (current !== undefined && current.length > 2 && current[2].path === 'history');
    });
    this.subcontractorId = +this.route.snapshot.paramMap.get('subcontractorId');
    this.subcontractorOrderId = +this.route.snapshot.paramMap.get('id');
    if (this.subcontractorOrderId !== 0) {
      this.subcontractorOrderService.getSubcontractorOrder(this.subcontractorOrderId)
        .subscribe(result => this.onSubcontractorOrderRetrieved(result.body));

      this.subcontractorOrderService.getActiveVehicles(this.subcontractorOrderId)
        .subscribe(result => this.onActiveVehiclesRetrieved(result.body));
    }
  }

  onSubcontractorOrderRetrieved(subcontractorOrder: SubcontractorOrder) {
    this.subcontractorOrder = subcontractorOrder;
    this.driveOrderService.getDriveOrderTripRoutes(this.subcontractorOrder.orderId)
      .subscribe(result => this.onDriveOrderTripRoutesRetrieved(result.body));
  }

  onDriveOrderTripRoutesRetrieved(routeVariants: DriveOrderTripRoute[]) {
    this.routeVariants = this.orderByService.transform(routeVariants, ['routeName', 'routeVariantName']);
    if (this.routeVariants.length === 1) {
        this.setSingleRouteVariant();
    }
  }

  setSingleRouteVariant() {
    this.mapForm.patchValue({
      routeVariant: this.routeVariants[0].routeVariantName
    });
    this.mapForm.disable();
    this.onRouteVariantChanged(this.routeVariants[0].routeVariantId);
  }

  onRouteGeoDataRetrieved(geoData: any) {
    this.routeGeoData = geoData;
  }
  onVehicleGeoDataRetrieved(geoData: any) {
    this.vehicleGeoData = geoData;
  }
  onActiveVehiclesRetrieved(vehicles: any) {
    this.vehicles = vehicles;
    if (this.vehicles !== null) {
      if (this.vehicles.length > 0) {
        this.getVehicleGeoData();
      }
    }
  }

  onRouteVariantChanged(routeVariantId?: number) {
    let routeVariant = new RouteVariant();
    if (routeVariantId !== undefined) {
      routeVariant.routeVariantId = routeVariantId;
    } else {
      routeVariant = this.mapForm.get('routeVariant').value;
    }
    this.geodataService.getRouteGeoData(this.subcontractorOrder.orderId, routeVariant.routeVariantId)
      .subscribe(result => this.onRouteGeoDataRetrieved(result.body));
  }

  getVehicleGeoData() {
    const vehicleIdentifiers = this.vehicles.map(a => a.vehicleIdentifier).join(',');
    this.geodataService.getVehicleGeoData(vehicleIdentifiers)
      .subscribe(result => this.onVehicleGeoDataRetrieved(result.body));
  }

  userHasAnyRole(userRoles: UserRoles[]) {
    return this.authService.userHasAnyRole(userRoles);
  }

}
