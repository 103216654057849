import { Component, OnInit } from '@angular/core';
import { NotificationMessage } from '@models/notification-message';
import { NotificationService } from '@services/notification.service';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { ServerNotificationService } from '@services/server-notification.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {

  notifications: NotificationMessage[];

  constructor(private notificationService: NotificationService,
              private router: Router,
              private serverNotificationService: ServerNotificationService) {
  }

  ngOnInit() {
    this.notificationService.getNotifications().pipe(map((notifications) => {
      return notifications.map(notification => {
        if (notification.notificationType) {
          return this.serverNotificationService.setServerNotificationLocalization(notification);
        } else {
          return notification;
        }
      });
    })).subscribe(n => {
      this.notifications = n;
    });
  }

  readNotification(notification: NotificationMessage) {
    this.notificationService.addNotificationRead([notification.id]).subscribe(() => {
      let unreadNotificationsCount = this.notifications.filter(un => !un.isRead).length;
      unreadNotificationsCount = unreadNotificationsCount > 0 ? unreadNotificationsCount - 1 : 0;
      this.notificationService.updateUnreadNotifications(unreadNotificationsCount);

      if (notification.link.indexOf('/order-new/') !== -1) {
        window.location.href = `${window.location.origin}${notification.link}`;
      } else if (notification.link.indexOf('drive-order') === -1) {
        this.router.navigateByUrl(notification.link);
      } 

      if (window.location.pathname === notification.link) {
        window.location.reload();
      }
    });
  }
}
