import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { UnboundOrder } from '../../common/models/unbound-order';
import { OrderType } from '../../common/models/order-type';
import { UnboundOrderService } from '../../common/services/unbound-order.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { OrderByService } from '../../common/services/order-by.service';
import { UserRoles } from '../../authentication/user-roles';
import { AuthService } from '../../common/services/auth.service';
import { UnboundOrderState } from '../../common/models/unbound-order-state';
import { SessionStorage } from '../../common/sessionStorage.decorator';
import { DateRange } from '../../common/models/dateRange';
import { roundOffMinutes } from '@common/date-extension';
import { DateFilterOption } from '@services/DateFilterOption';

@Component({
  selector: 'app-unbound-order-list',
  templateUrl: './unbound-order-list.component.html',
  styleUrls: ['./unbound-order-list.component.scss']
})
export class UnboundOrderListComponent implements OnInit, OnDestroy {

  @SessionStorage
  ordersForMenu: string;
  dateFilterOptionEnum = DateFilterOption;
  status: string;
  dateFilter: Date;
  paramsub: Subscription;
  orders: UnboundOrder[];
  filterForm: UntypedFormGroup;
  ordertype: number;
  isHistory: boolean;
  dateRangeFilter: DateRange;

  OrderType = OrderType;
  UserRoles = UserRoles;

  orderFilter = function (o: UnboundOrder) {
    if (this.isHistory) {
      return this.showCompleted(o);
    }
    return !this.showCompleted(o);
  };

  constructor(private fb: UntypedFormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private unboundOrderService: UnboundOrderService,
              private orderByService: OrderByService,
            private authService: AuthService) { }

  ngOnInit() {
    this.dateRangeFilter = DateRange.Default();
    this.isHistory = this.router.url.startsWith('/history');
    this.paramsub = this.route
      .queryParams
      .subscribe(params => {
        // Låt denna vara bortkommenterad!
        // Vi får ett event onDateRangeChanged som triggar en första hämtning
        this.getOrders(this.isHistory);
      });
    this.createFilterForm();
  }

  showCompleted(o: UnboundOrder): boolean {
    if (o.currentState.stateEnum === UnboundOrderState.Completed ||
      o.currentState.stateEnum === UnboundOrderState.CancellationConfirmed) {
      return true;
    }
    return false;
  }

  isUnboundOrder(): boolean {
    return true;
  }

  public onDateRangeChanged(dateRange: DateRange): void {
    this.dateRangeFilter = dateRange;
    this.getOrders(this.isHistory);
  }

  createFilterForm() {
    this.filterForm = this.fb.group({
      routeFilter: '',
      statusFilter: null
    });
  }

  getOrders(orderByDesc: boolean) {
    this.unboundOrderService.getOrders((this.isHistory ? this.dateRangeFilter : undefined))
    .subscribe(
      orders => {
        this.orders = orders.body;
        roundOffMinutes(this.orders.flatMap(ot => ot.startTime));
        this.orders = this.orders.filter(a => this.orderFilter(a));
        this.orders = this.orderByService.transform(this.orders, 'startTime', orderByDesc);
      });
  }

  getOrderLink(orderId: number) {
    const base = this.isHistory ? '/history/unboundorder/' : '/unboundorder/';
    return base + orderId;
  }

  newOrder() {
    this.router.navigateByUrl('/unboundorder');
  }

  ngOnDestroy() {
    this.paramsub.unsubscribe();
  }
  userHasAnyRole(userRoles: UserRoles[]) {
    return this.authService.userHasAnyRole(userRoles);
  }

  getOrderStartPlace(orderId: number): string {
    return this.orders.filter(x => x.id === orderId)[0].fromPlace;
  }

  getOrderEndPlace(orderId: number): string {
    return this.orders.filter(x => x.id === orderId)[0].toPlace;
  }

  // true om datum ligger mer än en vecka framåt i tiden
  isAheadWeek(d: Date): boolean {
    const weekAhead = new Date();
    weekAhead.setDate(weekAhead.getDate() + 7);

    return d > weekAhead;
  }

}
