import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { SubcontractorOrder, SubcontractorOrderRequest, SubcontractorOrderTrips } from '@models/subcontractor-order';
import { SubContractorListSearch } from '@models/list-search';
import { AvailableVehicle } from '@models/availability-inquiry';

@Injectable()
export class SubcontractorOrderService {
  private baseUrl = environment.apiUrl + environment.apiVersionMain + '/subcontractororders';

  constructor(private http: HttpClient) {
  }

  reviver(key, value): any {
    if (key === 'startTime' || key === 'endTime') {
      return new Date(value);
    }
    return value;
  }

  getSubcontractorOrders(subcontractorid: number): Observable<HttpResponse<SubcontractorOrder[]>> {
    return this.http.get<SubcontractorOrder[]>(`${this.baseUrl}?subcontractorid=${subcontractorid}`, { observe: 'response' });
  }

  getSubcontractorOrdersList(search: SubContractorListSearch): Observable<HttpResponse<SubcontractorOrderTrips>> {
    let url = `${this.baseUrl}/list?subcontractorid=${search.subcontractorid}&listtype=${search.listType}&page=${search.page}&pageSize=${search.pageSize}`

    return this.http.get<SubcontractorOrderTrips>(url, { observe: 'response' });
  }

  getSubcontractorOrdersFromQuery(query = ''): Observable<HttpResponse<SubcontractorOrder[]>> {
    return this.http.get<SubcontractorOrder[]>(`${this.baseUrl}` + query, { observe: 'response' });
  }

  getSubcontractorOrder(id: number): Observable<HttpResponse<SubcontractorOrder>> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<SubcontractorOrder>(url, { observe: 'response' });
  }

  getSubcontractorOrderFromLocation(url: string): Observable<HttpResponse<SubcontractorOrder>> {
    return this.http.get<SubcontractorOrder>(url, { observe: 'response' });
  }

  directAssign(orderId: number, subcontractorId: number, assignedVehiclesList: AvailableVehicle[]): Observable<HttpResponse<any>> {
    return this.Assign(orderId, subcontractorId, undefined, assignedVehiclesList)
  }
  inquiryAssign(orderId: number, subcontractorId: number, inquiryId: number, assignedVehiclesList: AvailableVehicle[]): Observable<HttpResponse<any>> {
    return this.Assign(orderId, subcontractorId, inquiryId, assignedVehiclesList)
  }
  private Assign(orderId: number, subcontractorId: number, inquiryId: number, assignedVehiclesList: AvailableVehicle[]): Observable<HttpResponse<any>> {
    const request = new SubcontractorOrderRequest();
    request.assignedVehicles = assignedVehiclesList;
    request.availabilityInquiryId = inquiryId;
    request.orderId = orderId;
    request.subcontractorId = subcontractorId;

    const url = `${this.baseUrl}`;
    return this.http.post<SubcontractorOrderRequest>(url, request, { observe: 'response' });
  }

  // Active vehicle for subcontractorOrder
  getActiveVehicles(subcontractorOrderId: number):
    Observable<HttpResponse<string>> {

    const url = `${this.baseUrl}/${subcontractorOrderId}/vehicles`;
    return this.http.get<string>(url, { observe: 'response' });
  }

}
