<h1 class="my-5 text-center"  i18n='@@import-order.title'>Administration</h1>
<app-admin-menu></app-admin-menu>
<div class='container'>
  <div>
    <form [formGroup]='importForm' (ngSubmit)='import()' novalidate class='form ms-5 mt-4'>
      <ng-container i18n='@@import-order.intructions'>
        <p>OBS! Detta ska göras i testmiljön för en specifik fil innan det görs i produktion.</p>
        <p>Det kan ta upp till 30 minuter innan inläsningen är klar. För större beställningar kan ett felmeddelande
          visas fast inläsningen fortsätter och går bra.
          Titta under <a routerLink='/orders' [queryParams]='{ordertype: 3}'>Beställningar - Planerad</a> för att se om
          inläsningen av beställningen är klar.
        <p>Har beställningen inte kommit in efter 30 minuter kontakta support via <a
          href='https://nobina.topdesk.net/tas/public/ssp/'>Nobinas Självbetjäningsportal</a></p>
      </ng-container>
      <div class='my-3'>
        <div class='col-6'>
          <label class='mb-2' for='operator' i18n='@@import-order.traffic-operator'>Trafikoperatör</label>
          <select class='form-select form-control' id='operator' formControlName='operator'>
            <option [ngValue]='null' [disabled]='true' i18n='@@import-order.select-traffic-operator'>Välj
              trafikoperatör
            </option>
            <option *ngFor='let op of operators' [ngValue]='op'>{{ op.name }}</option>
          </select>
          <div *ngIf='operatorCtrl.invalid && (operatorCtrl.dirty || operatorCtrl.touched)' class='text-danger mt-1'
               i18n='@@import-order.traffic-operator-mandatory'>
            Trafikoperatör är obligatoriskt
          </div>
        </div>
      </div>
      <div class='my-3'>
        <div class='col-6'>

          <label class='mb-2' for='file-input' i18n='@@import-order.file-with-planned-order'>Fil med planerad beställning</label>
          <div id="file-input" class='input-group custom-file-button'>
            <input type='file' class='form-control' id='importFile' accept='text/xml' formControlName="importFile" (change)="onFileChange($event.target.files)">
            <label i18n="@@import-order.select-file" class='input-group-text' for='importFile'>Välj fil</label>
          </div>

          <div *ngIf='fileInputCtrl.invalid && (fileInputCtrl.dirty || fileInputCtrl.touched)' class='text-danger mt-1'
               i18n='@@import-order.file-mandatory'>
            Fil är obligatoriskt
          </div>
        </div>
      </div>
      <div class='mt-5 mb-3'>
        <div class='col-6 d-grid gap-2'>
          <button class='btn btn-primary float-right' type='button' [disabled]='uploading'
                  (click)='import()' i18n='@@import-order.import'>
            Läs in
          </button>
        </div>
      </div>
      <div>
        <h3 *ngIf='uploading' i18n='@@import-order.wait'>Vänta under tiden filen läses in...</h3>
        <h3 *ngIf='importedOrderId' class='col-6' i18n='@@import-order.order-created'>
          Beställning med nummer <a href='/order/planned-order/{{importedOrderId}}'>{{importedOrderId}}</a> har skapats
          från filen "{{this.fileName}}".
        </h3>
      </div>
    </form>
  </div>
</div>
