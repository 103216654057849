import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ListType } from '@models/list-type';
import { AvailabilityInquiryState } from '@models/availability-inquiry';
import { Subcontractor } from '@models/subcontractor';

@Component({
  selector: 'app-subcontractor',
  templateUrl: './subcontractor.component.html',
  styleUrls: ['./subcontractor.component.scss']
})
export class SubcontractorComponent implements OnInit {
  subcontractor: Subcontractor = new Subcontractor();
  path: string;

  availabilityInquiryState: number = AvailabilityInquiryState.Submitted;
  listState: number = ListType.Ongoing;

  AvailabilityInquiryState = AvailabilityInquiryState;
  ListType = ListType;

  constructor(
    public route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.path = this.router.url;
    this.subcontractor.id = +this.route.snapshot.paramMap.get(
      'subcontractorId'
    );

    this.availabilityInquiryState = sessionStorage.getItem('availabilityInquiryState') ?
      +sessionStorage.getItem('availabilityInquiryState') : AvailabilityInquiryState.Submitted;
    this.listState = sessionStorage.getItem('listState') ? 
      +sessionStorage.getItem('listState') : ListType.Ongoing;
  }

  changeAvailabilityInquiryState(state: AvailabilityInquiryState) {
    this.availabilityInquiryState = state;
    sessionStorage.setItem('availabilityInquiryState', this.availabilityInquiryState.toString());
  }

  changeListState(state: ListType) {
    this.listState = state;
    sessionStorage.setItem('listState', this.listState.toString());
  }
}
