import { TripStopSummary } from '@models/drive-order';

export function roundOffMinutes(dates: Date[]) {
  dates.map(date => roundOffMinute(date));
}

export function datesAreEqual(date1: Date, date2: Date): boolean {
  return date1?.getTime() === date2?.getTime();
}

function roundOffMinute(time: Date) {
  if (time?.getSeconds() > 30) {
    time.setMinutes(time.getMinutes() + 1);
  }
  time?.setSeconds(0);
}

export function betweenMidnightAndEndOfTrafficDate(startTripStop: TripStopSummary) {
  return (
    startTripStop.orderedTime.getFullYear() === startTripStop.passTime.getFullYear() &&
    startTripStop.orderedTime.getMonth() === startTripStop.passTime.getMonth() &&
    startTripStop.orderedTime.getDate() !== startTripStop.passTime.getDate() &&
    startTripStop.passTime.getHours() < 4
  );
}
