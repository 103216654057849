import { Component, Injector } from '@angular/core';
import { ApplicationInsightsService } from '../services/application-insights.service';

@Component({
  template: ''
})
export class BaseComponent {
  private appInsightsService: ApplicationInsightsService;

  constructor() {
    // Manually retrieve the monitoring service from the injector
    // so that constructor has no dependencies that must be passed in from child
    const injector = Injector.create([{provide: ApplicationInsightsService, deps: []}]);
    this.appInsightsService = injector.get(ApplicationInsightsService);
    this.logNavigation();
  }

  private logNavigation() {
    this.appInsightsService.logPageView();
  }
}
