import { DateRange } from './dateRange';
import { ListType, SortBy } from './list-type';

export class ListSearch {
  listType: ListType;
  dateRange: DateRange;
  orderIds: number[] = [];
  referenceIds: string[] = [];
  driveOrderIds: number[] = [];
  operators: number[] = [];
  externalIds: string[] = [];
  tripIds: number[] = [];
  trainNumber = '';
  subContractor = '';
  pageSize: number;
  page: number;
  sortDesc: boolean;
  sortBy: SortBy;
}

export class SubContractorListSearch {
  subcontractorid: number;
  listType: number;
  pageSize: number;
  page: number;
}
