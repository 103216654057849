import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { roundOffMinutes } from '@common/date-extension';
import { AvailabilityInquiry, AvailabilityInquiryState } from '@models/availability-inquiry';
import { OrderState } from '@models/order-state';
import { OrderType } from '@models/order-type';
import { Subcontractor } from '@models/subcontractor';
import { SubcontractorOrder, SubcontractorOrderState } from '@models/subcontractor-order';
import { AvailabilityInquiryService } from '@services/availability-inquiry.service';
import { NotificationService } from '@services/notification.service';
import { OrderByService } from '@services/order-by.service';
import { SubcontractorOrderService } from '@services/subcontractor-order.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-subcontractor-history',
  templateUrl: './subcontractor-history.component.html',
  styleUrls: ['./subcontractor-history.component.scss']
})
export class SubcontractorHistoryComponent implements OnInit, OnDestroy {
  subcontractor: Subcontractor = new Subcontractor();
  state: number;
  paramsub: Subscription;
  stateChangedMessageSub: Subscription;

  availabilityInquiries: AvailabilityInquiry[];
  subcontractorOrders: SubcontractorOrder[];

  OrderType = OrderType;
  OrderState = OrderState;
  stateDescription = $localize`:Beställd@@subcontractor-order-list.state-ordered:Beställd`;
  isAvailabilityInquiry: boolean;

  constructor(
    private route: ActivatedRoute,
    private availabilityInquiryService: AvailabilityInquiryService,
    private orderByService: OrderByService,
    private notificationService: NotificationService,
    private subcontractorOrderService: SubcontractorOrderService,
  ) { }

  ngOnInit(): void {
    this.subcontractor.id = +this.route.snapshot.paramMap.get(
      'subcontractorId'
    );

    this.paramsub = this.route.queryParams.subscribe(params => {
      this.isAvailabilityInquiry = +params['availabilityinquiry'] === 1;
      this.state = +params['state'] || (this.isAvailabilityInquiry ? 0 : OrderType.Unplanned);

      if (this.state > 3) { this.state = OrderType.Unplanned; }

      if (this.isAvailabilityInquiry) {
        this.getAvailabilityInquiries();
      } else {
        this.getSubcontractorOrders();
      }
    });
    this.stateChangedMessageSub = this.notificationService.getStateChangedMessage().subscribe(message => {
      if (message.referenceType === 'AvailabilityInquiry'
        && this.isAvailabilityInquiry) {
        // TODO vi kan få notifiering INNAN main-servicen committat till databasen.
        // Då kommer detta anrop inte fånga upp ändrade data!!
        this.getAvailabilityInquiries();
      }
    });
  }

  ngOnDestroy() {
    this.paramsub.unsubscribe();
    this.stateChangedMessageSub.unsubscribe();
  }

  subcontractorOrderFilter = function (c: SubcontractorOrder, ordertype: number) {
    // Om vi vill se de pågående beställningarna ska förfrågan-status = Ongoing, oavsett ordertyp
    if (ordertype === OrderType.AnyType) {
      this.stateDescription = $localize`:Pågående@@subcontractor-order-list.state-ongoing:Pågående`;
      return c.currentState.stateEnum === SubcontractorOrderState.Ongoing;
    }

    // Om vi vill Planerad-fliken
    if (ordertype === OrderType.Planned) {
      return (
        c.orderType === OrderType.Planned &&
        c.currentState.stateEnum === SubcontractorOrderState.Completed
      );
    }

    // Om vi vill Oplanerad-fliken
    this.stateDescription = $localize`:Avslutad@@subcontractor-order-list.state-completed:Avslutad`;
    return (this.showContractorOrderAsUnplanned(c) && this.showCompleted(c));

  };


  showCompleted(o: SubcontractorOrder): boolean {
    return o.currentState.stateEnum === SubcontractorOrderState.Completed;
  }

  showContractorOrderAsUnplanned(c: SubcontractorOrder): boolean {
    return (c.currentState.stateEnum !== SubcontractorOrderState.Ongoing &&
      (c.orderType === OrderType.Urgent || c.orderType === OrderType.Unplanned));
  }

  getSubcontractorOrders() {
    this.subcontractorOrderService
      .getSubcontractorOrders(this.subcontractor.id)
      .subscribe(s => {
        this.subcontractorOrders = s.body;
        roundOffMinutes([
          ...this.subcontractorOrders.flatMap(ai => ai.startTripStop.passTime),
          ...this.subcontractorOrders.flatMap(ai => ai.endTripStop.passTime),
          ...this.subcontractorOrders.flatMap(ai => ai.currentState.updated)
        ]);
        this.subcontractorOrders = this.subcontractorOrders.filter(c =>
          this.subcontractorOrderFilter(c, this.state)
        );
        this.subcontractorOrders = this.orderByService.transform(
          this.subcontractorOrders,
          'subcontractorOrder.startTripStop.passTime',
          true
        );
      });
  }

  // #region Availability Inquiry (copied from availability-inquiry-list component) för historik av ej tilldelade
  getAvailabilityInquiries() {
    this.availabilityInquiryService.getAvailabilityInquiriesForSubcontractor(this.subcontractor.id)
      .subscribe(
        s => {
          this.availabilityInquiries = s.body;
          roundOffMinutes([
            ...this.availabilityInquiries.flatMap(ai => ai.startTripStop?.passTime),
            ...this.availabilityInquiries.flatMap(ai => ai.endTripStop?.passTime),
            ...this.availabilityInquiries.flatMap(ai => ai.replyInquiry?.repliedTime),
            ...this.availabilityInquiries.flatMap(ai => ai.currentState?.updated)
          ]);
          this.availabilityInquiries = this.availabilityInquiries.filter(a => a.currentState.stateEnum === AvailabilityInquiryState.NotAssigned);
          this.availabilityInquiries = this.orderByService.transform(this.availabilityInquiries, 'startTripStop.passTime', true);
        });
  }

  getStartPlace(availabilityInquiry: AvailabilityInquiry): string {
    return availabilityInquiry.startTripStop.placeDescription;
  }

  getStartTime(availabilityInquiry: AvailabilityInquiry): Date {
    return availabilityInquiry.startTripStop.passTime;
  }

  getEndPlace(availabilityInquiry: AvailabilityInquiry): string {
    return availabilityInquiry.endTripStop.placeDescription;
  }

  getEndTime(availabilityInquiry: AvailabilityInquiry): Date {
    return availabilityInquiry.endTripStop.passTime;
  }

  isReplied(availabilityInquiry: AvailabilityInquiry): boolean {
    return availabilityInquiry.replyInquiry !== null
      && availabilityInquiry.replyInquiry.repliedTime !== null;
  }
  // #endregion
}
