export class AssignDriveOrderRequest {
  driverId: number;
  vehicleId: number;
}

export class AssignDriverToDriveOrderTripRequest {
  driverId: number;
  driveOrderTripId: number;
}

export class AssignVehicleToDriveOrderRequest {
  vehicleId: number;
}

export class AssignSubcontractorToDriveOrderRequest {
  subcontractorId: number;
}

export class CancelDriveOrderTripRequest {
  id: number;
  driveOrderTripId: number;
}
