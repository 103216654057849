import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-clear',
  template: ``,
})
export class ClearComponent {

  router = inject(Router);

  constructor() {
    localStorage.clear();
    this.router.navigate(['/']);
  }
}
