export class Route {
  id: number;
  routeName: string;
  description: string;
  routeVariants: RouteVariantWithConnection[];
}

export class RouteVariant {
  id: number;
  routeVariantName: string;
  routeVariantId: number;
  scheduledStopPoints: ScheduledStopPoints[];
}

export class RouteVariantWithConnection {
  id: number;
  routeVariantName: string;
  routeVariantId: number;
  routeVariantConnection: RouteVariant;
  routeId: number;
  routeName: string;
  scheduledStopPoints: ScheduledStopPoints[];
}

export class ScheduledStopPoints {
  sequence: number;
  place: Place;
  active: boolean;
  disembarkingOnly: boolean;
}

export class Place {
  placeId: string;
  description: string;
}

