import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AvailableVehicle } from '@models/availability-inquiry';
import { OrderType } from '@models/order-type';

@Component({
  selector: 'app-availabilities-form',
  template: `
    <form [formGroup]="form" class="overflow-hidden">
      <app-availabilities
        [form]="form"
        [numberOfVehiclesLeftToSet]="numberOfVehiclesLeftToSet"
        [orderType]="orderType"
        [disabled]="disabled"
        [isInternal]="isInternal"
        [vehicles]="vehicles"
        [isAssigned]="isAssigned"
        (numberOfVehiclesSetEvent)="setNumberOfVehicles($event)"
      >
      </app-availabilities>
      <div class="p-3 text-end" *ngIf="!isAssigned">
        <button class="assign btn" [disabled]="isAssigned || !this.form.valid" type="submit" (click)="save()">
          <ng-container *ngIf="isInternal || vehicles" i18n="@@availabilities-form.assign">
            Tilldela
          </ng-container>
          <ng-container *ngIf="!isInternal && !vehicles" i18n="@@availabilities-form.direct-assign">
            Direkttilldela
          </ng-container>
          ( {{ numberOfVehiclesSet }} )
        </button>
      </div>
    </form>
  `,
  styleUrls: ['./availabilities-form.component.scss']
})
export class AvailabilitiesFormComponent implements OnInit {
  @Input() numberOfVehiclesLeftToSet: number;
  @Input() disabled: boolean;
  @Input() orderType: OrderType;
  @Input() isInternal: boolean;
  @Input() vehicles: AvailableVehicle[];
  @Input() isAssigned: boolean;

  @Output() saveEvent = new EventEmitter();

  numberOfVehiclesSet: number;
  form: FormGroup;

  get availableVehicleItems() {
    return this.form.controls['availableVehicleItems'] as FormArray;
  }

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      availableVehicleItems: this.fb.array([], [this.requiredShouldAssignValidator()])
    });
  }

  setNumberOfVehicles($event: number) {
    this.numberOfVehiclesSet = $event;
  }

  private requiredShouldAssignValidator(): ValidatorFn {
    return (formArray : FormArray) : ValidationErrors => {
      if (this.vehicles) {
        let anySelected : boolean = false;

        formArray.controls.forEach((inquiryVehicle: FormControl) => {
          if (inquiryVehicle.value.shouldAssign) {
            anySelected = true;
          }
        });
        return anySelected ? null : { requiredShouldAssignError: true };
      } else {
        return null;
      }
    }
  }

  save() {
    this.disabled = true;
    let list: AvailableVehicle[] = []

    this.availableVehicleItems.getRawValue().forEach(item => {
      if (item.shouldAssign) {
        list.push({
          count: +item.numberOfVehicles.numberOfVehicles,
          estimatedArrivalMinutes: +item.estimatedArrivalMinutes.estimatedArrivalMinutes,
        });
      }
    });

    if (this.availableVehicleItems.length > 0) {
      this.saveEvent.emit(list);
    } else {
      this.disabled = false;
    }
  }
}
