import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UnboundOrderService } from '@services/unbound-order.service';
import { UnboundOrderState } from '@models/unbound-order-state';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserRoles } from '@authentication/user-roles';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-cancel-unbound-order',
  templateUrl: './cancel-unbound-order.component.html',
  styleUrls: ['./cancel-unbound-order.component.scss']
})
export class CancelUnboundOrderComponent implements OnInit {

  @Input() orderId: number;

  isNobinaOperator = false;
  buttonsDisabled = false;
  form: FormGroup;

  constructor(public activeModal: NgbActiveModal, private unboundOrderService: UnboundOrderService,
              private fb: FormBuilder, private authService: AuthService) {
    this.form = this.fb.group({
      reason: ['', Validators.required],
      comment: [''],
    });

    this.isNobinaOperator = this.authService.userHasAnyRole([UserRoles.NobinaTrafikledare, UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare]);
  }

  ngOnInit() {
  }

  cancelOrder() {
    this.buttonsDisabled = true;
    const formValue = this.form.value;
    this.unboundOrderService.updateOrderState(this.orderId, UnboundOrderState.Cancelled, +formValue.reason, formValue.comment)
      .subscribe({
        next: () => this.activeModal.close(),
        error: () => this.activeModal.dismiss()
      });
  }

}
