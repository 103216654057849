import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponse, HttpClient } from '@angular/common/http';

import { ApplicationInsightsService } from './application-insights.service';
import { HubConnection } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { environment } from '../../../environments/environment';
import { AdminService } from './admin.service';
import { AuthService } from './auth.service';
import { ConfirmModalComponent } from '@common/components/confirm-modal/confirm-modal.component';
import { NotificationMessage } from '@models/notification-message';
import { StateChangedMessage } from '@models/statechanged-message';
import { UserRoles } from '@authentication/user-roles';
import { AccountInfo } from '@azure/msal-common';
import { ServerNotificationService } from '@services/server-notification.service';

@Injectable()
export class NotificationService {
  private baseUrl = environment.apiUrl + environment.apiVersionMain + '/notifications';

  private _hubConnection: HubConnection | undefined;
  private hubUrl = environment.apiUrl + 'notificationhub';
  private loadedUserSub: any;
  private user: AccountInfo;
  private lastNotification: NotificationMessage = null;
  private lastServerNotification: NotificationMessage = null;
  hideNotifications = false;
  private unreadNotificationsCountSource = new Subject<number>();
  private unreadNotificationsCount$ = this.unreadNotificationsCountSource.asObservable();
  private stateChangedMessageSource = new Subject<StateChangedMessage>();
  private stateChangedMessage$ = this.stateChangedMessageSource.asObservable();
  private lastStateChangedMessage: StateChangedMessage = null;
  private notificationMessageSource = new Subject<NotificationMessage>();
  private notificationMessage$ = this.notificationMessageSource.asObservable();

  constructor(private toastr: ToastrService,
    private authService: AuthService,
    private appInsightsService: ApplicationInsightsService,
    private adminService: AdminService,
    private modalService: NgbModal,
    private router: Router,
    private http: HttpClient,
              private serverNotificationService: ServerNotificationService) {

    this.init();
  }

  init(): void {
    this.loadedUserSub = this.authService.userLoadededEvent$
      .subscribe(authUser => {
        this.user = authUser;
        if (authUser && authUser.idTokenClaims !== undefined && authUser.idTokenClaims !== null) {
          this.adminService.getUser().subscribe(u => {
            this.initSignalR(authUser, u.body && u.body.selectedOperators.map(op => op.id));
          });
          this.getNotifications(true).subscribe(n => {
            this.updateUnreadNotifications(n.length);
          });
        }
      });
    // Gör det möjligt att dölja webbnotiser i mobil-appens webbvy
    this.hideNotifications = sessionStorage.getItem('hideMenu') === 'true';
  }

  initSignalR(user: AccountInfo, selectedOperatorIds: number[]): void {

    if (this.hideNotifications) {
      return;
    }

    this._hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.hubUrl)
      .withHubProtocol(new signalR.JsonHubProtocol())
      .configureLogging(signalR.LogLevel.Error)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
            if (retryContext.elapsedMilliseconds < 600000) {
                // If we've been reconnecting for less than 10 min seconds so far,
                // wait between 0 and 10 seconds before the next reconnect attempt.
                return Math.random() * 10000;
            } else {
                // If we've been reconnecting for more than 10 min so far, stop reconnecting.
                return null;
            }
        }
      })
      .build();

    this.startSignalR(user, selectedOperatorIds);
    this._hubConnection.on('ServerMessage', (notification: NotificationMessage) => {
      // Undvik multipla notiser per fönster om användaren skulle ha öppnat webben flera gånger eller
      // har flera tabbar/fönster öppna, och därmed har flera aktiva hubbar.
      if (this.lastNotification === null || (this.lastNotification !== null && !(
        notification.link === this.lastNotification.link &&
        notification.main === this.lastNotification.main &&
        notification.submain === this.lastNotification.submain &&
        notification.title === this.lastNotification.title))
      ) {
        if (notification.type === 'Alert_LateDeparture') {
          this.openAlertModal(notification);
        } else {
          const comp = this.toastr.show().toastRef.componentInstance;
          comp.notification = notification;
        }
      }
      this.lastNotification = notification;

      this.getNotifications(true).subscribe(n => {
        this.updateNotificationMessage(n[0]);
        this.updateUnreadNotifications(n.length);
      });
    });

    this._hubConnection.on('StateChangedMessage', (message: StateChangedMessage) => {
      if (this.lastStateChangedMessage === null || (this.lastStateChangedMessage !== null && !(
        message.referenceId === this.lastStateChangedMessage.referenceId &&
        message.referenceType === this.lastStateChangedMessage.referenceType &&
        message.message === this.lastStateChangedMessage.message &&
        message.updated === this.lastStateChangedMessage.updated))
      ) {
        this.stateChangedMessageSource.next(message);
      }
      this.lastStateChangedMessage = message;
    });

    this._hubConnection?.on('ServerNotification', (notification: NotificationMessage) => {
      let formattedNotification: NotificationMessage;
      if (
        this.lastServerNotification === null ||
        !(
          notification.data === this.lastServerNotification.data &&
          notification.notificationType === this.lastServerNotification.notificationType &&
          notification.id === this.lastServerNotification.id
        )
      ) {
        if (notification.notificationType) {
          formattedNotification = this.serverNotificationService.setServerNotificationLocalization(notification);
        }
          const comp = this.toastr.show().toastRef.componentInstance;
          comp.notification = formattedNotification;
      }
      this.lastServerNotification = formattedNotification;

      this.getNotifications(true).subscribe(n => {
        this.updateNotificationMessage(n[0]);
        this.updateUnreadNotifications(n.length);
      });
    });
  }

  startSignalR(user: AccountInfo, selectedOperatorIds: number[]) {
    this._hubConnection.start()
      .then(() => {
        // Join groups for each role and role-operator combination
        const roles = user.idTokenClaims.role as string[];
        roles.forEach(role => {
          const roleGroup = 'role' + role;
          this.joinGroup(roleGroup);
          if (selectedOperatorIds) {
            selectedOperatorIds.forEach(opId => {
              this.joinGroup(roleGroup + '_op' + opId);
            });
          }
        });

        if (this.authService.userHasAnyRole([UserRoles.UETrafikledare])) {
          this.joinGroup('org' + user.idTokenClaims.garageIds[0]);
        }
      })
      .catch(err => console.error(err.toString()));
  }

  joinGroup(group: string) {
    this._hubConnection.invoke('JoinGroup', group)
      .then(() => {
        // console.log('Joined signalr group: ' + group);
        // this.traceToAppInsights(group);
      })
      .catch(() => { });
  }

  traceToAppInsights(group: string) {
    const properties: { [name: string]: string } = {};
    if (this.user) {
      properties['user.profile.email'] = this.user.idTokenClaims.email as string;
      properties['user.profile.roles'] = this.user.idTokenClaims.role.toString();
      properties['notificationGroup'] = group.toString();
    }
    this.appInsightsService.logTrace('initSignalR', properties);
  }

  getNotifications(unreadOnly: boolean = false, numberOfMessages: number = 99): Observable<NotificationMessage[]> {
    const url = `${this.baseUrl}?unreadOnly=${unreadOnly}&numberOfMessages=${numberOfMessages}`;
    return this.http.get<NotificationMessage[]>(url);
  }

  addNotificationRead(notificationIds: number[]): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/UserNotificationRead`;
    const request = { notificationIds: notificationIds };
    return this.http.put(url, request, { observe: 'response' });
  }

  updateNotificationMessage(notification: NotificationMessage) {
    this.notificationMessageSource.next(notification);
  }

  updateUnreadNotifications(unreadNotifications: number) {
    this.unreadNotificationsCountSource.next(unreadNotifications);
  }

  getNotificationsMessage(): Observable<NotificationMessage> {
    return this.notificationMessage$;
  }

  getUnreadNotificationsCount(): Observable<number> {
    return this.unreadNotificationsCount$;
  }

  getStateChangedMessage(): Observable<StateChangedMessage> {
    return this.stateChangedMessage$;
  }

  openAlertModal(notification: NotificationMessage) {
    const opts: NgbModalOptions = { size: 'lg' };
    const modalRef = this.modalService.open(ConfirmModalComponent, opts);
    modalRef.componentInstance.title = notification.title;
    modalRef.componentInstance.description = notification.main;
    modalRef.componentInstance.confirmButton = $localize`:Öppna körorder@@notification-service.open:Öppna körorder`;
    modalRef.componentInstance.cancelButton = $localize`:Stäng dialog@@notification-service.close:Stäng dialog`;
    modalRef.result.then(() => {
      this.router.navigateByUrl('/orders');
    })
      .catch(() => { });
  }
}
