import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Operator } from '@models/operator';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss']
})
export class SidePanelComponent implements OnInit {
  @Input() operators: Operator[];
  @Output() operatorsEvent = new EventEmitter<any>();
  firstIsExpanded = true;

  constructor() { }

  ngOnInit(): void { }

  beforeChange($event: NgbPanelChangeEvent, acc) {
    if ($event.panelId === 'filter-panel') {
      this.firstIsExpanded = $event.nextState;
      if (!$event.nextState) {
        acc.expand('import-panel');
      }
    } else if ($event.panelId === 'import-panel') {
      this.firstIsExpanded = !$event.nextState;
      if (!$event.nextState) {
        acc.expand('filter-panel');
      }
    }
  }

}
