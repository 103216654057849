<div *ngIf="!!outcomeDriveOrder">
  <!--header row-->
  <!--Detail table view-->
  <form [formGroup]="outcomeDetailsForm" (ngSubmit)="save()" novalidate>
    <div *ngIf="!!outcomeDriveOrder">
      <div class="sticky-container">
        <div class="pb-3">
          <div class="row g-0 justify-content-sm-between">
            <div class="me-2 driveorder-caption">
              <p class="m-0">Körorder</p>
              <h2>{{outcomeDriveOrder.driveOrderId}}</h2>
            </div>
            <div class="driveorder-details">
              <app-driveorder-details captionText="Resor utan passagerare"
                [time]='outcomeDriveOrder?.totalNonCustomerCarrying.durationMinutes'
                [distance]='outcomeDriveOrder?.totalNonCustomerCarrying.distanceKm'
                i18n-captionText="@@outcome-details.without-passengers">
              </app-driveorder-details>
              <app-driveorder-details captionText="Tomkörning inom omlopp"
                [time]='outcomeDriveOrder?.totalDeadhead.durationMinutes'
                [distance]='outcomeDriveOrder?.totalDeadhead.distanceKm'
                i18n-captionText="@@outcome-details.deadhead-within-driveorder">
              </app-driveorder-details>
              <app-driveorder-details  captionText="Resor med passagerare"
                [time]='outcomeDriveOrder?.totalCustomerCarrying.durationMinutes'
                [distance]='outcomeDriveOrder?.totalCustomerCarrying.distanceKm'
                i18n-captionText="@@outcome-details.with-passengers">
              </app-driveorder-details>
            </div>
          </div>
        </div>
        <div id="table-container">
          <div class="d-flex triptable-header">
            <!--headerow-->
            <div class="outcome-details-small-column-width">
              <h4 i18n="@@outcome-details.type">Typ</h4>
            </div>
            <div class="outcome-details-large-column-width">
              <h4 i18n="@@outcome-details.start-trip-stop">Starthållplats</h4>
            </div>
            <div class="outcome-details-small-column-width">
              <h4 i18n="@@outcome-details.start-time">Starttid</h4>
            </div>
            <div class="outcome-details-large-column-width">
              <h4 i18n="@@outcome-details.end-trip-stop">Sluthållplats</h4>
            </div>
            <div class="outcome-details-small-column-width">
              <h4 i18n="@@outcome-details.end-time">Sluttid</h4>
            </div>
            <div class="outcome-details-small-column-width">
              <h4 i18n="@@outcome-details.duration">Tidsåtgång</h4>
            </div>
            <div class="outcome-details-small-column-width">
              <h4 i18n="@@outcome-details.distance">Distans</h4>
            </div>
            <div class="outcome-details-medium-column-width">
              <h4 i18n="@@outcome-details.passengers">Antal passagerare</h4>
            </div>
            <div class="outcome-details-medium-column-width">
              <h4 i18n="@@outcome-details.comment">Kommentar</h4>
            </div>
            <div class="outcome-details-small-column-width">
              <h4 i18n="@@outcome-details.status">Status</h4>
            </div>
            <!--Column with content but no title-->
            <div class="outcome-details-info-icon-column-width"></div>
          </div>
          <div *ngFor="let activity of outcomeDriveOrder.activities" class="triptable-row">
            <!--acitivitytrows-->
            <app-outcome-trip [activity]="activity" [editMode]="isEditMode" [orderDate]="orderDate"
              (addAlteredActivityEvent)="handleActivityAdd($event)" (rowFormGroup)="handleFormGroupChange($event)"
              (removeUnalteredActivityEvent)="handleActivityRemove($event)"></app-outcome-trip>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 ms-auto pt-3" *ngIf="userCanEditOutcome && isEditMode">
      <div class="d-flex px-1">
        <ng-container
          *ngIf="(outcomeDetailsForm.dirty || outcomeDetailsForm.touched) && formIsValidated && outcomeDetailsForm.invalid && outcomeDetailsForm.errors !== null">
          <div *ngIf="outcomeDetailsForm.errors['InvalidDate'] !== undefined" class="text-danger text-end col-12"
            i18n="@@outcome-details.error-times-mandatory">
            Vid redigering av start- och sluttid måste båda tider anges.
          </div>
          <div *ngIf="outcomeDetailsForm.errors['illegalEndTime'] !== undefined" class="text-danger text-end col-12"
            i18n="@@outcome-details.error-endtime">
            Sluttiden måste vara senare än starttiden eller mellan 00:00 och 03:59 nästa dag.
          </div>
          <div *ngIf="outcomeDetailsForm.errors['InvalidDistance'] !== undefined" class="text-danger text-end col-12"
            i18n="@@outcome-details.error-distance">
            Distans måste anges som numeriskt värde med upp till två decimaler.
          </div>
          <div *ngIf="outcomeDetailsForm.errors['InvalidPassengers'] !== undefined"
            class="text-danger text-end col-12" i18n="@@outcome-details.error-passengers">
            Passagerare måste anges som numeriskt värde mellan 0 och 150.
          </div>
          <div *ngIf="outcomeDetailsForm.errors['required'] !== undefined" class="text-danger text-end col-12"
            i18n="@@outcome-details.error-comment">
            Du måste fylla i en kommentar innan du kan spara dina ändringar.
          </div>
        </ng-container>
        <div
          *ngIf="(outcomeDetailsForm.pristine && outcomeDetailsForm.controls === null && formIsValidated && outcomeDetailsForm.errors === null) || (formIsValidated && alteredOutcomeActivities.length === 0 && outcomeDetailsForm.errors === null)"
          class="text-danger text-end col-12 pb-2" i18n="@@outcome-details.error-no-changes">
          Inga ändringar gjorda.
        </div>
      </div>
      <input id="outcomeDetailsSave" type="submit" class="btn btn-primary float-right p-2 m-1" value="Spara ändringar"
        i18n-value="@@outcome-details.save">
      <button id="outcomeDetailsReset" class="btn btn-outline-secondary float-right p-2 m-1" (click)="toggleEditMode(true)"
        i18n="@@outcome-details.reset">Återställ</button>
    </div>
    <div *ngIf="outcomeDriveOrder?.rejectionComment" class="col-5 callout-block-lg">
      <span class="callout"></span>
      <div class="d-flex">
        <div class="col">
          <div class="caption-lt" i18n="@@outcome-details.rejection-reason">Anledning till avslag</div>
          <div>
            {{outcomeDriveOrder?.rejectionComment}}
          </div>
        </div>
      </div>
    </div>
    <div class="ms-auto pt-3" *ngIf="userCanEditOutcome && !isEditMode">
      <button id="editDriveOrder" class="btn btn-primary float-end p-2 m-1" (click)="toggleEditMode(false)"
        i18n="@@outcome-details.edit-driveorder">Redigera körorder</button>
    </div>
    <div *ngIf="userCanApproveOrRejectOutcome" class="d-flex mt-3">
      <div class="min-content ms-auto me-3">
        <label for="rejectionCommentInput" class="d-flex ps-3" i18n="@@outcome-details.rejection-reason">Anledning till
          avslag</label>
        <textarea formControlName="rejectionComment" rows="4" cols="60" placeholder="Obligatoriskt vid avslag"
          id="rejectionCommentInput" i18n-placeholder="@@outcome-details.comment-placeholder">
        </textarea>
        <div class="red-text ms-1 mb-2">
          <div *ngIf="rejectionCommentMissing" i18n="@@outcome-details.comment-missing">
            Du måste fylla i en anledning innan du avslår utförarens ändringar.
          </div>
        </div>

        <div class="btn-group">
          <button id="rejectOutcomeDriveOrder" type="button" [disabled]="buttonsDisabled" class="btn btn-wide btn-outline-secondary me-3"
            (click)="reject()" i18n="@@outcome-details.reject">Avslå körorder</button>
          <button id="approveOutcomeDriveOrder" type="button" [disabled]="buttonsDisabled" class="btn btn-wide btn-primary" (click)="approve()"
            i18n="@@outcome-details.approve">Godkänn körorder</button>
        </div>
      </div>
    </div>
  </form>
</div>
