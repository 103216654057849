import { Input, Component, SimpleChanges, OnChanges, EventEmitter, Output } from '@angular/core';
import { OutcomeActivity, OutcomeActivityDataRequest, OutcomeDriveOrderWithActivities } from '../../../common/models/outcome';
import { OutcomeService } from '../../../common/services/outcome.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { AuthService } from '../../../common/services/auth.service';
import { OutcomeDriveOrderState } from '../../../common/models/outcome-state';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApproveDriveOrderComponent } from './approve-driveorder/approve-driveorder.component';
import { RejectDriveOrderComponent } from './reject-driveorder/reject-driveorder.component';
import { roundOffMinutes } from '@common/date-extension';

@Component({
  selector: 'app-outcome-details',
  templateUrl: './outcome-details.component.html',
  styleUrls: ['./outcome-details.component.scss']
})
export class OutcomeDetailsComponent implements OnChanges {

  Math = Math; // To access javascript math in template.

  @Input() outcomeDriveOrderId: number;
  @Input() orderDate: Date; // Used as default date for pullout/pullin when data is missing completely.
  @Input() userIsSubcontractorOrNobinaTrafficManager: boolean;
  @Input() userIsNobinaLeveransansvarig: boolean;
  @Output() wasUpdated = new  EventEmitter();

  outcomeDriveOrder: OutcomeDriveOrderWithActivities;
  outcomeActivitiesUnaltered: Array<OutcomeActivity>;
  alteredOutcomeActivities: Array<OutcomeActivityDataRequest>;
  OutcomeDriveOrderState = OutcomeDriveOrderState;
  isEditMode = false;
  outcomeDetailsForm: UntypedFormGroup;
  formIsValidated = false;
  formHasChanges = false;
  userCanEditOutcome = false;
  userCanApproveOrRejectOutcome = false;
  buttonsDisabled = false;
  rejectionCommentMissing = false;

  constructor(private outcomeService: OutcomeService, private fb: UntypedFormBuilder,
  private authService: AuthService, private modalService: NgbModal) {
    this.alteredOutcomeActivities = new Array<OutcomeActivityDataRequest>();
    this.outcomeDetailsForm = fb.group({
      rejectionComment: new UntypedFormControl('')
    });
  }

/*
* GET and POST
*/

  ngOnChanges(changes: SimpleChanges): void {
    if (this.outcomeDriveOrder !== null) {
      this.getOutcomeDriveOrderDetails();
    }
  }

  setUserCanEditOutcome() {
    this.userCanEditOutcome = (this.userIsSubcontractorOrNobinaTrafficManager && (
                                this.outcomeDriveOrder.currentState.stateEnum === OutcomeDriveOrderState.NotApprovedBySubcontractor ||
                                this.outcomeDriveOrder.currentState.stateEnum === OutcomeDriveOrderState.NotApprovedByNobina)) ||
                              (this.userIsNobinaLeveransansvarig && this.outcomeDriveOrder.subcontractorIsExternal === false &&
                                this.outcomeDriveOrder.currentState.stateEnum === OutcomeDriveOrderState.NotApprovedBySubcontractor);
  }

  setUserCanApproveOrRejectOutcome() {
    this.userCanApproveOrRejectOutcome = this.userIsNobinaLeveransansvarig && (
                                this.outcomeDriveOrder.currentState.stateEnum === OutcomeDriveOrderState.ApprovedBySubcontractor ||
                                this.outcomeDriveOrder.currentState.stateEnum === OutcomeDriveOrderState.ApprovedBySubcontractorVerificationRequired);
  }

  getOutcomeDriveOrderDetails() {
    this.outcomeService.getOutcomeDriveOrderWithActivities(this.outcomeDriveOrderId)
      .subscribe(result => {
        this.outcomeDriveOrder = result.body;
        this.outcomeActivitiesUnaltered = this.outcomeDriveOrder.activities.slice();
        roundOffMinutes([
          ...this.outcomeActivitiesUnaltered.flatMap(o => o.startTripStop?.time),
          ...this.outcomeActivitiesUnaltered.flatMap(o => o.endTripStop?.time),
          ...this.outcomeActivitiesUnaltered.flatMap(o => o.plannedRegistered?.startTripStop.time),
          ...this.outcomeActivitiesUnaltered.flatMap(o => o.plannedRegistered?.endTripStop.time)
        ]);
        this.outcomeDetailsForm.patchValue({rejectionComment: this.outcomeDriveOrder.rejectionComment });
        this.setUserCanEditOutcome();
        this.setUserCanApproveOrRejectOutcome();
        if (this.isEditMode) {
          this.toggleEditMode(true);
        }
      });
  }

  save() {
    this.validateForm();

    if (this.alteredOutcomeActivities.length > 0) {
      // save data and trigger update left menu
       if (this.outcomeDetailsForm.valid) {
      this.outcomeService.updateOutcomeActivitiesForDriveOrder(this.outcomeDriveOrder.id, this.alteredOutcomeActivities)
      .subscribe(result => {
        this.getOutcomeDriveOrderDetails();
        this.wasUpdated.emit();
      });
    }
    } else {
      this.outcomeDetailsForm.markAsPristine();
    }
  }

  approve() {
    this.confirm(ApproveDriveOrderComponent);
  }

  reject() {
    const rejectionCommentField = this.outcomeDetailsForm.controls['rejectionComment'];
    if (rejectionCommentField.value == null || rejectionCommentField.value === '') {
      this.rejectionCommentMissing = true;
    } else {
      this.rejectionCommentMissing = false;
      this.confirm(RejectDriveOrderComponent, rejectionCommentField.value);
    }
  }

  confirm(content: any, comment: string = null) {
    this.buttonsDisabled = true;
    const modalRef = this.modalService.open(content);
    modalRef.componentInstance.outcomeDriveOrderId = this.outcomeDriveOrderId;
    modalRef.componentInstance.rejectionComment = comment;
    modalRef.result.then(() => {
      this.getOutcomeDriveOrderDetails();
      this.wasUpdated.emit();
      this.buttonsDisabled = false;
    })
    .catch(reason => {
      this.buttonsDisabled = false;
    });
  }

/**
* Event related
*/

handleActivityAdd($event: OutcomeActivityDataRequest) {
    const index = this.alteredOutcomeActivities.findIndex(x => x.outcomeActionId === $event.outcomeActionId);
    if (index < 0) {
      this.alteredOutcomeActivities.push($event);
    } else {
      this.alteredOutcomeActivities[index] = $event;
    }
  }

  handleActivityRemove($event: number) {
    const index = this.alteredOutcomeActivities.findIndex(x => x.outcomeActionId === $event);
    if (index > -1) {
      this.alteredOutcomeActivities.splice(index, 1);
    }
  }

  handleFormGroupChange($event: UntypedFormGroup) {
    const controlName = 'formGroup_' + $event.controls['outcomeActionId'].value;
    const existingControl = this.outcomeDetailsForm.controls[controlName];
    if (existingControl !== undefined && existingControl !== null) {
      // Uppdatera existerande rad/formgroup
      this.outcomeDetailsForm.setControl(controlName, $event);
    } else {
      // Lägg till som ny rad/formgroup
      this.outcomeDetailsForm.registerControl(controlName, $event);
    }
  }


/**
* Template support
*/

  toggleEditMode(reset: boolean) {
    if (reset) {
      this.alteredOutcomeActivities = [];
      this.outcomeDriveOrder.activities = this.outcomeActivitiesUnaltered.slice();
      Object.keys(this.outcomeDetailsForm.controls).forEach(row => {
        const formGroup = this.outcomeDetailsForm.get(row) as UntypedFormGroup;
        if (formGroup.controls) {
          Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            control.setErrors(null, {emitEvent: false});
            control.markAsPristine();
          });
        }
      });
      this.outcomeDetailsForm.markAsPristine();
      this.formIsValidated = false;
    }
    this.isEditMode = !this.isEditMode;
  }

  validateForm() {
    this.formIsValidated = false;
    this.outcomeDetailsForm.setErrors(null, {emitEvent: false});
    Object.keys(this.outcomeDetailsForm.controls).forEach(row => {
      const formGroup = this.outcomeDetailsForm.get(row) as UntypedFormGroup;
      if (formGroup.controls) {
        Object.keys(formGroup.controls).forEach(field => {
          const control = formGroup.get(field);
          control.markAsTouched({ onlySelf: true });
          if (control.invalid) {
              this.outcomeDetailsForm.setErrors({...this.outcomeDetailsForm.errors, ...control.errors}, {emitEvent: false});
          }
        });
      }
    });
    this.formIsValidated = true;
  }
}
