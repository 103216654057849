import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OutcomeService } from '../../../common/services/outcome.service';

@Component({
  selector: 'app-outcome-billed',
  templateUrl: './outcome-billed.component.html',
  styleUrls: ['./outcome-billed.component.scss']
})
export class OutcomeBilledComponent implements OnInit {

  @Input() id: number;

  buttonsDisabled = false;

  constructor(public activeModal: NgbActiveModal, private outcomeService: OutcomeService) { }

  ngOnInit() {
  }

  bill() {
    this.buttonsDisabled = true;
    this.outcomeService.billOrderDriveOrder(this.id)
      .subscribe(response => this.activeModal.close(),
      err => {
         this.activeModal.dismiss();
      });
  }

}
