import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OutcomeService } from '../../../../common/services/outcome.service';
import { OutcomeDriveOrderState } from '../../../../common/models/outcome-state';
import { OutcomeDriveOrderStateRequest } from '../../../../common/models/outcome';

@Component({
  selector: 'app-approve-driveorder',
  templateUrl: './approve-driveorder.component.html',
  styleUrls: ['./approve-driveorder.component.scss']
})
export class ApproveDriveOrderComponent implements OnInit {

  @Input() outcomeDriveOrderId: number;

  buttonsDisabled = false;

  constructor(public activeModal: NgbActiveModal, private outcomeService: OutcomeService) { }

  ngOnInit() {
  }

  approve() {
    this.buttonsDisabled = true;
    const request: OutcomeDriveOrderStateRequest = { State: OutcomeDriveOrderState.Billable, comment: null };
    this.outcomeService.putOutcomeDriveOrderState(this.outcomeDriveOrderId, request)
      .subscribe(response => this.activeModal.close(),
      err => {
         this.activeModal.dismiss();
      });
  }

}
