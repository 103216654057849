import { Component, OnInit, OnDestroy } from '@angular/core';
import { Operator } from '../../common/models/operator';
import { OperatorService } from '../../common/services/operator.service';
import { UntypedFormGroup, UntypedFormBuilder, FormArray } from '@angular/forms';
import { AuthService } from '../../common/services/auth.service';
import { AdminService } from '../../common/services/admin.service';
import { NotificationService } from '../../common/services/notification.service';

@Component({
  selector: 'app-manage-operators',
  templateUrl: './manage-operators.component.html',
  styleUrls: ['./manage-operators.component.scss']
})
export class ManageOperatorsComponent implements OnInit {

  operatorForm: UntypedFormGroup;
  allOperators: Operator[];
  userStoredSelectedOperatorIds: number[];
  selectedOperatorIds: number[];
  displayedUserName = ''; // full name or, if name not set, email
  userSid: string;
  constructor(private fb: UntypedFormBuilder, private adminService: AdminService,
    private operatorService: OperatorService, private authService: AuthService,
    private notificationService: NotificationService, // Do not remove, initiates from constructor of service.
    ) {
    this.selectedOperatorIds = new Array<number>();
    this.userStoredSelectedOperatorIds = new Array<number>();
   }

  ngOnInit() {
    this.getUser();
    this.getOperators();
    this.createForm();

  }

  createForm() {
    this.operatorForm = this.fb.group({
    //  usersSelectedOperators: this.fb.array([this.fb.group(new Operator())])
    });
  }

  getOperators() {
    this.operatorService.getOperators(false, true)
    .subscribe(ops => {
      this.allOperators = ops.body;
    }, err => console.log('error ' + err));

    this.operatorService.getOperators(true, true)
    .subscribe(opsi => {
      this.userStoredSelectedOperatorIds = opsi.body.map(x => x.id);
      this.selectedOperatorIds = this.userStoredSelectedOperatorIds.slice();
      this.updateFormPristine();
    }, err => console.log('error ' + err));

  }

  getUser() {
    if (this.authService.user.idTokenClaims.name) {
      this.displayedUserName = this.authService.user.idTokenClaims.name;
    } else {
      this.displayedUserName = this.authService.user.idTokenClaims.email as string;
    }
    if (this.authService.user.idTokenClaims.sid) {
      this.userSid = this.authService.user.idTokenClaims.sid;
    }
  }

  toggleSelectedOperator(operator: Operator) {
    const index = this.selectedOperatorIds.indexOf(operator.id);
    if (index > -1) {
      this.selectedOperatorIds.splice(index, 1);
    } else {
      this.selectedOperatorIds.push(operator.id);
    }

    this.updateFormPristine();
  }

  updateFormPristine() {
    if (JSON.stringify(this.userStoredSelectedOperatorIds) === JSON.stringify(this.selectedOperatorIds)) {
    if (this.operatorForm !== undefined && 'markAsPristine' in this.operatorForm) { // <-- Workaround for magically mocked FormBuilder in test
      this.operatorForm.markAsPristine();
    }
    } else {
      if (this.operatorForm !== undefined && 'markAsDirty' in this.operatorForm) {
      this.operatorForm.markAsDirty();
    }
    }
  }


  save() {
    this.adminService.setUserOperatorSelection(this.selectedOperatorIds).subscribe(ops => {
      if (this.selectedOperatorIds.length === 0) {
        this.selectedOperatorIds = this.allOperators.map(x => x.id);
      }
      this.userStoredSelectedOperatorIds = this.selectedOperatorIds.slice();
      this.updateFormPristine();
      // Behövs för att SignlaR ska initialiseras med rätt grupper
      window.location.reload();
    }, err => console.log('error ' + err));
  }
}
