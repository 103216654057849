<div class="assign-container">
  <h2 class="mb-3" i18n="@@assign-driver.assign-driver">Tilldela förare</h2>

  <div class="row">
    <div class="col driver-col pt-4">
      <div class="row">
        <div class="col-2">
          <img src="/assets/img/icons/driver.png" alt="Icon driver"/>
        </div>
        <div class="col">
          <div class="caption-lt" i18n="@@assign-driver.assigned-driver">Tilldelad förare</div>
          <div>{{ assignedDriver?.firstName }} {{ assignedDriver?.lastName }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-2">
          <img src="/assets/img/icons/search.png" class="search-icon" alt="Icon search"/>
        </div>
        <div class="col">
          <input class="form-control" type="text" placeholder="Sök förare" #searchDriver (keyup)="0" i18n-placeholder="@@assign-driver.search-driver-placeholder"/>
        </div>
      </div>
      <div class="name-rows">
        <div class="row name-row" *ngFor="let driver of drivers | search:'firstName,lastName,phoneNumber':searchDriver.value; let i = index">
          <div class="col-2 button-col">
            <button *ngIf="selectedDriver?.id!=driver.id" id="{{'assignDriverBtn_'}}{{i + 1}}" (click)="selectedDriver=driver" class="small-button" i18n="@@assign-driver.select">Välj</button>
            <button *ngIf="selectedDriver?.id==driver.id" id="{{'assignedDriverBtn_'}}{{i + 1}}" (click)="selectedDriver=null" class="small-button active" i18n="@@assign-driver.selected">Vald</button>
          </div>
          <div class="col name-col">
            <div>{{ driver.firstName }} {{ driver.lastName }}</div>
            <div class="caption-lt">{{ driver.phoneNumber }}</div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="d-flex justify-content-end m-4">
    <button class="btn btn-outline-primary me-2" id="cancelAssignDriverBtn" (click)="activeModal.close({driver: assignedDriver})" [disabled]="buttonsDisabled" i18n="@@assign-driver.cancel">Avbryt</button>
    <button class="btn btn-primary" id="assignDriverBtn" (click)="assign()" [disabled]="buttonsDisabled || !selectedDriver" i18n="@@assign-driver.assign">Tilldela</button>
  </div>

</div>
