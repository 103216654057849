import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { Subcontractor } from '@models/subcontractor';
import { Operator } from '@models/operator';

@Component({
  selector: 'app-table-subcontractors',
  templateUrl: 'table-subcontractors.component.html',
  styleUrls: ['./table-subcontractors.component.scss']
})
export class TableSubcontractorsComponent implements OnChanges {
  @Input() operators: Operator[];
  @Input() subcontractors: Subcontractor[];
  @Output() saveEvent = new EventEmitter<boolean>();
  renderedSubcontractors = [];
  activeId: number = null;
  activeIdAfterSave: number = null;

  constructor() { }

  ngOnChanges() {
    this.resetRenderedSubcontractors();
    this.activeId = null;
    if (typeof this.activeIdAfterSave === 'number') {
      this.onClick(
        this.activeIdAfterSave,
        this.renderedSubcontractors[this.activeIdAfterSave].operators
      );
    }
  }

  onSaveEvent() {
    this.activeIdAfterSave = this.activeId;
    this.saveEvent.emit();
  }

  resetRenderedSubcontractors() {
    this.renderedSubcontractors = JSON.parse(JSON.stringify(this.subcontractors));
  }

  onClick = (idx: number, operators: Operator[]) => {
    const index = this.activeId !== null && idx > this.activeId ? idx - 2 : idx;
    this.resetRenderedSubcontractors();
    this.activeIdAfterSave = null;
    if (this.activeId === idx) {
      this.activeId = null;
    } else {
      this.activeId = index;
      this.renderedSubcontractors.splice(
        index + 1,
        0,
        { shadowElement: true }, { operators }
      );
    }
  }

}
