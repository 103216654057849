import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { MyInfoResponse } from './admin-response';
import { User } from '@models/user';
import { Version } from '@models/version';


@Injectable()
export class AdminService {
  private baseUrl = environment.apiUrl + environment.apiVersionMain + '/admin';
  private outcomeUrl = environment.outcomeUrl + environment.apiVersionOutcome + '/admin';


  constructor(private http: HttpClient) {
  }

  getMyOrganization(): Observable<HttpResponse<MyInfoResponse>> {
    const url = `${this.baseUrl}/MyInfo`;
    return this.http.get<MyInfoResponse>(url, { observe: 'response' });
  }

  getUser(): Observable<HttpResponse<User>> {
    const url = `${this.baseUrl}/User`;
    return this.http.get<User>(url, { observe: 'response' });
  }

  setUserOperatorSelection(operatorIds: number[]): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/UserOperators`;
    const req = new UserOperationSelectionRequest();
    req.OperatorIds = operatorIds;
    return this.http.put<UserOperationSelectionRequest>(url, req, { observe: 'response' });
  }

  addOMSGarage(omsGarageCode: string): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/AddOmsGarage`;
    const req = { omsGarageCode: omsGarageCode };
    return this.http.post<string>(url, req, { observe: 'response' });
  }

  changeSubcontractorOperators(subcontractorId: number, operatorIds: Array<number>): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/ChangeSubcontractorOperators`;
    const req = new ChangeSubcontractorOperatorsRequest(subcontractorId, operatorIds);
    return this.http.post<ChangeSubcontractorOperatorsRequest>(url, req, { observe: 'response' });
  }

  updateSubcontractor(id: number, subcontractorVacantType): Observable<HttpResponse<any>> {
    const url = `${this.baseUrl}/UpdateSubcontractor`;
    const req = { id, subcontractorVacantType };
    return this.http.post<ChangeSubcontractorOperatorsRequest>(url, req, { observe: 'response' });
  }

  getMainServiceVersion(): Observable<HttpResponse<Version>> {
    const url = `${this.baseUrl}/version`;
    return this.http.get<Version>(url, { observe: 'response' });
  }

  getOutcomeServiceVersion(): Observable<HttpResponse<Version>> {
    const url = `${this.outcomeUrl}/version`;
    return this.http.get<Version>(url, { observe: 'response' });
  }

}

export class UserOperationSelectionRequest {
  OperatorIds: number[];
}

export class ChangeSubcontractorOperatorsRequest {
  constructor(subcontractorId: number, operatorIds: number[]) {
    this.SubcontractorId = subcontractorId;
    this.OperatorIds = operatorIds;
  }

  SubcontractorId: number;
  OperatorIds: number[];
}


