import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OutcomeService } from '../../../common/services/outcome.service';

@Component({
  selector: 'app-outcome-billed-contractor',
  templateUrl: './outcome-billed-contractor.component.html',
  styleUrls: ['./outcome-billed-contractor.component.scss']
})
export class OutcomeBilledContractorComponent implements OnInit {

  @Input() id: number;

  buttonsDisabled = false;

  constructor(public activeModal: NgbActiveModal, private outcomeService: OutcomeService) { }

  ngOnInit() {
  }

  bill() {
    this.buttonsDisabled = true;
    this.outcomeService.billSubcontractorDriveOrder(this.id)
      .subscribe(response => this.activeModal.close(),
      err => {
         this.activeModal.dismiss();
      });
  }

}
